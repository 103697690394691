import React, { useState, useEffect } from "react";
import "./login.css";
import { Box } from "@mui/material";
import axios from "axios";
import Dashboard from "../../components/Dashboard/PostLoginDashboard";
import jwt_decode from "jwt-decode";


const Ingreso = () => {
  const token = window.sessionStorage.getItem("jwt");
  
  const [logo, setLogo] = useState([]);
  const [user, setUser] = useState(false);
  const [showDashboard, setShowDashboard] = useState(false);
  const [userInfo, setUserInfo] = React.useState({});
 

  const getLogoTerminal = async () => {
    axios
      .get("https://terminaldigital.com.ar/api/administrarterminal")
      .then((data) => {
        setLogo(data.data.terminal.logo);
      })
      .catch((error) => console.log("error users", error));
  };

  useEffect(() => {
    getLogoTerminal();
  }, []);

  useEffect(() => {
    if (sessionStorage.getItem("jwt")) {
      setUser(true);
      setTimeout(() => {
        setShowDashboard(true);
      }, 1000);
    } else return setUser(false);
    if (token) {
      const tokenDecoded = jwt_decode(token);
      const { rol } = tokenDecoded;
      setUserInfo((state) => ({ ...state, rol }));
    }
  }, [token]);
  

  return (
    user && userInfo.rol === 3 ? (
      <Box sx={{ minHeight: "68vh", width: "100%", display: "flex", background: "white" }}>
        <Box
          width={{ xs: "100%", sm: "100%", md: "100%", lg: "100%" }}
          height={{ xs: "100%", sm: "100%", md: "100%", lg: "100%" }}
        >
          <Dashboard />
        </Box> 
      </Box>
    ) : (
      <Box sx={{ minHeight: { xs: "25vh", sm: "80vh", md: "67vh", lg: "70.2vh" }, display: "flex", background: "white" }}>
        <Box
          component="img"
          alt={logo}
          src={logo}
          sx={{
            margin: "auto",
            height: "auto",
            maxWidth: "100vw",
            width: "100vh",
          }}
        />
      </Box>
    )
  );
};

export default Ingreso;