import { React, useEffect, useState } from 'react';
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import styledComponents, { keyframes, css } from 'styled-components';

import Typography from "@mui/material/Typography";

const textTranslateState = [
  "Estado", // Español
  "State", // Inglés
  "Estado", // Portugués
  "État", // Francés
  "Zustand", // Alemán
  "Stato", // Italiano
  "状态", // Chino (Taiwán)
  "상태", // Coreano
  "状態", // Japonés
  "מַצָב", // Hebreo
  "الحالة", // Árabe
].map(text => text.toUpperCase());

const textTranslateOrigin = [
  "Origen", // Español
  "Origin", // Inglés
  "Origem", // Portugués
  "Origine", // Francés
  "Herkunft", // Alemán
  "Origine", // Italiano
  "起源", // Chino (Taiwán)
  "원산지", // Coreano
  "起源", // Japonés
  "מָקוֹר", // Hebreo
  "الأصل", // Árabe
].map(text => text.toUpperCase());


const textTranslateCompany = [
  "Empresa", // Español
  "Company", // Inglés
  "Empresa", // Portugués
  "Entreprise", // Francés
  "Unternehmen", // Alemán
  "Azienda", // Italiano
  "公司", // Chino (Taiwán)
  "회사", // Coreano
  "会社", // Japonés
  "חֲבָרָה", // Hebreo
  "الشركة", // Árabe
].map(text => text.toUpperCase());

const textTranslatePlatform = [
  "Plataforma", // Español
  "Platform", // Inglés
  "Plataforma", // Portugués
  "Plateforme", // Francés
  "Plattform", // Alemán
  "Piattaforma", // Italiano
  "平台", // Chino (Taiwán)
  "플랫폼", // Coreano
  "プラットフォーム", // Japonés
  "פְּלַטְפוֹרְמָה", // Hebreo
  "منصة", // Árabe
].map(text => text.toUpperCase());

const textTranslateInternal = [
  "Interno", // Español
  "Internal", // Inglés
  "Interno", // Portugués
  "Interne", // Francés
  "Intern", // Alemán
  "Interno", // Italiano
  "內部", // Chino (Taiwán)
  "내부", // Coreano
  "内部", // Japonés
  "פְּנִימִי", // Hebreo
  "داخلي", // Árabe
].map(text => text.toUpperCase());


export default function TableArrivals({ props }) {

  const { height } = props
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % textTranslateOrigin.length);
    }, 30000);

    return () => clearInterval(intervalId);
  }, []);

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#0e315a",
      height: height,
    },
    [`&.${tableCellClasses.body}`]: {},
    [`&.${tableCellClasses.root}`]: {
      borderBottom: "none",
      fontSize: "0.93rem",
      color: "white",
      fontWeight: "bold",
      padding: "10px",
      marginLeft: "2rem",
    },
  }));
  const StyledTableCell2 = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#0e315a",
      height: height,
    },
    [`&.${tableCellClasses.body}`]: {},
    [`&.${tableCellClasses.root}`]: {
      borderBottom: "none",
      fontSize: 20,
      color: "white",
      fontWeight: "bold",
      padding: "10px",
      marginLeft: "2rem",
    },
  }));
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: "#1C68C0",
    },
    "&:nth-of-type(even)": {
      backgroundColor: "#124178",
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
    height: "77px",
    paddingLeft: "2rem",
  }));

  const blink = keyframes`
    0% {color: #ff3333; font-size: 18px; text-shadow: 0.5px 0.5px 0.5px #ffffff;}
    25% {color: #ffffff; font-size: 18px; text-shadow: 0.5px 0.5px 0.5px #000000;}
    50% {color: #ff3333; font-size: 18px; text-shadow: 0.5px 0.5px 0.5px #ffffff;}
    75% {color: #ffffff; font-size: 18px; text-shadow: 0.5px 0.5px 0.5px #000000;}
    100% {color: #ff3333; font-size: 18px; text-shadow: 0.5px 0.5px 0.5px #ffffff;}
  `;

  const blinkWarning = keyframes`
    0% {color: #FFA500; font-size: 18px; text-shadow: 0.5px 0.5px 0.5px #ffffff;}
    25% {color: #ffffff; font-size: 18px; text-shadow: 0.5px 0.5px 0.5px #000000;}
    50% {color: #FFA500; font-size: 18px; text-shadow: 0.5px 0.5px 0.5px #ffffff;}
    75% {color: #ffffff; font-size: 18px; text-shadow: 0.5px 0.5px 0.5px #000000;}
    100% {color: #FFA500; font-size: 18px; text-shadow: 0.5px 0.5px 0.5px #ffffff;}
  `;

  const BlinkingText = styledComponents(Typography)`
  ${props => props.blink ? css`animation: ${props.status === 'CANCELADO' ? blink : blinkWarning} 10s linear infinite;` : ''}
`;

  return (
    <TableContainer
      component={Paper}
      sx={{ borderRadius: "0px", background: "#0e315a" }}
    >
      <Table
        sx={{ fontSize: "20px", height: "100%" }}
        aria-label="customized table"
      >
        <TableHead >
          <TableRow>
            <StyledTableCell align="center">
              <Typography fontSize={{ xs: "10px", sm: "15px", md: "20px" }}>
                {textTranslateState[0]}
              </Typography>
              {index !== 0 &&
                <Typography fontSize={{ xs: "8px", sm: "10px", md: "15px", lg: "15px" }}>
                  ({textTranslateState[index]})
                </Typography>
              }
            </StyledTableCell>
            <StyledTableCell2 align="center">
              <Typography fontSize={{ xs: "10px", sm: "15px", md: "20px", lg: "20px" }}>
                {textTranslateOrigin[0]}
              </Typography>
              {index !== 0 &&
                <Typography fontSize={{ xs: "8px", sm: "10px", md: "15px", lg: "15px" }}>
                  ({textTranslateOrigin[index]})
                </Typography>
              }
            </StyledTableCell2>
            <StyledTableCell2 align="center">
              <Typography fontSize={{ xs: "10px", sm: "15px", md: "20px" }}>
                {textTranslateCompany[0]}
              </Typography>
              {index !== 0 &&
                <Typography fontSize={{ xs: "8px", sm: "10px", md: "15px", lg: "15px" }}>
                  ( {textTranslateCompany[index]} )
                </Typography>
              }
            </StyledTableCell2>
            <StyledTableCell2 align="center">
              <Typography fontSize={{ xs: "10px", sm: "15px", md: "20px" }}>
                {textTranslatePlatform[0]}
              </Typography>
              {index !== 0 &&
                <Typography fontSize={{ xs: "8px", sm: "10px", md: "15px", lg: "15px" }}>
                  ( {textTranslatePlatform[index]} )
                </Typography>
              }
            </StyledTableCell2>
            <StyledTableCell2 align="center">
              <Typography fontSize={{ xs: "10px", sm: "15px", md: "20px" }}>
                {textTranslateInternal[0]}
                {index !== 0 &&
                  <Typography fontSize={{ xs: "8px", sm: "10px", md: "15px", lg: "15px" }}>
                    ( {textTranslateInternal[index]} )
                  </Typography>
                }
              </Typography>
            </StyledTableCell2>
          </TableRow>
        </TableHead>
        <TableBody>
          {props &&
            props.map((row) => (
              <StyledTableRow key={row.id}>
                <StyledTableCell align="center">
                  {row.demora === 1 ? (
                    <BlinkingText blink status="DEMORADO" color="#FFA500">
                      DEMORADO (
                        {(() => {
                          const demora = row.tiempo_demora;
                          let valor;
                          let unidad;
                          if (demora >= 2 && demora <= 59) {
                            valor = demora;
                            unidad = 'minutos';
                          } else if (demora == 1) {
                            valor = demora;
                            unidad = 'minuto';
                          } else if (demora == 60) {
                            valor = 1;
                            unidad = 'hora';
                          } else if (demora > 60) {
                            valor = demora / 60;
                            unidad = 'horas';
                          }
                          return `${valor} ${unidad}`;
                        })()}
                      )
                    </BlinkingText>
                  ) : row.cancelado === 1 ? (
                    <BlinkingText blink status="CANCELADO" color="red">
                      CANCELADO
                    </BlinkingText>
                  ) : (
                    <Typography fontSize={{ xs: "10px", sm: "15px", md: "18px" }}>
                      {row.registro_estado.tipo.toUpperCase()}
                    </Typography>
                  )}
                </StyledTableCell>
                <StyledTableCell align="center">
                  <Typography
                    fontSize={{ xs: "10px", sm: "15px", md: "18px" }}
                    textTransform="uppercase"
                  >
                    {row.destino}
                  </Typography>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <Typography fontSize={{ xs: "10px", sm: "15px", md: "18px" }}>
                    {row.registro_empresa.empresa}
                  </Typography>
                </StyledTableCell>
                <StyledTableCell align="center" fontSize={{ xs: 10 }}>
                  <Typography fontSize={{ xs: "10px", sm: "15px", md: "18px" }}>
                    {row.registro_plataforma.plataforma}
                  </Typography>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <Typography fontSize={{ xs: "10px", sm: "15px", md: "18px" }}>
                    {row.interno}
                  </Typography>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          {/* <br></br>
          <br></br>
          <br></br> */}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
