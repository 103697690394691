import React, { useState, useEffect } from "react";
import { useMutation, useQueryClient } from 'react-query';
import { makeStyles } from '@material-ui/core/styles';
import {
  Autocomplete,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  FormLabel,
  Box
} from "@mui/material";
import { Stack } from "@mui/system";
import { useFormik } from "formik";
import * as yup from "yup";
import axios from "axios";
import { Link } from "react-router-dom";
import ReplyIcon from "@mui/icons-material/Reply";
import data1 from "../../json/provincias.json";
import data2 from "../../json/departamentos.json";
import data3 from "../../json/municipios.json";
import { toast } from 'react-toastify';


const getSuggestions = (value, locations) => {
  const inputValue = value.trim().toLowerCase();
  const inputLength = inputValue.length;

  return inputLength === 0 ? [] : locations.filter(location =>
    location.toLowerCase().slice(0, inputLength) === inputValue
  );
};

const getSuggestionValue = suggestion => suggestion;

const useStyles = makeStyles({
  inputRoot: {
    color: 'black',
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: 'black'
    },
  },
});

const FormTicket = () => {
  const classes = useStyles();
  const [suggestions, setSuggestions] = useState([]);
  const [autocompleteKey, setAutocompleteKey] = useState(0);
  const [dataDropdown, setDataDropdown] = useState({});
  const [valueEmpresa, setValueEmpresa] = useState(null);
  const [valueInterurbano, setValueInterurbano] = useState("L");
  const [valueServicio, setValueServicio] = useState(null);
  const [unidades, setUnidades] = useState([]);
  const [dominio, setDominio] = useState(null);
  const [displayDominio, setDisplayDominio] = useState("true");
  const [displayDominioNuevo, setDisplayDominioNuevo] = useState("true");
  const [destino, setDestino] = useState('');
  const [expanded, setExpanded] = React.useState(false);
  const handleChangeExpand = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
    setDisplayDominioNuevo(panel === "panel1" ? "false" : "true");
    setDisplayDominio(panel === "panel2" ? "false" : "true");
  };

  const token = sessionStorage.getItem("jwt");
  const config = { headers: { authorization: `Bearer ${token}` } };


  const dateNow = new Date();
  const menosUno = 1;
  dateNow.setHours(menosUno);
  const year = dateNow.getFullYear();
  const monthWithOffset = dateNow.getUTCMonth() + 1;
  const month =
    monthWithOffset.toString().length < 2
      ? `0${monthWithOffset}`
      : monthWithOffset;
  const date =
    dateNow.getUTCDate().toString().length < 2
      ? `0${dateNow.getUTCDate()}`
      : dateNow.getUTCDate();

  const getHour = () => {
    const dateNow = new Date();
    let hours = dateNow.getHours();

    if (hours < 10) {
      hours = 0 + `${hours}`;
      return hours;
    } else {
      hours = `${hours}`;
      return hours;
    }
  };
  const getGridSize = (displayDominioNuevo, displayDominio) => {
    return displayDominioNuevo === "true" && displayDominio === "true" ? { sm: 12, md: 6, lg: 2 } : { sm: 6, md: 3.5, lg: 2 };
  };
  const handleAutocompleteChange = (event, newValue) => {
    if (newValue === null || typeof newValue === 'string') {
      setDominio(newValue);
      formik.setFieldValue('dominio', newValue || '');
    } else if (newValue && newValue.inputValue) {
      setDominio(newValue.inputValue);
      formik.setFieldValue('dominio', newValue.inputValue);
    } else if (newValue) {
      setDominio(newValue.dominio);
      formik.setFieldValue("dominio", newValue.dominio);
      formik.setFieldValue("servicios_id", newValue.servicios_id);
      setValueServicio(newValue.servicios_id);
      formik.setFieldValue("empresa_id", newValue.empresa_id);
      formik.setFieldValue("interno", newValue.interno);
      setValueEmpresa(newValue.empresas);
    } else {
      formik.setFieldValue('dominio', '');
    }
  };
  const getMinutes = () => {
    const dateNow = new Date();
    let minutes = dateNow.getMinutes();

    if (minutes < 10) {
      minutes = 0 + `${minutes}`;
      return minutes;
    } else {
      minutes = `${minutes}`;
      return minutes;
    }
  };

  const getInitialTicket = () => {
    return {
      fecha_ingreso: `${year}-${month}-${date}`,
      hora_ingreso: `${getHour()}:${getMinutes()}`,
      interno: "",
      empresa_id: valueEmpresa,
      servicios_id: dominio === null ? null : `${valueServicio}`,
      estado_id: "2",
      destino: "",
      tipo_tv_id: 3,
      interurbano: "L",
      operacion_id: 1,
      dominio: dominio,
    };
  };

  const resetForm = () => {
    setValueEmpresa(null);
    setValueInterurbano("L");
    setDominio(null);
    setDisplayDominio("true");
    setDisplayDominioNuevo("true");
    formik.resetForm({
      values: getInitialTicket(),
    });
    formik.setFieldValue('dominio', '');
    formik.setFieldValue('destino', ''); 
  };

  function handleClick(event) {
    if (event.target.value === valueInterurbano) {
      setValueInterurbano("");
    } else {
      setValueInterurbano(event.target.value);
    }
  }

  const validationSchema = yup.object({
    fecha_ingreso: yup.string().required("Campo requerido"),
    hora_ingreso: yup.string().required("Campo requerido"),
    interno: yup.number().required("Campo requerido"),
    empresa_id: yup.number().required("Campo requerido"),
    servicios_id: yup.string().required("Campo requerido"),
    estado_id: yup.string().required("Campo requerido"),
    destino: yup.string().required("Campo requerido"),
    dominio: yup.string().required("Campo requerido"),
  });

  React.useEffect(() => {
    const url = "https://terminaldigital.com.ar/api/informes/dataDropdown";
    axios
      .get(url, config)
      .then((response) => setDataDropdown(response.data))
      .catch((error) => console.log("error jwt:", error.response.data.message));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const getUnit = () => {
    axios
      .all([
        axios.get("https://terminaldigital.com.ar/api/empresas/unidadesPorDominio", {
          headers: { authorization: `Bearer ${token}` },
        }),
      ])
      .then((data) => {
        setUnidades(data[0].data.unidades);
      })
      .catch((error) => console.log("error jwt:", error.response.data.message));
  };

  useEffect(() => {
    getUnit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const queryClient = useQueryClient();

  const mutation = useMutation(
    values => axios.post("https://terminaldigital.com.ar/api/informes/nuevoRegistro", values, config),
    {
      onSuccess: (data, variables) => {
        if (data.status === 200) {
          setValueEmpresa(null);
          setValueInterurbano(null);
          setDominio(null);
          setDisplayDominio("true");
          setDisplayDominioNuevo("true");
          formik.resetForm({
            values: getInitialTicket(),
          });
          formik.setFieldValue("dominio", null);
          setAutocompleteKey(prevKey => prevKey + 1);
          toast.success('Registro creado con éxito', { autoClose: 3000 })
        }
      },
      onError: (error) => {
        console.log("Error:", error);
        toast.error('Error al crear el registro', { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 5000 })
      },
      onSettled: () => {
        queryClient.invalidateQueries('tickets');
      },
    }
  );

  const formik = useFormik({
    validateOnChange: true,
    initialValues: getInitialTicket(),
    validationSchema: validationSchema,
    onSubmit: (values) => {
      mutation.mutate(values);
    },
    validateOnMount: true,
    validateOnChange: true,
  });

  const inputStyle = {
    ".MuiOutlinedInput-notchedOutline": {
      borderColor: "black",
      transition: "250ms all ease",
    },
    ".MuiInputBase-root": {
      color: "black",
      transition: "250ms all ease",
    },
    ".MuiSvgIcon-root": {
      color: "black",
    },
    ".css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root": {
      color: "black",
    },
    "input:hover": {
      background: "transparent",

    },
    ".MuiInputBase-root:hover ": {


      boxShadow: " inset 0 0 9px rgb(63, 100, 143)",
    },
    ".MuiInputBase-root:hover .MuiSvgIcon-root": {
      color: "rgb(19, 46, 77)",
      border: "none",
    },
    ".css-md26zr-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
    {
      border: "none",
    },
    ".css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
    {
      borderColor: "black",
    },
  };

  const style = {
    background: "#0e315a",
    color: "black",
    ".MuiAccordionSummary-root .MuiSvgIcon-root": {
      color: "black",
      fontSize: { xs: "2rem", md: "1.5rem" },
    },
    "Mui-expanded": {
      margin: "0px",
    },
    "&:focus": {
      background: "#0e315a",
    },
    fontSize: { xs: "15px", md: "15px" },
  };
  const styleSearchD = {
    background: "#0e315a",
    color: "black",
    ".MuiAccordionSummary-root .MuiSvgIcon-root": {
      color: "black",
      fontSize: { xs: "2rem", md: "1.5rem" },
    },
    "Mui-expanded": {
      margin: "0px",
    },
    "&:focus": {
      background: "#0e315a",
    },
    fontSize: { xs: "15px", md: "15px" },
    display: displayDominio === "true" ? "block" : "none",
  };
  const styleNewD = {
    background: "#0e315a",
    color: "black",
    ".MuiAccordionSummary-root .MuiSvgIcon-root": {
      color: "black",
      fontSize: { xs: "2rem", md: "1.5rem" },
    },
    "Mui-expanded": {
      margin: "0px",
    },
    "&:focus": {
      background: "#0e315a",
    },
    fontSize: { xs: "15px", md: "15px" },
    display: displayDominioNuevo === "true" ? "block" : "none",
  };

  const locations1 = [...new Set(data1.provincias.map(provincia => provincia.nombre))];
  const locations2 = [...new Set(data2.departamentos.map(departamento => `${departamento.nombre} (${departamento.provincia.nombre})`))];
  const locations3 = [...new Set(data3.municipios.map(municipio => `${municipio.nombre} (${municipio.provincia.nombre})`))];

  const locations = [...new Set([...locations1, ...locations2, ...locations3])];
  
  const normalize = (str) => {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  };

  const getSuggestions = (value) => {
    const inputValue = normalize(value.trim().toLowerCase());
    return inputValue.length === 0 ? [] : locations.filter(location =>
      normalize(location.toLowerCase()).includes(inputValue)
    );
  };

  const onSuggestionsFetchRequested = ({ value }) => {
    setSuggestions(getSuggestions(value, locations));
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const renderSuggestion = suggestion => (
    <div>
      {suggestion}
    </div>
  );

  return (
    <Stack
      sx={{ background: "#F4F2EC", borderRadius: "25px", shadow: 4 }}
      my={4}
      mx={{ xs: 1, sm: 6 }}
      p={4}
      sm={6}
    >
      <form onSubmit={formik.handleSubmit}>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Link to="/seguridad/control-de-accesos">
            <Button
              sx={{
                color: "#b22222",
                marginLeft: { xs: "230%", sm: "90%", md: "1095%" },
              }}
              variant="outlined"
              color="error"
              position="fixed"
            >
              <ReplyIcon />
            </Button>
          </Link>
          <Typography
            sx={{
              fontSize: { lg: "2rem" },
              fontFamily: 'Lato, sans-serif',
              color: '#0e315a', 
              textShadow: '2px 2px 2px rgba(0, 0, 0, 0.25)', 
              display: 'flex',
              justifyContent: { xs: "left", md: 'center' },
              marginLeft: { xs: "-85px", md: "0" },
            }}
          >
            Crear registros
          </Typography>
        </Box>
        <Grid container my={4}>
          <Grid
            item
            display={{ xs: "block", sm: "flex" }}
            alignItems="center"
            gap={2}
            xs={12}
            sm={12}
            md={6}
            lg={3}
            my={1}
          >
            <Autocomplete
              key={autocompleteKey}
              value={formik.values.dominio}
              onChange={handleAutocompleteChange}
              onInputChange={(event, newInputValue) => {
                formik.setFieldValue('dominio', newInputValue);
              }}
              freeSolo
              options={unidades}
              getOptionLabel={(option) => typeof option === 'string' ? option : option.dominio}
              isOptionEqualToValue={(option, value) => option === value}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Dominio"
                  error={formik.errors.dominio}
                  helperText={formik.errors?.dominio ? "Campo requerido" : null}
                  variant="outlined"
                  InputProps={{
                    ...params.InputProps,
                    classes: {
                      root: classes.inputRoot,
                    },
                  }}
                  InputLabelProps={{
                    style: { color: 'black' },
                  }}
                />
              )}
              style={{ width: '80%' }}
            />
          </Grid>
          <Grid
            item
            display={{ xs: "block", sm: "flex" }}
            alignItems="center"
            gap={2}
            xs={6}
            sm={4}
            md={3}
            lg={4}
            my={1}
          >
            <TextField
              sx={inputStyle}
              InputProps={{
                type: "text",
                style: {
                  color: "black",
                },
              }}
              value={formik.values.interno}
              name="interno"
              onChange={formik.handleChange}
              error={formik.errors.interno}
              helperText={formik.errors.interno ? "Campo requerido" : null}
              label="Interno"
              InputLabelProps={{
                style: { color: 'black', fontSize: "16px" },
              }}
            />
          </Grid>
          <Grid
            item
            display={{ xs: "none", sm: "none" }}
            alignItems="center"
            gap={2}
            xs={6}
            sm={6}
            lg={12}
            my={2}
          >
            <Typography
              variant="subtitle1"
              color="black"
              mb={{ xs: 1, sm: 0 }}
              display={{ xs: "none", sm: "block" }}
            >
              Tipo tv id:
            </Typography>
            <TextField
              sx={{
                ".MuiOutlinedInput-notchedOutline": {
                  borderColor: "black",
                },
                ".MuiInputBase-root": {
                  color: "black",
                },
              }}
              InputProps={{
                type: "number",
              }}
              defaultValue={3}
              name="tipo_tv_id"
            />
          </Grid>
          <Grid
            item
            display={{ xs: "block", sm: "flex", md: "flex", lg: "flex" }}
            alignItems="center"
            gap={2}
            xs={12}
            sm={6.4}
            md={5}
            lg={12}
            my={2}
            pr={{ xs: 0, md: 8 }}
          >
            {dataDropdown.empresas && (
              <Autocomplete
                fullWidth
                clearOnEscape
                value={valueEmpresa}
                options={dataDropdown.empresas}
                sx={{ ...inputStyle, width: { xs: "80%", md: "21%" } }}
                onChange={(event, newValue) => {
                  // eslint-disable-next-line no-unused-expressions
                  newValue === null
                    ? setValueEmpresa(newValue) +
                      formik.setFieldValue("empresa_id", newValue)
                    : setValueEmpresa(newValue) +
                      formik.setFieldValue("empresa_id", newValue.id);
                }}
                isOptionEqualToValue={(option, value) => option === value}
                getOptionLabel={(option) => option.empresa}
                renderInput={(params) => (
                  <TextField
                    error={formik.errors.empresa_id}
                    helperText={formik.errors?.empresa_id ? "Campo requerido" : null}
                    {...params}
                    label="Seleccione una empresa"
                    InputLabelProps={{
                      style: { color: 'black' },
                    }}
                  />
                )}
              />
            )}
          </Grid>
          <Grid
            item
            alignItems="center"
            gap={2}
            xs={12}
            sm={12}
            lg={12}
            my={2}
            pr={{ xs: 0, md: 2 }}
          >
            <FormControl error={formik.errors?.servicios_id}>
              <Typography
                variant="subtitle1"
                style={{ marginBottom: "3px" }}
                color="black"
              >
                Tipo de Servicio:
              </Typography>
              <RadioGroup
                row
                aria-labelledby="Estado"
                defaultValue={null}
                sx={inputStyle}
                fullWidth
                name="servicios_id"
                value={formik.values.servicios_id}
                error={formik.errors?.servicios_id}
                helperText={formik.errors?.servicios_id ? "Campo requerido" : null}
                onChange={formik.handleChange}
                style= {{marginLeft: "20px", display: "flex", flexDirection: "row"}}
              >
                <FormControlLabel
                  value={"2"}
                  control={<Radio />}
                  sx={{ color: "black" }}
                  label="Larga Distancia"
                />
                <FormControlLabel
                  value={"1"}
                  control={<Radio />}
                  sx={{ color: "black" }}
                  label="Media Distancia"
                />
                <FormControlLabel
                  value={"3"}
                  control={<Radio />}
                  sx={{ color: "black" }}
                  label="Corta Distancia"
                />
                <FormControlLabel
                  value={"4"}
                  control={<Radio />}
                  sx={{ color: "black" }}
                  label="Servicio Externo"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid
            item
            alignItems="center"
            gap={2}
            xs={12}
            sm={5}
            md={6}
            lg={12}
            pr={{ xs: 0, md: 2 }}
            my={3}
          >
            <Autocomplete
              key={autocompleteKey}
              freeSolo
              id="combo-box-demo"
              options={locations}
              getOptionLabel={(option) => option}
              style={{ width: 300 }}
              value={formik.values.destino || ''}
              onInputChange={(event, newValue) => {
                formik.setFieldValue('destino', newValue);
              }}
              isOptionEqualToValue={(option, value) => option === value}
              renderInput={(params) =>
                <TextField
                  {...params}
                  error={formik.errors.destino}
                  helperText={formik.errors?.destino ? "Campo requerido" : null}
                  variant="outlined"
                  InputProps={{
                    ...params.InputProps,
                    classes: {
                      root: classes.inputRoot,
                    },
                  }}
                  label="Inserte destino / origen / servicio"
                  InputLabelProps={{
                    style: { color: 'black' },
                  }}
                />
              }
            />
          </Grid>
          <Grid
            item
            display={{ xs: "block", sm: "flex" }}
            alignItems="center"
            gap={2}
            xs={8}
            sm={6}
            md={4.5}
            lg={2}
            my={2}
          >
            <TextField
              sx={inputStyle}
              InputProps={{
                type: "date",
              }}
              name="fecha_ingreso"
              value={formik.values.fecha_ingreso}
              onChange={formik.handleChange}
              error={formik.errors.fecha_ingreso}
              helperText={formik.errors?.fecha_ingreso}
              label="Fecha de ingreso"
              InputLabelProps={{
                style: { color: 'black', fontSize: "16px" },
              }}
            />
          </Grid>
          <Grid
            item
            display={{ xs: "block", sm: "flex" }}
            alignItems="center"
            gap={2}
            xs={4}
            sm={4}
            md={2.4}
            lg={1.5}
            my={2}
          >
            <TextField
              sx={inputStyle}
              InputProps={{
                type: "time",
              }}
              value={formik.values.hora_ingreso}
              name="hora_ingreso"
              onChange={formik.handleChange}
              error={formik.errors?.hora_ingreso}
              helperText={formik.errors?.hora_ingreso}
              label="Hora"
              InputLabelProps={{
                style: { color: 'black', fontSize: "16px" },
              }}
            />
          </Grid>
          <Grid
            item
            alignItems="center"
            gap={2}
            xs={12}
            pr={{ xs: 0, md: 8 }}
            sm={6}
            md={4.2}
            lg={2.5}
            my={2}
          >
            <FormLabel component="legend" sx={{ color: "black", marginBottom: { xs: 1, sm: 0 } }}>
              Barrera:
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="Estado"
              defaultValue={null}
              sx={inputStyle}
              fullWidth
              name="interurbano"
              value={valueInterurbano}
              onChange={formik.handleChange}
            >
              <FormControlLabel
                selected={"true"}
                value="L"
                control={<Radio onClick={handleClick} />}
                sx={{ color: "black" }}
                label="Ingreso"
              />
              <FormControlLabel
                value="S"
                control={<Radio onClick={handleClick} />}
                sx={{ color: "black" }}
                label="Salida"
              />
            </RadioGroup>
          </Grid>
          <Grid
            item
            alignItems="center"
            gap={2}
            xs={12}
            pr={{ xs: 0, md: 8 }}
            sm={6}
            md={4.2}
            lg={4}
            my={2}
          >
            <FormLabel component="legend" sx={{ color: "black", marginBottom: { xs: 1, sm: 0 } }}>
              Estado:
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="Estado"
              sx={inputStyle}
              fullWidth
              name="estado_id"
              value={formik.values.estado_id}
              onChange={formik.handleChange}
            >
              <FormControlLabel
                value={"2"}
                control={<Radio />}
                sx={{ color: "black" }}
                label="Ingresando"
              />
              <FormControlLabel
                value={"3"}
                control={<Radio />}
                sx={{ color: "black" }}
                label="Sin Plataforma"
              />
            </RadioGroup>
          </Grid>
          <Grid item xs={12} align="center" pt={{ xs: 4, sm: 6 }}>
            <Button
              variant="outlined"
              onClick={resetForm}
              type={"reset"}
              py={2}
              my={4}
              mx={4}
              sx={{ color: "black", margin: "16px" }}
            >
              Limpiar registro
            </Button>
            <Button variant="contained" type="submit" py={2} my={4} mx={4} disabled={!formik.isValid}>
              Crear registro
            </Button>
          </Grid>
          {/*{openModal && (
            <BasicModal
              title=<CheckCircleOutlineIcon
                sx={{ fontSize: "3.12rem", marginLeft: "10px" }}
              />
              message="Registro creado con éxito"
              openModal={openModal}
              redirectTo={"/seguridad/crear-ingreso"}
            />
          )}*/}
        </Grid>
      </form>
    </Stack>
  );
};

export default FormTicket;
