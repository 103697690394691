import React, { useEffect, useState } from 'react'
import { Grid, Typography } from '@mui/material'
import Box from "@mui/material/Box";
import styledComponents, { keyframes, css } from 'styled-components';
import { addMinutes, addHours, format } from 'date-fns';


export default function TicketCard({ props }) {

  const [mostrarHoraModificada, setMostrarHoraModificada] = useState(false);

  const blink = keyframes`
    0% {color: #ff3333; font-size: 18px; text-shadow: 0.5px 0.5px 0.5px #ffffff;}
    25% {color: #ffffff; font-size: 18px; text-shadow: 0.5px 0.5px 0.5px #000000;}
    50% {color: #ff3333; font-size: 18px; text-shadow: 0.5px 0.5px 0.5px #ffffff;}
    75% {color: #ffffff; font-size: 18px; text-shadow: 0.5px 0.5px 0.5px #000000;}
    100% {color: #ff3333; font-size: 18px; text-shadow: 0.5px 0.5px 0.5px #ffffff;}
  `;

  const blinkWarning = keyframes`
    0% {color: #FFA500; font-size: 18px; text-shadow: 0.5px 0.5px 0.5px #ffffff;}
    25% {color: #ffffff; font-size: 18px; text-shadow: 0.5px 0.5px 0.5px #000000;}
    50% {color: #FFA500; font-size: 18px; text-shadow: 0.5px 0.5px 0.5px #ffffff;}
    75% {color: #ffffff; font-size: 18px; text-shadow: 0.5px 0.5px 0.5px #000000;}
    100% {color: #FFA500; font-size: 18px; text-shadow: 0.5px 0.5px 0.5px #ffffff;}
  `;

  const BlinkingText = styledComponents(Typography)`
  ${props => props.blink ? css`animation: ${props.status === 'CANCELADO' ? blink : blinkWarning} 10s linear infinite;` : ''}
`;

  useEffect(() => {
    const intervalId = setInterval(() => {
      setMostrarHoraModificada(prevState => !prevState);
    }, 5000); 

    return () => clearInterval(intervalId); 
  }, []);

  return (
    <Box
      sx={{ width: "100%" }}
      minHeight={{ xs:"138vw"}}
    >
      <Typography
        fontWeight="bold"
        textAlign="center"
        fontSize={{ xs: "30px", sm: "50px" }}
        margin={{ xs: "0", sm: "5%" }}
        fontFamily="Roboto"
        backgroundColor="#2267c8"
        borderRadius="0"
        color="white"
        textTransform=" uppercase"
        position="sticky"
        top="0"
      >
        PARTIDAS
      </Typography>
      {props &&
        props.map((row, index) => (
          <Grid
            container
            sx={{
              background: index % 2 !== 0 ? "#012d61" : "#1C68C0",
              borderRadius: "25px",
              padding: "20px",
              width: "90%",
              margin: "auto",
              color: "white",
              marginBlock: "10px",
              boxShadow: "rgb(0 0 0 / 35%) 1px 2px 6px 2px",
            }}
            display={{ sm: "none" }}
            key={row.id}
          >
            <Grid item xs={8}>
              <Typography variant="caption">Destino:</Typography>
              <Typography variant="h5" fontWeight="bold">
                {row.destino}
              </Typography>
              <Typography variant="caption">Estado:</Typography>
              <Typography variant="h5" fontWeight="bold">
                {row.demora === 1 ? (
                  <BlinkingText blink status="DEMORADO" color="#FFA500">
                    DEMORADO (
                    {(() => {
                      const demora = row.tiempo_demora;
                      let valor;
                      let unidad;
                      if (demora >= 2 && demora <= 59) {
                        valor = demora;
                        unidad = 'minutos';
                      } else if (demora == 1) {
                        valor = demora;
                        unidad = 'minuto';
                      } else if (demora == 60) {
                        valor = 1;
                        unidad = 'hora';
                      } else if (demora > 60) {
                        valor = demora / 60;
                        unidad = 'horas';
                      }
                      return `${valor} ${unidad}`;
                    })()}
                    )
                  </BlinkingText>
                ) : row.cancelado === 1 ? (
                  <BlinkingText blink status="CANCELADO" color="red">
                    CANCELADO
                  </BlinkingText>
                ) : (
                  <Typography fontSize={{ xs: "10px", sm: "15px", md: "18px" }}>
                    {row.registro_estado.tipo.toUpperCase()}
                  </Typography>
                )}
              </Typography>
              <Typography variant="caption">Empresa:</Typography>
              <Typography variant="h5" fontWeight="bold">
                {row.registro_empresa.empresa}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="caption">Interno:</Typography>
              <Typography variant="h5" fontWeight="bold">
                {row.interno}
              </Typography>
              <Typography variant="caption">Plataforma:</Typography>
              <Typography variant="h5" fontWeight="bold">
                {row.plataformas_id}
              </Typography>
              <Typography variant="caption">Hora de salida:</Typography>
              <Typography variant="h5" fontWeight="bold">
                {row.demora === 1 ? (
                  <>
                    {mostrarHoraModificada ? (
                      <Typography status="DEMORADO" style={{
                        color: "#FFA500",
                        fontSize: { xs: "10px", sm: "15px", md: "16px" },
                        textShadow: "0.5px 0.5px 0.5px #000000"
                      }}>
                        {(() => {
                          const demora = row.tiempo_demora;
                          let valor;
                          let unidad;
                          let nuevaHoraSalida;
                          if (demora >= 2 && demora <= 59) {
                            valor = demora;
                            unidad = 'minutos';
                            nuevaHoraSalida = addMinutes(new Date(`1970-01-01T${row.hora_salida}`), demora);
                          } else if (demora == 1) {
                            valor = demora;
                            unidad = 'minuto';
                            nuevaHoraSalida = addMinutes(new Date(`1970-01-01T${row.hora_salida}`), demora);
                          } else if (demora == 60) {
                            valor = 1;
                            unidad = 'hora';
                            nuevaHoraSalida = addHours(new Date(`1970-01-01T${row.hora_salida}`), valor);
                          } else if (demora > 60) {
                            valor = demora / 60;
                            unidad = 'horas';
                            nuevaHoraSalida = addHours(new Date(`1970-01-01T${row.hora_salida}`), valor);
                          }
                          return `${format(nuevaHoraSalida, 'HH:mm')}`;
                        })()}
                      </Typography>
                    ) : (
                      <Typography style={{
                        color: "white",
                        fontSize: { xs: "10px", sm: "15px", md: "16px" },
                        textShadow: "0.5px 0.5px 0.5px #000000"
                      }}>
                        {row.hora_salida}
                      </Typography>
                    )}
                  </>
                ) : row.cancelado === 1 ? (
                  <Typography
                    status="CANCELADO"
                    style={{
                      color: "#ff3333",
                      fontSize: "16px",
                      textShadow: "0.5px 0.5px 0.5px #ffffff"
                    }}
                  >
                    CANCELADA
                  </Typography>
                ) : (
                  <Typography fontSize={{ xs: "10px", sm: "15px", md: "18px" }}>
                    {row.hora_salida}
                  </Typography>
                )}
              </Typography>
            </Grid>
          </Grid>
        ))}
    </Box>
  );
}
