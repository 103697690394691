import React, { useEffect, useState } from "react";
import axios from "axios";
import { Stack } from "@mui/system";
import { Button, Grid, MenuItem, TextField, Typography} from "@mui/material";
import ReplyIcon from "@mui/icons-material/Reply";
import * as yup from "yup";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

const validationSchema = yup.object({
  empresa_id: yup.number().required("Campo requerido"),
  desde: yup.number().required("Campo requerido"),
  hasta: yup.number().required("Campo requerido"),
});

const FormInterurbanosCreate = () => {
  const [companies, setCompanies] = useState([]);
  const [platforms, setPlatforms] = useState([]);
  const token = window.sessionStorage.getItem("jwt");
  const url = "https://terminaldigital.com.ar/api/interurbanos/";
  const config = { headers: { authorization: `Bearer ${token}` } };

  const stylesTextField = {
    ".MuiOutlinedInput-notchedOutline": {
      borderColor: "black",
    },
    ".MuiInputBase-root": {
      color: "black" ,
    },
    "& .MuiSvgIcon-root": {
      color: "black" ,
    },
    minWidth: "250px",
  }
 

  const getDropdown = () => {
    axios
      .all([
        axios.get("https://terminaldigital.com.ar/api/empresas/listadoInterurbano", config),
        axios.get(
          "https://terminaldigital.com.ar/api/plataformas/listadoInterurbano",
          config
        ),
      ])
      .then((data) => {
        const filteredCompanies = data[0].data.empresas.filter(
          (empresa) => empresa.servicio_id === 3
        );
        setCompanies(filteredCompanies);
        setPlatforms(data[1].data.plataformas);
      })
      .catch((error) => console.log("error users", error));
  };

  useEffect(() => {
    getDropdown();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setInitialReason = () => {
    return {
      empresa_id: "", 
      desde: "", 
      hasta: "", 
    };
  };

  const formik = useFormik({
    initialValues: setInitialReason(),
    validationSchema: validationSchema,
    onSubmit: () => {
      const data = formik.values;

      axios
        .post(url, data, config)
        .then((res) => {
          if (res.status === 200) {
            toast.success('Empresa Interurbana creada con éxito.')
            formik.resetForm({ values: setInitialReason() });
          }
        })
        .catch(function (error) {
          toast.error('Error en la creación de la Empresa Interurbana' + String(error))
        });
    },
  });

  return (
    <Stack
      sx={{ background: "#F4F2EC", borderRadius: "25px", shadow: 4 }}
      my={4}
      mx={{ xs: 1, sm: 6 }}
      p={4}
      sm={6}
      minHeight={{ xs: "10vh", sm: "68vh", md:"52vh" }}
    >
      <form onSubmit={formik.handleSubmit}>
        <Link to="/supervisor/interurbanos">
          <Button
            py={2}
            my={4}
            mx={4}
            sx={{
              color: "#b22222",
              marginLeft: { xs: "85%", sm: "92%", md: "95%" },
            }}
            variant="outlined"
            color="error"
          >
            <ReplyIcon />
          </Button>
        </Link>
        <Typography
          sx={{
            fontSize: { xs: "1rem", md: "2rem" },
            fontFamily: 'Lato, sans-serif',
            color: '#0e315a',
            textShadow: '2px 2px 2px rgba(0, 0, 0, 0.25)',
            display: 'flex',
            justifyContent: 'start',
            alignItems: 'center',
            margin: 5,
            ml: 1
          }} >
          Asignar plataformas a empresa interurbana:
        </Typography>
        <Grid container my={6}>
          <Grid
            item
            display={{ xs: "block", sm: "flex" }}
            alignItems="center"
            gap={2}
            xs={12}
            sm={12}
            md={12}
            lg={4}
            my={2}
            ml={{ xs: 0, sm: 13, md: 0 }}
          >
            <Typography
              variant="subtitle1"
              color="black"
              mb={{ xs: 1, sm: 0 }}
              display={{ xs: "block", sm: "block" }}
            >
              Empresa:
            </Typography>
            <TextField
              select
              label="Seleccione empresa"
              sx={stylesTextField}
              InputLabelProps={{
                style: { color: "black" },
              }}
              name="empresa_id"
              value={formik.values.empresa_id}
              onChange={formik.handleChange}
              error={formik.errors.empresa_id}
              helperText={formik.errors.empresa_id}
            >
              {companies?.length > 0 ? (
                companies.map((comp) => (
                  <MenuItem
                    value={comp.id}
                    key={comp.id + "empresa"}
                    selected={true}
                  >
                    {comp.empresa}
                  </MenuItem>
                ))
              ) : (
                <MenuItem disabled>
                  Sin empresas con este servicio
                </MenuItem>
              )}
            </TextField>
            <br></br>
          </Grid>
          <Grid
            item
            display={{ xs: "block", sm: "flex" }}
            alignItems="center"
            gap={2}
            xs={12}
            sm={12}
            md={6}
            lg={4}
            my={2}
            ml={{ xs: 0, sm: 14, md: 0 }}
          >
            <Typography
              variant="subtitle1"
              color="black"
              mb={{ xs: 1, sm: 0 }}
              sx={{ marginTop: { xs: "20px", sm: "0px" } }}
            >
              Desde:
            </Typography>
            <TextField
              sx={stylesTextField}
              select
              label="Seleccione plataforma"
              InputLabelProps={{
                style: { color: "black" },
              }}
              multiline
              rows={4}
              value={formik.values.desde}
              name="desde"
              onChange={formik.handleChange}
              error={formik.errors.desde}
              helperText={formik.errors.desde}
            >
              {platforms?.filter(plat => plat.disponible === 1).map((plat) => (
                <MenuItem
                  value={plat.id}
                  key={"desde" + plat.plataforma + plat.id}
                  selected={true}
                >
                  {plat.plataforma}
                </MenuItem>
              ))}
            </TextField>
            <br></br>
          </Grid>
          <Grid
            item
            display={{ xs: "block", sm: "flex" }}
            alignItems="center"
            gap={2}
            xs={12}
            sm={12}
            md={6}
            lg={4}
            my={2}
            ml={{ xs: 0, sm: 14, md: 0 }}
          >
            <Typography
              variant="subtitle1"
              color="black"
              mb={{ xs: 1, sm: 0 }}
              sx={{ marginTop: { xs: "20px", sm: "0px" } }}
            >
              Hasta:
            </Typography>
            <TextField
              sx={stylesTextField}
              select
              label="Seleccione plataforma"
              InputLabelProps={{
                style: { color: "black" },
              }}
              multiline
              rows={4}
              value={formik.values.hasta}
              name="hasta"
              onChange={formik.handleChange}
              error={formik.errors.hasta}
              helperText={formik.errors.hasta}
            >
              {platforms?.filter(plat => plat.disponible === 1).map((plat) => (
                <MenuItem
                  value={plat.id}
                  key={"hasta" + plat.plataforma + plat.id}
                  selected={true}
                >
                  {plat.plataforma}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item sx={{ marginRight: "auto" }} align="center" pt={6} xs={12}>
            <Button variant="contained" ml="auto" type="submit" my={2}>
              Asignar Plataformas
            </Button>
          </Grid>
        </Grid>
      </form>
    </Stack>
  );
};

export default FormInterurbanosCreate;
