import React, { useState, useEffect } from "react";
import axios from "axios";
import "./login.css";
import Box from "@mui/material/Box";
import { Typography, Card, Grid } from "@mui/material";
import ImagenTerminal from "../../assets/img/contacto.png";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import XIcon from '@mui/icons-material/X';
import styled from 'styled-components';

const styleT = {
  background: "#F4F2EC",
  color: "black",
  maxWidth: "100%",
  paddingBlock: "2vh",
  textAlign: "center",
  borderTopLeftRadius: "25px",
  borderTopRightRadius: "25px",
  fontFamily: 'Lato, sans-serif',
  color: '#0e315a',
  textShadow: '2px 2px 2px rgba(0, 0, 0, 0.25)',
};
const style = {
  maxWidth: "100%",
  maxHeight: "auto%",
  paddingBlock: "1vh",
  marginRight: "5%",
  marginTop: "4%",
  shadow: 4,
  color: "black",
};
const style2 = {
  maxWidth: "100%",
  maxHeight: "auto%",
  paddingBlock: "1vh",
  marginLeft: "6%",
  marginRight: "5%",
  marginTop: "4%",
  shadow: 4,
};

const styleInstitucional = {
  maxWidth: "100%",
  height: "62vh",
};

const ResponsiveImage = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 25px;

  @media (max-width: 600px) {
    display: none;
  }

  @media (min-width: 1200px) {
    height: 94%; 
    display: block;
  }
`;

const Contacto = () => {
  const [dataTel, setDataTel] = useState([]);
  const [dataCel, setDataCel] = useState([]);
  const [dataEmail1, setDataEmail1] = useState([]);
  const [dataFacebook, setDataFacebook] = useState([]);
  const [dataInstagram, setDataInstagram] = useState([]);
  const [dataTwitter, setDataTwitter] = useState([]);


  const getDataTerminal = async () => {
    axios
      .get("https://terminaldigital.com.ar/api/administrarterminal")
      .then((data) => {
        //console.log(data.data.terminal);
        setDataTel(data.data.terminal.contacto_tel);
        setDataCel(data.data.terminal.contacto_cel);
        setDataEmail1(data.data.terminal.contacto_email_1);
        setDataFacebook(data.data.terminal.facebook);
        setDataInstagram(data.data.terminal.instagram);
        setDataTwitter(data.data.terminal.twitter);

      })
      .catch((error) => console.log("error users", error));
  };

  useEffect(() => {
     getDataTerminal();
  }, []);

  const whatsApp = "https://wa.me/" + dataCel;
  const email1 = "mailto:" + dataEmail1;

const call = "tel:" + dataTel

  return (
    <Box px={3} pb={4} py={4} style={styleInstitucional}>
      <Grid container spacing={2} >
        <Grid item xs={12} md={4}>
          <ResponsiveImage src={ImagenTerminal} alt="Descripción de la imagen" style={{ width: '100%', height: '95', borderRadius: "25px" }} />
        </Grid>
        <Grid item xs={12} md={8} sx={{ width: { xs: '20%', md: '100%' } }}>
          <Typography variant="h3" style={styleT} sx={{ fontSize: { xs: "1.5rem", md: "2.5rem" } }}>
            <br></br>
            CONTACTO
            <br></br>
            <br></br>
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: {xs:"row", md:"column"},
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#F4F2EC",
              width: "100%",
              height: "32vh",
              borderBottomLeftRadius: "25px",
              borderBottomRightRadius: "25px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", md: "row" },
                justifyContent: "space-between",
                width: "95%",
                marginBottom: {xs:0, md:"1rem"},
                alignItems:{xs:"center", md: "none"},
              }}
            >
              <Typography variant="h5" style={style}>
                <a href={call}>
                  <LocalPhoneIcon sx={{ fontSize: "2.5rem",marginLeft: { xs: 0, md: 4 } }} />
                </a>
              </Typography>
              <Typography variant="h5" style={style2}>
                <a href={whatsApp}>
                  <WhatsAppIcon sx={{ color: "#00d221", fontSize: "2.5rem" }} />
                </a>
              </Typography>
              <Typography variant="h5" style={style2}>
                <a href={email1}>
                  <MailOutlineIcon sx={{ color: "rgb(25, 118, 210)", fontSize: "2.5rem" }} />
                </a>
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: {xs:"column", md:"row"},
                justifyContent: "space-between",
                alignItems: {xs:"left", md: "none"},
                width: "60%",
              }}
            >
              <Typography variant="h5" style={style}>
                <a href={dataFacebook} target="_blank" rel="noreferrer">
                  <FacebookIcon
                    color="facebook"
                    sx={{ fontSize: "3rem", marginLeft: {xs:0, md: 2} }}
                  />
                </a>
              </Typography>
              <Typography variant="h5" style={style2}>
                <a href={dataInstagram} target="_blank" rel="noreferrer">
                  <InstagramIcon
                    color="instagram"
                    sx={{ fontSize: "2.5rem", marginLeft: { xs: 0, md: 0 } }}
                  />
                </a>
              </Typography>
              <Typography variant="h5" style={style2}>
                <a href={dataTwitter} target="_blank" rel="noreferrer">
                  <XIcon
                    color="black"
                    sx={{ fontSize: "2.5rem", marginLeft: { xs: 0, md: 2 } }}
                  />
                </a>
              </Typography>
            </Box>
          </Box>
          <br></br>
        </Grid>
      </Grid>
    </Box>
  );
};
export default Contacto;
