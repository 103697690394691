import React, { useState, useEffect } from "react";
import { Box, CircularProgress } from "@mui/material";
import { Stack } from "@mui/system";
import axios from "axios";
import "./ArrivalsBoard.styles.css";
import TableIntercityHead from "../../components/table/TableIntercityHead";
import GenericTableInterurbanosList from "../../components/table/TableInterurbanosList";
import TicketCardIntercity from "../../components/ticketCards/TicketCardIntercity";
import MarketingModuleWeb from "../../components/MarketingModule/MarketingModuleWeb";
import sinRegistros from "../../assets/img/sinregistros-1.png";
import Typography from "@mui/material/Typography";
import Pagination from '@mui/material/Pagination';

const style = {
  padding: "auto",
  margin: "0px",
  maxWidth: "100%",
  height: "auto",
  marginBottom: "auto",
  marginLeft: "1%",
};

const InterurbanosBoard = () => {
  const token = sessionStorage.getItem("jwt");
  const [data, setData] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const itemsPerPage = 5;

  const isLogged = token ? "true" : "false";

  const url = "https://terminaldigital.com.ar/api/interurbanos/all";
  const config = { headers: { authorization: `Bearer ${token}` } };

  const getInterurbano = () => {
    axios
      .get(url)
      .then((data) => {
        return setData(data.data.interurbanos);
      })
      .catch((error) => console.log("Error Interurbanos Table:", error));
  };
  React.useEffect(() => {
    getInterurbano();
  }, []);

  const [logo, setLogo] = useState([]);

  const getLogoTerminal = async () => {
    axios
      .get("https://terminaldigital.com.ar/api/administrarterminal")
      .then((data) => {
        setLogo(data.data.terminal.logo);
      })
      .catch((error) => console.log("error users", error));
  };

  useEffect(() => {
    getLogoTerminal();
  }, []);

  const handleChange = (event, value) => {
    setPage(value);
  };

  useEffect(() => {
    const totalPages = Math.ceil(data.length / itemsPerPage);
    const timer = setInterval(() => {
      setPage((prevPage) => {
        if (prevPage >= totalPages) {
          return 1;
        } else {
          return prevPage + 1;
        }
      });
    }, 15000);
    return () => {
      clearInterval(timer);
    };
  }, [data, itemsPerPage]);

  return (
    <Box className="containerBoard">
      <div>
        {data.length === 0 && (
          <>
            <Box display={{ xs: "none", sm: "block" }} sx={{ minHeight: { xs: "1vh", md: "16vh" }, maxHeight: { xs: "1vh", md: "16vh" }, overflow: "auto" }}>
              <TableIntercityHead />
            </Box>
            <Typography
              fontWeight="bold"
              textAlign="center"
              fontSize={{ xs: "3vh", sm: "5vh" }}
              margin={{ xs: "0", sm: "5%" }}
              sx={{ fontFamily: "Roboto" }}
              backgroundColor="#2267c8"
              borderRadius={{ xs: "0", sm: "2.5vh" }}
              position={"sticky"}
              top="0"
              right="0"
              left="0"
              color="white"
              textTransform=" uppercase"
              width={"100%"}
              display={{ xs: "block", sm: "none" }}
            >
              INTERURBANOS
            </Typography>
            <Box className="centeredContent" sx={{
              minHeight: isLogged === "false" ? window.innerWidth <= 768 ? "1vh" : "55.5vh" : window.innerWidth <= 768 ? "68vh" : "58vh"
            }}>
              <img
                style={{ maxWidth: "20vw", ...style }}
                src={sinRegistros}
                alt={sinRegistros}
              />
            </Box>
          </>
        )}
        {data.length === undefined && (
          <div className="centeredContent" style={{ height: "40vh" }}>
            <CircularProgress />
          </div>
        )}
        {data.length > 0 && (
          <>
            <Box display={{ xs: "none", sm: "block" }} style={{ minHeight: "71.5vh", maxHeight: "71.5vh", overflow: "auto" }}>
              <Typography
                fontWeight="bold"
                textAlign="center"
                fontSize={{ sm: "1rem", md: "2rem" }}
                margin={{ xs: "0", sm: "0%" }}
                sx={{ fontFamily: "Roboto" }}
                backgroundColor="#2267c8"
                position={"sticky"}
                top="0"
                right="0"
                left="0"
                color="white"
                textTransform=" uppercase"
                width={{ sm: "216.1%", md: "222.3%", lg: "100vw" }}
                display={{ xxl: "none" }}
              >
                interurbanos
              </Typography>
              <GenericTableInterurbanosList props={data.slice((page - 1) * itemsPerPage, page * itemsPerPage)} />
            </Box>
            <Box display={{ xs: "block", sm: "none" }} style={{ minHeight: "87vh", maxHeight: "87vh", overflow: "auto" }}>
              <TicketCardIntercity props={data.slice((page - 1) * itemsPerPage, page * itemsPerPage)} />
            </Box>
            <Pagination count={Math.ceil(data.length / itemsPerPage)} page={page} onChange={handleChange} style={{ display: "none" }} />
          </>
        )}
        <Box sx={{
          minHeight: "18vh",
          maxHeight: "18vh",
          display: { xs: "none", sm: "block" }
        }}>
          <MarketingModuleWeb />
        </Box>
      </div>
    </Box>
  );
};

export default InterurbanosBoard;
