import { TextField } from '@mui/material'
import React from 'react'

const FormInput = ({
  value,
  onChange,
  error,
  helperText,
  type,
  placeholder,
  inputProps,
  sx,
  background,
  onKeyDown,
}) => {
  return (
    <TextField
      sx={{
        ".MuiOutlinedInput-notchedOutline": {
          borderColor: "white",
        },
        ".MuiInputBase-root": {
          color: "white",
          background: background ? background : "#0e315a",
        },
        ...sx,
      }}
      InputProps={{
        type: type,
      }}
      name="fecha_salida"
      value={value}
      onChange={onChange}
      onKeyDown={onKeyDown}
      error={error}
      helperText={helperText}
      inputProps={inputProps}
      placeholder={placeholder}
    />
  );
};

export default  FormInput