import { withCheckPasswordStatus } from '../../helper/withCheckPasswordStatus';
import React, { useState, useEffect } from "react";
import { useQuery, useQueryClient } from 'react-query';
import { Box, Button as MuiButton, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TextField, Typography, useMediaQuery, Divider, Button, TableRow, InputLabel } from '@mui/material';
import axios from "axios";
import io from 'socket.io-client';
import TableAdmin from "../../components/table/TableAdminTicketEntry";
import TableAdmin2 from "../../components/table/TableAdminTicketOnPlatform";
import TableAdmin3 from "../../components/table/TableAdminTicketOffPlatform";
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ReactPaginate from 'react-paginate';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Link } from "react-router-dom";


const socket = io('https://terminaldigital.com.ar');

const style = {
  background: "#0e315a",
  color: "black",
  ".MuiAccordionSummary-root .MuiSvgIcon-root": {
    color: "black",
    fontSize: { xs: "2.5rem", md: "3rem" },
  },
  "Mui-expanded": {
    margin: "0px",
  },
  "&:focus": {
    background: "#0e315a",
  },
};

const styleTitle = { margin: "auto", fontSize: { xs: "1.31rem", md: "2.18rem" } };

const typographyStyles = {
  paddingBlock: "2vh",
};
const styles = {
  margin: "auto",
  width: "100%",
  background: "#0e315a",
  color: "black",
  paddingBottom: "32px",
};

const InformsHome = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(0);
  const [currentView, setCurrentView] = useState("ingresantes");
  const itemsPerPage = 10;

  const token = window.sessionStorage.getItem("jwt");

  const fetchArrivals = async () => {
    const url = "https://terminaldigital.com.ar/api/informes/listadoSeparado";
    const config = { headers: { authorization: `Bearer ${token}` } };

    const response = await axios.get(url, config);
    if (response && response.data && response.data.respuesta) {
      return response.data.respuesta;
    } else {
      throw new Error('Error fetching arrivals');
    }
  };

  const actualizarDatos = () => {
    fetchArrivals().then(data => {
      queryClient.setQueryData('arrivals', data);
    });
  };

  const CustomToast = ({ message }) => (
    <div style={{ fontSize: '15px', color: 'black', textTransform: "uppercase" }}>
      {message}
    </div>
  );

  useEffect(() => {
    socket.on('newRegister', ({ message, shouldRerender }) => {
      if (shouldRerender && message) {
        queryClient.invalidateQueries('arrivals');
        toast.success(<CustomToast message={message} />, { position: toast.POSITION.TOP_RIGHT, autoClose: 8000 });
      }
      if (shouldRerender) {
        queryClient.invalidateQueries('arrivals');
      }
    });
    socket.on('connect', () => {
      console.log('Conectado al servidor');
    });
    return () => {
      socket.off('newRegister');
    };
  }, []);

  const { data: arrivals } = useQuery('arrivals', fetchArrivals, {
    refetchOnWindowFocus: true,
  });

  const ingresando = arrivals?.ingresando || [];
  const enPlataforma = arrivals?.enPlataforma || [];
  const fueraDePlataforma = arrivals?.fueraDePlataforma || [];

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const switchView = (view) => {
    setCurrentView(view);
    setCurrentPage(0);
  };

  const renderTable = () => {
    switch (currentView) {
      case "ingresantes":
        return <TableAdmin edit={true} data={slicedData(ingresando)} actualizarDatos={actualizarDatos} />;
      case "enPlataforma":
        return <TableAdmin2 edit={true} data={slicedData(enPlataforma)} actualizarDatos={actualizarDatos} />;
      case "fueraDePlataforma":
        return <TableAdmin3 edit={true} data={slicedData(fueraDePlataforma)} actualizarDatos={actualizarDatos} />;
      default:
        return null;
    }
  };

  const slicedData = (data) => {
    const start = currentPage * itemsPerPage;
    const end = start + itemsPerPage;
    return data.slice(start, end);
  };


  return (
    <>
      <Box style={styles} minHeight={{ xs: "70.8vh", sm: "76.5vh", md: "70vh", lg: "66.8vh" }}>
        <Typography
          align="center"
          variant="h4"
          sx={{
            paddingBlock: "5vh", fontSize: { xs: "1.25rem", md: "2rem" }, fontFamily: 'Lato, sans-serif',
            color: 'white',
            textShadow: '2px 2px 2px rgba(0, 0, 0, 1)',
          }}
        >
          CONTROL DE PLATAFORMAS
        </Typography>

        {/* Selector de vista */}
        <Box display="flex" justifyContent="center" marginBottom="1rem" >
          <Button
            onClick={() => switchView("ingresantes")}
            sx={{ marginRight: { xs: "0rem", md: "1rem" }, color: currentView === "ingresantes" ? 'white' : "gray", fontSize: { xs: "0.7rem", md: "1rem" } }}
          >
            Ingresantes
          </Button>
          <Button
            onClick={() => switchView("enPlataforma")}
            sx={{ marginRight: { xs: "0rem", md: "1rem" }, color: currentView === "enPlataforma" ? 'white' : "gray", fontSize: { xs: "0.7rem", md: "1rem" } }}
          >
            En Plataforma
          </Button>
          <Button
            onClick={() => switchView("fueraDePlataforma")}
            sx={{ color: currentView === "fueraDePlataforma" ? 'white' : "gray", fontSize: { xs: "0.7rem", md: "1rem" } }}
          >
            Fuera de Plataforma
          </Button>
        </Box>

        {/* Tabla actual */}
        {renderTable()}

        {/* Paginación
        <TablePagination
          rowsPerPageOptions={[]}
          component="div"
          count={currentView === "ingresantes" ? ingresando.length : currentView === "enPlataforma" ? enPlataforma.length : fueraDePlataforma.length}
          rowsPerPage={itemsPerPage}
          page={currentPage}
          onPageChange={handlePageChange}
        /> */}

        {/* Botón para crear ingreso 
        <Box display="flex" justifyContent="center" marginTop="2rem">
          <Link to={userInfo.rol === 4 ? "/seguridad/crear-ingreso" : "/supervisor/crear-ingreso"}>
            <Button
              sx={{
                my: 2,
                color: "#FFFFFF",
                fontSize: "0.80rem",
                width: { xs: "40%", sm: "20%", lg: "100%" },
              }}
              variant="contained"
            >
              Crear Ingreso
            </Button>
          </Link>
        </Box>*/}
      </Box>
    </>
  );
};


export default withCheckPasswordStatus(InformsHome);
