import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import { alpha, makeStyles } from '@material-ui/core/styles';
import { Box, Button as MuiButton, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TextField, Typography, useMediaQuery, Divider, Button, TableRow, InputLabel, Card, CardHeader, CardContent  } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SearchIcon from '@mui/icons-material/Search';
import SettingsIcon from '@mui/icons-material/Settings';
import { styled } from '@mui/system';
import axios from 'axios';
import jwt_decode from "jwt-decode";
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import CustomTablePaginationActions from '../Custom/CustomTablePaginationActions';
import DialogPlatform from '../modals/DialogPlatforms';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import IconButton from '@mui/material/IconButton';
import Hidden from '@mui/material/Hidden';


export default function BasicTable() {

  const useStyles = makeStyles({
    tableContainer: {
      '& .table': {
        minWidth: 650,
        marginTop: '3%',
      },
    },
  });

  const StickyHeader = styled(TableHead)(({ theme }) => ({
    position: 'sticky',
    top: 10,
    zIndex: theme.zIndex.appBar - 1,
    backgroundColor: theme.palette.background.paper,
  }));
  
  const StickyFooter = styled(TableFooter)(({ theme }) => ({
    position: 'sticky',
    bottom: -20,
    zIndex: theme.zIndex.appBar - 1,
    backgroundColor: theme.palette.background.paper,
  }));

  const Search = styled("div")(({ theme }) => ({
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.5),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.7),
    },
    color: 'black',
    marginLeft: 10,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "35%",
    },
  }));
  const CancelButton = styled(MuiButton)({
    backgroundColor: '#0e315a',
    color: 'white',
    '&:hover': {
      backgroundColor: 'darkblue',
    },
  });

  const DeleteButton = styled(MuiButton)({
    backgroundColor: '#ef2f2f',
    color: 'white',
    '&:hover': {
      backgroundColor: 'darkred',
    },
  });

  const style = {
    color: "white",
    minWidth: "100%",
    paddingBlock: "2vh",
    textAlign: "center",

  };

  const classes = useStyles();
  const [plataformas, setPlataformas] = useState([]);
  const [servicioSeleccionado, setServicioSeleccionado] = useState(0);
  const [selectedPlatform, setSelectedPlatform] = useState(null);
  const [open, setOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [platformToDelete, setPlatformToDelete] = useState(null);
  const [editado, setEditado] = useState(false);
  const [paginaActual, setPaginaActual] = useState(0);
  const [totalPaginas, setTotalPaginas] = useState(0);
  const [elementosPorPagina, setElementosPorPagina] = useState(10);
  const [search, setSearch] = useState("");
  const [countSearch, setCountSearch] = useState(1);
  const [resultadosBusqueda, setResultadosBusqueda] = useState(plataformas);
  const [userInfo, setUserInfo] = React.useState({});
  const [totalPlatform, setTotalPlatform] = useState(0);

  const token = window.sessionStorage.getItem("jwt");

  useEffect(() => {
    if (token) {
      const tokenDecoded = jwt_decode(token);
      const { nombre, rol } = tokenDecoded;
      setUserInfo((state) => ({ ...state, nombre, rol }));
    }
  }, [token]);

  const handleOpen = () => setOpen(true);

  const handleChange = (event) => {
    setCountSearch(countSearch + 1);
    if (event.target.value === "") {
      setCountSearch(1);
    }
    setSearch(event.target.value);
    filtrar(event.target.value);
  };
  const filtrar = (terminoBusqueda) => {
    if (terminoBusqueda === "") {
      setResultadosBusqueda(plataformas);
    } else {
      const resultados = plataformas.filter((dato) => {
        const plataforma = dato.plataforma;
        const servicio = dato.plataforma_servicio.tipo_servicio;
        const estado = dato.disponible === 1 ? "Libre" : "Ocupada";

        return (
          (plataforma && plataforma.toUpperCase().includes(terminoBusqueda.toUpperCase())) ||
          (servicio && servicio.toUpperCase().includes(terminoBusqueda.toUpperCase())) ||
          (estado && estado.toUpperCase().includes(terminoBusqueda.toUpperCase()))
        );
      });
      setResultadosBusqueda(resultados);
    }
  };

  const editThis = (plataforma) => {
    handleOpen();
    setSelectedPlatform(plataforma);
  };

  const openFalse = (openFalse) => {
    setOpen(openFalse);
  };

  const editadoFalse = (editadoFalse) => {
    setEditado(editadoFalse);
    setTimeout(() => setEditado("false"), 2000);
  };

  useEffect(() => {
    axios.get('https://terminaldigital.com.ar/api/plataformas/listado', {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
      .then(response => {
        const plataformasFiltradas = response.data.plataformas;
        setPlataformas(plataformasFiltradas);
        setResultadosBusqueda(plataformasFiltradas);
        setTotalPlatform(response.data.totalPlataformas);
      })
      .catch(error => {
        console.error('There was an error!', error);
      });
  }, [editado, token]);

  const handleSelectChange = (event) => {
    setServicioSeleccionado(event.target.value);
    updateList();
  };

  const updateList = async () => {
    try {
      const response = await axios.get('https://terminaldigital.com.ar/api/plataformas/listado', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      const plataformasFiltradas = response.data.plataformas;
      setPlataformas(plataformasFiltradas);
      setTotalPlatform(response.data.totalPlataformas);
    } catch (error) {
      console.error('There was an error!', error);
    }
  };

  const deleteThis = (row) => {
    setPlatformToDelete(row);
    setOpenDialog(true);
  };

  const confirmDelete = async () => {
    setOpenDialog(false);
    try {
      const response = await axios.delete(`https://terminaldigital.com.ar/api/plataformas/${platformToDelete.id}`, { headers: { authorization: `Bearer ${token}` } });
      if (response.status === 200) {
        toast.success('Plataforma eliminada exitosamente', {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setPlataformas(plataformas.filter(plataforma => plataforma.id !== platformToDelete.id));
      }
    } catch (error) {
      console.error("Error deleting platform:", error);
      toast.error('Ha ocurrido un error al eliminar la plataforma', {
        position: toast.POSITION.TOP_RIGHT
      });
    }
  };

  const updateDisponible = async (plataforma) => {
    try {
      const response = await axios.patch(`https://terminaldigital.com.ar/api/plataformas/updateState/${plataforma.id}`, { disponible: 1 }, { headers: { authorization: `Bearer ${token}` } });
      if (response.status === 200) {
        toast.success('Estado de plataforma actualizado exitosamente', {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        updateList();
      }
    } catch (error) {
      console.error("Error updating platform:", error);
      toast.error('Ha ocurrido un error al actualizar el estado de la plataforma', {
        position: toast.POSITION.TOP_RIGHT
      });
    }
  }

  const filtroPlataformas = (plataforma) => {
    if (plataforma.id === 1) {
      return false;
    }

    if (servicioSeleccionado === 5) {
      return plataforma.borrado === 1;
    }

    if (plataforma.borrado !== 0) {
      return false;
    }

    return servicioSeleccionado === 0 ||
      servicioSeleccionado === plataforma.servicios_id ||
      (servicioSeleccionado === 4 && plataforma.disponible === 0);
  };

  const reactivarPlataforma = async (plataforma) => {
    try {
      const response = await axios.patch(`https://terminaldigital.com.ar/api/plataformas/activate/${plataforma.id}`, { borrado: 0 }, { headers: { authorization: `Bearer ${token}` } });
      if (response.status === 200) {
        toast.success('Plataforma reactivada exitosamente', {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        updateList();
      }
    } catch (error) {
      console.error("Error updating platform:", error);
      toast.error('Ha ocurrido un error al reactivar la plataforma', {
        position: toast.POSITION.TOP_RIGHT
      });
    }
  }

  useEffect(() => {
    setTotalPaginas(Math.ceil(plataformas.length / elementosPorPagina));
    setResultadosBusqueda(plataformas);
  }, [plataformas, elementosPorPagina]);

  const isMobile = useMediaQuery('(max-width:600px)');
  const margin = isMobile ? "0px 30px -20px 190px" : "-10px 10px -10px 652px";

  return (
    <Box sx={{ background: "#0e315a", paddingBlock: '1px' }}>
      <TableContainer
        component={Paper}
        sx={{ borderRadius: "10px", overflowX: 'auto' }}
        style={style}
      >
        <Card>
          <CardHeader
            title={
              <Box display="flex" justifyContent="start" flexGrow={1}>
                <Typography sx={{
                  fontSize: { lg: "2rem" },
                  fontFamily: 'Lato, sans-serif',
                  color: '#0e315a',
                  textShadow: '2px 2px 2px rgba(0, 0, 0, 0.25)',
                  display: 'flex',
                  justifyContent: 'start',
                }}>
                  Listado de Plataformas
                </Typography>
              </Box>
            }
           
            style={{ backgroundColor: "white" }}
          />
          <CardContent>
            <Box display="flex" flexDirection={{ xs: 'column', sm: 'row' }} justifyContent="space-between">
              <Box sx={{ marginTop: { xs: "5%", sm: "2%" } }}>
                <Search
                  sx={{
                    marginTop: { xs: "1%", lg: "0%" },
                    marginBottom: { xs: "1%", sm: "1%", lg: "1%" },
                    width: { xs: "65%", sm: "90%", md: "70%", lg: "100%" },
                  }}
                >
                  <TextField
                    placeholder="Buscar plataforma"
                    value={search}
                    onChange={handleChange}
                    fullWidth
                    variant="outlined"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                      sx: {
                        color: 'black',
                        '&::placeholder': {
                          color: 'black',
                        },
                        '&.Mui-focused': {
                          color: 'black',
                        },
                      }
                    }}
                  />
                </Search>
              </Box>
              <Box display="flex" flexDirection="row" alignItems="center" sx={{ marginTop: 2, marginLeft:2 }}>
                <InputLabel id="servicio-label" sx={{ marginRight: 2, display: { xs: 'none', md: "block" } }} >Seleccionar servicio:</InputLabel>
                <Select
                  labelId="servicio-label"
                  value={servicioSeleccionado}
                  onChange={handleSelectChange}
                >
                  <MenuItem value={0}>Todos los servicios</MenuItem>
                  <MenuItem value={2}>Larga distancia</MenuItem>
                  <MenuItem value={1}>Media distancia</MenuItem>
                  <MenuItem value={3}>Corta distancia</MenuItem>
                  <MenuItem value={4}>Ocupadas</MenuItem>
                  <MenuItem value={5}>Eliminadas</MenuItem>
                </Select>
              </Box>
              {userInfo.rol !== 1 ? (
                <Box display="flex" justifyContent="flex-end" sx={{ marginTop: {xs: "-35px",md:2} }}>
                  <Hidden mdUp>
                    <IconButton component={Link} to="/supervisor/plataformas/crear">
                      <AddCircleIcon color="primary" sx={{ marginLeft: 1 }} />
                    </IconButton>
                  </Hidden>
                  <Hidden smDown>
                    <Button variant="outlined" component={Link} to="/supervisor/plataformas/crear">
                      Agregar plataforma
                    </Button>
                  </Hidden>
                </Box>
              ) : null}
            </Box>
          </CardContent>
        </Card>
        <div style={{ maxHeight: '500px', overflow: 'auto' }} className={classes.tableContainer}>
          <Table className="table" aria-label="simple table">
            <StickyHeader>
              <TableRow>
                <TableCell align='center'>Nº Plataforma</TableCell>
                <TableCell align="center">Servicio</TableCell>
                <TableCell align="center">Estado</TableCell>
                <TableCell align="center">Editar</TableCell>
                {servicioSeleccionado === 5 || servicioSeleccionado === 4 || userInfo.rol !== 1 ? <TableCell align="center"></TableCell> : <TableCell align="center">Eliminar</TableCell>}
              </TableRow>
            </StickyHeader>
            <TableBody>
              {resultadosBusqueda && resultadosBusqueda
                .filter(filtroPlataformas)
                .sort((a, b) => servicioSeleccionado === 0 ? a.id - b.id : 0)
                .slice(paginaActual * elementosPorPagina, (paginaActual + 1) * elementosPorPagina)
                .length > 0 ? (
                resultadosBusqueda
                  .filter(filtroPlataformas)
                  .sort((a, b) => servicioSeleccionado === 0 ? a.id - b.id : 0)
                  .slice(paginaActual * elementosPorPagina, (paginaActual + 1) * elementosPorPagina)
                  .map((plataforma) => (
                    <TableRow key={plataforma.id} style={{
                      backgroundColor: plataforma.disponible === 0 ? '#f8d7da' : ''
                    }}>
                      <TableCell component="th" scope="row" align='center'>
                        {plataforma.plataforma || 'N/A'}
                      </TableCell>
                      <TableCell align="center">
                        {plataforma.plataforma_servicio && plataforma.plataforma_servicio.tipo_servicio ? plataforma.plataforma_servicio.tipo_servicio : 'N/A'}
                      </TableCell>
                      <TableCell align="center">
                        {
                          plataforma.borrado === 1 ? (
                            <Typography color="error">Borrada</Typography>
                          ) : plataforma.disponible === 0 ? (
                            <Typography color="error">Ocupada</Typography>
                          ) : (
                            <Typography style={{ color: 'green' }}>Libre</Typography>
                          )
                        }
                      </TableCell>
                      <TableCell align="center">
                        {
                          plataforma.borrado === 1 ? (
                            <Button
                              onClick={() => reactivarPlataforma(plataforma)}
                              sx={{ cursor: "pointer", color: "green" }}
                            >
                              Reactivar plataforma
                            </Button>
                          ) : plataforma.disponible === 1 ? (
                            <SettingsIcon
                              onClick={() => editThis(plataforma)}
                              sx={{ cursor: "pointer" }}
                            />
                          ) : (
                            <Button
                              onClick={() => updateDisponible(plataforma)}
                              sx={{ cursor: "pointer" }}
                            >
                              Actualizar estado
                            </Button>
                          )
                        }
                      </TableCell>
                      <TableCell align="center">
                        {
                          servicioSeleccionado !== 5 && plataforma.disponible === 1 && userInfo.rol === 1 &&
                          <DeleteIcon
                            onClick={() => {
                              deleteThis(plataforma);
                              updateList();
                            }}
                            sx={{ cursor: "pointer" }}
                          />
                        }
                      </TableCell>
                    </TableRow>
                  ))) : (
                <TableRow>
                  <TableCell colSpan={6} align="center">
                    Sin registros - Seleccionar otro filtro
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
            <StickyFooter>
              <TablePagination
                style={{ textAlign: 'right' }}
                rowsPerPageOptions={[5, 10, 25]}
                count={totalPlatform - 1}
                rowsPerPage={Number.isInteger(elementosPorPagina) ? elementosPorPagina : 0}
                page={paginaActual}
                onPageChange={(event, newPage) => {
                  if (newPage > totalPaginas) {
                    setPaginaActual(0);
                  } else {
                    setPaginaActual(newPage);
                  }
                }}
                SelectProps={{
                  inputProps: { 'aria-label': 'rows per page' },
                  native: true,
                }}
                onRowsPerPageChange={(event) => {
                  setElementosPorPagina(parseInt(event.target.value, 10));
                }}
                ActionsComponent={CustomTablePaginationActions}
                labelRowsPerPage="Filas por página"
                labelDisplayedRows={({ from, to, count }) => `Visualizando desde el registro ${from} al ${to} de ${count}`}
              />
            </StickyFooter>
          </Table>
        </div>
      </TableContainer>
      <DialogPlatform
        dataModal={selectedPlatform}
        openDialog={open}
        openFalse={openFalse}
        editadoFalse={editadoFalse}
      />
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
      >
        <DialogTitle>¿Estás seguro de que quieres eliminar esta plataforma?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Vas a borrar la plataforma {platformToDelete?.plataforma} con todos sus datos.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <CancelButton onClick={() => setOpenDialog(false)}>
            Cancelar
          </CancelButton>
          <DeleteButton onClick={confirmDelete} autoFocus>
            Borrar
          </DeleteButton>
        </DialogActions>
      </Dialog>
    </Box>
  );
}