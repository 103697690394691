import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { useParams } from 'react-router-dom';
import FormEditTicketOffPlatform from "../../components/forms/FormEditTicketOffPlatform";
import { CircularProgress } from '@mui/material';

const InformsEditTicketOffPlatform = () => {
  const [ticket, setTicket] = useState("");
  const token = sessionStorage.getItem("jwt");
  const params = useParams();
  const id = params.id;


  useEffect(() => {
    const url = `https://terminaldigital.com.ar/api/informes/${id}`;
    const config = { headers: { authorization: `Bearer ${token}` } };

    axios
      .get(url, config)
      .then((data) => {
        setTicket(data.data.ingresos);
      })
      .catch((error) => console.log("error get edit ticket:", error));
  }, [id, token]);

  return (
    <>
      {ticket === "" ? (
        <CircularProgress />
      ) : (
        <FormEditTicketOffPlatform ticket={ticket} />
      )}
    </>
  );
};

export default InformsEditTicketOffPlatform