import React from 'react'
import { Button, Grid, MenuItem, Typography, Box, TextField } from '@mui/material'
import { Stack } from '@mui/system'
import { Link } from "react-router-dom";
import ReplyIcon from "@mui/icons-material/Reply";
import { useFormik } from 'formik';
import * as yup from 'yup';
import axios from 'axios'
import FormField from '../forms/FormField';
import { toast } from 'react-toastify';

const user = {
  nombre: "",
  apellido: "",
  rol: "",
  usuario: "",
  password: "1234",
  email: "",
  telefono: "",
};


const validationSchema = yup.object({
  nombre: yup.string().required("*Campo requerido"),
  apellido: yup.string().required("*Campo requerido"),
  usuario: yup.string().required("*Campo requerido"),
  password: yup.string().required("*Campo requerido"),
  rol: yup.number().required("*Campo requerido"),
  email: yup.string().email("Email inválido").required("*Campo requerido"),
  telefono: yup.string().required("*Campo requerido"),
});

const FormCreateUser = () => {

  const formik = useFormik({
    initialValues: user,
    validationSchema: validationSchema,
    onSubmit: (values) => {

      const url = `https://terminaldigital.com.ar/api/users/register/`
      const data = formik.values

      axios.post(url, data)
        .then((res) => {
          if (res.status === 200) {
            toast.success('Usuario creado con éxito.')
            formik.resetForm();
          }
        })
        .catch(function (error) {
          toast.error('Error en la creación de usuario:' + String(error))

        });
    },
  });

  const resetForm = () => {
    formik.resetForm();
  };

  const ROLES = [
    { name: "Administración", value: 1 },
    { name: "Recursos Humanos", value: 2 },
    { name: "Supervisor", value: 3 },
    { name: "Operador de Seguridad", value: 4 },
    { name: "Informes", value: 5 },
  ];

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <Stack sx={{ background: "#F4F2EC", borderRadius: "25px", shadow: 4 }} my={4} mx={{ xs: 1, sm: 6 }} p={4} sm={6} width={{ xs: "60vw", md: "50vw" }} height={{ xs: "85vh", md: "75vh" }}>
        <form onSubmit={formik.handleSubmit}>
          <Link to="/usuarios">
            <Button
              sx={{
                color: "#b22222",
                marginLeft: { xs: "80%", sm: "90%", md: "95%" },
              }}
              variant="outlined"
              color="error"
              position="fixed"
            >
              <ReplyIcon />
            </Button>
          </Link>
          <Typography
            sx={{
              fontSize: { xs: "1.5rem", lg: "2rem" },
              fontFamily: 'Lato, sans-serif',
              color: '#0e315a',
              textShadow: '2px 2px 2px rgba(0, 0, 0, 0.25)',
              display: 'flex',
              justifyContent: 'start',
              marginTop: { xs: "0%", md: "-5%" }
            }}
          >
            Crear usuario
          </Typography>
          <Grid container my={1}>
            <Box marginLeft={{ xs: 0, md: 28 }}>
              <Grid item display={{ xs: 'block', sm: 'flex' }} alignItems='center' gap={2} xs={12} sm={6} md={12} my={2}>
                <TextField
                  label='Nombre'
                  sx={{
                    '.MuiOutlinedInput-notchedOutline': {
                      borderColor: 'black'
                    },
                    '.MuiInputBase-root': {
                      color: 'black'
                    },
                    width: { xs: "60vw", md: '20vw' }
                  }}
                  InputProps={{
                    type: "text"
                  }}
                  InputLabelProps={{
                    style: { color: 'black' },
                  }}
                  value={formik.values.nombre}
                  name='nombre'
                  onChange={formik.handleChange}
                  error={formik.errors.nombre}
                  helperText={formik.errors.nombre}
                />

              </Grid>
              <Grid item display={{ xs: 'block', md: 'flex' }} alignItems='center' gap={2} xs={12} sm={6} md={12} my={2}>
                <TextField
                  sx={{
                    '.MuiOutlinedInput-notchedOutline': {
                      borderColor: 'black'
                    },

                    '.MuiInputBase-root': {
                      color: 'black'
                    },
                    width: { xs: "60vw", md: '20vw' }
                  }}
                  InputLabelProps={{
                    style: { color: 'black' },
                  }}
                  label='Apellido'
                  name='apellido'
                  value={formik.values.apellido}
                  onChange={formik.handleChange}
                  error={formik.errors.apellido}
                  helperText={formik.errors.apellido}
                />
              </Grid>
              <Grid item display={{ xs: 'block', sm: 'flex' }} alignItems='center' gap={2} xs={12} sm={6} md={12} my={2}>
                <TextField
                  label='Usuario'
                  sx={{
                    '.MuiOutlinedInput-notchedOutline': {
                      borderColor: 'black'
                    },
                    '.MuiInputBase-root': {
                      color: 'black'
                    },
                    width: { xs: "60vw", md: '20vw' }
                  }}
                  InputLabelProps={{
                    style: { color: 'black' },
                  }}
                  InputProps={{
                    type: "text",
                  }}
                  name='usuario'
                  value={formik.values.usuario}
                  onChange={formik.handleChange}
                  error={formik.errors.usuario}
                  helperText={formik.errors.usuario}
                />
              </Grid>
              <Grid item xs={12} md={12} my={1}>
                <TextField
                  select
                  label='Rol del Usuario'
                  sx={{
                    '.MuiOutlinedInput-notchedOutline': {
                      borderColor: 'black'
                    },
                    '.MuiInputBase-root': {
                      color: 'black'
                    },
                    '& .MuiSvgIcon-root': {
                      color: 'black',
                    },
                    minWidth: '195px',
                    width: {xs: "60vw", md: '20vw' }
                  }}
                  InputLabelProps={{
                    style: { color: 'black' },
                  }}
                  name='rol'
                  value={formik.values.rol}
                  onChange={formik.handleChange}
                  error={formik.errors.rol}
                  helperText={formik.errors.rol}
                >
                  {ROLES.map((role) => (
                    <MenuItem key={role.name} value={role.value}>
                      {role.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item display={{ xs: 'block', sm: 'flex' }} alignItems='center' gap={2} xs={12} sm={6} md={12} my={2}>
                <TextField
                  label='Correo electrónico'
                  sx={{
                    '.MuiOutlinedInput-notchedOutline': {
                      borderColor: 'black'
                    },
                    '.MuiInputBase-root': {
                      color: 'black'
                    },
                    width: { xs: "60vw", md: '20vw' }
                  }}
                  InputLabelProps={{
                    style: { color: 'black' },
                  }}
                  InputProps={{
                    type: "email",
                  }}
                  name='email'
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={formik.errors.email}
                  helperText={formik.errors.email}
                />
              </Grid>
              <Grid item hidden xs={12} sm={6} my={1}>
                <FormField
                  name="password"
                  defaultValue="1234"
                  value="1234"
                  onChange={formik.handleChange}
                  error={formik.errors.password}
                  helperText={formik.errors.password}
                />

              </Grid>
              <Grid item display={{ xs: 'block', sm: 'flex' }} alignItems='center' gap={2} xs={12} sm={6} md={12} my={2}>
                <TextField
                  label='Telefono'
                  sx={{
                    '.MuiOutlinedInput-notchedOutline': {
                      borderColor: 'black'
                    },
                    '.MuiInputBase-root': {
                      color: 'black'
                    },
                    width: { xs: "60vw", md: '20vw' }
                  }}
                  InputLabelProps={{
                    style: { color: 'black' },
                  }}
                  InputProps={{
                    type: "number",
                  }}
                  name='telefono'
                  value={formik.values.telefono}
                  onChange={formik.handleChange}
                  error={formik.errors.telefono}
                  helperText={formik.errors.telefono}
                />
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12} align="center" pt={{ xs: "-3%", sm: 2 }}>
            <Button
              variant="outlined"
              onClick={resetForm}
              type={"reset"}
              py={2}
              my={4}
              mx={4}
              sx={{ color: "black", margin: "16px" }}
            >
              Limpiar registro
            </Button>
            <Button variant="contained" type="submit" py={2} my={4} mx={4}>
              Crear registro
            </Button>
          </Grid>
        </form>
      </Stack>
    </div>
  );

}

export default FormCreateUser