import React, { useEffect, useState } from "react";
import { Box, Skeleton, Button, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TextField, Grid, TableRow, Paper, Stack } from '@mui/material';
import { styled, alpha } from "@mui/material/styles";
import { format } from 'date-fns';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import { Link } from "react-router-dom";

export default function TableUsersLogs({ data, count, rowsPerPage, page, onPageChange, onRowsPerPageChange, renderTextField, fechaInicio, setFechaInicio, fechaFin, setFechaFin, setShouldFetch }) {

  const [searchText, setSearchText] = React.useState('');
  const [filteredData, setFilteredData] = useState(data);

  const StickyFooter = styled(TableFooter)(({ theme }) => ({
    position: 'sticky',
    bottom: -20,
    zIndex: theme.zIndex.appBar - 1,
    backgroundColor: theme.palette.background.paper,
  }));

  useEffect(() => {
    setFilteredData(
      data?.filter((row) => {
        return (
          row.tipo_de_estado?.toLowerCase().includes(searchText.toLowerCase()) ||
          row.createdAt?.toLowerCase().includes(searchText.toLowerCase()) ||
          row.usuario_log?.toLowerCase().includes(searchText.toLowerCase())
        );
      })
    );
  }, [data, searchText]);

  const style = {
    width: "100%",
    textAlign: "center",
    align: "center",
  };

  const StyledButton = styled(Button)`
  background-color: #0e315a;
  border: none;
  color: white;
  padding: 15px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: "1rem";
  margin: 4px 2px 0 20px;
  cursor: pointer;
  vertical-align: middle; 
  &:hover {
    background-color: #4CAF50; 
  }

`;

  return (
    <Stack direction='row' justifyContent={{ xs: 'center', sm: 'flex-end' }} pr={{ xs: 0, sm: 0 }} pb={2} pt={0}>
      <TableContainer
        component={Paper}
        style={style}
      >
        <Box >
          <Box
            px={4}
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            marginTop={{xs:5, md:4}}
            marginBottom={4}
          >
            <Grid item xs={12} sm={4}>
              <TextField
                label="Buscar"
                value={searchText}
                onChange={(event) => setSearchText(event.target.value)}
                sx={{ width: { xs: "90%" } }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                  sx: {
                    color: 'black',
                    '&::placeholder': {
                      color: 'black',
                    },
                    '&.Mui-focused': {
                      color: 'black',
                    },
                  }
                }}
              />
            </Grid>
            <Grid
              container
              item
              xs={12}
              md={6}
              sx={{ display: { xs: "none", md: "block" }, background: "#fff", padding: 5 }}
            >
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                {renderTextField("fecha-inicio", "Fecha de inicio", format(fechaInicio, 'yyyy-MM-dd'), (e) => setFechaInicio(new Date(e.target.value + 'T00:00:00')))}
                {renderTextField("fecha-fin", "Fecha de fin", format(fechaFin, 'yyyy-MM-dd'), (e) => setFechaFin(new Date(e.target.value + 'T00:00:00')))}
                <StyledButton onClick={() => setShouldFetch(true)}>Buscar</StyledButton>
              </Box>
            </Grid>
          </Box>
          <Table aria-label="simple table" id="rrhh">
            <TableHead>
              <TableRow>
                <TableCell align="center">Registro</TableCell>
                <TableCell align="center">Operación</TableCell>
                <TableCell align="center">Usuario</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredData.length > 0 ? (
                filteredData.map((row) => (
                  <TableRow
                    key={row.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="center">{row.createdAt}</TableCell>
                    <TableCell align="center">{row.tipo_de_estado}</TableCell>
                    <TableCell align="center">{row.usuario_log}</TableCell>
                  </TableRow>
                ))
              ) : ([
                <TableRow key="no-data">
                  <TableCell colSpan={3} align="center">No hay registros, verifica las fichas</TableCell>
                </TableRow>,
                ...Array.from(new Array(5)).map((_, index) => (
                  <TableRow key={index}>
                    {Array.from(new Array(3)).map((_, cellIndex) => (
                      <TableCell key={cellIndex}>
                        <Skeleton />
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              ]
              )}
            </TableBody>
            <StickyFooter>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 100, 1000]}
                count={count}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={onPageChange}
                onRowsPerPageChange={onRowsPerPageChange}
                labelRowsPerPage="Filas por página"
              />
            </StickyFooter>
          </Table>
        </Box>
      </TableContainer>
    </Stack>
  );
}
