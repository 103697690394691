import React, { useState } from "react";
import {
  Button,
  FormControlLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import axios from "axios";
import BasicModal from "../modals/Modal";
import { Link } from "react-router-dom";
import ReplyIcon from "@mui/icons-material/Reply";

const validationSchema = yup.object({
  estado_id: yup.string().required("Campo requerido"),
  usuarios_id: yup.string().required("Campo requerido"),
});

const InformsEditTicketError = ({ ticket }) => {
  console.log("🚀 ~ InformsEditTicketError ~ ticket:", ticket)

  const [fechaHoraSalida, setFechaHoraSalida] = useState(
    ticket.tipo_tv_id === 2 ? "true" : "false"
  );
  const [openModal, setOpenModal] = useState(false);
  const [dataDropdown, setDataDropdown] = useState({});
  console.log("🚀 ~ InformsEditTicketError ~ dataDropdown:", dataDropdown)
  const navigate = useNavigate();
  const token = sessionStorage.getItem("jwt");


  const dateNow = new Date();
  const menosUno = 1;
  dateNow.setHours(menosUno);
  const year = dateNow.getFullYear();
  const monthWithOffset = dateNow.getUTCMonth() + 1;
  const month =
    monthWithOffset.toString().length < 2
      ? `0${monthWithOffset}`
      : monthWithOffset;
  const date =
    dateNow.getUTCDate().toString().length < 2
      ? `0${dateNow.getUTCDate()}`
      : dateNow.getUTCDate();

  const initialTicket = {
    fecha_ingreso: "",
    hora_ingreso: "",
    interno: "",
    empresa_id: "",
    servicios_id: "",
    usuarios_id: "",
    plataformas_id: ticket.registro_plataforma.id && dataDropdown.plataformas_selected ? dataDropdown.plataformas_selected.id : null,
    estado_id: "",
    tipo_tv: "",
    destino: "",
    hora_salida: "",
    fecha_salida: "",
    operacion_id: "",
  };

  const editTicket = {
    fecha_ingreso: ticket.fecha_ingreso,
    hora_ingreso: ticket.hora_ingreso,
    interno: ticket.interno,
    usuarios_id: ticket.usuarios_id,
    plataformas_id: ticket.plataformas_id || null,
    estado_id: ticket.registro_estado.id,
    destino: ticket.destino,
    tipo_tv: ticket.tipo_tv_id,
    fecha_salida: `${year}-${month}-${date}`,
    hora_salida: ticket.hora_salida,
    empresa_id: ticket?.empresa_id,
    servicios_id: ticket?.registro_servicio.id,
    operacion_id: 2,
  };


  const inputStyle = {
    ".MuiOutlinedInput-notchedOutline": {
      borderColor: "black",
      transition: "250ms all ease",
    },
    ".MuiInputBase-root": {
      color: "black",
      transition: "250ms all ease",
    },
    ".MuiSvgIcon-root": {
      color: "black",
    },
    ".css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root": {
      color: "black",
    },
    "input:hover": {
      background: "transparent",

    },
    ".MuiInputBase-root:hover ": {


      boxShadow: " inset 0 0 9px rgb(63, 100, 143)",
    },
    ".MuiInputBase-root:hover .MuiSvgIcon-root": {
      color: "rgb(19, 46, 77)",
      border: "none",
    },
    ".css-md26zr-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
    {
      border: "none",
    },
    ".css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
    {
      borderColor: "black",
    },
  };

  React.useEffect(() => {
    const url = "https://terminaldigital.com.ar/api/informes/dataDropdown";

    axios
      .get(url, { headers: { authorization: `Bearer ${token}` } })
      .then((response) => setDataDropdown(response.data))
      .catch((error) => console.log("error jwt:", error.response.data.message));

  }, [token]);

  const formik = useFormik({
    initialValues: editTicket || initialTicket,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      const config = { headers: { authorization: `Bearer ${token}` } };
      const url = `https://terminaldigital.com.ar/api/informes/modificarData/${ticket.id}`;
      const data = formik.values;



      axios
        .patch(url, data, config)
        .then((res) => {
          if (res.status === 200) {

            setOpenModal(true);
            navigate(-1);
          }
        })
        .catch(function (error) {
          console.log("Error:", error);
        });
    },
  });
  const onClickFechaHoraSalida = () => {
    setFechaHoraSalida("true");
  };
  const onClickFechaHoraArribo = () => {
    setFechaHoraSalida("false");
  };
  return (
    <Stack
      sx={{ background: "#F4F2EC", borderRadius: "25px", shadow: 4 }}
      my={4}
      mx={{ xs: 1, sm: 6 }}
      p={4}
      sm={6}
    >
      <form onSubmit={formik.handleSubmit}>
        <Link to="/informes">
          <Button
            variant="text"
            py={2}
            my={4}
            mx={4}
            sx={{ color: "#b22222", marginLeft: { xs: "90%", md: "95%" } }}
          >
            <ReplyIcon />
          </Button>
        </Link>
        <Typography sx={{
          fontSize: { lg: "2rem" },
          fontFamily: 'Lato, sans-serif',
          color: '#0e315a',
          textShadow: '2px 2px 2px rgba(0, 0, 0, 0.25)',
          display: 'flex',
          justifyContent: 'start',
          alignItems: 'center',
          margin: 5,
          ml: 1
        }} >
          Editar ticket
        </Typography>
        <Grid container my={4}>
          <Grid
            item
            display={{ xs: "block", md: "flex" }}
            alignItems="center"
            gap={2}
            xs={12}
            sm={4.3}
            my={2}
          >
            <Typography
              variant="subtitle1"
              color="black"
              display={{ xs: "none", sm: "block" }}
            >
              Estado:
            </Typography>
            <RadioGroup
              row
              aria-labelledby="Estado"
              fullWidth
              sx={inputStyle}
              name="estado_id"
              value={formik.values.estado_id}
              onChange={formik.handleChange}
              error={formik.errors?.estado_id}
              helperText={formik.errors?.estado_id}
            >
              <FormControlLabel
                value={"1"}
                control={<Radio />}
                sx={{ color: "black" }}
                label="En Plataforma"
              />
              <FormControlLabel
                value={"4"}
                control={<Radio />}
                sx={{ color: "black" }}
                label="Fuera de Plataforma"
              />
            </RadioGroup>
          </Grid>
          <Grid
            item
            display={{ xs: "block", md: "flex" }}
            alignItems="center"
            gap={2}
            xs={12}
            md={3}
            my={2}
          >
            <Typography variant="subtitle1" color="black">
              Plataforma:
            </Typography>
            <TextField
              label="Seleccionada"
              select
              sx={{
                ".MuiOutlinedInput-notchedOutline": {
                  borderColor: "black",
                },
                ".MuiInputBase-root": {
                  color: "black",
                },
                width: "50%",
              }}
              InputLabelProps={{
                style: { color: "black" },
              }}
              name="plataformas_id"
              value={formik.values.plataformas_id}
              onChange={formik.handleChange}
              error={formik.errors.plataformas_id}
              helperText={formik.errors.plataformas_id}
            >
              {
                [
                  ...(dataDropdown.plataformas?.filter(plataforma => plataforma.servicios_id === ticket.servicios_id) || []),
                  ...(dataDropdown.plataforma_selected?.filter(plataforma => plataforma.id === ticket.plataformas_id) || [])
                ].map((plataforma) => (
                  <MenuItem
                    key={plataforma.id}
                    value={plataforma.id}
                  >
                    {plataforma.plataforma}
                  </MenuItem>
                ))
              }
            </TextField>
          </Grid>
          <Grid
            item
            display={{ xs: "block", md: "flex" }}
            alignItems="center"
            gap={2}
            xs={12}
            sm={fechaHoraSalida === "true" ? 4 : 4}
            my={2}
          >
            <Typography
              variant="subtitle1"
              color="black"
              display={{ xs: "none", sm: "block" }}
            >
              Tipo de tv:
            </Typography>
            <RadioGroup
              row
              aria-labelledby="Estado"
              fullWidth
              sx={inputStyle}
              name="tipo_tv"
              value={formik.values.tipo_tv}
              onChange={formik.handleChange}
              error={formik.errors.tipo_tv}
              helperText={formik.errors.tipo_tv}
            >
              <FormControlLabel
                value={"1"}
                control={<Radio />}
                onClick={onClickFechaHoraArribo}
                sx={{ color: "black" }}
                label="Arribos"
              />
              <FormControlLabel
                value={"2"}
                control={<Radio />}
                onClick={onClickFechaHoraSalida}
                sx={{ color: "black" }}
                label="Partidas"
              />
            </RadioGroup>
          </Grid>
          <Grid
            item
            display={{ xs: fechaHoraSalida === "true" ? "block" : "none" }}
            alignItems="center"
            gap={2}
            xs={12}
            sm={1.8}
            my={4}
          >
            <TextField
              label="Fecha de salida"
              sx={{
                ".MuiOutlinedInput-notchedOutline": {
                  borderColor: "black",
                },
                ".MuiInputBase-root": {
                  color: "black",
                },
              }}
              InputLabelProps={{
                style: { color: "black" },
              }}
              InputProps={{
                type: "date",
              }}
              name="fecha_salida"
              value={formik.values.fecha_salida}
              onChange={formik.handleChange}
              error={formik.errors.fecha_salida}
              helperText={formik.errors.fecha_salida}
            />
          </Grid>
          <Grid
            item
            display={{ xs: fechaHoraSalida === "true" ? "block" : "none" }}
            alignItems="center"
            gap={2}
            xs={12}
            sm={1.2}
            my={4}
          >
            <TextField
              label={ticket.hora_salida}
              sx={{
                ".MuiOutlinedInput-notchedOutline": {
                  borderColor: "black",
                },
                ".MuiInputBase-root": {
                  color: "black",
                },
                "& .MuiSvgIcon-root": {
                  color: "black",
                },
              }}
              InputLabelProps={{
                style: { color: "black" },
              }}
              InputProps={{
                type: "time",
              }}
              value={formik.values.hora_salida}
              name="hora_salida"
              onChange={formik.handleChange}
              error={formik.errors.hora_salida}
              helperText={formik.errors.hora_salida}
            />
          </Grid>
          <Grid
            item
            display={{ xs: "block", sm: "flex" }}
            alignItems="center"
            gap={2}
            xs={12}
            sm={1.4}
            my={2}
          >
            <TextField
              label="Interno"
              sx={{
                ".MuiOutlinedInput-notchedOutline": {
                  borderColor: "black",
                },
                ".MuiInputBase-root": {
                  color: "black",
                  width: "76%",
                },
              }}
              InputProps={{
                type: "text",
              }}
              InputLabelProps={{
                style: { color: "black" },
              }}
              value={formik.values.interno}
              name="interno"
              onChange={formik.handleChange}
              error={formik.errors.interno}
              helperText={formik.errors.interno}
            />
          </Grid>
          <Grid
            item
            display={{ xs: "none", md: "none" }}
            alignItems="center"
            gap={2}
            xs={12}
            sm={6}
            my={2}
          >
            <Typography
              variant="subtitle1"
              color="black"
              display={{ xs: "none", sm: "block" }}
            >
              Usuario ID:
            </Typography>
            <TextField
              sx={{
                ".MuiOutlinedInput-notchedOutline": {
                  borderColor: "black",
                },

                ".MuiInputBase-root": {
                  color: "black",
                },
              }}
              InputLabelProps={{
                style: { color: "black" },
                readOnly: true,
              }}
              label="Inserte usuario ID"
              name="usuarios_id"
              value={formik.values.usuarios_id}
              onChange={formik.handleChange}
              error={formik.errors.usuarios_id}
              helperText={formik.errors.usuarios_id}
            />
          </Grid>
          <Grid
            item
            display={{ xs: "block", sm: "flex" }}
            alignItems="center"
            gap={2}
            xs={12}
            sm={1.7}
            my={2}
          >
            <TextField
              label="Fecha Ingreso"
              sx={{
                ".MuiOutlinedInput-notchedOutline": {
                  borderColor: "black",
                },
                ".MuiInputBase-root": {
                  color: "black",
                  width: "90%",
                },
              }}
              InputProps={{
                type: "date",
              }}
              InputLabelProps={{
                style: { color: "black" },
              }}
              name="fecha_ingreso"
              value={formik.values.fecha_ingreso}
              onChange={formik.handleChange}
              error={formik.errors.fecha_ingreso}
              helperText={formik.errors.fecha_ingreso}
            />
          </Grid>
          <Grid
            item
            display={{ xs: "block", sm: "flex" }}
            alignItems="center"
            gap={2}
            xs={12}
            sm={1.2}
            my={2}
          >
            <TextField
              label="Hora Ingreso"
              sx={{
                ".MuiOutlinedInput-notchedOutline": {
                  borderColor: "black",
                },
                ".MuiInputBase-root": {
                  width: "100%",
                  color: "black",
                },
              }}
              InputProps={{
                type: "time",
              }}
              InputLabelProps={{
                style: { color: "black" },
              }}
              value={formik.values.hora_ingreso}
              name="hora_ingreso"
              onChange={formik.handleChange}
              error={formik.errors.hora_ingreso}
              helperText={formik.errors.hora_ingreso}
            />
          </Grid>
          <Grid
            item
            display={{ xs: "block", md: "flex" }}
            alignItems="center"
            gap={2}
            xs={12}
            md={2}
            my={2}
          >
            <TextField
              select
              label={ticket.registro_empresa.empresa}
              sx={{
                ".MuiOutlinedInput-notchedOutline": {
                  borderColor: "black",
                },
                ".MuiInputBase-root": {
                  color: "black",
                  width: "90%",
                },
                "& .MuiSvgIcon-root": {
                  color: "black",
                },
                minWidth: "200px",
              }}
              InputLabelProps={{
                style: { color: "black" },
              }}
              name="empresa_id"
              value={formik.registro_empresa?.id}
              onChange={formik.handleChange}
              error={formik.errors.empresa_id}
              helperText={formik.errors.empresa_id}
            >
              {dataDropdown.empresas?.map((empresa) => (
                <MenuItem key={empresa.id} value={empresa.id} selected={true}>
                  {empresa.empresa}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid
            item
            display={{ xs: "block", md: "flex" }}
            alignItems="center"
            gap={2}
            xs={12}
            md={2.7}
            my={2}
          >
            <TextField
              select
              label={ticket.registro_servicio.tipo_servicio}
              sx={{
                ".MuiOutlinedInput-notchedOutline": {
                  borderColor: "black",
                },
                ".MuiInputBase-root": {
                  color: "black",
                },
                "& .MuiSvgIcon-root": {
                  color: "black",
                },
                minWidth: "260px",
              }}
              InputLabelProps={{
                style: { color: "black" },
              }}
              name="servicios_id"
              value={formik.servicios_id}
              onChange={formik.handleChange}
              error={formik.errors.servicios_id}
              helperText={formik.errors.servicios_id}
            >
              {dataDropdown.servicios?.map((servicio) => (
                <MenuItem key={servicio.id} value={servicio.id} selected={true}>
                  {servicio.tipo_servicio} - {servicio.siglas}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid
            item
            display={{ xs: "block", md: "flex" }}
            alignItems="center"
            gap={2}
            xs={12}
            md={2}
            my={2}
          >
            <TextField
              sx={{
                ".MuiOutlinedInput-notchedOutline": {
                  borderColor: "black",
                },
                ".MuiInputBase-root": {
                  color: "black",
                },
              }}
              InputLabelProps={{
                style: { color: "black" },
              }}
              label=" Destino / Origen"
              name="destino"
              value={formik.values.destino}
              onChange={formik.handleChange}
              error={formik.errors.destino}
              helperText={formik.errors.destino}
            />
          </Grid>

          <Grid item sx={{ marginRight: "auto" }} align="center" xs={12} pt={4}>
            <Button variant="contained" ml="auto" type="submit" my={2}>
              Editar ingreso
            </Button>
          </Grid>
          {openModal && (
            <>
              <BasicModal
                title="Éxito"
                message="El registro fue modificado"
                openModal={openModal}
                type="ingresos"
              />
            </>
          )}
        </Grid>
      </form>
    </Stack>
  );
};


export default InformsEditTicketError;
