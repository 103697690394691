/* TABLERO DE SEGURIDAD */

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { Box, Stack, Typography } from '@mui/material';
import axios from 'axios'
import io from 'socket.io-client';
import { toast, ToastContainer } from 'react-toastify';

import TableAdminSeguridadHome from "../../components/table/TableAdminSeguridadHome";

// const socket = io();

const socket = io('https://terminaldigital.com.ar');

const SecurityHome = () => {
  
  const [ arrivals, setArrivals ] = useState([])

  const token = window.sessionStorage.getItem('jwt')

  // FETCH DATA
  const getArrivals = () => {
    const url = "https://terminaldigital.com.ar/api/informes/listadoSeparado";
    const config = { headers: { authorization: `Bearer ${token}` } };

    axios.get(url, config)
      .then(data => {
        // console.log('listado:',data.data.respuesta )
        setArrivals(data.data.respuesta.ingresandoSeguridad)
      })
      .catch(error => console.log('error security home', error))
  };

  useEffect(() => {
    socket.on('newRegister', ({ message, shouldRerender }) => {
      if (shouldRerender && message) {
        toast.success(message,{ position: toast.POSITION.TOP_RIGHT, autoClose: 8000, fontSize: '1.5rem', color: 'black' });
        getArrivals();
      }
      if (shouldRerender) {
        getArrivals();
      }
    });
    socket.on('connect', () => {
      console.log('Conectado al servidor');
    });
    return () => {
      socket.off('newRegister');
    };
  }, []);

  useEffect(() => {
    getArrivals();
    /*const interval = setInterval(() => {
        getArrivals();
    }, 20000);
    return () => {
        clearInterval(interval);
    };*/
  }, []);

  
  const styles = {
    margin:'auto', 
    width: '100%',
    background: '#0e315a',
    color: 'white',
    paddingBottom: '32px',
  }

  const typographyStyles = {
    paddingBlock: '2vh'
  }
  

  return (
    <Stack>
      <Box
        style={styles}
        sx={{ minHeight: { xs: "71vh", sm: "77vh", md: "58vh", lg: "63vh" } }}
      >
     
        {/* <Typography
          align="center"
          variant="h4"
          style={typographyStyles}
          sx={{
            fontSize: { xs: "25px", md: "45px" },
          }}
        >
          CONTROL DE ACCESOS
        </Typography> */}
        <TableAdminSeguridadHome data={arrivals} edit={true} />
      </Box>
    </Stack>
  );
}

export default SecurityHome