import React, { useState } from "react";
import {
  Button,
  Divider,
  FormControlLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import ReplyIcon from "@mui/icons-material/Reply";
import { useFormik } from "formik";
import * as yup from "yup";
import axios from "axios";
import BasicModal from "../modals/Modal";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { toast } from 'react-toastify';


const validationSchema = yup.object({
  
  estado_id: yup.string().required("Campo requerido"),
  usuarios_id: yup.string().required("Campo requerido"),
 
});
const style = {
  background: "#0e315a",
  color: "white",
  ".MuiAccordionSummary-root .MuiSvgIcon-root": {
    color: "white",
  },
  "Mui-expanded": {
    margin: "0px",
  },
  "&:focus": {
    background: "#0e315a",
  },
};

const FormEditTicketOnPlatform = ({ ticket }) => {
  const [fechaHoraSalida, setFechaHoraSalida] = useState(
    ticket.tipo_tv_id === 2 ? "true" : "false"
  );
  const [openModal, setOpenModal] = useState(false);
  const [dataDropdown, setDataDropdown] = useState({});
  const [horaSalida, setHoraSalida] = useState(null);
  const token = sessionStorage.getItem("jwt");
  const navigate = useNavigate();
  const [expanded, setExpanded] = React.useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const getHour = () => {
    const dateNow = new Date();
    let hours = dateNow.getHours();

    if (hours < 10) {
      hours = 0 + `${hours}`;
      return hours;
    } else {
      hours = `${hours}`;
      return hours;
    }
  };

  const getMinutes = () => {
    const dateNow = new Date();
    let minutes = dateNow.getMinutes();

    if (minutes < 10) {
      minutes = 0 + `${minutes}`;
      return minutes;
    } else {
      minutes = `${minutes}`;
      return minutes;
    }
  };

  const onClickFueraPlataforma = () => {
    setHoraSalida(`${getHour()}:${getMinutes()}`);
  };

  const initialTicket = {
    fecha_ingreso: "", 
    hora_ingreso: "", 
    interno: "", 
    empresa_id: "", 
    servicios_id: "", 
    usuarios_id: "", 
    plataformas_id: ticket.plataformas_id || null,
    estado_id: "", 
    tipo_tv: "", 
    destino: "", 
    hora_salida: "",
    fecha_salida: "",
  };

  const editTicket = {
    fecha_ingreso: ticket.fecha_ingreso, 
    hora_ingreso: ticket.hora_ingreso, 
    interno: ticket.interno, 
    usuarios_id: ticket.usuarios_id, 
    plataformas_id: ticket.plataformas_id || null,
    estado_id: ticket.registro_estado.id, 
    destino: ticket.destino, 
    tipo_tv: ticket.tipo_tv_id, 
    fecha_salida: ticket.fecha_salida,
    hora_salida: horaSalida === null ? ticket.hora_salida : horaSalida,
    operacion_id: 4,
  };

  React.useEffect(() => {
    const url = "https://terminaldigital.com.ar/api/informes/dataDropdown";

    axios
      .get(url, { headers: { authorization: `Bearer ${token}` } })
      .then((response) => setDataDropdown(response.data))
      .catch((error) => console.log("error jwt:", error.response.data.message));

    //console.log('dataDropdown:', data)
  }, [token]);

  const inputStyle = {
    ".MuiOutlinedInput-notchedOutline": {
      borderColor: "#fff",
      transition: "250ms all ease",
    },
    ".MuiInputBase-root": {
      color: "white",
      transition: "250ms all ease",
    },
    ".MuiSvgIcon-root": {
      color: "white",
    },
    ".css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root": {
      color: "white",
    },
    "input:hover": {
      background: "transparent",
      
    },
    ".MuiInputBase-root:hover ": {
      
      
      boxShadow: " inset 0 0 9px rgb(63, 100, 143)",
    },
    ".MuiInputBase-root:hover .MuiSvgIcon-root": {
      color: "rgb(19, 46, 77)",
      border: "none",
    },
    ".css-md26zr-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
      {
        border: "none",
      },
    ".css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
      {
        borderColor: "white",
      },
  };

  const estadoMap = {
    1: 'en plataforma',
    2: 'ingresando',
    3: 'servicio sin plataforma',
    4: 'fuera de plataforma',
  };

  let initialValues = editTicket || initialTicket;

  if (initialValues.estado_id !== '2') {
    initialValues = {
      ...initialValues,
      estado_id: '4',
    };
  }
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      const config = { headers: { authorization: `Bearer ${token}` } };
      const url = `https://terminaldigital.com.ar/api/informes/modificar/${ticket.id}`;
      const data = formik.values;

      //console.log('data to send:', data)

      axios
        .patch(url, data, config)
        .then((res) => {
          if (res.status === 200) {
            setOpenModal(true);
            const estadoId = Number(formik.values.estado_id);
            const tipoEstado = estadoMap[estadoId] || 'Estado desconocido';
            toast.success(`${ticket.id} enviada a ${tipoEstado} con éxito`)
            navigate(-1); 
          }
        })
        .catch(function (error) {
          console.log("Error:", error);
        });
    },
  });
  const onClickFechaHoraSalida = () => {
    setFechaHoraSalida("true");
  };
  const onClickFechaHoraArribo = () => {
    setFechaHoraSalida("false");
  };
  return (
    <Stack
      sx={{ background: "#F4F2EC", borderRadius: "25px", shadow: 4 }}
      my={4}
      mx={{ xs: 1, sm: 6 }}
      p={4}
      sm={6}
    >
      <form onSubmit={formik.handleSubmit}>
        <Link to="/informes">
          <Button
            variant="outlined"
            color="error"
            py={2}
            my={4}
            md={6}
            sx={{
              color: "#b22222",
              marginLeft: { xs: "85%", sm: "92%", md: "95%" },
            }}
          >
            <ReplyIcon />
          </Button>
        </Link>
        <Typography
          sx={{ fontSize: { xs: "1.25rem", lg: "2.18rem" } }}
          color="white"
        >
          Editar ticket en plataforma
        </Typography>
        <Grid container my={4}>
          <Grid
            item
            display={{ xs: "block", md: "flex" }}
            alignItems="center"
            gap={2}
            xs={12}
            sm={4.3}
            my={2}
          >
            <Typography
              variant="subtitle1"
              color="white"
              display={{ xs: "block", sm: "block" }}
            >
              Estado:
            </Typography>
            <RadioGroup
              row
              aria-labelledby="Estado"
              fullWidth
              name="estado_id"
              sx={inputStyle}
              value={formik.values.estado_id}
              onChange={formik.handleChange}
              error={formik.errors?.estado_id}
              helperText={formik.errors?.estado_id}
            >
              <FormControlLabel
                value={"4"}
                control={<Radio />}
                onClick={onClickFueraPlataforma}
                sx={{ color: "black" }}
                label="Fuera de Plataforma"
              />
              <FormControlLabel
                value={"2"}
                control={<Radio />}
                sx={{ color: "black" }}
                label="Ingresando"
              />
            </RadioGroup>
          </Grid>
          <Grid
            item
            display={{ xs: "block", md: "flex" }}
            alignItems="center"
            gap={2}
            xs={6}
            md={2}
            my={2}
          >
            <Typography variant="subtitle1" color="white" mb={{ xs: 1, sm: 0 }}>
              Plataforma:
            </Typography>
            <TextField
              select
              sx={{
                ".MuiOutlinedInput-notchedOutline": {
                  borderColor: "white",
                },
                ".MuiInputBase-root": {
                  color: "#fff",
                  width: "100%",
                  fontSize: "1.5rem",
                },
              }}
              InputLabelProps={{
                style: { color: "#fff" },
              }}
              name="plataformas_id"
              value={formik.values.plataformas_id}
              InputProps={{
                readOnly: true,
              }}
            >
              {dataDropdown.plataforma_selected?.map((plataforma) => (
                <MenuItem
                  key={plataforma.id}
                  value={plataforma.id}
                >
                  {plataforma.plataforma}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid
            item
            display={{ xs: "block", md: "flex" }}
            alignItems="center"
            gap={2}
            xs={4}
            sm={fechaHoraSalida === "true" ? 4 : 4}
            my={2}
          >
            <Typography
              variant="subtitle1"
              color="white"
              display={{ xs: "block", sm: "block" }}
            >
              Tipo de tv:
            </Typography>
            <RadioGroup
              row
              aria-labelledby="Estado"
              fullWidth
              name="tipo_tv"
              sx={inputStyle}
              value={formik.values.tipo_tv}
              onChange={formik.handleChange}
              error={formik.errors.tipo_tv}
              helperText={formik.errors.tipo_tv}
            >
              <FormControlLabel
                value={"1"}
                control={<Radio />}
                onClick={onClickFechaHoraArribo}
                sx={{ color: "black" }}
                label="Arribos"
              />
              <FormControlLabel
                value={"2"}
                control={<Radio />}
                onClick={onClickFechaHoraSalida}
                sx={{ color: "black" }}
                label="Partidas"
              />
            </RadioGroup>
          </Grid>
          <Grid
            item
            display={{
              xs: fechaHoraSalida === "true" ? "block" : "none",
            }}
            alignItems="center"
            gap={2}
            xs={6}
            sm={4}
            md={2}
            my={2}
          >
            <Typography variant="subtitle1" color="white" mb={{ xs: 0, sm: 0 }}>
              Fecha de salida:
            </Typography>
            <TextField
              sx={{
                ".MuiOutlinedInput-notchedOutline": {
                  borderColor: "white",
                },
                ".MuiInputBase-root": {
                  color: "white",
                  width: "120%",
                },
              }}
              InputProps={{
                type: "date",
              }}
              name="fecha_salida"
              value={formik.values.fecha_salida}
              onChange={formik.handleChange}
              error={formik.errors.fecha_salida}
              helperText={formik.errors.fecha_salida}
            />
          </Grid>
          <Grid
            item
            display={{ xs: fechaHoraSalida === "true" ? "block" : "none" }}
            alignItems="center"
            gap={2}
            xs={12}
            sm={4}
            md={6}
            my={2}
            ml={{xs:0, lg:5}}
          >
            <Typography variant="subtitle1" color="white" mb={{ xs: 1, sm: 0 }}>
              Hora de salida:
            </Typography>
            <TextField
              sx={{
                ".MuiOutlinedInput-notchedOutline": {
                  borderColor: "white",
                },
                ".MuiInputBase-root": {
                  color: "white",
                  width: "150%",
                },
                "& .MuiSvgIcon-root": {
                  color: "white",
                },
              }}
              InputProps={{
                type: "time",
              }}
              value={formik.values.hora_salida}
              name="hora_salida"
              onChange={formik.handleChange}
              error={formik.errors.hora_salida}
              helperText={formik.errors.hora_salida}
            />
          </Grid>
          <Grid item xs={12} sm={12} gap={2} py={4}>
            <Accordion
              expanded={expanded === "panel1"}
              onChange={handleChange("panel1")}
              sx={style}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Divider
                  sx={{
                    color: "white",
                    textAlign: "left",
                    fontSize: {
                      xs: "1rem",
                      sm: "1.10",
                      md: "1.20rem",
                      lg: "1.25rem",
                    },
                  }}
                >
                  DATOS DEL TICKET
                </Divider>
              </AccordionSummary>
              <AccordionDetails>
                <Grid
                  item
                  display={{ xs: "block", sm: "flex" }}
                  alignItems="center"
                  gap={2}
                  xs={12}
                  sm={2}
                  my={2}
                >
                  <TextField
                    label="Interno"
                    sx={{
                      ".MuiOutlinedInput-notchedOutline": {
                        borderColor: "white",
                      },
                      ".MuiInputBase-root": {
                        color: "white",
                        width: "76%",
                      },
                    }}
                    InputProps={{
                      type: "text",
                      readOnly: true,
                    }}
                    InputLabelProps={{
                      style: { color: "#fff" },
                    }}
                    value={formik.values.interno}
                    name="interno"
                    onChange={formik.handleChange}
                    error={formik.errors.interno}
                    helperText={formik.errors.interno}
                  />
                </Grid>
                <Grid
                  item
                  display={{ xs: "block", sm: "flex" }}
                  alignItems="center"
                  gap={2}
                  xs={12}
                  sm={2}
                  my={2}
                >
                  <TextField
                    label="Fecha Ingreso"
                    sx={{
                      ".MuiOutlinedInput-notchedOutline": {
                        borderColor: "white",
                      },
                      ".MuiInputBase-root": {
                        color: "white",
                        width: "85%",
                      },
                    }}
                    InputProps={{
                      type: "date",
                      readOnly: true,
                    }}
                    InputLabelProps={{
                      style: { color: "#fff" },
                    }}
                    name="fecha_ingreso"
                    value={formik.values.fecha_ingreso}
                    onChange={formik.handleChange}
                    error={formik.errors.fecha_ingreso}
                    helperText={formik.errors.fecha_ingreso}
                  />
                </Grid>
                <Grid
                  item
                  display={{ xs: "block", sm: "flex" }}
                  alignItems="center"
                  gap={2}
                  xs={12}
                  sm={2}
                  my={2}
                >
                  <TextField
                    label="Hora Ingreso"
                    sx={{
                      ".MuiOutlinedInput-notchedOutline": {
                        borderColor: "white",
                        width: "100%",
                      },
                      ".MuiInputBase-root": {
                        color: "white",
                      },
                    }}
                    InputProps={{
                      type: "time",
                      readOnly: true,
                    }}
                    InputLabelProps={{
                      style: { color: "#fff" },
                    }}
                    value={formik.values.hora_ingreso}
                    name="hora_ingreso"
                    onChange={formik.handleChange}
                    error={formik.errors.hora_ingreso}
                    helperText={formik.errors.hora_ingreso}
                  />
                </Grid>

                <Grid
                  item
                  display={{ xs: "block", md: "flex" }}
                  alignItems="center"
                  gap={2}
                  xs={12}
                  md={3}
                  my={2}
                >
                  <TextField
                    label="Empresa"
                    sx={{
                      ".MuiOutlinedInput-notchedOutline": {
                        borderColor: "white",
                      },
                      ".MuiInputBase-root": {
                        color: "white",
                        width: "105%",
                      },
                      "& .MuiSvgIcon-root": {
                        color: "white",
                      },
                      minWidth: "200px",
                    }}
                    InputProps={{
                      type: "text",
                      readOnly: true,
                    }}
                    InputLabelProps={{
                      style: { color: "#fff" },
                    }}
                    name="empresa_id"
                    value={ticket.registro_empresa?.empresa}
                    onChange={formik.handleChange}
                    error={formik.errors.empresa_id}
                    helperText={formik.errors.empresa_id}
                  ></TextField>
                </Grid>
                <Grid
                  item
                  display={{ xs: "block", md: "flex" }}
                  alignItems="center"
                  gap={2}
                  xs={12}
                  md={3}
                  my={2}
                >
                  <TextField
                    label="Tipo de servicio"
                    sx={{
                      ".MuiOutlinedInput-notchedOutline": {
                        borderColor: "white",
                      },
                      ".MuiInputBase-root": {
                        color: "white",
                      },
                      "& .MuiSvgIcon-root": {
                        color: "white",
                      },
                      minWidth: "260px",
                    }}
                    InputLabelProps={{
                      style: { color: "#fff" },
                    }}
                    InputProps={{
                      readOnly: true,
                    }}
                    name="servicios_id"
                    value={ticket.registro_servicio.tipo_servicio}
                    onChange={formik.handleChange}
                    error={formik.errors.servicios_id}
                    helperText={formik.errors.servicios_id}
                  >
                    {dataDropdown.servicios?.map((servicio) => (
                      <MenuItem
                        key={servicio.id}
                        value={servicio.id}
                        selected={true}
                      >
                        {servicio.tipo_servicio} - {servicio.siglas}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid
                  item
                  display={{ xs: "block", md: "flex" }}
                  alignItems="center"
                  gap={2}
                  xs={12}
                  md={12}
                  my={2}
                >
                  <TextField
                    sx={{
                      ".MuiOutlinedInput-notchedOutline": {
                        borderColor: "white",
                      },
                      ".MuiInputBase-root": {
                        color: "white",
                      },
                    }}
                    InputLabelProps={{
                      style: { color: "#fff" },
                    }}
                    InputProps={{
                      readOnly: true,
                    }}
                    label=" Destino / Origen"
                    name="destino"
                    value={formik.values.destino}
                    onChange={formik.handleChange}
                    error={formik.errors.destino}
                    helperText={formik.errors.destino}
                  />
                </Grid>
                <Grid item display={{ xs: "none", md: "none" }}>
                  <Typography
                    variant="subtitle1"
                    color="white"
                    display={{ xs: "none", sm: "block" }}
                  >
                    Usuario ID:
                  </Typography>
                  <TextField
                    sx={{
                      ".MuiOutlinedInput-notchedOutline": {
                        borderColor: "white",
                      },

                      ".MuiInputBase-root": {
                        color: "white",
                      },
                    }}
                    InputLabelProps={{
                      style: { color: "#fff" },
                      readOnly: true,
                    }}
                    label="Inserte usuario ID"
                    name="usuarios_id"
                    value={formik.values.usuarios_id}
                    onChange={formik.handleChange}
                    error={formik.errors.usuarios_id}
                    helperText={formik.errors.usuarios_id}
                  />
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item sx={{ marginRight: "auto" }} align="center" xs={12} pt={4}>
            <Button variant="contained" ml="auto" type="submit" my={2}>
              Editar ingreso
            </Button>
          </Grid>
          {openModal && (
            <BasicModal
              title="Éxito"
              message="El registro fue modificado"
              openModal={openModal}
            />
          )}
        </Grid>
      </form>
    </Stack>
  );
};

export default FormEditTicketOnPlatform;
