import axios from "axios";
import React, { useEffect } from "react";
import CardCompany from "../../components/ticketCards/CardCompany";
import "./ArrivalsBoard.styles.css";
import { Box, Typography } from "@mui/material";


const EmpresasBoxPublic = () => {
  const [empresas, setEmpresas] = React.useState([]);

  const getEmpresas = () => {
    axios
      .get("https://terminaldigital.com.ar/api/empresas/listadoTarjetas")
      .then((data) => {
        return setEmpresas(data.data.empresas);
      })
      .catch((err) => console.log("Error GET Tarjetas:", err));
  };

  useEffect(() => {
     getEmpresas();
  }, []);

  return (
    <>
      <div className="containerBoard">
        {empresas.length > 0 ? (
          <Box>
            <CardCompany data={empresas} />
          </Box>
        ) : (
          <Box>
            <Typography variant="subtitle1">
              No hay empresas registradas aún
            </Typography>
          </Box>
        )}
      </div>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
    </>
  );
};

export default EmpresasBoxPublic;
