import React, { useEffect, useState } from 'react';
import { Box, Skeleton, Button as MuiButton, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TextField, Typography, useMediaQuery, Divider, Button, TableRow, InputLabel, Paper, Grid, RadioGroup, FormControlLabel, Radio, FormLabel } from '@mui/material';
import { Link } from "react-router-dom";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import EditIcon from "@mui/icons-material/Edit";
import CustomTablePaginationActions from '../Custom/CustomTablePaginationActions';
import { alpha, makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@mui/icons-material/Search';
import { styled } from '@mui/system';
import jwt_decode from "jwt-decode";
import InputAdornment from '@mui/material/InputAdornment';
import SettingsIcon from '@mui/icons-material/Settings';
import DepartureBoardIcon from '@mui/icons-material/DepartureBoard';
import CancelIcon from '@mui/icons-material/Cancel';
import DirectionsBusIcon from '@mui/icons-material/DirectionsBus';
import Tooltip from '@mui/material/Tooltip';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import IconButton from '@mui/material/IconButton';
import MultipleStopIcon from '@mui/icons-material/MultipleStop';
import axios from 'axios';
import { toast } from 'react-toastify';


const inputStyle = {
  ".MuiOutlinedInput-notchedOutline": {
    borderColor: "black",
    transition: "250ms all ease",
  },
  ".MuiInputBase-root": {
    color: "black",
    transition: "250ms all ease",
  },
  ".MuiSvgIcon-root": {
    color: "black",
  },
  ".css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root": {
    color: "black",
  },
  "input:hover": {
    background: "transparent",

  },
  ".MuiInputBase-root:hover ": {


    boxShadow: " inset 0 0 9px rgb(63, 100, 143)",
  },
  ".MuiInputBase-root:hover .MuiSvgIcon-root": {
    color: "rgb(19, 46, 77)",
    border: "none",
  },
  ".css-md26zr-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
  {
    border: "none",
  },
  ".css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
  {
    borderColor: "black",
  },
  marginLeft: "0px",
};

export default function TableAdmin2({ data, edit, actualizarDatos }) {

  const token = sessionStorage.getItem("jwt");
  const StickyFooter = styled(TableFooter)(({ theme }) => ({
    position: 'sticky',
    bottom: -20,
    zIndex: theme.zIndex.appBar - 1,
    backgroundColor: theme.palette.background.paper,
  }));

  const [paginaActual, setPaginaActual] = useState(0);
  const [elementosPorPagina, setElementosPorPagina] = useState(5); 
  const [search, setSearch] = useState("");
  const [resultadosBusqueda, setResultadosBusqueda] = useState(data);

  const [open, setOpen] = useState(false);
  const [demora, setDemora] = useState('');
  const [tiempo_demora, setTiempoDemora] = useState('');
  const [selectedRow, setSelectedRow] = useState(null);
  const [tiempo, setTiempo] = useState('hora');



  const [timeState, setTimeState] = React.useState("");
  const timeNow = () => {
    const dateNow = new Date();
    const time = dateNow.getHours() + ":" + dateNow.getMinutes();

    return time;
  };
  const checkTime = (date, hour) => {
    if (date === undefined || date === null) return "none";
    if (hour === undefined || hour === null) return "none";
    const dateNow = new Date();
    const string = `${date}T${hour}`;
    const dateToDate = new Date(string);
    const dateCheck = new Date(dateToDate.getTime() + 20 * 60000);
    if (dateCheck < dateNow) {
      return "#ff00005c";
    } else {
      return "none";
    }
  };

  const setState = (t) => {
    setTimeState(t);
  };

  useEffect(() => {
    const getTime = (t) => setState(t);
    const setIntervalData = setInterval(() => {
      const time = timeNow();
      getTime(time);
    }, 60000);

    const pauseInterval = () => {
      clearInterval(setIntervalData);
    };
    return () => {
      pauseInterval();
    };
  }, [timeState]);

  const handlePageChange = (event, newPage) => {
    setPaginaActual(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setElementosPorPagina(parseInt(event.target.value, 10));
    setPaginaActual(0); 
  };

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };

  useEffect(() => {
    const resultadosFiltrados = data.filter((dato) =>
      (dato.plataforma?.toString().toLowerCase().includes(search.toLowerCase())) ||
      (dato.destino?.toString().toLowerCase().includes(search.toLowerCase())) ||
      (dato.dominio?.toString().toLowerCase().includes(search.toLowerCase())) ||
      (dato.empresa?.toString().toLowerCase().includes(search.toLowerCase())) ||
      (dato.interno?.toString().toLowerCase().includes(search.toLowerCase())) ||
      (dato.tipo_tv?.toString().toLowerCase().includes(search.toLowerCase())) ||
      (dato.estado?.toString().toLowerCase().includes(search.toLowerCase())) ||
      (dato.fecha_ingreso?.toString().toLowerCase().includes(search.toLowerCase())) ||
      (dato.horario_ingreso?.toString().toLowerCase().includes(search.toLowerCase())) ||
      (dato.fecha_salida?.toString().toLowerCase().includes(search.toLowerCase())) ||
      (dato.horario_salida?.toString().toLowerCase().includes(search.toLowerCase()))
    );
    setResultadosBusqueda(resultadosFiltrados);
    setPaginaActual(0);
  }, [search, data]);

  const handleButtonClickCancel = (row) => {

    const url = `https://terminaldigital.com.ar/api/informes/cancelar/${row.id}`;
    const config = { headers: { authorization: `Bearer ${token}` } };
    const data = {cancelado: 1, tipo_tv: row.tipo_tv};

    axios.patch(url, data, config)
      .then(response => {
        if (response.data && response.data.error) {
          toast.error('Hubo un error al actualizar el estado: ' + response.data.error);
        } else {
          toast.success('El estado ha sido actualizado a cancelado');
          actualizarDatos();
        }
      })
      .catch(error => {
        toast.error('Hubo un error al actualizar el estado');
      });
  };

  const handleButtonClickFueraDePlataforma = (row) => {
    const url = `https://terminaldigital.com.ar/api/informes/fueraDePlataforma/${row.id}`;
    const config = { headers: { authorization: `Bearer ${token}` } };
    const data = { estado: 4 };
    axios.patch(url, data, config)
      .then(response => {
        if (response.data && response.data.error) {
          toast.error('Hubo un error al actualizar el estado: ' + response.data.error);
        } else {
          toast.success('El estado ha sido actualizado a fuera de plataforma');
          actualizarDatos();
        }
      })
      .catch(error => {
        toast.error('Hubo un error al actualizar el estado');
      });
  };


  const handleClickOpen = (row) => {
    setSelectedRow(row);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    
    const url = `https://terminaldigital.com.ar/api/informes/informeDemorado/${selectedRow.id}`;
    const config = { headers: { authorization: `Bearer ${token}` } };
    const data = { tiempo_demora, demora: 1, tiempo };
      axios.patch(url, data, config)
        .then(response => {
          if (response.data && response.data.error) {
            toast.error('Hubo un error al actualizar el estado: ' + response.data.error);
          } else {
            toast.success('El estado ha sido actualizado a demorado');
            actualizarDatos();
            setOpen(false);
          }
          setOpen(false);
        })
        .catch(error => {
          console.error(error);
        });
    };

  return (
    <TableContainer component={Paper} sx={{ width: "98%", margin: "auto", borderRadius: "7px" }}>
      <Box p={2}>
        <TextField
          label="Buscar"
          variant="outlined"
          value={search}
          onChange={handleSearchChange}
          sx={{ width: { xs: "40%", md: "15%" }, margin: "auto", height: { xs: 1, md: "4vh" } }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            sx: {
              color: 'black',
              '&::placeholder': {
                color: 'black',
              },
              '&.Mui-focused': {
                color: 'black',
              },
            }
          }}
        />
      </Box>
      <Box px={2}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table" id="informes">
          <TableHead>
            <TableRow>
              {edit && <TableCell align="center">Acciones</TableCell>}
              <TableCell align="center">Estado</TableCell>
              <TableCell align="center">Tipo TV</TableCell>
              <TableCell align="center">Plataforma</TableCell>
              {/* <TableCell align="center">Estado</TableCell> */}
              <TableCell align="center">Destino/Origen</TableCell>
              {/* <TableCell align="center">Dominio</TableCell> */}
              <TableCell align="center">Empresa</TableCell>
              <TableCell align="center">Interno</TableCell>
              <TableCell align="center">Fecha ingreso</TableCell>
              <TableCell align="center">Horario Ingreso</TableCell>
              <TableCell align="center">Fecha Salida</TableCell>
              <TableCell align="center">Horario Salida</TableCell>
              <TableCell align="center">Editar</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.length > 0 ?
              (elementosPorPagina > 0
                ? resultadosBusqueda.slice(paginaActual * elementosPorPagina, (paginaActual + 1) * elementosPorPagina)
                : resultadosBusqueda
              ).map((row) => (
                <TableRow
                  key={row.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  style={{
                    border: `${checkTime(row.fecha_salida, row.horario_salida)} 5px solid`,
                  }}
                >
                  {edit && (
                    row.estado_de_datos === 1 ? (
                      <TableCell align="center">
                        <Link
                          to={`/informes/editar/registroDatoFaltante/${row.id}`}
                        >
                          {" "}
                          <EditIcon sx={{ color: "#b22222" }} />{" "}
                        </Link>
                      </TableCell>
                    ) : (
                        <TableCell>
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                           
                              <Tooltip title="Fuera de plataforma">
                                <MultipleStopIcon sx={{ color: "blue", fontSize: "1.5rem", marginRight: 2 }} onClick={() => handleButtonClickFueraDePlataforma(row) } />
                              </Tooltip>
                            
                            {row.demora === 0 ? (
                            <Tooltip title="Demora">
                              <DepartureBoardIcon onClick={() => handleClickOpen(row)} sx={{ color: "orange", fontSize: "1.5rem", marginRight: 1 }} />
                              </Tooltip>) : (
                              <Tooltip title="Normalizar demora">
                                  <DirectionsBusIcon sx={{ color: "green", fontSize: "1.5rem", marginRight: 1 }} />
                              </Tooltip>
                            )
                            }
                            {
                              row.cancelado === 1 ? (
                                <Tooltip title="Cancelado">
                                  
                                </Tooltip>
                              ) : (
                                <Tooltip title="Cancelar">
                                    <CancelIcon sx={{ color: "red", fontSize: "1.5rem", marginRight: 1 }} onClick={() => handleButtonClickCancel(row)} />
                                </Tooltip>
                              )
                            }
                          </div>
                        </TableCell>
                    )
                  )}
                  <TableCell align="center" sx={{ color: row.demora === 1 ? "#FFA500" : row.cancelado === 1 ? "#b22222" : "inherit" }}>
                    {row.demora === 1 ? (
                      'Demora (' +
                      (() => {
                        const demora = row.tiempo_demora;
                        let valor;
                        let unidad;
                        if (demora >= 2 && demora <= 59) {
                          valor = demora;
                          unidad = 'minutos';
                        } else if (demora == 1) {
                          valor = demora;
                          unidad = 'minuto';
                        } else if (demora == 60) {
                          valor = 1;
                          unidad = 'hora';
                        } else if (demora > 60) {
                          valor = demora / 60;
                          unidad = 'horas';
                        }
                        return `${valor} ${unidad}`;
                      })() +
                      ')'
                    ) : row.cancelado === 1 ? 'Cancelado' : row.estado}
                  </TableCell>
                  <TableCell align="center">{row.tipo_tv}</TableCell>
                  <TableCell align="center">{row.plataforma}</TableCell>
                  {/* <TableCell align="center">{row.estado}</TableCell> */}
                  <TableCell component="th" scope="row" align="center">
                    {row.destino}{" "}
                  </TableCell>
                  {/* <TableCell align="center">{row.dominio}</TableCell> */}
                  <TableCell align="center">{row.empresa}</TableCell>
                  <TableCell align="center">{row.interno}</TableCell>
                  <TableCell align="center">{row.fecha_ingreso}</TableCell>
                  <TableCell align="center">{row.horario_ingreso}</TableCell>
                  <TableCell align="center"> {row.fecha_salida} </TableCell>
                  <TableCell align="center"> {row.horario_salida} </TableCell>
                  {edit && (
                    <TableCell align="right">
                      <Link to={`/informes/editar/registro/${row.id}`}>
                        {" "}
                        <SettingsIcon sx={{ pt: 1 }} />{" "}
                      </Link>
                    </TableCell>
                  )}
                </TableRow>
              ))
              : (
                Array(7).fill().map((_, i) => (
                  <TableRow key={i} >
                    <TableCell colSpan={12}>
                      <Skeleton animation={i % 7 === 0 ? "wave" : false} />
                    </TableCell>
                  </TableRow>
                ))
              )}
            {data.length > 0 && (elementosPorPagina > 0 ? resultadosBusqueda.slice(paginaActual * elementosPorPagina, (paginaActual + 1) * elementosPorPagina) : resultadosBusqueda).length < 5 && Array(5 - (elementosPorPagina > 0 ? resultadosBusqueda.slice(paginaActual * elementosPorPagina, (paginaActual + 1) * elementosPorPagina) : resultadosBusqueda).length).fill().map((_, i) => (
              <TableRow key={i}>
                <TableCell colSpan={12} sx={{ height: { xs: "50px", lg: "37px" } }} />
              </TableRow>
            ))}
          </TableBody>
          <StickyFooter>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              count={resultadosBusqueda.length}
              rowsPerPage={elementosPorPagina}
              page={paginaActual}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleRowsPerPageChange}
              labelRowsPerPage="Filas por página"
            />
          </StickyFooter>
        </Table>
      </Box>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Ingrese el tiempo de demora</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Por favor, ingrese el tiempo de demora estimado.
          </DialogContentText>
          <Grid container spacing={12}>
            <Grid
              item
              display={{ xs: "block", md: "flex" }}
              alignItems="center"
              gap={2}
              xs={4}
              sm={4.3}
              lg={6}
              my={2}
            >

              <FormLabel component="legend">Tiempo</FormLabel>
              <TextField
                sx={{
                  ".MuiOutlinedInput-notchedOutline": {
                    borderColor: "black",
                  },
                  ".MuiInputBase-root": {
                    color: "black",
                  },
                  width: {xs: "20vw", md: "5vw"},
                }}
                InputLabelProps={{
                  style: { color: "black" },
                }}
                InputProps={{
                  type: "number",
                  inputProps: {
                    min: 1,
                  },
                }}
                label={selectedRow ? selectedRow.tolerancia : ''}
                name="tolerancia"
                value={tiempo_demora}
                onChange={e => setTiempoDemora(e.target.value)}
              />
            </Grid>
            <Grid
              item
              display={{ xs: "block", md: "flex" }}
              alignItems="center"
              gap={2}
              xs={6}
              md={3}
              lg={6}
              my={{ xs: 4, sm: 7, lg: 4 }}
            >
              <RadioGroup
                sx={inputStyle}
                row
                aria-labelledby="Estado"
                fullWidth
                name="tiempo"
                value={tiempo}
                onChange={(e) => setTiempo(e.target.value)}
              >
                <FormControlLabel
                  value={"hora"}
                  control={<Radio />}
                  sx={{ color: "black" }}
                  label="Hora/as"
                />
                <FormControlLabel
                  value={"minutos"}
                  control={<Radio />}
                  sx={{ color: "black" }}
                  label=" Minuto/os"
                />
              </RadioGroup>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button startIcon={<CancelIcon />} color="secondary" onClick={handleClose}>Cancelar</Button>
          <Button startIcon={<CheckCircleIcon />} color="primary" onClick={handleConfirm}>Confirmar</Button>
        </DialogActions>
      </Dialog>
    </TableContainer>
  );
}
