import React, { useEffect, useState } from 'react';
import { Box, Skeleton, Button as MuiButton, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TextField, Typography, useMediaQuery, Divider, Button, TableRow, InputLabel, Paper } from '@mui/material';
import { Link } from "react-router-dom";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import EditIcon from "@mui/icons-material/Edit";
import CustomTablePaginationActions from '../Custom/CustomTablePaginationActions';
import { alpha, makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@mui/icons-material/Search';
import { styled } from '@mui/system';
import jwt_decode from "jwt-decode";
import InputAdornment from '@mui/material/InputAdornment';
import { toast } from 'react-toastify';
import SettingsIcon from '@mui/icons-material/Settings';


export default function TableAdmin3({ data, edit = false }) {

  const StickyHeader = styled(TableHead)(({ theme }) => ({
    position: 'sticky',
    top: 10,
    zIndex: theme.zIndex.appBar - 1,
    backgroundColor: theme.palette.background.paper,
  }));

  const StickyFooter = styled(TableFooter)(({ theme }) => ({
    position: 'sticky',
    bottom: -20,
    zIndex: theme.zIndex.appBar - 1,
    backgroundColor: theme.palette.background.paper,
  }));

  const Search = styled("div")(({ theme }) => ({
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.5),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.7),
    },
    color: 'black',
    marginLeft: 10,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "35%",
    },
  }));

  const style = {
    color: "white",
    minWidth: "100%",
    paddingBlock: "2vh",
    textAlign: "center",

  };

  const [paginaActual, setPaginaActual] = useState(0);
  const [elementosPorPagina, setElementosPorPagina] = useState(5); 
  const [search, setSearch] = useState("");
  const [resultadosBusqueda, setResultadosBusqueda] = useState(data);

  const handlePageChange = (event, newPage) => {
    setPaginaActual(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setElementosPorPagina(parseInt(event.target.value, 10));
    setPaginaActual(0); 
  };

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };

  useEffect(() => {
    const resultadosFiltrados = data.filter((dato) =>
      (dato.plataforma?.toString().toLowerCase().includes(search.toLowerCase())) ||
      (dato.destino?.toString().toLowerCase().includes(search.toLowerCase())) ||
      (dato.dominio?.toString().toLowerCase().includes(search.toLowerCase())) ||
      (dato.empresa?.toString().toLowerCase().includes(search.toLowerCase())) ||
      (dato.interno?.toString().toLowerCase().includes(search.toLowerCase())) ||
      (dato.tipo_tv?.toString().toLowerCase().includes(search.toLowerCase())) ||
      (dato.estado?.toString().toLowerCase().includes(search.toLowerCase())) ||
      (dato.fecha_ingreso?.toString().toLowerCase().includes(search.toLowerCase())) ||
      (dato.horario_ingreso?.toString().toLowerCase().includes(search.toLowerCase())) ||
      (dato.fecha_salida?.toString().toLowerCase().includes(search.toLowerCase())) ||
      (dato.horario_salida?.toString().toLowerCase().includes(search.toLowerCase()))
    );
    setResultadosBusqueda(resultadosFiltrados);
    setPaginaActual(0);
  }, [search, data]);

  return (
    <TableContainer component={Paper} sx={{ width: "98%", margin: "auto", borderRadius: "7px" }}>
      <Box p={2}>
        <TextField
          label="Buscar"
          variant="outlined"
          value={search}
          onChange={handleSearchChange}
          sx={{ width: { xs: "40%", md: "15%" }, margin: "auto", height: { xs: 1, md: "7vh" } }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            sx: {
              color: 'black',
              '&::placeholder': {
                color: 'black',
              },
              '&.Mui-focused': {
                color: 'black',
              },
            }
          }}
        />
      </Box>
      <Box px={2} >
        <Table sx={{ minWidth: 650 }} aria-label="simple table" id="informes">
          <TableHead>
            <TableRow>
              <TableCell align="center">Horario Salida</TableCell>
              <TableCell align="center">Destino/Origen</TableCell>
              <TableCell align="center">Dominio</TableCell>
              <TableCell align="center">Empresa</TableCell>
              <TableCell align="center">Interno</TableCell>
              <TableCell align="center">Plataforma</TableCell>
              <TableCell align="center">Tipo TV</TableCell>
              <TableCell align="left">Fecha de ingreso</TableCell>
              <TableCell align="center">Horario Ingreso</TableCell>
              <TableCell align="center">Fecha Salida</TableCell>
              {edit && <TableCell align="center">Cambiar estado</TableCell>}
              {/* <TableCell align="center">Editar</TableCell> */}
            </TableRow>
          </TableHead>

          <TableBody>
            {data.length > 0 ?
              (elementosPorPagina > 0
                ? resultadosBusqueda.slice(paginaActual * elementosPorPagina, (paginaActual + 1) * elementosPorPagina)
                : resultadosBusqueda
              ).map((row) => (
                <TableRow
                  key={row.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="center"> {row.horario_salida} </TableCell>
                  {row.destino === null ? (
                    <TableCell
                      component="th"
                      scope="row"
                      align="center"
                      sx={{ color: "#b22222" }}
                    >
                      FALTA INGRESAR DESTINO
                    </TableCell>
                  ) : (
                    <TableCell component="th" scope="row" align="center">
                      {row.destino}{" "}
                    </TableCell>
                  )}
                  <TableCell align="center">{row.dominio}</TableCell>
                  {row.empresa === "SIN EMPRESA" ? (
                    <TableCell align="center" sx={{ color: "#b22222" }}>
                      DOMINIO SIN EMPRESA
                    </TableCell>
                  ) : (
                    <TableCell align="center">{row.empresa}</TableCell>
                  )}
                  {row.interno === null ? (
                    <TableCell align="center" sx={{ color: "#b22222" }}>
                      DOMINIO SIN INTERNO
                    </TableCell>
                  ) : (
                    <TableCell align="center">{row.interno}</TableCell>
                  )}
                  <TableCell align="center">{row.plataforma}</TableCell>
                  <TableCell align="center">{row.tipo_tv}</TableCell>
                  <TableCell align="left">{row.fecha_ingreso}</TableCell>
                  <TableCell align="center">{row.horario_ingreso}</TableCell>
                  <TableCell align="center"> {row.fecha_salida} </TableCell>
                  {edit && (
                    <TableCell align="center">
                      {row.estado_de_datos === 1 ? (
                        <Link
                          to={`/informes/editar/registroDatoFaltante/${row.id}`}
                        >
                          {" "}
                          <EditIcon sx={{ color: "#b22222" }} />{" "}
                        </Link>
                      ) : (
                        <Link
                          to={`/informes/editar/fuera-plataforma/${row.id}`}
                        >
                          {" "}
                          <ChangeCircleIcon sx={{ color: "green" }} />{" "}
                        </Link>
                      )}
                    </TableCell>
                  )}
                  {/* {edit && (
                    <TableCell align="right">
                      <Link to={`/informes/editar/registro/${row.id}`}>
                        {" "}
                        <SettingsIcon sx={{ pt: 1 }} />{" "}
                      </Link>
                    </TableCell>
                  )} */}
                </TableRow>
              )) : (
                Array(6).fill().map((_, i) => (
                  <TableRow key={i} >
                    <TableCell colSpan={11}>
                      <Skeleton animation={i % 7 === 0 ? "wave" : false} />
                    </TableCell>
                  </TableRow>
                ))
              )}
            {data.length > 0 && (elementosPorPagina > 0 ? resultadosBusqueda.slice(paginaActual * elementosPorPagina, (paginaActual + 1) * elementosPorPagina) : resultadosBusqueda).length < 5 && Array(5 - (elementosPorPagina > 0 ? resultadosBusqueda.slice(paginaActual * elementosPorPagina, (paginaActual + 1) * elementosPorPagina) : resultadosBusqueda).length).fill().map((_, i) => (
              <TableRow key={i}>
                <TableCell colSpan={11} sx={{ height: { xs: "50px", lg: "29px" } }} />
              </TableRow>
            ))}
          </TableBody>
          <StickyFooter>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              count={resultadosBusqueda.length}
              rowsPerPage={elementosPorPagina}
              page={paginaActual}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleRowsPerPageChange}
              labelRowsPerPage="Filas por página"
            />
          </StickyFooter>
        </Table>
      </Box>
    </TableContainer>
  );
}
