/* TABLERO DE SEGURIDAD */

import React, { useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableFooter from "@mui/material/TableFooter";
import Paper from "@mui/material/Paper";
import {
  Box,
  Button,
  Switch,
  FormControlLabel,
  Accordion,
  AccordionSummary,
  TextField,
} from "@mui/material";
import { Link } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SettingsIcon from "@mui/icons-material/Edit";
import Typography from "@mui/material/Typography";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import jwt_decode from "jwt-decode";
import { styled } from '@mui/system';
import Hidden from '@mui/material/Hidden';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Skeleton } from '@mui/material';
import TablePagination from '@mui/material/TablePagination';




export default function TableAdmin3({ data, edit }) {

  const StickyHeader = styled(TableHead)(({ theme }) => ({
    position: 'sticky',
    top: 0,
    zIndex: theme.zIndex.appBar - 1,
    backgroundColor: theme.palette.background.paper,
  }));

  const StickyHeaderFilter = styled(TableHead)(({ theme }) => ({
    position: 'sticky',
    bottom: 0,
    zIndex: theme.zIndex.appBar - 1,
    //backgroundColor: "#0e315a",
  }));

  const StickyFooter = styled(TableFooter)(({ theme }) => ({
    position: 'sticky',
    bottom: -10,
    zIndex: theme.zIndex.appBar - 1,
    backgroundColor: theme.palette.background.paper,
  }));

  const token = sessionStorage.getItem("jwt");
  const [checkedRegistro, setCheckedRegistro] = React.useState(true);
  const [checkedFingreso, setCheckedFingreso] = React.useState(true);
  const [checkedHingreso, setCheckedHingreso] = React.useState(true);
  const [checkedDestino, setCheckedDestino] = React.useState(true);
  const [checkedDominio, setCheckedDominio] = React.useState(true);
  const [checkedInterno, setCheckedInterno] = React.useState(true);
  const [checkedEmpresa, setCheckedEmpresa] = React.useState(true);
  const [checkedServicio, setCheckedServicio] = React.useState(true);
  const [checkedBarrera, setCheckedBarrera] = React.useState(false);
  const [checkedFsalida, setCheckedFsalida] = React.useState(true);
  const [checkedHsalida, setCheckedHsalida] = React.useState(true);
  const [checkedPlataforma, setCheckedPlataforma] = React.useState(false);
  const [checkedEstado, setCheckedEstado] = React.useState(false);
  const [checkedTv, setCheckedTv] = React.useState(true);


  const [count, setCount] = React.useState(11);
  const [userInfo, setUserInfo] = React.useState({});

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(100);

  const [searchValue, setSearchValue] = React.useState('');

  useEffect(() => {
    if (token) {
      const tokenDecoded = jwt_decode(token);
      const { rol } = tokenDecoded;
      setUserInfo((state) => ({ ...state, rol }));
    }
  }, [token]);

  const handleChangeCheckedRegistro = (event) => {
    setCheckedRegistro(event.target.checked);
    event.target.checked === true ? setCount(count + 1) :
      setCount(count - 1);
  };
  const handleChangeCheckedFingreso = (event) => {
    setCheckedFingreso(event.target.checked);
    event.target.checked === true ? setCount(count + 1) :
      setCount(count - 1);
  };
  const handleChangeCheckedHingreso = (event) => {
    setCheckedHingreso(event.target.checked);
    event.target.checked === true ? setCount(count + 1) :
      setCount(count - 1);
  };
  const handleChangeCheckedDestino = (event) => {
    setCheckedDestino(event.target.checked);
    event.target.checked === true ? setCount(count + 1) :
      setCount(count - 1);
  };
  const handleChangeCheckedDominio = (event) => {
    setCheckedDominio(event.target.checked);
    event.target.checked === true ? setCount(count + 1) :
      setCount(count - 1);
  };
  const handleChangeCheckedInterno = (event) => {
    setCheckedInterno(event.target.checked);
    event.target.checked === true ? setCount(count + 1) :
      setCount(count - 1);
  };
  const handleChangeCheckedEmpresa = (event) => {
    setCheckedEmpresa(event.target.checked);
    event.target.checked === true ? setCount(count + 1) :
      setCount(count - 1);
  };
  const handleChangeCheckedServicio = (event) => {
    setCheckedServicio(event.target.checked);
    event.target.checked === true ? setCount(count + 1) :
      setCount(count - 1);
  };
  const handleChangeCheckedBarrera = (event) => {
    setCheckedBarrera(event.target.checked);
    event.target.checked === true ? setCount(count + 1) :
      setCount(count - 1);
  };
  const handleChangeCheckedFsalida = (event) => {
    setCheckedFsalida(event.target.checked);
    event.target.checked === true ? setCount(count + 1) :
      setCount(count - 1);
  };
  const handleChangeCheckedHsalida = (event) => {
    setCheckedHsalida(event.target.checked);
    event.target.checked === true ? setCount(count + 1) :
      setCount(count - 1);
  };
  const handleChangeCheckedPlataforma = (event) => {
    setCheckedPlataforma(event.target.checked);
    event.target.checked === true ? setCount(count + 1) : setCount(count - 1);
  };
  const handleChangeCheckedEstado = (event) => {
    setCheckedEstado(event.target.checked);
    event.target.checked === true ? setCount(count + 1) : setCount(count - 1);
  };
  const handleChangeCheckedTV = (event) => {
    setCheckedTv(event.target.checked);
    event.target.checked === true ? setCount(count + 1) :
      setCount(count - 1);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const style1 = {
    //background: "#0e315a",
    border: "none",
    boxShadow: 'none',
    color: "black",
    ".MuiAccordionSummary-root .MuiSvgIcon-root": {
      color: "black",
      fontSize: { xs: "2rem", md: "2rem" },
    },
    "Mui-expanded": {
      margin: "0px",
    },
    "&:focus": {
      //background: "#0e315a",
    },
    fontSize: { xs: "1rem", md: "2rem" },
    display: { xs: "none", md: "block" },
    marginTop: { xs: "10px", md: "10px", lg: "20px" },
  };
  const style2 = {
    //background: "#0e315a",
    color: "black",
    fontSize: { xs: "1rem", md: "2rem" },

  };

  const widthValues = {
    14: { md: "155.5%", lg: "112%" },
    11: { md: "125%", lg: "100%" },
    12: { md: "135.4%", lg: "100%" },
    13: { md: "145.1%", lg: "105%" },
  };

  const switches = [
    { checked: checkedRegistro, handler: handleChangeCheckedRegistro, label: "Nº DE REGISTRO" },
    { checked: checkedFingreso, handler: handleChangeCheckedFingreso, label: "DÍA DE INGRESO" },
    { checked: checkedHingreso, handler: handleChangeCheckedHingreso, label: "HORA DE INGRESO" },
    { checked: checkedDestino, handler: handleChangeCheckedDestino, label: "DESTINO / ORIGEN / SERVICIO" },
    { checked: checkedDominio, handler: handleChangeCheckedDominio, label: "DOMINIO" },
    { checked: checkedInterno, handler: handleChangeCheckedInterno, label: "INTERNO" },
    { checked: checkedEmpresa, handler: handleChangeCheckedEmpresa, label: "EMPRESA" },
    { checked: checkedFsalida, handler: handleChangeCheckedFsalida, label: "DIA SALIDA" },
    { checked: checkedHsalida, handler: handleChangeCheckedHsalida, label: "HORA DE INGRESO" },
    { checked: checkedPlataforma, handler: handleChangeCheckedPlataforma, label: "PLATAFORMA" },
    { checked: checkedEstado, handler: handleChangeCheckedEstado, label: "ESTADO" },
    { checked: checkedServicio, handler: handleChangeCheckedServicio, label: "SERVICIO" },
    { checked: checkedBarrera, handler: handleChangeCheckedBarrera, label: "BARRERA" },
    { checked: checkedTv, handler: handleChangeCheckedTV, label: "TV" },
  ];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Agrega esta función para manejar el cambio en el campo de entrada de búsqueda
  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
  };

  // Agrega este código para filtrar tus datos basado en el valor de búsqueda
  const filteredData = data.filter((item) => {
    return Object.values(item).some((s) =>
      String(s).toLowerCase().includes(searchValue.toLowerCase())
    );
  });

  return (
    <TableContainer
      component={Paper}
      sx={{ width: "98%", margin: "auto", borderRadius: "7px", maxHeight: 600, border: '1px solid white', marginTop: { xs: "10px", md: "10px", lg: "50px" } }}
    >
      <Box px={1}>
          <Box
            width={widthValues[count] || "auto"}>
          <Accordion sx={style1}>
            <AccordionSummary sx={{ ...style2, marginLeft: "10px" }}>
              <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
                <Typography
                  sx={{
                    fontSize: { lg: "2rem" },
                    fontFamily: 'Lato, sans-serif',
                    color: '#0e315a',
                    textShadow: '2px 2px 2px rgba(0, 0, 0, 0.25)',
                  }}
                >
                  Control de accesos
                </Typography>
                <Box display="flex" alignItems="center" justifyContent="space-between" >
                  <TextField
                    label="Buscar"
                    value={searchValue}
                    onChange={handleSearchChange}
                  />
                  <Link to={userInfo.rol === 4 ? "/seguridad/crear-ingreso" : "/supervisor/crear-ingreso"}>
                    <Button
                      sx={{
                        my: 2,
                        color: "#FFFFFF",
                        fontSize: "0.80rem",
                        width: { xs: "40%", sm: "20%", lg: "100%" },
                        marginLeft: { xs: "10px", sm: "10px", md: "10px" },
                      }}
                      variant="contained"
                    disableRipple
                    >
                      Crear Ingreso
                    </Button>
                  </Link>
                </Box>
              </Box>
            </AccordionSummary>
          </Accordion>

          </Box>
        <Table sx={{ minWidth: 650 }} aria-label="simple table" id="informes">
          <StickyHeader>
            <TableRow>
              <TableCell>
                <IconButton
                  aria-label="more"
                  aria-controls="long-menu"
                  aria-haspopup="true"
                  onClick={handleClick}
                >
                  <MoreVertIcon />
                </IconButton>
                <Menu
                  id="long-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  sx={{
                    mt: 37,
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      flexWrap: 'wrap',
                    }}
                  >
                    {switches.map(({ checked, handler, label }, index) => (
                      <Box
                        sx={{
                          width: {xs:"60%",md:'20%'}, 
                        }}
                      >
                        <MenuItem onClick={handleClose}>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={checked}
                                onChange={handler}
                                inputProps={{ "aria-label": "controlled" }}
                              />
                            }
                            sx={{
                              color: "black",
                              m: 1, 
                              minWidth: '100px' 
                            }}
                            label={label}
                          />
                        </MenuItem>
                      </Box>
                    ))}
                  </Box>
                </Menu>
              </TableCell>
              {edit && <TableCell align="right">ESTADO</TableCell>}
              {checkedRegistro && (
                <TableCell align="left">
                  <Typography
                    sx={{ fontSize: { xs: "13px", md: "15px" } }}
                    style={{ textTransform: 'uppercase' }}
                  >
                    Nº de Registro
                    <br></br>
                  </Typography>
                </TableCell>
              )}
              {checkedFingreso && (
                <TableCell align="left">
                  <Typography
                    sx={{ fontSize: { xs: "13px", md: "15px" } }}
                    style={{ textTransform: 'uppercase' }}
                  >
                    Fecha ingreso
                  </Typography>
                </TableCell>
              )}
              {checkedHingreso && (
                <TableCell align="left">
                  <Typography
                    sx={{ fontSize: { xs: "13px", md: "15px" } }}
                    style={{ textTransform: 'uppercase' }}
                  >
                    {" "}
                    HORA INGRESO
                  </Typography>
                </TableCell>
              )}
              {checkedDestino && (
                <TableCell align="center">
                  <Typography
                    sx={{ fontSize: { xs: "13px", md: "15px" } }}
                    style={{ textTransform: 'uppercase' }}
                  >
                    Destino/Origen/Servicio
                  </Typography>
                </TableCell>
              )}
              {checkedDominio && (
                <TableCell align="center" style={{ textTransform: 'uppercase' }}>
                  <Typography
                    sx={{ fontSize: { xs: "13px", md: "15px" } }}
                    style={{ textTransform: 'uppercase' }}
                  >
                    Dominio
                  </Typography>
                </TableCell>
              )}
              {checkedInterno && (
                <TableCell align="center" style={{ textTransform: 'uppercase' }}>
                  <Typography
                    sx={{ fontSize: { xs: "13px", md: "15px" } }}
                    style={{ textTransform: 'uppercase' }}
                  >
                    Interno
                  </Typography>
                </TableCell>
              )}
              {checkedEmpresa && (
                <TableCell align="center" style={{ textTransform: 'uppercase' }}>
                  <Typography
                    sx={{ fontSize: { xs: "13px", md: "15px" } }}
                    style={{ textTransform: 'uppercase' }}
                  >
                    Empresa
                  </Typography>
                </TableCell>
              )}
              {checkedFsalida && (
                <TableCell align="center" style={{ textTransform: 'uppercase' }}>
                  <Typography
                    sx={{ fontSize: { xs: "13px", md: "15px" } }}
                    style={{ textTransform: 'uppercase' }}
                  >
                    Fecha Salida
                  </Typography>
                </TableCell>
              )}
              {checkedHsalida && (
                <TableCell align="center" style={{ textTransform: 'uppercase' }}>
                  <Typography
                    sx={{ fontSize: { xs: "13px", md: "15px" } }}
                    style={{ textTransform: 'uppercase' }}
                  >
                    HORA Salida
                  </Typography>
                </TableCell>
              )}
              {checkedPlataforma && (
                <TableCell align="center" style={{ textTransform: 'uppercase' }}>
                  <Typography
                    sx={{ fontSize: { xs: "13px", md: "15px" } }}
                    style={{ textTransform: 'uppercase' }}
                  >
                    Plat
                  </Typography>
                </TableCell>
              )}
              {checkedEstado && (
                <TableCell align="center" style={{ textTransform: 'uppercase' }}>
                  <Typography
                    sx={{ fontSize: { xs: "13px", md: "15px" } }}
                    style={{ textTransform: 'uppercase' }}
                  >
                    Estado
                  </Typography>
                </TableCell>
              )}
              {checkedServicio && (
                <TableCell align="center" style={{ textTransform: 'uppercase' }}>
                  <Typography
                    sx={{ fontSize: { xs: "13px", md: "15px" } }}
                    style={{ textTransform: 'uppercase' }}
                  >
                    Servicio
                  </Typography>
                </TableCell>
              )}
              {checkedBarrera && (
                <TableCell align="center" style={{ textTransform: 'uppercase' }}>
                  <Typography
                    sx={{ fontSize: { xs: "13px", md: "15px" } }}
                    style={{ textTransform: 'uppercase' }}
                  >
                    BARRERA
                  </Typography>
                </TableCell>
              )}
              {checkedTv && (
                <TableCell align="center" style={{ textTransform: 'uppercase' }}>
                  <Typography
                    sx={{ fontSize: { xs: "13px", md: "15px" } }}
                    style={{ textTransform: 'uppercase' }}
                  >
                    TV
                  </Typography>
                </TableCell>
              )}
            </TableRow>
          </StickyHeader>
          <TableBody>
            {filteredData.length > 0 ? (
              filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                <TableRow
                  key={row.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="left"></TableCell>
                  {row.estado_de_datos === 1 ||
                    row.destino === null ||
                    row.interno === 999999 ||
                    row.empresa === "Sictranscore" ||
                    row.servicio === "Sin asignar" ? (
                    <TableCell align="center">
                      <Link to={`/seguridad/editar/registro/${row.id}`}>
                        {" "}
                        <SettingsIcon sx={{ color: "#b22222" }} />{" "}
                      </Link>
                    </TableCell>
                  ) : (
                    <TableCell align="center" sx={{ color: "green" }}>
                      {" "}
                      <CheckCircleOutlineIcon />
                    </TableCell>
                  )}
                  {checkedRegistro && (
                    <TableCell align="left">{row.id}</TableCell>
                  )}
                  {checkedFingreso && (
                    <TableCell align="left">{row.fecha_ingreso}</TableCell>
                  )}
                  {checkedHingreso && (
                    <TableCell align="center">{row.horario_ingreso}</TableCell>
                  )}
                  {checkedDestino &&
                    (row.destino === null ? (
                      <TableCell
                        component="th"
                        scope="row"
                        align="center"
                        sx={{ color: "#b22222" }}
                      >
                        FALTA INGRESAR DESTINO
                      </TableCell>
                    ) : (
                      <TableCell component="th" scope="row" align="center">
                        {row.destino}{" "}
                      </TableCell>
                    ))}
                  {checkedDominio && (
                    <TableCell align="center">{row.dominio}</TableCell>
                  )}
                  {checkedInterno &&
                    (row.interno === 999999 ? (
                      <TableCell align="center" sx={{ color: "#b22222" }}>
                        DOMINIO SIN INTERNO
                      </TableCell>
                    ) : (
                      <TableCell align="center">{row.interno}</TableCell>
                    ))}
                  {checkedEmpresa &&
                    (row.empresa === "Sictranscore" ? (
                      <TableCell align="center" sx={{ color: "#b22222" }}>
                        DOMINIO SIN EMPRESA
                      </TableCell>
                    ) : (
                      <TableCell align="center">{row.empresa}</TableCell>
                    ))}
                  {checkedFsalida && (
                    <TableCell align="center"> {row.fecha_salida} </TableCell>
                  )}
                  {checkedHsalida && (
                    <TableCell align="center"> {row.horario_salida} </TableCell>
                  )}
                  {checkedPlataforma && (
                    <TableCell align="center">{row.plataforma}</TableCell>
                  )}
                  {checkedEstado &&
                    (row.estado === null ? (
                      <TableCell align="center">SIN ESTADO</TableCell>
                    ) : (
                      <TableCell align="center">{row.estado}</TableCell>
                    ))}
                  {checkedServicio &&
                    (row.servicio === "Sin asignar" ? (
                      <TableCell align="center" sx={{ color: "#b22222" }}>
                        DOMINIO SIN SERVICIO
                      </TableCell>
                    ) : (
                      <TableCell align="center">{row.servicio}</TableCell>
                    ))}
                  {checkedBarrera &&
                    (row.interurbano !== null ? (
                      <TableCell align="center">
                        {row.interurbano === "L" ? "Ingreso" : " Salida"}
                      </TableCell>
                    ) : (
                      <TableCell align="center">-</TableCell>
                    ))}
                  {checkedTv && (
                    <TableCell align="center">{row.tipo_tv}</TableCell>
                  )}
                </TableRow>
              ))) : (
              Array.from(new Array(5)).map((_, index) => (
                <TableRow key={index}>
                  <TableCell /> {}
                  {Array.from(new Array(12)).map((_, cellIndex) => ( 
                    <TableCell key={cellIndex}>
                      <Skeleton />
                    </TableCell>
                  ))}
                </TableRow>
              ))
            )}
          </TableBody>
          <StickyFooter>
              <TablePagination
                rowsPerPageOptions={[100, 10, 25]}
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: { 'aria-label': 'filas por página' },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage="Filas por página"
              />
          </StickyFooter>
        </Table>
      </Box>
    </TableContainer>
  );
}
