import React, { useState, useEffect } from "react";
import axios from "axios";
import "./login.css";
import Box from "@mui/material/Box";
import { Typography, Card, Grid } from "@mui/material";
import MarkdownPreview from "../../app/MarkdownPreview.js";
import ImagenTerminal from "../../assets/img/mujerTerminal.png";
import styled from 'styled-components';

const styleT = {
  background: "#F4F2EC",
  color: "white",
  maxWidth: "100%",
  paddingBlock: "2vh",
  textAlign: "center",
  fontFamily: 'Lato, sans-serif',
  color: '#0e315a',
  textShadow: '2px 2px 2px rgba(0, 0, 0, 0.25)',
  border: '1px solid #0e315a',
  borderRadius: '25px',
};
const style = {
  maxWidth: "100%",
  maxHeight: "100%",
  paddingBlock: "0vh",
  marginLeft: "5%",
  marginRight: "5%",
  marginTop: "2%",
  shadow: 4,
  minHeight: "30vh",
};

const styleInstitucional = {
  maxWidth: "100%",
};

const ResponsiveImage = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 25px;

  @media (max-width: 600px) {
    display: none;
  }

  @media (min-width: 1200px) {
    height: 100%; 
    display: block;
  }
`;

const Institucional = () => {
  const [dataTerminal, setDataTerminal] = useState([]);

  const dataNull =
    '<p><span style="font-size: 20px;font-family: Verdana;"><strong>Terminal Digital</strong> es una plataforma web diseñada para la gestión de terminales de ómnibus.</span></p><p><span style="font-size: 18px;font-family: Verdana;">Ofrece a los pasajeros la posibilidad de visualizar en tiempo real, desde cualquier dispositivo con conexión a internet, la información sobre los arribos, las partidas y las unidades interurbanas presentes en la terminal.</span></p><p><span style="font-size: 18px;font-family: Verdana;">Desarrollado en 2022 por un equipo de jóvenes emprendedores, el sistema tuvo su primera implementación en la terminal de Mendoza, donde sigue en uso hasta el día de hoy.</span></p>';

  const getDataTerminal = async () => {
    axios
      .get("https://terminaldigital.com.ar/api/administrarterminal")
      .then((data) => {
        //console.log(data.data.terminal.institucional)
        setDataTerminal(data.data.terminal.institucional);
      })
      .catch((error) => console.log("error users", error));
  };

  useEffect(() => {
    getDataTerminal();
  }, []);

  return (
    <Box px={3} pb={4} py={4} style={styleInstitucional}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} md={4}>
          <ResponsiveImage src={ImagenTerminal} alt="Persona esperando en terminal" />
        </Grid>
        <Grid item xs={12} md={8}>
          <Box>
            <Typography sx={{ fontSize: { xs: "20px", lg: "60px" } }} style={styleT}>
              Institucional
            </Typography>
          </Box>
          <Card
            sx={{
              px: 3,
              pb: 2,
              py: 2,
              backgroundColor: '#F4F2EC',
              boxShadow: '0px 10px 10px rgba(0, 0, 0, 0.25)',
              mt: 4,
              borderRadius: '25px',
            }}
          >
            <Typography variant="h5" style={style}>
              {dataTerminal === "" ||
                dataTerminal === null ||
                dataTerminal === undefined ||
                dataTerminal === "<p></p>" ? (
                <MarkdownPreview markdown={dataNull} />
              ) : (
                <MarkdownPreview markdown={dataTerminal} />
              )}
            </Typography>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Institucional;
