import React, { useState, useEffect } from "react";
import axios from "axios";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box, Button, Typography } from "@mui/material";
import Divider from "@mui/material/Divider";
import { Stack } from "@mui/system";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import Modal from "@mui/material/Modal";

const styleModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  height: 200,
  bgcolor: "white",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  borderRadius: 5,
};

const AdminCobroTelepaseManual = (idUser) => {
  const [edit, setEdit] = useState("");
  const [cobroManual, setCobroManual] = useState([]);
  const [count, setCount] = useState([]);
  const [cobradoManual, setCobradoManual] = useState([]);
  const [countCobrado, setCountCobrado] = useState([]);
  const [openSuccessModal, setOpenSuccessModal] = React.useState(false);
  const handleOpenSuccess = () => setOpenSuccessModal(true);
  const handleCloseSuccess = () => setOpenSuccessModal(false);
  const [displayManual, setDisplayManual] = useState("");
  const [cobroTelepase, setCobradoTelepase] = useState([]);
  const [countTelepase, setCountTelepase] = useState([]);

  const getDataCobros = async () => {
    const url = `https://terminaldigital.com.ar/api/sictranscore/RegistrosDeBarrerasSinTag`;

    axios
      .get(url)
      .then((data) => {
        setCobroManual(data.data.getRegistrosCobroManual.rows);
        setCount(data.data.getRegistrosCobroManual.count);
        setCobradoManual(data.data.getRegistrosCobradosManual.rows);
        setCountCobrado(data.data.getRegistrosCobradosManual.count);
      })
      .catch((error) => console.log("error supervisor home", error));
  };

  const cambiar_estado = (id) => {
    const url = `https://terminaldigital.com.ar/api/sictranscore/ActualizarEstadoCobroManual/${id}`;

    axios
      .patch(url, idUser)
      .then((res) => {
        setEdit("OK");
        toast.success('Cambio de estado exitoso'); 
      })
      .catch((err) =>
        console.log("Error en cambio de estado de cobro manual", err)
      );
  };

  const getDataCobrosTelepase = async () => {
    const url = `https://terminaldigital.com.ar/api/sictranscore/RegistrosDeBarreras`;

    axios
      .get(url)
      .then((data) => {
        setCobradoTelepase(data.data.getRegistrosTelepase.rows);
        setCountTelepase(data.data.getRegistrosTelepase.count);
      })
      .catch((error) => console.log("error supervisor home", error));
  };

  useEffect(() => {
    getDataCobros();
    getDataCobrosTelepase();
    setEdit("NO");
    cobroManual.length <= 0
      ? setDisplayManual("none")
      : setDisplayManual("block");
  }, [edit]);

  return (
    <Stack
      sx={{
        background: "#0e315a",
        minHeight: { xs: "71vh", sm: "77vh", md: "61vh", lg: "67vh" },
      }}
    >
      <br></br>
      <br></br>
      <TableContainer
        component={Paper}
        sx={{ width: "98%", margin: "auto", borderRadius: "30px" }}
        style={{ display: displayManual }}
      >
        <br></br>
        <Divider variant="h5" textAlign="left">
          Cobros por toma de patente de las unidades
        </Divider>
        <br></br>
        <Box px={2}>
          <Divider variant="h5" textAlign="right">
            Total a cobrar: {count}
          </Divider>
          <Table sx={{ minWidth: 650 }} aria-label="simple table" id="informes">
            <TableHead>
              <TableRow>
                <TableCell align="center">REGISTRO</TableCell>
                <TableCell align="center">FECHA</TableCell>
                <TableCell align="center">DOMINIO</TableCell>
                <TableCell align="center">INTERNO</TableCell>
                <TableCell align="center">EMPRESA</TableCell>
                <TableCell align="center">TOLERANCIA</TableCell>
                <TableCell align="center">VALOR BÁSICO</TableCell>
                <TableCell align="center">TIEMPO EXCEDENTE</TableCell>
                <TableCell align="center">VALOR EXCEDENTE POR MINUTO</TableCell>
                <TableCell align="center">DESCUENTO</TableCell>
                <TableCell align="center">COBRO TOTAL</TableCell>
                {/*<TableCell align="center">FECHA DE MODIFICACIÓN </TableCell>*/}
                <TableCell align="center">CAMBIAR ESTADO</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {cobroManual.length > 0 &&
                cobroManual?.map((row) => (
                  <TableRow
                    key={row.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="center">{row.id}</TableCell>
                    <TableCell align="center">{row.createdAt}</TableCell>
                    <TableCell align="center">{row.dominio}</TableCell>
                    <TableCell align="center">{row.interno}</TableCell>
                    <TableCell align="center">
                      {row.empresas_id.empresa}
                    </TableCell>

                    <TableCell align="center">
                      {row.tolerancia > 10000
                        ? `${row.tolerancia / 10000} HORAS`
                        : row.tolerancia === 10000
                        ? `${row.tolerancia / 10000} HORA`
                        : row.tolerancia === 100
                        ? `${row.tolerancia / 100} MINUTO`
                        : `${row.tolerancia / 100} MINUTOS`}
                    </TableCell>
                    <TableCell align="center">
                      ${row.valor_basico} (ARG)
                    </TableCell>
                    <TableCell align="center">
                      {row.tiempo_excedente > 10000
                        ? `${row.tiempo_excedente / 10000} HORAS`
                        : row.tiempo_excedente === 10000
                        ? `${row.tiempo_excedente / 10000} HORA`
                        : row.tiempo_excedente === 100
                        ? `${row.tiempo_excedente / 100} MINUTO`
                        : row.tiempo_excedente <= 0
                        ? "SIN TIEMPO EXCEDENTE"
                        : `${row.tiempo_excedente / 100} MINUTOS`}
                    </TableCell>
                    <TableCell align="center">${row.valor_excedente}</TableCell>
                    <TableCell align="center">${row.descuento} (ARG)</TableCell>
                    <TableCell align="center">${row.cobro_total}</TableCell>
                    {/*<TableCell align="center">{row.created_at}</TableCell>*/}
                    <TableCell align="center">
                      <Button
                        variant="outlined"
                        onClick={() => cambiar_estado(row.id)}
                      >
                        <PublishedWithChangesIcon sx={{ color: "orange" }} />
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Box>
        <br></br>
        <br></br>
        <Box px={2}>
          <Divider variant="h5" textAlign="left">
            Total cobrados: {countCobrado}
          </Divider>
          <Table sx={{ minWidth: 650 }} aria-label="simple table" id="informes">
            <TableHead>
              <TableRow>
                <TableCell align="center"></TableCell>
                <TableCell align="center"></TableCell>
                <TableCell align="center"></TableCell>
                <TableCell align="center"></TableCell>
                <TableCell align="center"></TableCell>
                <TableCell align="center"></TableCell>
                <TableCell align="center"></TableCell>
                <TableCell align="center"></TableCell>
                <TableCell align="center"></TableCell>
                <TableCell align="center"></TableCell>
                <TableCell align="center">FECHA DE CAMBIO ESTADO</TableCell>
                <TableCell align="center">REALIZADO POR</TableCell>
                <TableCell align="center"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {cobradoManual.length > 0 &&
                cobradoManual?.map((row) => (
                  <TableRow
                    key={row.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="center">{row.id}</TableCell>
                    <TableCell align="center">{row.dominio}</TableCell>
                    <TableCell align="center">{row.interno}</TableCell>
                    <TableCell align="center">
                      {row.empresas_id.empresa}
                    </TableCell>
                    <TableCell align="center">
                      {row.tolerancia > 10000
                        ? `${row.tolerancia / 10000} HORAS`
                        : row.tolerancia === 10000
                        ? `${row.tolerancia / 10000} HORA`
                        : row.tolerancia === 100
                        ? `${row.tolerancia / 100} MINUTO`
                        : `${row.tolerancia / 100} MINUTOS`}
                    </TableCell>
                    <TableCell align="left">
                      ${row.valor_basico} (ARG)
                    </TableCell>
                    <TableCell align="left">
                      {row.tiempo_excedente > 10000
                        ? `${row.tiempo_excedente / 10000} HORAS`
                        : row.tiempo_excedente === 10000
                        ? `${row.tiempo_excedente / 10000} HORA`
                        : row.tiempo_excedente === 100
                        ? `${row.tiempo_excedente / 100} MINUTO`
                        : row.tiempo_excedente <= 0
                        ? "SIN TIEMPO EXCEDENTE"
                        : `${row.tiempo_excedente / 100} MINUTOS`}
                    </TableCell>
                    <TableCell align="left">${row.valor_excedente}</TableCell>
                    <TableCell align="center">${row.descuento} (ARG)</TableCell>
                    <TableCell align="center">
                      ${row.cobro_total} (ARG)
                    </TableCell>
                    <TableCell align="center">{row.createdAt}</TableCell>
                    <TableCell align="center">{row.user_id.usuario}</TableCell>
                    <TableCell align="center">
                      <CheckCircleOutlineIcon sx={{ color: "green" }} />
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
            <br></br>
          </Table>
        </Box>
      </TableContainer>
      <br></br>
      <br></br>
      <TableContainer
        component={Paper}
        sx={{
          width: "98%",
          margin: { xs: 0, lg: 0 },
          marginLeft: { sm: 1, md: 1.5, lg: 1.5 },
          borderRadius: "7px",

        }}
      >
        <br></br>
        <Divider
          sx={{
            fontSize: { xs: "1rem", lg: "1.5rem" },
            fontFamily: 'Lato, sans-serif',
            color: '#0e315a',
            textShadow: '2px 2px 2px rgba(0, 0, 0, 0.25)',
            display: 'flex',
            justifyContent: 'center',
          }}
          textAlign="left"
        >
          REGISTRO DE COBRO DE TELEPEAJE
        </Divider>
        <br></br>
        <Box px={2}>
          <Divider
            sx={{
              fontSize: { xs: "1rem", lg: "1.5rem" },
              fontFamily: 'Lato, sans-serif',
              color: '#0e315a',
              textShadow: '2px 2px 2px rgba(0, 0, 0, 0.25)',
              marginLeft: { xs: "0", lg: "-55%" },
              textAlign: { xs: "left", lg: "right" },
            }}
          >
            Total cobrados del mes: {countTelepase}
          </Divider>
          <br></br>
          <Table sx={{ minWidth: 650 }} aria-label="simple table" id="informes">
            <TableHead>
              <TableRow>
                <TableCell align="left">ID</TableCell>
                <TableCell align="center">FECHA</TableCell>
                <TableCell align="center">TAG</TableCell>
                <TableCell align="center">TOLERANCIA</TableCell>
                <TableCell align="center">VALOR BÁSICO</TableCell>
                <TableCell align="center">TIEMPO EXCEDENTE</TableCell>
                <TableCell align="center">VALOR EXCEDENTE</TableCell>
                <TableCell align="center">DESCUENTO</TableCell>
                <TableCell align="center">COBRO TOTAL</TableCell>
                {/*<TableCell align="center">FECHA DE MODIFICACIÓN </TableCell>*/}
                <TableCell align="center">ESTADO</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {cobroTelepase.length > 0 ? (
                cobroTelepase?.map((row) => (
                  <TableRow
                    key={row.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="left">{row.id}</TableCell>
                    <TableCell align="left">{row.created_at}</TableCell>
                    <TableCell align="center">{row.TAG}</TableCell>
                    <TableCell align="center">
                      {row.tolerancia <= 0
                        ? ""
                        : `${Math.floor(row.tolerancia / 60) > 0 ? `${Math.floor(row.tolerancia / 60)} ${Math.floor(row.tolerancia / 60) === 1 ? 'HORA' : 'HORAS'}` : ''} ${row.tolerancia % 60 > 0 ? `${row.tolerancia % 60} ${row.tolerancia % 60 === 1 ? 'MINUTO' : 'MINUTOS'}` : ''}`}
                    </TableCell>
                    <TableCell align="center">
                      ${row.valor_basico} (ARG)
                    </TableCell>
                    <TableCell align="center">
                      {row.tiempo_excedente <= 0
                        ? ""
                        : `${Math.floor(row.tiempo_excedente / 3600) > 0 ? `${Math.floor(row.tiempo_excedente / 3600)} ${Math.floor(row.tiempo_excedente / 3600) === 1 ? 'HORA' : 'HORAS'}` : ''} ${((row.tiempo_excedente / 60) % 60).toFixed(0) > 0 ? `${((row.tiempo_excedente / 60) % 60).toFixed(0)} ${((row.tiempo_excedente / 60) % 60).toFixed(0) === 1 ? 'MINUTO' : 'MINUTOS'}` : ''}`}
                    </TableCell>
                    <TableCell align="center">
                      ${row.valor_cobro_excedente}
                    </TableCell>
                    <TableCell align="center">
                      ${row.descuento_diferencial} (ARG)
                    </TableCell>
                    <TableCell align="center">
                      ${row.valor_cobro_total}
                    </TableCell>
                    <TableCell align="center">
                      {row.estado === 6 ? "Cobrado" : "Para cobrar"}
                    </TableCell>
                  </TableRow>
                ))) : (
                <TableRow>
                  <TableCell colSpan={10} align="center">
                    Sin registros
                    </TableCell>
                  </TableRow>
                )}
              <br></br>
              <br></br>
            </TableBody>
          </Table>
        </Box>
      </TableContainer>
      <br></br>
      <br></br>
      <br></br>

      {/* MODAL DE MENSAJE DE Éxito DE ELIMINACIÓN DE EMPRESA */}
      {/* <Modal
        open={openSuccessModal}
        onClose={handleCloseSuccess}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleModal}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ marginLeft: "7%", marginTop: "50px" }}
          >
            El cobro manual fue modificado exitosamente
          </Typography>
          <Button
            variant="contained"
            onClick={closeEverything}
            sx={{ marginLeft: "75%", marginTop: "60px" }}
          >
            Cerrar
          </Button>
        </Box>
      </Modal>*/}
    </Stack>
  );
};

export default AdminCobroTelepaseManual;
