import React, { useEffect, useState } from "react";
import TableUsersApplication from "../../components/table/TableApplicationPass";
import axios from "axios";
import { Box, Typography } from "@mui/material";
import { toast } from 'react-toastify';
import { useSelector } from "react-redux";
import { format } from 'date-fns';


const ListUsersApplication = () => {
  const token = sessionStorage.getItem("jwt");
  const [users, setUsers] = useState([]);

  const [fechaInicio, setFechaInicio] = useState(new Date());
  const [fechaFin, setFechaFin] = useState(new Date());

  const count = useSelector((state) => state.counter.value);

  useEffect(() => {
    const config = { headers: { authorization: `Bearer ${token}` } };
    const fechaInicioDate = new Date(fechaInicio);
    const fechaFinDate = new Date(fechaFin);
    const url = `https://terminaldigital.com.ar/api/users/usersToApplication?page=${count}&fechaInicio=${format(fechaInicioDate, 'yyyy-MM-dd')}&fechaFin=${format(fechaFinDate, 'yyyy-MM-dd')}`;

    axios
      .get(url, config)
      .then((data) => {
        if (data.data.solicitudes.count == 0) {
          toast.error("No hay solicitudes de aplicaciones");
        }
        setUsers(data.data.solicitudes.rows);
      })
      .catch((error) => {
        console.log("error users to application ", error)
      });
  }, [token]);

  const style = {
    background: "#0e315a",
    color: "white",
    minWidth: "100%",
    paddingBlock: "2vh",
    textAlign: "center",
  };

  return (
    <Box
      px={3}
      pb={4}
      sx={{ background: "#0e315a" }}
      minHeight={{ xs: "152.5vw", sm: "103vw", md: "52vw", lg: "20vw" }}
      pt={{ xs: 2, sm: 4 }}
    >
      <TableUsersApplication data={users} />
    </Box>
  );
};

export default ListUsersApplication;
