import React, { useEffect, useState } from 'react'
import TableUsers from '../../components/table/TableUsers';

import axios from 'axios'
import { Box, Typography } from '@mui/material';
import { toast } from 'react-toastify';

const ListUsers = () => {
    const token = sessionStorage.getItem('jwt')
    const [ users, setUsers ] = useState([])
    
    const config = { headers: {"authorization": `Bearer ${token}` }}

    const updateData = () => {
        axios.get('https://terminaldigital.com.ar/api/users', config )
        .then(data => {
            setUsers(data.data.usuarios)})
        .catch(error => toast.error('Error' + String(error)))
    }
    
    useEffect(() => {
        updateData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token])
    
    const style ={
        background: '#0e315a',
        color: 'white',
        minWidth: '100%',
        paddingBlock: '2vh',
        textAlign: 'center'
    }
    

    return (
      <Box px={3} pb={4} sx={{ background: "#0e315a" }}>
        <TableUsers data={users} updateData={updateData} />
      </Box>
    );
}

export default ListUsers