import React, { useState } from "react";
import { Button, Typography, FilledInput, InputAdornment, IconButton, Box } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import ForgotPasswordForm from "./ForgotPasswordForm";

const LoginForm = ({ email, setEmail, password, setPassword, handleSubmit, handleClickShowPassword, showPassword }) => {

  const [openModalRemember, setOpenModalRemember] = useState(false);

  return (
    <Box maxWidth="300px" margin="auto">
      <form onSubmit={handleSubmit}>
        {/* <Typography variant="h6" sx={{ marginBottom: "16px" }}>
          Inicia sesión
        </Typography> */}
        <FilledInput
          type="text"
          placeholder="Usuario"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          fullWidth
          required
          sx={{ marginBottom: "16px" }}
        />
        <FilledInput
          type={showPassword ? "text" : "password"}
          placeholder="Contraseña"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          fullWidth
          required
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }
          sx={{ marginBottom: "16px" }}
        />
        <Button
          type="submit"
          variant="contained"
          fullWidth
          sx={{ marginBottom: "16px" }}
        >
          Iniciar sesión
        </Button>
        <Button
          type="button"
          variant="text"
          onClick={() => setOpenModalRemember(true)}
          sx={{
            color: 'gray',
            fontSize: '0.7rem',
            fontFamily: 'Arial, sans-serif',
            fontStyle: 'italic',
          }}
        >
          ¿Olvidaste tu contraseña?
        </Button>
      </form>
      <Dialog
        open={openModalRemember}
        onClose={() => setOpenModalRemember(false)}
        maxWidth="sm" 
        fullWidth={true} 
      >
        <DialogTitle>Recuperar contraseña</DialogTitle>
        <DialogContent>
          <ForgotPasswordForm setOpenModalRemember={setOpenModalRemember} />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenModalRemember(false)}>
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );

};

export default LoginForm;