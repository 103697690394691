import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Stack } from '@mui/material';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: "15px"
};
const titulo = {
  color: 'red',
  textAlign: 'center',
  fontWeight: 'bold'
}


export default function ModalError({ title , message, openModal, type }) {
  const [open, setOpen] = React.useState(false);



  const handleClose = () => console.log("Cerrar popup");

  const closeSession = () => {
    setOpen(false)
  };

  React.useEffect(() => {
    setOpen(openModal)
  }, [openModal]);



  return (
    <Stack>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h5"
            component="h5"
            sx={titulo}
          >
            {title}
          </Typography>
          <Typography
            textAlign="center"
            id="modal-modal-description"
            sx={{
              mt: 1,
              fontSize: "20px",
              fontWeight: "semi-bold",
              color: "black",
            }}
          >
            {message}
          </Typography>

          <Button
            variant="contained"
            sx={{
              mt: 4,
              fontSize: "15px",
              left: "33%",
              borderRadius: "25px",
              px: 5,
            }}
            size="small"
            onClick={() => closeSession()}
          >
            Cerrar
          </Button>
        </Box>
      </Modal>
    </Stack>
  );
}