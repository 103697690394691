import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
const titulo = {
  color: "green",
  textAlign: "center",
  fontWeight: "bold",
};

export default function ModalExcel({ title, message, openModal, type }) {
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();

  const handleClose = () => setOpen(false);

  const closeSession = () => {
    setOpen(false);
    if (type === "ingresos") {
      navigate(-1);
    }
  };

  React.useEffect(() => {
    setOpen(openModal);
  }, [openModal]);

  return (
    <Stack>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: {xs:"50%",lg:"50%"},
            left: {xs:"50%",lg:"50%"},
            transform: "translate(-50%, -50%)",
            width: {xs:"50%", lg:"27%"},
            bgcolor: "background.paper",
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
            borderRadius: "15px",
          }}
        >
          <Typography
            id="modal-modal-title"
            variant="h5"
            component="h5"
            sx={titulo}
          >
            {title}
          </Typography>
          <Typography
            textAlign="center"
            id="modal-modal-description"
            sx={{
              mt: 1,
              fontSize: "1.12rem",
              fontWeight: "semi-bold",
              color: "#1C40C0",
            }}
          >
            Nombre del archivo:
          </Typography>
          <Typography
            textAlign="center"
            id="modal-modal-description"
            sx={{
              mt: 1,
              fontSize: "15px",
              fontWeight: "semi-bold",
              color: "#1C40C0",
            }}
          >
            {message}
          </Typography>

          <Button
            variant="contained"
            sx={{
              mt: 4,
              fontSize: "15px",
              left: {xs:"15%",lg:"33%"},
              borderRadius: "25px",
              px: 5,
            }}
            size="small"
            onClick={() => closeSession()}
          >
            Cerrar
          </Button>
        </Box>
      </Modal>
    </Stack>
  );
}
