import { React, useEffect, useState } from 'react';
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import styledComponents, { keyframes, css } from 'styled-components';
import { addMinutes, addHours, format } from 'date-fns';

const textTranslateState = [
  "Estado", // Español
  "State", // Inglés
  "Estado", // Portugués
  "État", // Francés
  "Zustand", // Alemán
  "Stato", // Italiano
  "状态", // Chino (Taiwán)
  "상태", // Coreano
  "状態", // Japonés
  "מַצָב", // Hebreo
  "الحالة", // Árabe
].map(text => text.toUpperCase());

const destinoTranslate = [
  "DESTINO", // Español
  "DESTINATION", // Inglés
  "DESTINO", // Portugués
  "DESTINATION", // Francés
  "ZIEL", // Alemán
  "DESTINAZIONE", // Italiano
  "目的地", // Chino (para turistas de Taiwán)
  "목적지", // Coreano
  "目的地", // Japonés
  "יעד", // Hebreo
  "المقصد", // Árabe
];

const empresaTranslate = [
  "EMPRESA", // Español
  "COMPANY", // Inglés
  "EMPRESA", // Portugués
  "ENTREPRISE", // Francés
  "UNTERNEHMEN", // Alemán
  "AZIENDA", // Italiano
  "公司", // Chino (para turistas de Taiwán)
  "회사", // Coreano
  "会社", // Japonés
  "חברה", // Hebreo
  "الشركة", // Árabe
];

const partidaTranslate = [
  "PARTIDA", // Español
  "DEPARTURE", // Inglés
  "PARTIDA", // Portugués
  "DÉPART", // Francés
  "ABFAHRT", // Alemán
  "PARTENZA", // Italiano
  "出发", // Chino (para turistas de Taiwán)
  "출발", // Coreano
  "出発", // Japonés
  "יציאה", // Hebreo
  "المغادرة", // Árabe
];

const plataformaTranslate = [
  "PLATAFORMA", // Español
  "PLATFORM", // Inglés
  "PLATAFORMA", // Portugués
  "PLATEFORME", // Francés
  "PLATTFORM", // Alemán
  "PIATTAFORMA", // Italiano
  "平台", // Chino (para turistas de Taiwán)
  "플랫폼", // Coreano
  "プラットフォーム", // Japonés
  "פלטפורמה", // Hebreo
  "منصة", // Árabe
];

const internoTranslate = [
  "INTERNO", // Español
  "INTERNAL", // Inglés
  "INTERNO", // Portugués
  "INTERNE", // Francés
  "INTERN", // Alemán
  "INTERNO", // Italiano
  "内部", // Chino (para turistas de Taiwán)
  "내부", // Coreano
  "内部", // Japonés
  "פנימי", // Hebreo
  "داخلي", // Árabe
];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#0e315a",
    height: "80px",
  },
  [`&.${tableCellClasses.body}`]: {},
  [`&.${tableCellClasses.root}`]: {
    borderBottom: "none",
    fontSize: "0.93rem",
    color: "white",
    fontWeight: "bold",
    padding: "10px",
    marginLeft: "2rem",
  },
}));
const StyledTableCell2 = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#0e315a",
    height: "80px",
  },
  [`&.${tableCellClasses.body}`]: {},
  [`&.${tableCellClasses.root}`]: {
    borderBottom: "none",
    fontSize: 20,
    color: "white",
    fontWeight: "bold",
    padding: "10px",
    marginLeft: "2rem",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#1C68C0",
  },
  "&:nth-of-type(even)": {
    backgroundColor: "#124178",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
  height: "77px",
  paddingLeft: "2rem",
}));

const StyledTableCell3 = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#0E315A",
    height: "20px",
  },
  [`&.${tableCellClasses.body}`]: {},
  [`&.${tableCellClasses.root}`]: {
    borderBottom: "none",
    fontSize: 20,
    color: "white",
    fontWeight: "bold",
    padding: "10px",
    marginLeft: '2rem'
  },
}));

export default function TableDepartures({ props }) {

  const [index, setIndex] = useState(0);
  const [mostrarHoraModificada, setMostrarHoraModificada] = useState(false);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % destinoTranslate.length);
    }, 30000);

    return () => clearInterval(intervalId);
  }, []);

  const blink = keyframes`
    0% {color: #ff3333; font-size: 18px; text-shadow: 0.5px 0.5px 0.5px #ffffff;}
    25% {color: #ffffff; font-size: 18px; text-shadow: 0.5px 0.5px 0.5px #000000;}
    50% {color: #ff3333; font-size: 18px; text-shadow: 0.5px 0.5px 0.5px #ffffff;}
    75% {color: #ffffff; font-size: 18px; text-shadow: 0.5px 0.5px 0.5px #000000;}
    100% {color: #ff3333; font-size: 18px; text-shadow: 0.5px 0.5px 0.5px #ffffff;}
  `;

  const blinkWarning = keyframes`
    0% {color: #FFA500; font-size: 18px; text-shadow: 0.5px 0.5px 0.5px #ffffff;}
    25% {color: #ffffff; font-size: 18px; text-shadow: 0.5px 0.5px 0.5px #000000;}
    50% {color: #FFA500; font-size: 18px; text-shadow: 0.5px 0.5px 0.5px #ffffff;}
    75% {color: #ffffff; font-size: 18px; text-shadow: 0.5px 0.5px 0.5px #000000;}
    100% {color: #FFA500; font-size: 18px; text-shadow: 0.5px 0.5px 0.5px #ffffff;}
  `;

  const BlinkingText = styledComponents(Typography)`
  ${props => props.blink ? css`animation: ${props.status === 'CANCELADO' ? blink : blinkWarning} 10s linear infinite;` : ''}
`;
  
  useEffect(() => {
    const intervalId = setInterval(() => {
      setMostrarHoraModificada(prevState => !prevState);
    }, 5000); // Cambia cada 5 segundos

    return () => clearInterval(intervalId); // Limpia el intervalo cuando el componente se desmonta
  }, []);

  return (
    <TableContainer
      component={Paper}
      sx={{ borderRadius: "0px", background: "#0e315a", maxWidth: "100vw" }}
    >
      <Table
        sx={{ fontSize: "20px", height: "100%" }}
        aria-label="customized table"
      >
        <TableHead>
          <TableRow>
            <StyledTableCell align="center">
              <Typography fontSize={{ xs: "10px", sm: "15px", md: "20px" }}>
                {textTranslateState[0]}
              </Typography>
              {index !== 0 &&
                <Typography fontSize={{ xs: "8px", sm: "10px", md: "15px", lg: "15px" }}>
                  ({textTranslateState[index]})
                </Typography>
              }
            </StyledTableCell>
            <StyledTableCell2 align="center">
              <Typography fontSize={{ xs: "10px", sm: "15px", md: "20px", lg: "20px" }}>
                {destinoTranslate[0]}
              </Typography>
              {index !== 0 &&
                <Typography fontSize={{ xs: "8px", sm: "10px", md: "15px", lg: "15px" }}>
                  ( {destinoTranslate[index]} )
                </Typography>
              }
            </StyledTableCell2>
            <StyledTableCell2 align="center">
              <Typography fontSize={{ xs: "10px", sm: "15px", md: "20px", lg: "20px" }}>
                {empresaTranslate[0]}
              </Typography>
              {index !== 0 &&
                <Typography fontSize={{ xs: "8px", sm: "10px", md: "15px", lg: "15px" }}>
                  ( {empresaTranslate[index]} )
                </Typography>
              }
            </StyledTableCell2>
            <StyledTableCell3 align="center">
              <Typography fontSize={{ xs: "10px", sm: "15px", md: "20px", lg: "20px" }}>
                {partidaTranslate[0]}
              </Typography>
              {index !== 0 &&
                <Typography fontSize={{ xs: "8px", sm: "10px", md: "15px", lg: "15px" }}>
                  ( {partidaTranslate[index]} )
                </Typography>
              }
            </StyledTableCell3>
            <StyledTableCell2 align="center">
              <Typography fontSize={{ xs: "10px", sm: "15px", md: "20px", lg: "20px" }}>
                {plataformaTranslate[0]}
              </Typography>
              {index !== 0 &&
                <Typography fontSize={{ xs: "8px", sm: "10px", md: "15px", lg: "15px" }}>
                  ( {plataformaTranslate[index]} )
                </Typography>
              }
            </StyledTableCell2>
            <StyledTableCell2 align="center">
              <Typography fontSize={{ xs: "10px", sm: "15px", md: "20px", lg: "20px" }}>
                {internoTranslate[0]}
              </Typography>
              {index !== 0 &&
                <Typography fontSize={{ xs: "8px", sm: "10px", md: "15px", lg: "15px" }}>
                  ( {internoTranslate[index]} )
                </Typography>
              }
            </StyledTableCell2>
          </TableRow>
        </TableHead>
        <TableBody>
          {props &&
            props.map((row) => (
              <StyledTableRow key={row.id}>
                <StyledTableCell align="center">
                  {row.demora === 1 ? (
                    <BlinkingText blink status="DEMORADO" color="#FFA500">
                      DEMORADO (
                      {(() => {
                        const demora = row.tiempo_demora;
                        let valor;
                        let unidad;
                        if (demora >= 2 && demora <= 59) {
                          valor = demora;
                          unidad = 'minutos';
                        } else if (demora == 1) {
                          valor = demora;
                          unidad = 'minuto';
                        } else if (demora == 60) {
                          valor = 1;
                          unidad = 'hora';
                        } else if (demora > 60) {
                          valor = demora / 60;
                          unidad = 'horas';
                        }
                        return `${valor} ${unidad}`;
                      })()}
                      )
                    </BlinkingText>
                  ) : row.cancelado === 1 ? (
                    <BlinkingText blink status="CANCELADO" color="red">
                      CANCELADO
                    </BlinkingText>
                  ) : (
                    <Typography fontSize={{ xs: "10px", sm: "15px", md: "18px" }}>
                      {row.registro_estado.tipo.toUpperCase()}
                    </Typography>
                  )}
                </StyledTableCell>
                <StyledTableCell align="center">
                  <Typography
                    fontSize={{ xs: "10px", sm: "15px", md: "18px" }}
                    textTransform="uppercase"
                  >
                    {row.destino}
                  </Typography>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <Typography fontSize={{ xs: "10px", sm: "15px", md: "18px" }}>
                    {row.registro_empresa.empresa}
                  </Typography>
                </StyledTableCell>
                <StyledTableCell align="center">
                  {row.demora === 1 ? (
                    <>
                      {mostrarHoraModificada ? (
                        <Typography status="DEMORADO" style={{
                          color: "#FFA500",
                          fontSize: { xs: "10px", sm: "15px", md: "16px" },
                          textShadow: "0.5px 0.5px 0.5px #000000"
                        }}>
                          {(() => {
                            const demora = row.tiempo_demora;
                            let valor;
                            let unidad;
                            let nuevaHoraSalida;
                            if (demora >= 2 && demora <= 59) {
                              valor = demora;
                              unidad = 'minutos';
                              nuevaHoraSalida = addMinutes(new Date(`1970-01-01T${row.hora_salida}`), demora);
                            } else if (demora == 1) {
                              valor = demora;
                              unidad = 'minuto';
                              nuevaHoraSalida = addMinutes(new Date(`1970-01-01T${row.hora_salida}`), demora);
                            } else if (demora == 60) {
                              valor = 1;
                              unidad = 'hora';
                              nuevaHoraSalida = addHours(new Date(`1970-01-01T${row.hora_salida}`), valor);
                            } else if (demora > 60) {
                              valor = demora / 60;
                              unidad = 'horas';
                              nuevaHoraSalida = addHours(new Date(`1970-01-01T${row.hora_salida}`), valor);
                            }
                            return `${format(nuevaHoraSalida, 'HH:mm')}`;
                          })()}
                        </Typography>
                      ) : (
                        <Typography style={{
                          color: "white",
                            fontSize: { xs: "10px", sm: "15px", md: "16px" },
                          textShadow: "0.5px 0.5px 0.5px #000000"
                        }}>
                          {row.hora_salida}
                        </Typography>
                      )}
                    </>
                  ) : row.cancelado === 1 ? (
                      <Typography 
                        status="CANCELADO" 
                        style={{
                          color: "#ff3333", 
                          fontSize: "16px", 
                          textShadow: "0.5px 0.5px 0.5px #ffffff"
                        }}
                      >
                        CANCELADA
                      </Typography>
                  ) : (
                    <Typography fontSize={{ xs: "10px", sm: "15px", md: "18px" }}>
                      {row.hora_salida}
                    </Typography>
                  )}
                </StyledTableCell>
                <StyledTableCell align="center">
                  <Typography fontSize={{ xs: "10px", sm: "15px", md: "18px" }}>
                    {row.registro_plataforma.plataforma}
                  </Typography>
                </StyledTableCell>
                {/*<StyledTableCell align="center">
                  {row.registro_empresa.img !== null && (
                    <div className="box" style={styles.cajaFoto}>
                      <img
                        src={require("../../assets/img/empresas/" +
                          row.registro_empresa.img)}
                        alt=""
                      />
                    </div>
                  )}
                </StyledTableCell> */}
                <StyledTableCell align="center">
                  <Typography fontSize={{ xs: "10px", sm: "15px", md: "18px" }}>
                    {row.interno}
                  </Typography>
                </StyledTableCell>
              </StyledTableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
