import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import chroma from 'chroma-js';

function PlatformsStatus() {
  const token = window.sessionStorage.getItem('jwt');
  const [plataformas, setPlataformas] = useState([]);
  const [totalPlatform, setTotalPlatform] = useState(0);

  useEffect(() => {
    axios
      .get('https://terminaldigital.com.ar/api/plataformas/listCount', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setPlataformas(response.data.porcentajeOcupacionPorServicio);
        setTotalPlatform(response.data.porcentajeOcupacionTotal);
      })
      .catch((error) => {
        console.error('There was an error!', error);
      });
  }, [token]);

  function getColor(percentage) {
    if (typeof percentage !== 'string') {
      return 'black';
    }

    const numericPercentage = parseFloat(percentage.replace('%', ''));
    const colorScale = chroma
      .scale(['#20c67a', '#faf728', '#f50400'])
      .mode('lch');
    const color = colorScale(numericPercentage / 100).hex();

    return color;
  }

  return (
    <React.Fragment>
      <Typography variant="h6" sx={{ mb: 2 }} color="primary">
        Ocupación total de plataformas
      </Typography>
      <Card style={{ boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)" }}>
        <CardContent>
          <Typography variant="h3" style={{ color: getColor(totalPlatform.toString()), display: "flex", alignItems: "start" }}>
            {totalPlatform}
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
            <Typography color="text.secondary">{new Date().toLocaleDateString()}</Typography>
          </Box>
        </CardContent>
      </Card>
      <Typography variant="h6" sx={{ mt: 3, mb: 2 }} color="primary">
        Ocupación por servicio
      </Typography>
      {plataformas.map((plataforma, index) => (
        <Card key={index} sx={{ mb: 2 }} style={{ backgroundColor: getColor(plataforma.porcentajeOcupacion.toString()), boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)" }}>
          <CardContent>
            <Typography variant="h6">
              {plataforma.servicio === 'Servicio de media distancia'
                ? 'Media distancia'
                : plataforma.servicio === 'Servicio de larga distancia'
                  ? 'Larga distancia'
                  : plataforma.servicio === 'Servicio de corta distancia'
                    ? 'Corta distancia'
                    : ''}
            </Typography>
            <Typography color="text.secondary" sx={{ mt: 1, ml: 2 }}>
              {plataforma.porcentajeOcupacion} de ocupación
            </Typography>
          </CardContent>
        </Card>
      ))}
    </React.Fragment>
  );
}

export default PlatformsStatus;
