import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import axios from "axios";
import TableAdminTerminal from "../../components/table/TableAdminTerminal";
import TableAdminTerminalEdit from "../../components/table/TableAdminTerminalEdit";

const style = {
  background: "#0e315a",
  color: "white",
  minWidth: "100%",
  paddingBlock: "2vh",
  textAlign: "center",
};

export default function AdminTerminal(editar) {   


  const [dataTerminal, setDataTerminal] = useState([]);

  const getDataTerminal = async () => {
    axios
      .get("https://terminaldigital.com.ar/api/administrarterminal")
      .then((data) => {
        setDataTerminal(data.data.terminal);
      })
      .catch((error) => console.log("error users", error));
  };

  useEffect(() => {
     getDataTerminal();
  }, [editar.editado]);

  return (
    <Box px={3} pb={4} py={4} sx={{ background: "#0e315a" }}>
      
      <br></br>
      {editar.editar === "List" ? (
        <TableAdminTerminal dataTerminal={dataTerminal} />
      ) : editar.editar === "IA" ? (
        <TableAdminTerminalEdit dataTerminal={dataTerminal} edit="1" />
      ) : editar.editar === "RS" ? (
        <TableAdminTerminalEdit dataTerminal={dataTerminal} edit="2" />
      ) : editar.editar === "LI" ? (
        <TableAdminTerminalEdit dataTerminal={dataTerminal} edit="3" />
      ) : editar.editar === "Logo" ? (
        <TableAdminTerminalEdit dataTerminal={dataTerminal} edit="4" />
      ) : (
        console.log("NADA")
      )}
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
    </Box>
  );
}
