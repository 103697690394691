import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Card from '@mui/material/Card';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { makeStyles } from '@material-ui/core/styles';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import DirectionsBusIcon from '@mui/icons-material/DirectionsBus';
import PercentIcon from '@mui/icons-material/Percent';


export default function Cards() {
  
  const currentMonth = new Date().toLocaleString('es-ES', { month: 'long' });

  const lastMonthDate = new Date();
  lastMonthDate.setMonth(lastMonthDate.getMonth() - 1);
  const lastMonth = lastMonthDate.toLocaleString('es-ES', { month: 'long' });

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const lastMonthCapitalized = capitalizeFirstLetter(lastMonth);
  
  const currentMonthCapitalized = capitalizeFirstLetter(currentMonth);
  

  const [largeNow, setLargeNow] = useState([]);
  const [mediumNow, setMediumNow] = useState([]);
  const [smallNow, setSmallNow] = useState([]);
  const [otherNow, setOtherNow] = useState([]);
  const [totalNow, setTotalNow] = useState(0);

  const [largeLast, setLargeLast] = useState([]);
  const [mediumLast, setMediumLast] = useState([]);
  const [smallLast, setSmallLast] = useState([]);
  const [otherLast, setOtherLast] = useState([]);
  const [totalLast, setTotalLast] = useState(0);

  const [compareLarge, setCompareLarge] = useState(0);
  const [compareMedium, setCompareMedium] = useState(0);
  const [compareSmall, setCompareSmall] = useState(0);
  const [compareOther, setCompareOther] = useState(0);
  const [compareTotal, setCompareTotal] = useState(0);
  
  const token = sessionStorage.getItem("jwt");

  useEffect(() => {
    const url = 'https://terminaldigital.com.ar/api/graficas/mes-actual';
    axios
      .get(url)
      .then((response) => {
        
        setLargeNow(response.data.registrosModificadosActuales[0]);
        setMediumNow(response.data.registrosModificadosActuales[1]);
        setSmallNow(response.data.registrosModificadosActuales[2]);
        setOtherNow(response.data.registrosModificadosActuales[3]);
        setTotalNow(response.data.registrosModificadosActuales[4]);
        setLargeLast(response.data.registrosModificadosPasados[0]);
        setMediumLast(response.data.registrosModificadosPasados[1]);
        setSmallLast(response.data.registrosModificadosPasados[2]);
        setOtherLast(response.data.registrosModificadosPasados[3]);
        setTotalLast(response.data.registrosModificadosPasados[4]);
        setCompareLarge(response.data.comparaciones[0]);
        setCompareMedium(response.data.comparaciones[1]);
        setCompareSmall(response.data.comparaciones[2]);
        setCompareOther(response.data.comparaciones[3]);
        setCompareTotal(response.data.comparaciones[4]);
      })
      .catch((error) => console.log("error jwt:", error));
  }, [token]);

  const colors = ["rgba(255, 99, 132)", "rgba(255, 159, 64)", "rgb(75, 192, 192)", "rgb(153, 102, 255)"];

  const allData = [
    { type: 'larga', now: largeNow, last: largeLast, compare: compareLarge },
    { type: 'media', now: mediumNow, last: mediumLast, compare: compareMedium },
    { type: 'corta', now: smallNow, last: smallLast, compare: compareSmall },
    { type: 'otro_servicio', now: otherNow, last: otherLast, compare: compareOther },
  ];

  const renderArrowPrev = (onClickHandler, hasPrev, label) => (
    <IconButton onClick={onClickHandler} title={label} style={{ position: 'absolute', left: 0, top: 'calc(50% - 15px)', visibility: hasPrev ? 'visible' : 'hidden', zIndex: 999 }}>
      <ArrowBack />
    </IconButton>
  );

  const renderArrowNext = (onClickHandler, hasNext, label) => (
    <IconButton onClick={onClickHandler} title={label} style={{ position: 'absolute', right: 0, top: 'calc(50% - 15px)', visibility: hasNext ? 'visible' : 'hidden', zIndex: 999 }}>
      <ArrowForward />
    </IconButton>
  );

  const distanceLabels = {
    "larga": "Larga distancia",
    "media": "Media distancia",
    "corta": "Corta distancia",
    "otro_servicio": "Otros servicios",
    "": "No definido",
    undefined: "No definido"
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: { xs: "column", md: 'row' }, justifyContent: "space-between", mt: 2, ml: { xs: 2, md: 1 }, maxWidth: "90vw" }}>
      <Grid item xs={12} md={6} sx={{ flexGrow: 1, ml: { xs: 2, sm: 4, md: 5 }, mt: { xs: 3, lg: 0 } }}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={12} sx={{ display: "flex", justifyContent: { xs: "left", md: "center" }, ml: { xs: 4, sm: 2 } }}>
            <Box sx={{ width: { xs: "100%", md: "105%" } }}>
              <Carousel showThumbs={false} showStatus={false} renderArrowPrev={renderArrowPrev} renderArrowNext={renderArrowNext} autoPlay={true} interval={5000} infiniteLoop={true}>
                {allData.map((data, index) => (
                  <Card key={index} sx={{ bgcolor: colors[index % colors.length], color: 'white', p: 2, height: { xs: "280px", lg: '200px' }, borderRadius: 5 }}>
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                      <div style={{ flexGrow: 1, p: 2, display: 'flex', flexDirection: "row", justifyContent: "space-between", alignItems: {xs: "center", md: "flex-start"}, marginBottom: { xs: 10, md: 0 }, marginTop: '2px' }}>
                        <Typography component="p" sx={{ fontSize: { xs: "1.5rem", sm: "1.1rem", md: "1.2rem", fontWeight: 600, fontFamily: 'Roboto, sans-serif' } }}>Ingreso mensual acumulado</Typography>
                      </div>
                      <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 10, padding: 10 }}>
                        <Box>
                          <div style={{ flexGrow: 1, p: 2, display: 'flex', flexDirection: "row", justifyContent: { xs: "center", md: "flex-start" }, marginTop: { xs: 2, md: 2 } }}>
                            <Typography component="p" sx={{ fontSize: { xs: "1.5rem", sm: "1rem", md: "1.1rem" }, marginLeft: { xs: 7, md: 2 } }}>
                              {distanceLabels[data.now[1]] || ""}
                            </Typography>
                          </div>
                          <div style={{ flexGrow: 1, p: 2, display: 'flex', flexDirection: "row", justifyContent: { xs: "center", md: "flex-start" } }}>
                            <Typography component="p" sx={{ fontSize: { xs: "2rem", sm: "1rem", md: "1.8rem" }, marginLeft: { xs: 15, md: 8 } }} >{data.now[0]}</Typography>
                          </div>
                          <div style={{ flexGrow: 1, p: 2, display: 'flex', flexDirection: "row", justifyContent: { xs: "center", lg: "flex-start" } }}>
                            <Typography component="p" sx={{
                              fontSize: { xs: "1.5rem", sm: "1rem", md: "1rem" }, marginLeft: { xs: 14, md: 8 }
                            }} >{currentMonthCapitalized}</Typography>
                          </div>
                        </Box>
                        <Box sx={{ display: {xs: "none", md:"block"}}}>
                          <div style={{ flexGrow: 1, p: 2, display: 'flex', flexDirection: "row", justifyContent: "center", marginTop: { xs: 5, md: 150 } }}>
                            <br></br>
                          </div>
                          <div style={{ flexGrow: 1, p: 2, display: 'flex', flexDirection: "row", justifyContent: "center" }}>
                            <Typography component="p" sx={{ fontSize: { xs: "1.8rem", sm: "1rem", md: "1.8rem" }, marginLeft: { xs: 5, md: 5 } }} >{data.last[0]}</Typography>
                          </div>
                          <div style={{ flexGrow: 1, p: 2, display: 'flex', flexDirection: "row", justifyContent: "center" }}>
                            <Typography component="p" sx={{
                              fontSize: { xs: "1.8rem", sm: "1rem", md: "1rem" }, marginLeft: { xs: 5, md: 5 }
                            }} >{lastMonthCapitalized}</Typography>
                          </div>
                        </Box>
                        <Box sx={{marginRight:{xs:0, md: 2}, display: {xs: "none", md: "block"}}}>
                          <div style={{ flexGrow: 1, p: 2, display: 'flex', flexDirection: "row", justifyContent: "center", marginTop: { xs: 5, md: 2 } }}>
                            <Typography component="p" sx={{ fontSize: { xs: "1rem", sm: "1rem", md: "1.1rem" }, marginLeft: { xs: 5, md: "150px" } }}>
                              <br></br>
                            </Typography>
                          </div>
                          <div style={{ flexGrow: 1, p: 2, display: 'flex', flexDirection: "row", justifyContent: "center" }}>
                            <Typography component="p" sx={{ fontSize: { xs: "1.8rem", sm: "1rem", md: "1.8rem" }, marginLeft: { xs: 5, md: 0 } }} >{data.compare[1]} %</Typography>
                          </div>
                          <div style={{ flexGrow: 1, p: 2, display: 'flex', flexDirection: "row", justifyContent: "center" }}>
                            <Typography component="p" sx={{
                              fontSize: { xs: "1.8rem", sm: "1rem", md: "1rem" }, marginLeft: { xs: 5, md: "-10px" }
                            }} >{data.compare[4]}</Typography>
                          </div>
                        </Box>
                      </div>
                    </div>
                  </Card>
                ))}
              </Carousel>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={4} sx={{ flexGrow: 1, ml: { xs: 2, sm: 4, md: 0 }, mt: { xs: 3, lg: 0 } }}>
        <Grid container spacing={6} sx={{display: "flex", flexDirection: {lg: "row"}  }}>
          <Grid item xs={12} sm={6} md={6} sx={{ display: "flex", justifyContent: { xs: "left", md: "center" }, ml: { xs: 8, sm: "-50px" } }}>
            <Card sx={{
              bgcolor: 'primary.main',
              color: 'white',
              p: 2,
              height: { xs: "100px", sm: "250px", md: "125px" },
              width: { xs: "100%", md: "95%" },
              display: 'flex',
              flexDirection: 'column',
              justifyContent: "flex-start",
              alignItems: { xs: 'center', md: 'left' },
              borderRadius: 5
            }}>
              <Typography component="p" sx={{ fontSize: { xs: "1rem", sm: "1.5rem", md: "1.1rem" }, mb: 0, marginLeft: { xs: -12, md: 0 }, }}>
                {totalNow[1] === "total" ? "Ingresos totales" : ""}
              </Typography>
              <Typography component="p" sx={{ fontSize: { xs: "1.5rem", sm: "1.1rem", md: "1.5rem" }, marginLeft: 0, mt: { xs: 0, md: 1 } }}>
                {totalNow[0]} 
                <DirectionsBusIcon sx={{ fontSize: "30px", verticalAlign: 'middle' }} />
              </Typography>
              <Typography component="p" sx={{ fontSize: { xs: "0.7rem", sm: "1.5rem", md: "0.8rem" }, mb: 0, marginLeft: { xs: 2, lg: 1 }, mt: { xs: 0, md: 1} }}>
                (Acumulado mensual)
              </Typography>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={6} sx={{ display: "flex", justifyContent: { xs: "left", md: "center" }, ml: { xs: 8, sm:"-50px" } }}>
            <Card sx={{
              bgcolor: 'orange',
              color: 'white',
              p: 2,
              height: { xs: "100px", sm: "250px", md: "125px" },
              width: { xs: "100%", md: "95%" },
              display: 'flex',
              flexDirection: 'column',
              justifyContent: "flex-start",
              alignItems: { xs: 'center', md: 'left' },
              borderRadius: 5
            }}>
              <Typography component="p" sx={{ fontSize: { xs: "1rem", sm: "1.5rem", md: "1.1rem" }, mb: 0, marginLeft: { xs: -10, md: 0 }, }}>
                {compareTotal[2] === "total" ? `Dif. con ${lastMonthCapitalized}` : ""}
              </Typography>
              <Typography component="p" sx={{ fontSize: { xs: "1.5rem", sm: "1.1rem", md: "1.5rem" }, marginLeft: 0, mt: { xs: 0, md: 1 } }}>
                {compareTotal[0]}
                <PercentIcon sx={{ fontSize: "30px", verticalAlign: 'middle' }} />
              </Typography>
              <Typography component="p" sx={{ fontSize: { xs: "0.7rem", sm: "1.5rem", md: "1rem" }, mb: 0, marginLeft: {xs:2, lg:1}, mt: { xs: 0, md: 1 } }}>
                {compareTotal[4]}
              </Typography>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}