import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import SettingsIcon from "@mui/icons-material/Settings";
import DeleteIcon from "@mui/icons-material/Delete";
import { Link } from "react-router-dom";
import { Box, Button } from "@mui/material";
import axios from "axios";
import BasicModal from "../modals/Modal";
import { useNavigate } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';


export default function GenericTableInterurbanos({ initialProps }) {
  const [props, setProps] = useState(initialProps);
  const [openModal, setOpenModal] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const navigate = useNavigate();

  const fetchData = async () => {
    const url = 'https://terminaldigital.com.ar/api/interurbanos/all';
    const config = {
      headers: { authorization: `Bearer ${sessionStorage.getItem("jwt")}` },
    };

    try {
      const response = await axios.get(url, config);
      setProps(response.data.interurbanos);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const deleteInterCity = () => {
    if (deleteId !== null) {
      const url = `https://terminaldigital.com.ar/api/interurbanos/eliminar/${deleteId}`;
      const config = {
        headers: { authorization: `Bearer ${sessionStorage.getItem("jwt")}` },
      };

      axios
        .delete(url, config)
        .then((res) => {
          if (res.status === 200) {
            handleCloseDialog();
            setTimeout(() => {
              fetchData();
              toast.success("Interurbano borrado con éxito", { autoClose: 2000 });
            }, "500");
          }
        })
        .catch((err) => {
          console.log("Error delete:", err);
          console.log("Response data:", err.response.data);
        });
    }
  };

  const handleOpenDialog = (id) => {
    setDeleteId(id);
    setOpenDialog(true);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <Box sx={{
      minHeight: { xs: "63vh", md: "40vh" },
    }}>
      <TableContainer
        component={Paper}
        sx={{ width: "100%", margin: "auto", borderRadius: "10px" }}
      >
        <Box px={1} mb={{ xs: 40, md: 32 }}>
          <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"} >
            <Typography sx={{
              fontSize: { xs: "1rem", lg: "2rem" },
              fontFamily: 'Lato, sans-serif',
              color: '#0e315a',
              textShadow: '2px 2px 2px rgba(0, 0, 0, 0.25)',
              display: 'flex',
              justifyContent: 'start',
              alignItems: 'center',
              margin: 5,
              ml: 1
            }} >
              Lineas Interurbanas
            </Typography>
            <Link to={`/supervisor/interurbanos/crear`}>
              <Button
                sx={{ fontSize: {xs: "0.5rem", md: "1rem" }, align: "right", ml: {xs:"0", md:"-50%"} }}
                variant="contained"
              >
                Crear
              </Button>
            </Link>
          </Box>
          <Table sx={{ minWidth: 650 }} aria-label="simple table" id="informes">
            <TableHead>
              <TableRow>
                <TableCell align="left">ID</TableCell>
                <TableCell align="center">Empresa</TableCell>
                <TableCell align="center">Desde plataforma</TableCell>
                <TableCell align="center">Hasta plataforma</TableCell>
                <TableCell align="center">Eliminar</TableCell>
                <TableCell align="center">Editar</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props && props.length > 0 &&
                props?.map((row) => (
                  <TableRow
                    key={row.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="left">{row.id}</TableCell>
                    <TableCell align="center">
                      {row.empresa.empresa}
                    </TableCell>
                    <TableCell align="center">
                      {row.plataforma_desde.plataforma}
                    </TableCell>
                    <TableCell align="center">
                      {row.plataforma_hasta.plataforma}
                    </TableCell>
                    <TableCell align="center">
                      <DeleteIcon
                        sx={{ cursor: "pointer" }}
                        onClick={() => handleOpenDialog(`${row.id}`)}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <Link to={`/supervisor/interurbano/editar/${row.id}`}>
                        {" "}
                        <SettingsIcon />{" "}
                      </Link>
                    </TableCell>
                    {openModal && (
                      <BasicModal
                        title="Éxito"
                        message="Interurbano borrado con éxito"
                        openModal={openModal}
                      />
                    )}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Box>
        <Dialog
          open={openDialog}
          onClose={handleCloseDialog}
          sx={{
            '.MuiDialog-paper': {
              width: '100%',
              maxHeight: 435,
            },
          }}
        >
          <DialogTitle
            sx={{
              background: "#0e315a",
              color: '#fff',
              fontWeight: 'bold',
              textAlign: 'center',
              fontSize: '1.1rem',
            }}
          >
            ¿Estás seguro de que quieres borrar la asignación interurbana?
          </DialogTitle>
          <DialogActions
            sx={{
              justifyContent: 'center',
              padding: '1em',
              background: "#0e315a",
            }}
          >
            <Button
              onClick={handleCloseDialog}
              sx={{
                backgroundColor: '#f5f5f5',
                color: '#000',
                '&:hover': {
                  backgroundColor: '#F4F2EC',
                },
              }}
            >
              Cancelar
            </Button>
            <Button
              onClick={deleteInterCity}
              color="primary"
              autoFocus
              sx={{
                backgroundColor: 'red',
                color: '#fff',
                '&:hover': {
                  backgroundColor: '#303f9f',
                },
              }}
            >
              Borrar
            </Button>
          </DialogActions>
        </Dialog>
      </TableContainer>
    </Box>
  );
}
