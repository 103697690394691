import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Autocomplete,
  Box,
  Stack,
  Button,
  Grid,
  TextField,
  Typography,
  Alert,
} from "@mui/material";
import { useFormik } from "formik";
import ModalExcel from "../../components/modals/ModalExcel";
import ModalError from "../../components/modals/ModalError";
import * as yup from "yup";
import Divider from "@mui/material/Divider";

const inputStyle = {
  ".MuiOutlinedInput-notchedOutline": {
    borderColor: "black",
    transition: "250ms all ease",
  },
  ".MuiInputBase-root": {
    color: "black",
    transition: "250ms all ease",
  },
  ".MuiSvgIcon-root": {
    color: "black",
  },
  ".css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root": {
    color: "black",
  },
  "input:hover": {
    background: "transparent",

  },
  ".MuiInputBase-root:hover ": {


    boxShadow: " inset 0 0 9px rgb(63, 100, 143)",
  },
  ".MuiInputBase-root:hover .MuiSvgIcon-root": {
    color: "rgb(19, 46, 77)",
    border: "none",
  },
  ".css-md26zr-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
  {
    border: "none",
  },
  ".css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
  {
    borderColor: "black",
  },
  width: { xs: "50%", md: "20vw" },
};


const AdminRegistry = () => {
  const dateNow = new Date();
  const menosUno = 1;
  dateNow.setHours(menosUno);
  const year = dateNow.getFullYear();
  const monthWithOffset = dateNow.getUTCMonth() + 1;
  const month =
    monthWithOffset.toString().length < 2
      ? `0${monthWithOffset}`
      : monthWithOffset;
  const date =
    dateNow.getUTCDate().toString().length < 2
      ? `0${dateNow.getUTCDate()}`
      : dateNow.getUTCDate();

  const [valueEmpresa, setValueEmpresa] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [openModal2, setOpenModal2] = useState(false);
  const [mensualEmpresa, setMensualEmpresa] = useState(0);
  const [mensualGeneral, setMensualGeneral] = useState(0);
  const [alertaDescargaMensual, setAlertaDescargaMensual] = useState("false");
  const [alertaDescargaEmpresa, setAlertaDescargaEmpresa] = useState("false");
  const [companies, setCompanies] = useState([]);
  const token = window.sessionStorage.getItem("jwt");
  const url = "https://terminaldigital.com.ar/api/empresas/listado";
  const config = { headers: { authorization: `Bearer ${token}` } };

  const functionCloseModal = () => {
    setOpenModal(false);
    setMensualEmpresa(0);
    setMensualGeneral(0);
  };
  const functionOpenModal = () => {
    setTimeout(() => functionCloseModal(), 5000);
  };
  const functionCloseModal2 = () => {
    setOpenModal2(false);
    setMensualEmpresa(0);
    setMensualGeneral(0);
  };
  const functionOpenModal2 = () => {
    setTimeout(() => functionCloseModal2(), 5000);
  };

  const getDropdown = () => {
    axios
      .all([axios.get(url, config)])
      .then((data) => {
        setCompanies(data[0].data.empresas);
      })
      .catch((error) => console.log("error compañías", error));
  };

  useEffect(() => {
    getDropdown();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getInitialTicket = () => {
    return {
      month: `${month}`, 
      year: `${year}`, 
    };
  };


  const formik = useFormik({
    initialValues: getInitialTicket(),
    onSubmit: (values) => {
      const token = window.sessionStorage.getItem("jwt");
      const url = `https://terminaldigital.com.ar/api/logs/month?month=${formik.values.month}&year=${formik.values.year}`;

      axios({
        url: url,
        method: "GET",
        config: { headers: { authorization: `Bearer ${token}` } },
        responseType: "blob",
      })
        .then((res) => {
          if (res.status === 200) {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(
              "download",
              `Registro de Operaciones mensual ${formik.values.month}_${formik.values.year}.xlsx`
            );
            document.body.appendChild(link);
            link.click();
            setOpenModal(true);
            setMensualGeneral(1);
            functionOpenModal2();
            functionOpenModal();
            setTimeout(() => setAlertaDescargaMensual("false"), 3000);
            setTimeout(
              () =>
                formik.resetForm({
                  values: getInitialTicket(),
                }),
              1
            );
          }
        })
        .catch((error) => {
          if (error.response.status === 404) {
            console.log(error.response.status);
            setOpenModal2(true);
            setTimeout(() => setAlertaDescargaMensual("false"), 3000);
            setTimeout(() => functionOpenModal2(), 1);
          }
        });
    },
  });


  const getInitialTicketCompany = () => {
    return {
      dateFrom: "", 
      dateTo: `${year}-${month}-${date}`, 
      empresa: valueEmpresa === null ? "" : valueEmpresa, 
    };
  };

  const validationSchemaCompany = yup.object({
    dateFrom: yup.string().required("Campo requerido"),
    empresa: yup.string().required("Campo requerido"),
  });

 
  const formikCompany = useFormik({
    initialValues: getInitialTicketCompany(),
    validationSchema: validationSchemaCompany,
    onSubmit: (values) => {
      const token = window.sessionStorage.getItem("jwt");
      const url = `https://terminaldigital.com.ar/api/logs/company?dateFrom=${formikCompany.values.dateFrom}&dateTo=${formikCompany.values.dateTo}&company=${formikCompany.values.empresa}`;

      axios({
        url: url,
        method: "GET",
        config: { headers: { authorization: `Bearer ${token}` } },
        responseType: "blob",
      })
        .then((res) => {
          if (res.status === 200) {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(
              "download",
              `Registro de empresa ${formikCompany.values.empresa} desde ${formikCompany.values.dateFrom} hasta ${formikCompany.values.dateTo}.xlsx`
            );
            document.body.appendChild(link);
            link.click();
            setOpenModal(true);
            setMensualEmpresa(1);
            setTimeout(() => setAlertaDescargaEmpresa("false"), 3000);
            functionOpenModal2();
            functionOpenModal();
            setTimeout(
              () =>
                formik.resetForm({
                  values: getInitialTicket(),
                }),
              1
            );
          }
        })
        .catch((error) => {
          if (error.response.status === 404) {
            setTimeout(() => setAlertaDescargaEmpresa("false"), 3000);
            setOpenModal2(true);
            setTimeout(() => functionOpenModal2(), 1);
          }
        });
    },
  });

  const onClickAlertOpen = () => {
    setAlertaDescargaMensual("true");
  };
  const onClickAlertOpenEmpresa = () => {
    setAlertaDescargaEmpresa("true");
  };
  return (
    <Stack sx={{ background: "#0e315a", height:{xs: "auto", lg: "68vh"} }}>
      <Box sx={{ display: "flex", justifyContent: "start", margin: 6 }}>
        <Typography sx={{
          fontSize: { lg: "2rem" },
          fontFamily: 'Lato, sans-serif',
          color: '#0e315a',
          textShadow: '2px 2px 2px rgba(0, 0, 0, 0.25)',
          display: 'flex',
          justifyContent: 'center',
          background: "#F4F2EC",
          width: { lg: "100vw", md: "25vw" },
          borderRadius: "10px",
          padding: 2,
        }}>
          Descarga de registros
        </Typography>
      </Box>
      <Grid container direction="row">
        <Grid item xs={12} md={6}>
          <Box sx={{ display: "flex", flexDirection: "column", alignItems: "start", gap: 2, p: 2, bgcolor: '#f5f5f5', borderRadius: '10px', width: { xs: "80vw", md: "40vw" }, mx: 'auto' }}>
            <Typography
              sx={{
                fontSize: { lg: "1.5rem" },
                fontFamily: 'Lato, sans-serif',
                color: 'black',
                textAlign: "start",
                marginBottom: 2,
                marginLeft: 2
              }}
            >
              Descargar registro mensual
            </Typography>

            <Typography
              sx={{
                fontSize: { lg: "1rem" },
                fontFamily: 'Lato, sans-serif',
                color: 'grey',
                textAlign: "center",
                marginBottom: 2,
                marginLeft: {
                  xs: 1, md: 5
                }
              }}
            >
              Ejemplo: 08, para el mes, 2024 para el año.
            </Typography>

            <form onSubmit={formik.handleSubmit}>
              <Grid container direction={{ xs: "column", sm: "row" }} alignItems="center" gap={2} ml={{xs:"-50px", md:11}}>
                <Grid item xs={12} sm={4}>
                  <Typography variant="subtitle1" color="black">
                    Mes:
                  </Typography>
                  <TextField
                    sx={{
                      ".MuiOutlinedInput-notchedOutline": { borderColor: "black" },
                      ".MuiInputBase-root": { color: "black", width: "100%" },
                    }}
                    InputProps={{ type: "text" }}
                    value={formik.values.month}
                    name="month"
                    onChange={formik.handleChange}
                    error={formik.errors.interno}
                    helperText={formik.errors.interno}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography variant="subtitle1" color="black">
                    Año:
                  </Typography>
                  <TextField
                    sx={{
                      ".MuiOutlinedInput-notchedOutline": { borderColor: "black" },
                      ".MuiInputBase-root": { color: "black", width: "100%" },
                    }}
                    InputProps={{ type: "text" }}
                    value={formik.values.year}
                    name="year"
                    onChange={formik.handleChange}
                    error={formik.errors.interno}
                    helperText={formik.errors.interno}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    type="submit"
                    sx={{
                      fontSize: { xs: "10px", sm: "12px" },
                      marginTop: 2,
                      marginLeft: {xs: 35, md:45}
                    }}
                    onClick={onClickAlertOpen}
                  >
                    Descargar
                  </Button>
                </Grid>
              </Grid>
            </form>
            {alertaDescargaMensual === "true" && (
              <Alert severity="warning" sx={{ fontSize: { xs: "10px", lg: "20px" }, marginTop: 2 }}>
                El proceso de descarga se inicio, no cambies de vista y aguarda la descarga
              </Alert>
            )}
          </Box>
        </Grid>
        <Grid item xs={12} md={6} mt={{xs: 5, md: 0}} mb={{xs: 5, md: 0}}>
          <Box sx={{ display: "flex", flexDirection: "column", alignItems: "start", gap: 2, p: 2, pb: 0.5, bgcolor: '#f5f5f5', borderRadius: '10px', width: { xs: "80vw", md: "40vw" }, mx: 'auto' }}>
            <Typography
              sx={{
                fontSize: { lg: "1.5rem" },
                fontFamily: 'Lato, sans-serif',
                color: 'black',
                textAlign: "start",
                marginBottom: 2,
                marginLeft: 2
              }}
            >
              Descargar registro mensual por empresa{" "}
            </Typography>
            <form onSubmit={formikCompany.handleSubmit}>
              <Grid container direction={{ xs: "column", sm: "row" }} alignItems="center" gap={2} ml={{xs: 0, lg: 13}}>
                <Grid item xs={12} sm={4}>
                  <Typography
                    variant="subtitle1"
                    color="black"
                    mb={{ xs: 1, sm: 0 }}
                    marginLeft={{ xs: "0px", md: "0px", lg: "0px" }}
                  >
                    Fecha desde:
                  </Typography>
                  <TextField
                    sx={{
                      width: "100%",
                      ".MuiOutlinedInput-notchedOutline": {
                        borderColor: "black",
                      },
                      ".MuiInputBase-root": {
                        color: "black",
                      },
                    }}
                    InputProps={{
                      type: "date",
                    }}
                    value={formik.values.dateFrom}
                    name="dateFrom"
                    onChange={formikCompany.handleChange}
                    error={formikCompany.errors.dateFrom}
                    helperText={formikCompany.errors.dateFrom}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography
                    variant="subtitle1"
                    color="black"
                    mb={{ xs: 1, sm: 0 }}
                  >
                    Fecha hasta:
                  </Typography>
                  <TextField
                    sx={{
                      width: "100%",
                      ".MuiOutlinedInput-notchedOutline": {
                        borderColor: "black",
                      },
                      ".MuiInputBase-root": {
                        color: "black",
                      },
                    }}
                    InputProps={{
                      type: "date",
                    }}
                    value={formikCompany.values.dateTo}
                    name="dateTo"
                    onChange={formikCompany.handleChange}
                    error={formikCompany.errors.dateTo}
                    helperText={formikCompany.errors.dateTo}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography
                    variant="subtitle1"
                    color="black"
                    mb={{ xs: 1, sm: 0 }}
                    display={{ xs: "block", sm: "block" }}
                  >
                    Empresa:
                  </Typography>

                  {companies && (
                    <Autocomplete
                      fullWidth
                      autoHighlight
                      clearOnEscape
                      value={valueEmpresa}
                      options={companies}
                      sx={inputStyle}
                      name="empresa"
                      onChange={(event, newValue) => {
                        // eslint-disable-next-line no-unused-expressions
                        newValue === null
                          ? setValueEmpresa(newValue) +
                          formikCompany.setFieldValue("empresa", newValue)
                          : setValueEmpresa(newValue) +
                          formikCompany.setFieldValue(
                            "empresa",
                            newValue.empresa
                          );
                      }}
                      getOptionLabel={(option) => option.empresa}
                      renderOption={(props, option) => (
                        <Box
                          component="li"
                          sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                          {...props}
                        >
                          {option.empresa}
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Seleccione empresa"
                          style={{
                            color: "black",
                          }}
                          sx={{
                            ".css-1sumxir-MuiFormLabel-root-MuiInputLabel-root": {
                              color: "black",
                            },
                            width: { xs: "80vw", md: "30%", lg: "70%" },
                          }}
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: "new-password",
                          }}
                        />
                      )}
                    />
                  )}
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Button
                    variant="contained"
                    ml="auto"
                    type="submit"
                    sx={{
                      marginBlock: "auto",
                      marginInline: "15px",
                      fontSize: { xs: "10px", sm: "15px" },
                      marginTop: 5,
                      marginLeft: {xs: 5, lg:"-18%"}
                    }}
                    onClick={onClickAlertOpenEmpresa}
                  >
                    Descargar
                  </Button>
                </Grid>
              </Grid>
            </form>
            {alertaDescargaEmpresa === "true" ? (
              <Alert
                severity="warning"
                sx={{ fontSize: { xs: "10px", lg: "20px" } }}
              >
                El proceso de descarga se inicio, no cambies de vista y
                aguarda la descarga
              </Alert>
            ) : (
              <br></br>
            )}
          </Box>
        </Grid>
      </Grid>
      {openModal && (
        <ModalExcel
          title="Éxito"
          message={
            mensualGeneral === 1
              ? `Registro de Operaciones mensual ${formik.values.month}_${formik.values.year}`
              : mensualEmpresa === 1
                ? `Registro de empresa ${formikCompany.values.empresa} desde ${formikCompany.values.dateFrom} hasta ${formikCompany.values.dateTo}`
                : "nada"
          }
          openModal={openModal}
        />
      )}
      {openModal2 && (
        <ModalError
          title="Sin registros"
          message="Por favor verifique la fecha ingresada"
          openModal={openModal2}
        />
      )}
    </Stack>
  );
};

export default AdminRegistry;
