import React, { useState, useEffect, useRef } from "react";
import video from '../../assets/img/sin_publicidad.mp4'
import axios from "axios";


const MarketingModuleTV = () => {
  const videoEl = useRef(null);
  const [src_url, setSrc_url] = useState("");
  const [alt_comment, setAlt_comment] = useState("");
  const [archive, setArchive] = useState("");

  const [token, setToken] = useState(null);
  
  const obtenerJWT = async () => {
    try {
      const response = await axios.get('https://terminaldigital.com.ar/api/auth/codeTerminal');
      setToken(response.data);
    } catch (error) {
      console.error('Error al obtener el JWT:', error);
    }
  };
  useEffect(() => {
    obtenerJWT();
  }, []);


  const getPublicidad = async () => {
    axios
      .get(`http://localhost:9090/advertising/getAdvertisingVideoTvTerminal`, { headers: { Authorization: `Bearer ${token}` } })
      .then((data) => {
        setSrc_url(data.data.src_url);
        setAlt_comment(data.data.alt_comment);
        setArchive(data.data.archive)
      })
      .catch((error) => {

        setSrc_url("");
      })
  };
  useEffect(() => {
    if (token) {
      getPublicidad();
      attemptPlay();
      setInterval(() => {
        getPublicidad();
        attemptPlay();
      }, 60 * 60 * 1000);
    }
  }, [token]);

  const attemptPlay = () => {
    videoEl &&
      videoEl.current &&
      videoEl.current.play().catch(error => {
        console.error("Error attempting to play", error);
      });
  };

  const [isLargeScreen, setIsLargeScreen] = useState(window.matchMedia('(min-width: 1920px)').matches);


  useEffect(() => {
    const mediaQueryList = window.matchMedia('(min-width: 1280px)');
    const listener = (event) => setIsLargeScreen(event.matches);

    mediaQueryList.addListener(listener);

    return () => mediaQueryList.removeListener(listener);
  }, []);

  const styles = isLargeScreen ? {
    transform: ' rotate(90deg)',
    position: 'fixed',
    height: '31.7vw',
    //with: '100vw',
    objectFit: 'cover',
    top: '21.8%',
    left: '-12.2%'
  } : {
    transform: ' rotate(90deg)',
    position: 'fixed',
    height: '50vw',
    //with: '100vw',
    objectFit: 'cover',
    top: '6.5%',
    left: '-19.5%'
  };

  return (
    <div style={styles}>
      <video autoPlay loop muted ref={videoEl} playsInline src={src_url === "" ? video : src_url} style={{ width: '100vw', height: '100vh' }} />
    </div>

  );
};

export default MarketingModuleTV



