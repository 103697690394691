import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  FormLabel,
  MenuItem,
  ListItemText,
  ListItem,
  List,
  Box
} from "@mui/material";
import { Stack } from "@mui/system";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import axios from "axios";
import { toast } from "react-toastify";
import FormField from "./FormField";
import ReplyIcon from "@mui/icons-material/Reply";
import jwtDecode from 'jwt-decode';

const validationSchema = yup.object({
  interno: yup.mixed().required("Campo requerido"),
  empresa_id: yup.number().required("Campo requerido"),
  servicios_id: yup.number().required("Campo requerido"),
  dominio: yup.string().required("Campo requerido"),
});

const FormCreatePlatform = () => {

  const [service, setService] = useState([]);
  const [company, setCompany] = useState([]);
  const [valueEmpresa, setValueEmpresa] = useState(null);
  const token = sessionStorage.getItem("jwt");
  const config = { headers: { authorization: `Bearer ${token}` } };

  const user = jwtDecode(token);

  const getDropdown = () => {
    axios
      .all([axios.get("https://terminaldigital.com.ar/api/informes/dataDropdown", config)])
      .then((data) => {
        setService(data[0].data.servicios);
        setCompany(data[0].data.empresas);
      })
      .catch((error) => console.log("error users", error));
  };

  useEffect(() => {
    getDropdown();
  }, []);

  const setInitialDomain = () => {
    return {
      interno: "",
      servicios_id: "",
      empresa_id: null,
      dominio: "",
      descuento: 0,
      valor_descuento: 0,
    };
  }

  const formik = useFormik({
    initialValues: setInitialDomain(),
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      const config = {
        headers: {
          authorization: `Bearer ${token}`,
        },
      };

      const data = formik.values;

      axios
        .post("https://terminaldigital.com.ar/api/empresas/agregarDominio", data, config)
        .then((response) => {
          if (response.status === 200 || response.status === 400) {
            toast.success(response.data.message);
            formik.resetForm();
            setValueEmpresa(null);
            setShowDiscountInput(false); 
          }
        })
        .catch((error) => {
          if (error.response && (error.response.status === 200 || error.response.status === 400)) {
            toast.error(error.response.data.message);
            formik.resetForm();
            setValueEmpresa(null);
            setShowDiscountInput(false); 
          }
          console.log(error);
        });
    },
  });

  const inputStyle = {
    ".MuiOutlinedInput-notchedOutline": {
      borderColor: "black",
      transition: "250ms all ease",
    },
    ".MuiInputBase-root": {
      color: "black",
      transition: "250ms all ease",
      width: { xs: "68vw", md: "18.5vw" },
    },
    ".MuiSvgIcon-root": {
      color: "black",
    },
    ".css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root": {
      color: "black",
    },
    "input:hover": {
      background: "transparent",

    },
    ".MuiInputBase-root:hover ": {


      boxShadow: " inset 0 0 9px rgb(63, 100, 143)",
    },
    ".MuiInputBase-root:hover .MuiSvgIcon-root": {
      color: "rgb(19, 46, 77)",
      border: "none",
    },
    ".css-md26zr-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
    {
      border: "none",
    },
    ".css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
    {
      borderColor: "black",
    },
  };

  const [showDiscountInput, setShowDiscountInput] = useState(false);

  return (
    <Stack
      sx={{ background: "#F4F2EC", borderRadius: "25px", shadow: 4, minHeight: { xs: "50%", sm: "60%", md: "73%", lg: "40%" } }}
      my={4}
      mx={{ xs: 1, sm: 6 }}
      p={4}
      sm={6}
      md={8}
      lg={10}
      mb={7.2}
    >
      <Grid container my={1} gap={1} p={1} direction="column" alignItems="center">
        <form onSubmit={formik.handleSubmit}>
          <Grid
            item
            gap={2}
            xs={12}
            md={12} // Cambia esto
            lg={12} // Y esto
            my={2}
            width="100%"
          >
            <FormField
              name="dominio"
              label="Dominio"
              value={formik.values.dominio}
              onChange={formik.handleChange}
              error={formik.errors.dominio}
              helperText={formik.errors.dominio}
            />
          </Grid>
          <Grid
            item
            gap={2}
            xs={12}
            md={12} // Cambia esto
            lg={12} // Y esto
            my={2}
            width="100%"
          >
            <FormField
              name="interno"
              label="Interno"
              value={formik.values.interno}
              onChange={formik.handleChange}
              error={formik.errors.interno}
              helperText={formik.errors.interno}
            />
          </Grid>
          <Grid
            item
            gap={2}
            xs={12}
            sm={12}
            md={6}
            lg={12}
            my={2}
            width="100%"
          >
            <FormField
              select
              multiline
              label="Tipo de Servicio"
              name="servicios_id"
              value={formik.values.servicios_id}
              onChange={formik.handleChange}
              error={formik.errors.servicios_id}
              helperText={formik.errors.servicios_id}
            >
              {service?.map((service) => (
                <MenuItem
                  value={service.id}
                  key={service.tipo_servicio + service.id}
                  selected={true}
                >
                  {service.tipo_servicio}
                </MenuItem>
              ))}
            </FormField>
          </Grid>
          { (user.rol === 3 || user.rol === 1) && (
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={12}
            >
              <ListItem>
                <ListItemText
                  primary="Descuento"
                  secondary={
                    <Box width="100%" marginLeft="0px" >
                      <RadioGroup
                        name="descuento"
                        value={formik.values.descuento}
                        onChange={(event) => {
                          formik.setFieldValue('descuento', Number(event.target.value));
                          setShowDiscountInput(Number(event.target.value) === 1);
                        }}
                        row
                      >
                        <FormControlLabel value={0} control={<Radio />} label="No" />
                        <FormControlLabel value={1} control={<Radio />} label="Sí" />
                      </RadioGroup>
                      {showDiscountInput && (
                        <TextField
                          name="valor_descuento" 
                          label="% de descuento"
                          type="number"
                          value={formik.values.valor_descuento} 
                          onChange={formik.handleChange}
                        />
                      )}
                    </Box>
                  }
                  sx={{ textAlign: 'left' }}
                />
              </ListItem>
            </Grid>
          )}
          <Grid
            item
            gap={2}
            xs={12}
            sm={12}
            md={6}
            lg={6}
            my={2}
          >
            {company && (
              <Autocomplete
                fullWidth
                clearOnEscape
                value={valueEmpresa}
                options={company}
                sx={inputStyle}
                onChange={(event, newValue) => {
                  setValueEmpresa(newValue);
                  formik.setFieldValue("empresa_id", newValue ? newValue.id : null);
                }}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionLabel={(option) => option.empresa}
                renderInput={(params) => (
                  <TextField
                    error={formik.errors.company}
                    {...params}
                    label="Empresa"
                    InputLabelProps={{
                      style: { color: 'black', width: '100%' },
                    }}
                  />
                )}
              />
            )}
          </Grid>
          <Grid item align="center" xs={12} pt={1}>
            <Button type="submit" variant="contained">
              Crear Dominio
            </Button>
          </Grid>
        </form>
      </Grid>
    </Stack>
  );

};

export default FormCreatePlatform;