import React, { useState, useEffect, useRef } from "react";
import video from '../../assets/img/sin_publicidad_h.mp4'
import axios from "axios";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import FullscreenIcon from "@mui/icons-material/Fullscreen";


const MarketingModuleTvVertical = () => {
  const videoEl = useRef(null);

  const handle = useFullScreenHandle();

  const [src_url, setSrc_url] = useState("");
  const [alt_comment, setAlt_comment] = useState("");
  const [archive, setArchive] = useState("");

  const [token, setToken] = useState(null);
  const obtenerJWT = async () => {
    try {
      const response = await axios.get('https://terminaldigital.com.ar/api/auth/codeTerminal');
      setToken(response.data);
    } catch (error) {
      console.error('Error al obtener el JWT:', error);
    }
  };
  useEffect(() => {
    obtenerJWT();
  }, []);

  const getPublicidad = async () => {
    axios
      .get(`http://localhost:9090/advertising/getAdvertisingVideoTvTerminal`, { headers: { Authorization: `Bearer ${token}` } })
      .then((data) => {
        setSrc_url(data.data.src_url);
        setAlt_comment(data.data.alt_comment);
        setArchive(data.data.archive)
      })
      .catch((error) => {
        console.log("error src_url", error);
        setSrc_url("");
      })
  };

  useEffect(() => {
    if (token) {
      getPublicidad();
      setInterval(() => {
        getPublicidad();
        attemptPlay();
        console.log("getPublicidad")
      }, 60 * 60 * 1000);
    }
  }, [token]);

  const attemptPlay = () => {
    videoEl &&
      videoEl.current &&
      videoEl.current.play().catch(error => {
        console.error("Error attempting to play", error);
      });
  };
  const [isLargeScreen, setIsLargeScreen] = useState(window.matchMedia('(min-width: 1920px)').matches);


  useEffect(() => {
    const mediaQueryList = window.matchMedia('(min-width: 1280px)');
    const listener = (event) => setIsLargeScreen(event.matches);

    mediaQueryList.addListener(listener);

    return () => mediaQueryList.removeListener(listener);
  }, []);

  const styles = isLargeScreen ? {
    //transform: ' rotate(90deg)',
    position: 'fixed',
    height: '100vh',
    with: '100vw',
    objectFit: 'cover',
    top: '0%',
    left: '0%'
  } : {
      //transform: ' rotate(90deg)',
      position: 'fixed',
      height: '100vh',
      with: '100vw',
      objectFit: 'cover',
      top: '0%',
      left: '0%'
  };
  return (
    <>
      <FullScreen handle={handle}>
        <video autoPlay loop muted ref={videoEl} playsInline src={src_url === "" ? video : src_url} style={src_url === "" ? ({
          transform: ' rotate(90deg)',
          position: 'fixed',
          height: '35vw',
          with: '90vw',
          objectFit: 'cover',
          top: '20%',
          left: '18%'
        }): styles}
        />
      </FullScreen>
      <button
        className="fullscreen-button"
        style={{ zIndex: "99" }}
        onClick={handle.enter}
      >
        <FullscreenIcon />
      </button>
    </>

  );
};

export default MarketingModuleTvVertical



