import axios from 'axios';
import io from 'socket.io-client';
import Marquee from "react-fast-marquee";
import React, { useCallback, useEffect, useState } from "react";
import { Box, CircularProgress } from "@mui/material";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { Stack } from "@mui/system";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import Typography from "@mui/material/Typography";
import GenericTableInterurbanos from "../../components/table/TableInterurbanosList";
import Greating from "../../components/clock2/Greating";
import MarketingModuleTV from "../../components/MarketingModule/MarketingModuleTV";
import TableIntercityHead from "../../components/table/TableIntercityHead";
import Weather from '../../components/Weather/Weather';
import "./ArrivalsBoard.styles.css";
import sinRegistros from "../../assets/img/sinregistros-1.png";
import Pagination from '@mui/material/Pagination';

const socket = io('https://terminaldigital.com.ar');

const api = axios.create({
  baseURL: 'https://terminaldigital.com.ar/api'
});

const getMarquesina = () => api.get('/marquesinas/marquesina');
const getInterurbanos = () => api.get('/interurbanos/all');
const getLogoTerminal = () => api.get('/administrarterminal');

const Clock = () => {
  const [time, setTime] = useState(new Date().toLocaleTimeString());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTime(new Date().toLocaleTimeString());
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <Greating
      text={time}
      style={{
        color: "white",
        fontSize: { xs: "30px", sm: "45px", md: "50px", lg: "55px" },
        fontWeight: "400",
        marginLeft: "0",
        paddingBottom: "70px",
      }}
    />
  );
};

const imgArray = [
  "/publi1.png",
  "/publi2.png",
  "/publi3.png",
  "/publi1.png",
  "/publi2.png",
  "/publi3.png",
];

const textTranslateInterurbano = [
  "interurbano", // Español
  "interurban", // Inglés
  "interurbano", // Portugués
  "interurbain", // Francés
  "überörtlich", // Alemán
  "interurbano", // Italiano
  "城际", // Chino (para turistas de Taiwan)
  "시외", // Coreano
  "都市間", // Japonés
  "בינעירוני", // Hebreo
  "بين المدن", // Árabe
];

const InterurbanosBoardTV = () => {

  const handle = useFullScreenHandle();
  const [data, setData] = React.useState([]);
  const [intercity, setIntercity] = useState([]);
  const [logo, setLogo] = useState([]);
  const [index, setIndex] = useState(0);
  const [page, setPage] = React.useState(1);
  const itemsPerPage = 9;

  const token = sessionStorage.getItem("jwt");

  const config = { headers: { authorization: `Bearer ${token}` } };

  const url = "https://terminaldigital.com.ar/api/interurbanos/all";


  /*useEffect(() => {
    getInterurbanos()
      .then((data) => setIntercity(data.data.interurbanos))
      .catch((error) => console.log("Error Interurbanos Table:", error));
  }, []);*/

  useEffect(() => {
    getMarquesina()
      .then((data) => setData(data.data.marquesina))
      .catch((error) => console.log("Error Marquesina:", error));
  }, []);

  useEffect(() => {
    const fetchIntercity = async () => {
      try {
        const response = await getInterurbanos();
        setIntercity(response.data.interurbanos);
      } catch (error) {
        console.log("Error Interurbanos Table:", error);
      }
    };

    socket.on('connect', () => {
      console.log('connected to socket');
      fetchIntercity();
    });

    socket.on('newRegisterIntercity', ({ shouldRerenderIntercity }) => {
      if (shouldRerenderIntercity) {
        fetchIntercity();
      }
    });

    return () => {
      socket.off('newRegisterIntercity');
    };
  }, []);

  useEffect(() => {
    getLogoTerminal()
      .then((data) => setLogo(data.data.terminal.logo))
      .catch((error) => console.log("error users", error));
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % textTranslateInterurbano.length);
    }, 30000);
    return () => clearInterval(intervalId);
  }, []);

  const styleMarquee = {
    padding: "0px",
    maxHeight: "100px",
    color: "#0E315A",
    margin: "0",
    background: "white",
    transform: "rotate(90deg)",
    textTransform: "uppercase",
    position: "absolute",
    left: "49.3%",
    bottom: "96.6vh",
  };

  const handleChange = (event, value) => {
    setPage(value);
  };

  useEffect(() => {
    const totalPages = Math.ceil(intercity.length / itemsPerPage);
    const timer = setInterval(() => {
      setPage((prevPage) => {
        if (prevPage >= totalPages) {
          return 1;
        } else {
          return prevPage + 1;
        }
      });
    }, 15000);
    return () => {
      clearInterval(timer);
    };
  }, [intercity, itemsPerPage]);

  return (
    <>
      <Marquee gradient={false} speed={0.051} style={styleMarquee}>
        <span className="texto" style={{ paddingTop: "3px" }}>
          {data?.texto && data.texto}
        </span>
      </Marquee>
      <Box
        className="containerBoard"
        sx={{
          textOrientation: "sideways-right",
          writingMode: "tb-rl",
          overflow: "hidden",
          position: "relative",
        }}
      >
        {/* ACA VA lo que entra en full screen */}
        <FullScreen handle={handle}>
          <Stack
            direction="row"
            pr={3}
            justifyContent="space-between"
            alignItems="flex-start"
            spacing={4}
            sx={{ height: "100vh", background: "#0e315a" }}
          >
            <Typography
              pr={1.4}
              pt={2}
              textAlign="left"
              sx={{
                fontFamily: "Roboto",
                color: "white",
                fontSize: { xs: "15px", sm: "20px", md: "20px" },
                marginBottom: "80px",
              }}
              textTransform=" uppercase"
            >
              {textTranslateInterurbano[0]} {index !== 0 && ` - ${textTranslateInterurbano[index]}`}
            </Typography>
            {/* CLIMA */}
            <Weather />
            {/* RELOJ */}
            <Clock />
          </Stack>
          <Box>
            {intercity.length === 0 && (
              <>
                <TableIntercityHead />
                <Stack
                  justifyContent={"center"}
                  alignItems={"center"}
                  width={"40vw"}
                  mr={4}
                >
                  {/* <Box
                    component="img"
                    src={sinRegistros}
                    alt={sinRegistros}
                    sx={{
                      width: "auto",
                      height: { xs: "20vh", sm: "25vh", lg: "25vh" },
                      transform: "rotate(90deg)",
                    }}
                  /> */}
                  <Box
                    component="img"
                    src={logo}
                    sx={{
                      width: "auto",
                      height: { xs: "10vh", sm: "15vh", lg: "15vh" },
                      transform: "rotate(90deg)",
                    }}
                  />
                </Stack>
              </>
            )}
            {intercity.length === undefined && (
              <Stack
                justifyContent={"center"}
                alignItems={"center"}
                height={"40vh"}
              >
                <CircularProgress />
              </Stack>
            )}
            {intercity.length > 0 && <GenericTableInterurbanos props={intercity.slice((page - 1) * itemsPerPage, page * itemsPerPage)} />}
            <MarketingModuleTV data={imgArray} />
          </Box>
        </FullScreen>
        {/* BOTÓN FULL SCREEN */}
        <button className="fullscreen-button" onClick={handle.enter}>
          <FullscreenIcon />
        </button>
      </Box>
    </>
  );
};

export default InterurbanosBoardTV;
