import axios from 'axios'
import React from 'react'
import { useParams } from 'react-router-dom'
import FormIntercityEdit from '../../components/forms/FormIntercityEdit'

const AdminIntercityEdit = () => {
    const [ intercity, setIntercity ] = React.useState(null)

    const { id }  = useParams()
    const getIntercityToEdit = (id) => {
        const url = 'https://terminaldigital.com.ar/api/interurbanos/' + id
        const token = sessionStorage.getItem('jwt')
        const config = { headers: { authorization: `Bearer ${token}`}}
        
        axios.get(url, config)
          .then(data => {
            setIntercity(data.data.interurbano);
          })
          .catch((err) => console.log("error intercity edit page", err));
    }

    React.useEffect(() => {
        getIntercityToEdit(id)
    }, [])

    return (
        <FormIntercityEdit intercity={intercity} />
    )
}

export default AdminIntercityEdit