import React, { useState } from "react";
import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { useFormik } from "formik";
import * as yup from "yup";
import axios from "axios";
import { Link } from "react-router-dom";
import ReplyIcon from "@mui/icons-material/Reply";
import { readAndCompressImage } from 'browser-image-resizer';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";


const validationSchema = yup.object({
  empresa: yup.string().required("Campo requerido"),
  siglas: yup.string().required("Campo requerido"),
  cuit: yup.string().required("Campo requerido"),
});

const FormCompany = ({ company }) => {
  const [img, setImg] = useState(company?.img);
  const token = sessionStorage.getItem("jwt");
  const navigator = useNavigate();

  const initialCompany = {
    empresa: "",
    siglas: "",
    imagen: img,
    cuit: "",
    telefono: "",
    email: "",
    horario_atencion: "",
    ubicacion_en_terminal: "",
  };

  const editCompany = {
    empresa: company?.empresa, 
    siglas: company?.siglas, 
    imagen: company?.img || img, 
    cuit: company?.cuit, 
    telefono: company?.telefono,
    email: company?.email,
    ubicacion_en_terminal: company?.ubicacion_en_terminal,
    horario_atencion: company?.horario_atencion,
  };

  const formik = useFormik({
    initialValues: editCompany || initialCompany,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      const config = { headers: { authorization: `Bearer ${token}` } };
      const url = `https://terminaldigital.com.ar/api/empresas/${company.id}`;
      const data = formik.values;
      

      const formData = new FormData();
      
      formData.append("imagen", img);
      formData.append("empresa", data.empresa);
      formData.append("siglas", data.siglas);
      formData.append("cuit", data.cuit);
      formData.append("telefono", data.telefono);
      formData.append("email", data.email);
      formData.append("ubicacion_en_terminal", data.ubicacion_en_terminal);
      formData.append("horario_atencion", data.horario_atencion);

      axios
        .patch(url, formData, config)
        .then((res) => {
      
          if (res.status === 200) {
            toast.success("La empresa fue modificada con éxito");
            setTimeout(() => {
              navigator("/supervisor/empresas");
            }, 2000);
          };
        })
        .catch(function (error) {
          console.log("Error:", error);
          toast.error("No se pudo modificar la empresa");
          setTimeout(() => {
            navigator("/supervisor/empresas");
          }, 2000);
        });
    },
  });

  const handleImageUpload = async (event) => {
    if (event.target.files.length === 0) {
      return; 
    }

    const imageFile = event.target.files[0];

    const img = new Image();
    img.src = URL.createObjectURL(imageFile);
    img.onload = async () => {
      if (img.width > 800 || img.height > 800) {
        toast.warn("La imagen debe tener un tamaño máximo de 800x800px.");
        setImg(null); 
        return;
      }

      const config = {
        quality: 0.5,
        maxWidth: 800,
        maxHeight: 800,
        autoRotate: true,
        debug: true
      };

      try {
        const compressedImage = await readAndCompressImage(imageFile, config);
        setImg(compressedImage);
      } catch (error) {
        console.log(error);
      }
    };
  };

  return (
    <Stack
      sx={{ background: "#F4F2EC", borderRadius: "25px", shadow: 4 }}
      my={4}
      mx={{ xs: 1, sm: 6 }}
      p={4}
      sm={6}
    >
      <form onSubmit={formik.handleSubmit}>
        <Link to="/supervisor/empresas">
          <Button
            sx={{ color: "#b22222", marginLeft: { xs: "85%", sm:"92%", md: "95%" } }}
            variant="outlined"
            color="error"
          >
            <ReplyIcon />
          </Button>
        </Link>
        <Typography
          sx={{
            fontSize: { xs: "1rem", lg: "2rem" },
            fontFamily: 'Lato, sans-serif',
            color: '#0e315a',
            textShadow: '2px 2px 2px rgba(0, 0, 0, 0.25)',
            display: 'flex',
            justifyContent: 'center',
            marginTop: { xs: "0%", md: "0%" }
          }}
        >
          Editar Empresa
        </Typography>
        <Grid container my={2}>
          <Grid
            item
            display={{ xs: "block", md: "flex" }}
            alignItems="center"
            gap={2}
            xs={12}
            md={12}
            my={2}
          >
            <Typography
              variant="subtitle1"
              color="black"
              display={{ xs: "none", sm: "block" }}
            >
              Nombre de la Empresa:
            </Typography>
            <TextField
              sx={{
                ".MuiOutlinedInput-notchedOutline": {
                  borderColor: "black",
                  width: "150%",
                },
                ".MuiInputBase-root": {
                  color: "black",
                  width: "100%",
                },
              }}
              InputLabelProps={{
                style: { color: "black" },
              }}
              InputProps={{
                type: "text",
              }}
              label=""
              name="empresa"
              value={formik.values.empresa}
              onChange={formik.handleChange}
              error={formik.errors.empresa}
              helperText={formik.errors.empresa}
            ></TextField>
          </Grid>
          <Grid
            item
            display={{ xs: "block", md: "flex" }}
            alignItems="center"
            gap={2}
            xs={12}
            sm={12}
            my={2}
          >
            <Typography
              variant="subtitle1"
              color="black"
              display={{ xs: "none", sm: "block" }}
            >
              Siglas de la empresa:
            </Typography>
            <TextField
              label=""
              InputLabelProps={{
                style: { color: "black" },
              }}
              sx={{
                ".MuiOutlinedInput-notchedOutline": {
                  borderColor: "black",
                },
                ".MuiInputBase-root": {
                  color: "black",
                },
                "& .MuiSvgIcon-root": {
                  color: "black",
                },
                minWidth: "250px",
              }}
              InputProps={{
                type: "text",
              }}
              name="siglas"
              value={formik.values.siglas}
              onChange={formik.handleChange}
              error={formik.errors.siglas}
              helperText={formik.errors.siglas}
            ></TextField>
          </Grid>
          <Grid
            item
            display={{ xs: "block", md: "flex" }}
            alignItems="center"
            gap={2}
            xs={12}
            sm={12}
            my={2}
          >
            <Typography
              variant="subtitle1"
              color="black"
              display={{ xs: "none", sm: "block" }}
            >
              Imagen de la empresa:
            </Typography>
            <TextField
              label=""
              InputLabelProps={{
                style: { color: "black" },
              }}
              sx={{
                ".MuiOutlinedInput-notchedOutline": {
                  borderColor: "black",
                },
                ".MuiInputBase-root": {
                  color: "black",
                },
                "& .MuiSvgIcon-root": {
                  color: "black",
                },
                minWidth: "250px",
              }}
              InputProps={{
                type: "file",
              }}
              id="imagen"
              name="imagen"
              type="file"
              accept="image/*"
              onChange={handleImageUpload}
            ></TextField>
            <Box
              component="img"
              sx={{
                height: 60,
                width: 200,
                maxHeight: { xs: 233, md: 167 },
                maxWidth: { xs: 350, md: 250 },
              }}
              alt={formik.values.img}
              src={company?.img || img}
            />
          </Grid>
          <Grid
            item
            display={{ xs: "block", md: "flex" }}
            alignItems="center"
            gap={2}
            xs={12}
            sm={12}
            my={2}
          >
            <Typography
              variant="subtitle1"
              color="black"
              display={{ xs: "none", sm: "block" }}
            >
              CUIT de la empresa:
            </Typography>
            <TextField
              label=""
              InputLabelProps={{
                style: { color: "black" },
              }}
              sx={{
                ".MuiOutlinedInput-notchedOutline": {
                  borderColor: "black",
                },
                ".MuiInputBase-root": {
                  color: "black",
                },
                "& .MuiSvgIcon-root": {
                  color: "black",
                },
                minWidth: "250px",
              }}
              InputProps={{
                type: "text",
              }}
              name="cuit"
              value={formik.values.cuit}
              onChange={formik.handleChange}
              error={formik.errors.cuit}
              helperText={formik.errors.cuit}
            ></TextField>
          </Grid>
          <Grid
            item
            display={{ xs: "block", md: "flex" }}
            alignItems="center"
            gap={2}
            xs={12}
            sm={12}
            my={2}
          >
            <Typography
              variant="subtitle1"
              color="black"
              display={{ xs: "none", sm: "block" }}
            >
              Teléfono de contacto para el público:
            </Typography>
            <TextField
              label=""
              InputLabelProps={{
                style: { color: "black" },
              }}
              sx={{
                ".MuiOutlinedInput-notchedOutline": {
                  borderColor: "black",
                },
                ".MuiInputBase-root": {
                  color: "black",
                },
                "& .MuiSvgIcon-root": {
                  color: "black",
                },
                minWidth: "250px",
              }}
              InputProps={{
                type: "number",
              }}
              name="telefono"
              value={formik.values.telefono}
              onChange={formik.handleChange}
              error={formik.errors.telefono}
              helperText={formik.errors.telefono}
            ></TextField>
          </Grid>
          <Grid
            item
            display={{ xs: "block", md: "flex" }}
            alignItems="center"
            gap={2}
            xs={12}
            sm={12}
            my={2}
          >
            <Typography
              variant="subtitle1"
              color="black"
              display={{ xs: "none", sm: "block" }}
            >
              Email de contacto para el publico:
            </Typography>
            <TextField
              label=""
              InputLabelProps={{
                style: { color: "black" },
              }}
              sx={{
                ".MuiOutlinedInput-notchedOutline": {
                  borderColor: "black",
                },
                ".MuiInputBase-root": {
                  color: "black",
                },
                "& .MuiSvgIcon-root": {
                  color: "black",
                },
                minWidth: "250px",
              }}
              InputProps={{
                type: "email",
              }}
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.errors.email}
              helperText={formik.errors.email}
            ></TextField>
          </Grid>
          <Grid
            item
            display={{ xs: "block", md: "flex" }}
            alignItems="center"
            gap={2}
            xs={12}
            sm={12}
            my={2}
          >
            <Typography
              variant="subtitle1"
              color="black"
              display={{ xs: "none", sm: "block" }}
            >
              Ubicación en la terminal:
            </Typography>
            <TextField
              label=""
              InputLabelProps={{
                style: { color: "black" },
              }}
              sx={{
                ".MuiOutlinedInput-notchedOutline": {
                  borderColor: "black",
                },
                ".MuiInputBase-root": {
                  color: "black",
                },
                "& .MuiSvgIcon-root": {
                  color: "black",
                },
                minWidth: "250px",
              }}
              InputProps={{
                type: "text",
              }}
              name="ubicacion_en_terminal"
              value={formik.values.ubicacion_en_terminal}
              onChange={formik.handleChange}
              error={formik.errors.ubicacion_en_terminal}
              helperText={formik.errors.ubicacion_en_terminal}
            ></TextField>
          </Grid>
          <Grid
            item
            display={{ xs: "block", md: "flex" }}
            alignItems="center"
            gap={2}
            xs={12}
            sm={12}
            my={2}
          >
            <Typography
              variant="subtitle1"
              color="black"
              display={{ xs: "none", sm: "block" }}
            >
              Horario de atención al público:
            </Typography>
            <TextField
              label=""
              InputLabelProps={{
                style: { color: "black" },
              }}
              sx={{
                ".MuiOutlinedInput-notchedOutline": {
                  borderColor: "black",
                },
                ".MuiInputBase-root": {
                  color: "black",
                },
                "& .MuiSvgIcon-root": {
                  color: "black",
                },
                minWidth: "250px",
              }}
              InputProps={{
                type: "text",
              }}
              name="horario_atencion"
              value={formik.values.horario_atencion}
              onChange={formik.handleChange}
              error={formik.errors.horario_atencion}
              helperText={formik.errors.horario_atencion}
            ></TextField>
          </Grid>
          <Grid item sx={{ marginLeft: "auto" }} align="center" xs={12} pt={4}>
            <Button
              variant="contained"
              ml="auto"
              type="submit"
              my={2}
              sx={{ marginLeft: "20px" }}
            >
              Modificar empresa
            </Button>
          </Grid>
        </Grid>
      </form>
    </Stack>
  );
};

export default FormCompany;
