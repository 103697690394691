import React, { useState, useEffect } from "react";
import axios from "axios";

import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

const dateNow = new Date();

const WEEK = [
  "Domingo",
  "Lunes",
  "Martes",
  "Miércoles",
  "Jueves",
  "Viernes",
  "Sábado",
];

export function weeks(config) {
  var cfg = config || {};
  var count = cfg.count || 7;
  var section = cfg.section;
  var values = [];
  var i, value;

  for (i = 0; i < count; ++i) {

    value = WEEK[Math.ceil(i) % 7];
    values.push(value.substring(0, section));
  }

  return values;
}

export default function BarsChartMonth({dateFrom, dateTo}) {
  const [weekData, setWeekData] = useState([]);
  const [maxCount, setMaxCount] = useState([])
  const [weekDates, setWeekDates] = useState([]);
  const [total, setTotal] = useState([])

  const token = sessionStorage.getItem("jwt");
  
  useEffect(() => {
    const url = `https://terminaldigital.com.ar/api/graficas/semana?dateFrom=${dateFrom}&dateTo=${dateTo}`;

    axios
      .get(url)
      .then((response) => {
        if (Array.isArray(response.data.registrosModificados)) {
          const newData = response.data.registrosModificados.map(dataset => dataset.map(item => (item.value) ? item.value : item));
          let newDates = response.data.registrosModificados[0].map(item => (item.fecha) ? item.fecha : 0);
          newDates = newDates.sort((a, b) => new Date(a).getDay() - new Date(b).getDay());
          setWeekData(newData);
          setWeekDates(newDates);
          const flatArray = newData.flat();
          const totalSum = flatArray.reduce((sum, value) => sum + Number(value), 0);
          setMaxCount(Math.max(...flatArray) + 10);
          setTotal(totalSum);
        }
      })
      .catch((error) => console.log("error jwt:", error));
  }, [token]);

  var midata = {
    labels: weeks({ count: 7 }),
    datasets: [
      {
        label: "Larga distancia",
        data: weekData[0] ? weekData[0] : [],
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
      {
        label: "Mediana distancia",
        data: weekData[1] ? weekData[1] : [],
        backgroundColor: "rgba(255, 159, 64, 0.5)",
      },
      {
        label: "Corta distancia",
        data: weekData[2] ? weekData[2] : [],
        backgroundColor: "rgba(75, 192, 192, 0.5)",
      },
      {
        label: "Otro servicio",
        data: weekData[3] ? weekData[3] : [],
        backgroundColor: "rgba(153, 102, 255, 0.5)",
      },
    ],
  };

  var misoptions = {
    responsive: true,
    animation: false,
    plugins: {
      legend: {
        display: true,
      },
      title: {
        display: true,
        text: 'Total registros: ' + total
      }
    },
    scales: {
      y: {
        min: 0,
        max: maxCount,
      },
      x: {
        ticks: {
          color: "rgb(0, 0, 0)",
          callback: function (value, index, values) {
            const date = new Date(weekDates[index] + 'T00:00:00Z'); // Asegura que la fecha es interpretada en UTC
            const day = date.getUTCDate();
            const month = date.getUTCMonth() + 1; // Los meses en JavaScript empiezan en 0
            return WEEK[date.getUTCDay()] + '\n' + day + '/' + month;
          }
        },
      },
    },
  }

  return <Bar data={midata} options={misoptions} />;
}