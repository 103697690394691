import * as React from 'react';
import { useQuery } from 'react-query';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, Modal, Typography } from '@mui/material';
import axios from 'axios';
import SettingsIcon from '@mui/icons-material/Settings';
import FormMarquesineEdit from '../forms/FormMarquesineEdit';
import WifiProtectedSetupIcon from '@mui/icons-material/WifiProtectedSetup';
import { useMarquesine } from '../../pages/marquesine/MarquesineContext';
import DeleteIcon from '@mui/icons-material/Delete';
import { toast } from 'react-toastify';


const style = {
  width: "100%",
  // height: "50vh",
  paddingBlock: "2vh",
  textAlign: "center",
  align: "center",
  zIndex: "99",
};

const stylesModal = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80vw',
  maxWidth: '400px',
  height: 'auto',
  bgcolor: 'background.paper',
  boxShadow: 24,
  
};

export default function TableMarquesine() {
  const token = window.sessionStorage.getItem('jwt');
  const url = 'https://terminaldigital.com.ar/api/marquesinas';
  const config = { headers: { 'authorization': `Bearer ${token}` } };
  const marquesineUpdate = useMarquesine();
  
  const getMarquesinas = async () => {
    const response = await axios.get(url, config);
    return response.data.marquesinas;
  }

  const { data, isLoading, isError, refetch } = useQuery('marquesinas', getMarquesinas);

  const [open, setOpen] = React.useState(false);
  const [dataModal, setDataModal] = React.useState({})
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const editThis = (data) => {
    handleOpen();
    setDataModal(data)
  }

  const updateList = () => {
    refetch();
  };

  React.useEffect(() => {

  }, [marquesineUpdate]);

  const deleteMarquesina = async (row) => {
    try {
      const response = await axios.delete(`https://terminaldigital.com.ar/api/marquesinas/delete/${row.id}`, config);

      if (response.status === 200) {
        toast.success('Marquesina eliminada con éxito', { autoClose: 2000 });
      }
    } catch (error) {
      toast.error('Error al eliminar la marquesina');
    }
  }


  return (
    <Box sx={{ background: "#0e315a", paddingBlock: '1px', mb: 5, mt:2 }}>
      <TableContainer
        component={Paper}
        sx={{ borderRadius: "0px" }}
        style={style}
      >

        <Box px={{ xs: 1, sm: 2, md: 4 }} > 
          <Table sx={{ minWidth: 150 }} aria-label="simple table" id="rrhh">
            <TableHead>
              <TableRow>
                <TableCell align="left">Texto</TableCell>
                <TableCell align="right">
                  <WifiProtectedSetupIcon
                    sx={{ color: "#a9a9a9", cursor: "pointer" }}
                    onClick={getMarquesinas}
                  />
                </TableCell>
                <TableCell align="center">Estado</TableCell>
                <TableCell align="center">Editar</TableCell>
                <TableCell align="center">Eliminar</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.reverse().map((row) => (
                <TableRow
                  key={row.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="center">{row.texto}</TableCell>
                  <TableCell align="left"></TableCell>
                  {row.estado !== null ? (
                    <TableCell align="center">
                      {row.estado === 0 ? "Inactiva" : "Activa"}
                    </TableCell>
                  ) : (
                    <TableCell align="center">-</TableCell>
                  )}

                  <TableCell align="center">
                    <SettingsIcon
                      onClick={() => editThis(row)}
                      sx={{ cursor: "pointer" }}
                    />
                  </TableCell>
                  <TableCell align="center">
                    <DeleteIcon
                      onClick={() => {
                        deleteMarquesina(row);
                        updateList();
                      }}
                      sx={{ cursor: "pointer" }}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          pt={4}
        >
          <Box sx={stylesModal}>
            <Typography
              onClick={handleClose}
              sx={{
                color: "white",
                background: "#0e315a",
                fontWeight: "bold",
                fontSize: "1.7rem",
                position: "absolute",
                top: "15px",
                right: "30px",
                cursor: "pointer",
              }}
            >
              X
            </Typography>
            <FormMarquesineEdit
              texto={dataModal?.texto}
              estado={dataModal?.estado}
              id={dataModal?.id}
              onClose={handleClose}
              updateList={updateList} 
            />
          </Box>
        </Modal>
        <br></br>
        <br></br>
        <br></br>
        <br></br>

      </TableContainer>
    </Box>
  );
}
