import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import XIcon from '@mui/icons-material/X';
import axios from "axios";
import LogoDigital from "../../assets/img/TerminalDigital2.png";

import { Stack, Typography, Box } from "@mui/material";

const Footer = (edit) => {
  const [yearState, setYearState] = React.useState("");
  const [user, setUser] = React.useState(false);

  React.useEffect(() => {
    const date = new Date();
    let year = date.getYear() + 1900;
    setYearState(year);
  }, []);

  const [dataTerminal, setDataTerminal] = useState([]);

  const getDataTerminal = async () => {
    axios
      .get("https://terminaldigital.com.ar/api/administrarterminal")
      .then((data) => {
        setDataTerminal(data.data.terminal);
      })
      .catch((error) => console.log("error users", error));
  };

  useEffect(() => {
    getDataTerminal();
    if (sessionStorage.getItem("jwt")) {
      setUser(true);
    } else return setUser(false);
  }, [edit]);

  return (
    <>
      {user ? (
        <Stack
          sx={{ background: "white", py: 2, px: 4 }}
          direction={{
            xs: "column-reverse",
            sm: "row-reverse",
            lg: "row-reverse ",
          }}
          position={"sticky"}
        >
          <Box py={{ xs: 0, lg: 0 }} mt={{ xs: 2, sm: 2.5, lg: 6 }}>
            <Box textAlign={{ xs: "center", sm: "center" }}>
              <Typography variant="body3" gutterBottom>
                Terminal de {dataTerminal.nombre}
              </Typography>
              <Typography variant="body2" gutterBottom>
                Provincia de {dataTerminal.provincia}
              </Typography>
              <Typography variant="body2">
                Año {yearState}
              </Typography>
            </Box>
          </Box>
          <Box
            component="img"
            className=""
            alt="Logo Terminal Digital"
            src={LogoDigital}
            sx={{
              height: { xs: "50px", sm: "60px", lg: "100px" },
              width: { xs: "auto", sm: "auto", lg: "auto" },
              maxWidth: { xs: "100%", sm: "100%", lg: "100%" },
              marginTop: { xs: "auto", sm: "auto", lg: "40px" },
              marginRight: { xs: "auto", sm: "auto", lg: "auto" },
              marginBottom: { xs: "auto", sm: "auto", lg: "0" },
              marginLeft: { xs: "auto", sm: "auto", lg: "-80px" },
            }}
          />
          <Box
            component="img"
            className=""
            alt={dataTerminal.logo}
            src={dataTerminal.logo}
            sx={{
              maxWidth: { sm: "200px", lg: "300px" },
              maxHeight: { sm: "100px", lg: "150px" },
              height: { sm: "auto", lg: "auto" },
              width: { sm: "auto", lg: "auto" },
              marginTop: { lg: "auto" },
              marginRight: { lg: "auto" },
              marginBottom: { lg: "0" },
              marginLeft: { lg: "0" },
              display: { xs: "none", sm: "block", lg: "block" },
            }}
          />
        </Stack>
      ) : (
        <Stack
          role="footer"
          sx={{
            paddingBottom: { xs: "140px", sm: 2 },
            color: "#0e315a",
            backgroundColor: "white",
            paddingTop: { xs: "0px", sm: "10px" },
            paddingRight: { xs: 0, md: "48px" },
          }}
          direction={{ xs: "column", sm: "row" }}
          justifyContent={"space-between"}
          alignItems={{ xs: "center" }}
          //sx={{ position: "fixed" }}
        >
            <Box
              component="img"
              className=""
              alt={dataTerminal.logo}
              src={dataTerminal.logo}
              sx={{ maxWidth: "200px", display: { xs: "none", sm: "block" } }}
            />
            <Box sx={{ display: { xs: "block", sm: "none" } }}>
              <br></br>
              <br></br>
            </Box>
            <Box
              textAlign={{ xs: "center", sm: "center", fontSize: "0.93rem" }}
            >
              {dataTerminal.como_llegar === "" ? (
                <a href="/"></a>
              ) : (
                <a
                  href={dataTerminal.como_llegar}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Typography>¿Cómo llegar?</Typography>
                </a>
              )}
              <Link to={"/institucional"}>
                <Typography textAlign="center">Institucional</Typography>
              </Link>
            </Box>
            <Box
              textAlign={{ xs: "center", sm: "center", fontSize: "0.93rem" }}
            >
              <Link to="/agencias">
                <Typography>Agencias</Typography>
              </Link>
              <Link to={"/contacto"}>
                <Typography textAlign="center">Contacto</Typography>
              </Link>
              <a href="/">
                <Typography textAlign="center"></Typography>
              </a>
            </Box>
            <Box
              textAlign={{ xs: "center", sm: "center", fontSize: "0.93rem" }}
            >
              {dataTerminal.venta_pasajes === "" ? (
                <a href="/" rel="noreferrer" target="_blank"></a>
              ) : (
                <a
                  href={dataTerminal.venta_pasajes}
                  rel="noreferrer"
                  target="_blank"
                >
                  <Typography textAlign="center">Venta de pasajes</Typography>
                </a>
              )}
              <a
                href="https://www.argentina.gob.ar/transporte/cnrt"
                rel="noreferrer"
                target="_blank"
              >
                <Typography textAlign="center">CNRT</Typography>
              </a>
            </Box>
            <Box sx={{ display: { xs: "block", sm: "none" } }}>
              <br></br>
            </Box>
            <Box textAlign={{ xs: "center", sm: "center" }} pt={3}>
              {dataTerminal.facebook === "" ? (
                <a href="/"></a>
              ) : (
                <a
                  href={dataTerminal.facebook}
                  target="_blank"
                  rel="noreferrer"
                >
                  <FacebookIcon
                    color="facebook"
                    sx={{ fontSize: "2.5rem", marginLeft: { xs: 0, sm: 2 } }}
                  />{" "}
                </a>
              )}
              {dataTerminal.instagram === "" ? (
                <a href="/"></a>
              ) : (
                <a
                  href={dataTerminal.instagram}
                  target="_blank"
                  rel="noreferrer"
                >
                  <InstagramIcon
                    color="instagram"
                    sx={{
                      fontSize: "2.5rem",
                      marginLeft: { xs: 5, sm: 2, lg: 6 },
                    }}
                  />
                </a>
              )}
              {dataTerminal.twitter === "" ? (
                <a href="/"></a>
              ) : (
                <a href={dataTerminal.twitter} target="_blank" rel="noreferrer">
                  <XIcon
                    color="black"
                    sx={{
                      fontSize: "2.4rem",
                      marginLeft: { xs: 6, sm: 3, lg: 6 },
                      marginRight: { xs: -0.7, sm: 3, lg: 0 },
                    }}
                  />
                </a>
              )}
            </Box>
            <Box sx={{ display: { xs: "block", sm: "none" } }}>
              <br></br>
            </Box>
            <Box
              py={{ xs: 0, sm: 2 }}
              sx={{ display: { xs: "block", sm: "none", md: "block" } }}
            >
              <Box
                textAlign={{ xs: "center", sm: "center" }}
                py={{ xs: 2, sm: 2 }}
              >
                <Typography variant="body2">
                  Terminal de {dataTerminal.nombre}
                </Typography>
                <Typography variant="body2">
                  Provincia de {dataTerminal.provincia}
                </Typography>
                <Typography variant="body2">Año {yearState}</Typography>
              </Box>
            </Box>

            <Box
              component="img"
              className=""
              alt="Logo Terminal Digital"
              src={LogoDigital}
              sx={{
                height: "50px",
                display: { xs: "none", sm: "none", lg: "block" },
              }}
            />
        </Stack>
      )}
    </>
  );
};

export default Footer;
