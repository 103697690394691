import React, { useState, useEffect } from "react";
import axios from "axios";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

const dateNow = new Date();
const hour = dateNow.getHours() + 1;

const HOUR = [
  "00:00",
  "01:00",
  "02:00",
  "03:00",
  "04:00",
  "05:00",
  "06:00",
  "07:00",
  "08:00",
  "09:00",
  "10:00",
  "11:00",
  "12:00",
  "13:00",
  "14:00",
  "15:00",
  "16:00",
  "17:00",
  "18:00",
  "19:00",
  "20:00",
  "21:00",
  "22:00",
  "23:00",
];

export function hours(config) {
  var cfg = config || {};
  var count = cfg.count || 24;
  var section = cfg.section;
  var values = [];
  var i, value;

  for (i = 0; i < count; ++i) {
    value = HOUR[Math.ceil(i) % 24];
    values.push(value.substring(0, section));
  }

  return values;
}

export default function BarsChartMonth({dateFrom,dateTo,empresa}) {
  const [dayData, setMonth] = useState([]);
  const [maxCount, setMaxCount] = useState();
  const [maxHour, setMaxHour] = useState();
  const [totalIngresos, setTotalIngresos] = useState();
  const [topRegistros, setTopRegistros] = useState([]);



  const token = sessionStorage.getItem("jwt");

  useEffect(() => {
    const url = `https://terminaldigital.com.ar/api/graficas/dia?dateFrom=${dateFrom}&dateTo=${dateTo}&empresa_id=${empresa}`;

    axios
      .get(url)
      .then((response) => {
        setMonth(response.data.registrosModificados);
        setMaxCount(Math.max(...response.data.max) + 0.5);
        if (response.data.registrosModificados[0]) {
          let maxIndex = response.data.registrosModificados[0].reduce((iMax, x, i, arr) => x > arr[iMax] ? i : iMax, 0);
          setMaxHour(HOUR[maxIndex]);
          let totalIngresos = response.data.registrosModificados[4].reduce((a, b) => a + b, 0);
          setTotalIngresos(totalIngresos);
        }
      })
      .catch((error) => console.log("error jwt:", error));
  }, [dateFrom, dateTo, token]);

  useEffect(() => {
   
    const url = "https://terminaldigital.com.ar/api/graficas/top-horarios";

    axios
      .get(url)
      .then((response) => {
        setTopRegistros(response.data.registros)
      })
      .catch((error) => console.log("error jwt:", error));
  }, [token]);

  // Ordena los registros de mayor a menor ingresos
  let registrosOrdenados = [...topRegistros].sort((a, b) => b.total - a.total);

  // Toma los primeros 3 registros (o la cantidad que desees)
  let topHorarios = registrosOrdenados.slice(0, 3);

  // Genera el texto
  let texto = topHorarios.map((registro, index) => `El puesto ${index + 1} es para el día ${registro.dia} a las ${registro.hora}:00hs con ${registro.total} ingresos.`).join(' ');

  console.log(texto);
  

  var misoptions = {
    responsive: true,
    animation: false,
    plugins: {
      title: {
        display: true,
        text: maxHour ? `${maxHour}hs el horario con mayor registro de ingresos - (Total: ${totalIngresos})` : '',
        font: {
          weight: 'bold'
        }
      },
      legend: {
        display: true,
      },
    },
    scales: {
      y: {
        min: 0,
        max: maxCount,
      },
      x: {
        ticks: { color: "rgba(0, 0, 0)" },
        title: {
          display: true,
        },
      },
    },
  };

  var midata = {
    labels: hours({ count: hour }),
    datasets: [
      {
        label: "Larga distancia",
        data: dayData[0],
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
      {
        label: "Media distancia",
        data: dayData[1],
        backgroundColor: "rgba(255, 159, 64, 0.5)",
      },
      {
        label: "Corta distancia",
        data: dayData[2],
        backgroundColor: "rgba(75, 192, 192, 0.5)",
      },
      {
        label: "Otro servicio",
        data: dayData[3],
        backgroundColor: "rgba(153, 102, 255, 0.5)",
      },
    ],
  };

  return <Bar data={midata} options={misoptions} />;
}