import { React, useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import Typography from "@mui/material/Typography";

const textTranslateState = [
  "Estado", // Español
  "State", // Inglés
  "Estado", // Portugués
  "État", // Francés
  "Zustand", // Alemán
  "Stato", // Italiano
  "状态", // Chino (Taiwán)
  "상태", // Coreano
  "状態", // Japonés
  "מַצָב", // Hebreo
  "الحالة", // Árabe
].map(text => text.toUpperCase());

const destinoTranslate = [
  "DESTINO", // Español
  "DESTINATION", // Inglés
  "DESTINO", // Portugués
  "DESTINATION", // Francés
  "ZIEL", // Alemán
  "DESTINAZIONE", // Italiano
  "目的地", // Chino (para turistas de Taiwán)
  "목적지", // Coreano
  "目的地", // Japonés
  "יעד", // Hebreo
  "المقصد", // Árabe
];

const empresaTranslate = [
  "EMPRESA", // Español
  "COMPANY", // Inglés
  "EMPRESA", // Portugués
  "ENTREPRISE", // Francés
  "UNTERNEHMEN", // Alemán
  "AZIENDA", // Italiano
  "公司", // Chino (para turistas de Taiwán)
  "회사", // Coreano
  "会社", // Japonés
  "חברה", // Hebreo
  "الشركة", // Árabe
];

const partidaTranslate = [
  "PARTIDA", // Español
  "DEPARTURE", // Inglés
  "PARTIDA", // Portugués
  "DÉPART", // Francés
  "ABFAHRT", // Alemán
  "PARTENZA", // Italiano
  "出发", // Chino (para turistas de Taiwán)
  "출발", // Coreano
  "出発", // Japonés
  "יציאה", // Hebreo
  "المغادرة", // Árabe
];

const plataformaTranslate = [
  "PLATAFORMA", // Español
  "PLATFORM", // Inglés
  "PLATAFORMA", // Portugués
  "PLATEFORME", // Francés
  "PLATTFORM", // Alemán
  "PIATTAFORMA", // Italiano
  "平台", // Chino (para turistas de Taiwán)
  "플랫폼", // Coreano
  "プラットフォーム", // Japonés
  "פלטפורמה", // Hebreo
  "منصة", // Árabe
];

const internoTranslate = [
  "INTERNO", // Español
  "INTERNAL", // Inglés
  "INTERNO", // Portugués
  "INTERNE", // Francés
  "INTERN", // Alemán
  "INTERNO", // Italiano
  "内部", // Chino (para turistas de Taiwán)
  "내부", // Coreano
  "内部", // Japonés
  "פנימי", // Hebreo
  "داخلي", // Árabe
];
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#0e315a",
    height: "80px",
  },
  [`&.${tableCellClasses.body}`]: {},
  [`&.${tableCellClasses.root}`]: {
    borderBottom: "none",
    fontSize: "0.93rem",
    color: "white",
    fontWeight: "bold",
    padding: "10px",
    marginLeft: "2rem",
  },
}));
const StyledTableCell2 = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#0E315A",
    height: "30px",
  },
  [`&.${tableCellClasses.body}`]: {},
  [`&.${tableCellClasses.root}`]: {
    borderBottom: "none",
    fontSize: 20,
    color: "white",
    fontWeight: "bold",
    padding: "10px",
    marginLeft: '2rem'
  },
}));

const StyledTableCell3 = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#0E315A",
    height: "20px",
  },
  [`&.${tableCellClasses.body}`]: {},
  [`&.${tableCellClasses.root}`]: {
    borderBottom: "none",
    fontSize: 20,
    color: "white",
    fontWeight: "bold",
    padding: "10px",
    marginLeft: '2rem'
  },
}));

export default function TableDeparturesHead(  ) {
   
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % destinoTranslate.length);
    }, 30000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <TableContainer component={Paper} sx={{borderRadius: '0px'}}>
      <Table sx={{ fontSize: "20px", height: "100%" }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell align="center">
              <Typography fontSize={{ xs: "10px", sm: "15px", md: "20px" }}>
                {textTranslateState[0]}
              </Typography>
              {index !== 0 &&
                <Typography fontSize={{ xs: "8px", sm: "10px", md: "15px", lg: "15px" }}>
                  ({textTranslateState[index]})
                </Typography>
              }
            </StyledTableCell>
            <StyledTableCell2 align="center">
              <Typography fontSize={{ xs: "10px", sm: "15px", md: "20px", lg: "20px" }}>
                {destinoTranslate[0]}
              </Typography>
              {index !== 0 &&
                <Typography fontSize={{ xs: "8px", sm: "10px", md: "15px", lg: "15px" }}>
                  ( {destinoTranslate[index]} )
                </Typography>
              }
            </StyledTableCell2>
            <StyledTableCell2 align="center">
              <Typography fontSize={{ xs: "10px", sm: "15px", md: "20px", lg: "20px" }}>
                {empresaTranslate[0]}
              </Typography>
              {index !== 0 &&
                <Typography fontSize={{ xs: "8px", sm: "10px", md: "15px", lg: "15px" }}>
                  ( {empresaTranslate[index]} )
                </Typography>
              }
            </StyledTableCell2>
            <StyledTableCell3 align="center">
              <Typography fontSize={{ xs: "10px", sm: "15px", md: "20px", lg: "20px" }}>
                {partidaTranslate[0]}
              </Typography>
              {index !== 0 &&
                <Typography fontSize={{ xs: "8px", sm: "10px", md: "15px", lg: "15px" }}>
                  ( {partidaTranslate[index]} )
                </Typography>
              }
            </StyledTableCell3>
            <StyledTableCell2 align="center">
              <Typography fontSize={{ xs: "10px", sm: "15px", md: "20px", lg: "20px" }}>
                {plataformaTranslate[0]}
              </Typography>
              {index !== 0 &&
                <Typography fontSize={{ xs: "8px", sm: "10px", md: "15px", lg: "15px" }}>
                  ( {plataformaTranslate[index]} )
                </Typography>
              }
            </StyledTableCell2>
            <StyledTableCell2 align="center">
              <Typography fontSize={{ xs: "10px", sm: "15px", md: "20px", lg: "20px" }}>
                {internoTranslate[0]}
              </Typography>
              {index !== 0 &&
                <Typography fontSize={{ xs: "8px", sm: "10px", md: "15px", lg: "15px" }}>
                  ( {internoTranslate[index]} )
                </Typography>
              }
            </StyledTableCell2>
          </TableRow>
        </TableHead>
      </Table>
    </TableContainer>
  );
}
