import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Autocomplete, Button, Grid, MenuItem, TextField, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import { useNavigate } from "react-router-dom";
import { useFormik } from 'formik';
import * as yup from 'yup';
import axios from 'axios'
import BasicModal from '../modals/Modal';
import { Link } from "react-router-dom";
import ReplyIcon from "@mui/icons-material/Reply";
import data1 from "../../json/provincias.json";
import data2 from "../../json/departamentos.json";
import data3 from "../../json/municipios.json";
import { toast } from 'react-toastify';

const useStyles = makeStyles({
  inputRoot: {
    color: 'black',
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: 'black'
    },
  },
});

const validationSchema = yup.object({
  estado_id: yup.string().required("Campo requerido"),
  usuarios_id: yup.string().required("Campo requerido"),
});

const SecurityFormEditTicketError = ({ ticket }) => {
  const classes = useStyles();
  const [autocompleteKey, setAutocompleteKey] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [dataDropdown, setDataDropdown] = useState({});

  const navigate = useNavigate()
  const token = sessionStorage.getItem("jwt");



  const dateNow = new Date();
  const menosUno = 1;
  dateNow.setHours(menosUno);

  const initialTicket = {
    fecha_ingreso: "",
    hora_ingreso: "",
    interno: "",
    empresa_id: "",
    servicios_id: "",
    usuarios_id: "",
    plataformas_id: "" || null,
    estado_id: 2,
    tipo_tv: "",
    destino: "",
    hora_salida: "",
    fecha_salida: "",
    operacion_id: "",
    estado_de_datos: "",
  };

  const editTicket = {
    fecha_ingreso: ticket.fecha_ingreso,
    hora_ingreso: ticket.hora_ingreso,
    interno: ticket.interno === 999999 ? "" : ticket.interno,
    usuarios_id: ticket.usuarios_id,
    plataformas_id: ticket.plataformas_id || null,
    estado_id: 2,
    destino: ticket.destino,
    tipo_tv: ticket.tipo_tv_id,
    fecha_salida: ticket.fecha_salida,
    hora_salida: ticket.hora_salida,
    empresa_id: ticket?.empresa_id,
    servicios_id: ticket?.registro_servicio.id,
    operacion_id: 2,
    estado_de_datos: 2,
  };

  const locations1 = [...new Set(data1.provincias.map(provincia => provincia.nombre))];
  const locations2 = [...new Set(data2.departamentos.map(departamento => departamento.nombre))];
  const locations3 = [...new Set(data3.municipios.map(municipio => municipio.nombre))];

  const locations = [...new Set([...locations1, ...locations2, ...locations3])];

  React.useEffect(() => {
    const url = "https://terminaldigital.com.ar/api/informes/dataDropdown";

    axios
      .get(url, { headers: { authorization: `Bearer ${token}` } })
      .then((response) => setDataDropdown(response.data))
      .catch((error) => console.log("error jwt:", error.response.data.message));

  }, [token]);

  const formik = useFormik({
    initialValues: editTicket || initialTicket,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      const config = { headers: { authorization: `Bearer ${token}` } };
      const url = `https://terminaldigital.com.ar/api/informes/modificarData/${ticket.id}`;
      const data = formik.values;



      axios
        .patch(url, data, config)
        .then((res) => {
          if (res.status === 200) {

            setOpenModal(true);
            navigate(-1);
          }
        })
        .catch(function (error) {
          console.log("Error:", error);
        });
    },
  });

  return (
    <Stack
      sx={{ background: "#F4F2EC", borderRadius: "25px", shadow: 4 }}
      my={4}
      mx={{ xs: 1, sm: 6 }}
      p={4}
      sm={6}
    >
      <form onSubmit={formik.handleSubmit}>
        <Link to="/seguridad/control-de-accesos">
          <Button
            variant="outlined"
            color="error"
            py={2}
            my={4}
            mx={4}
            sx={{ color: "#b22222", marginLeft: { xs: "90%", md: "95%" } }}
          >
            <ReplyIcon />
          </Button>
        </Link>

        <Typography sx={{
          fontSize: { lg: "2rem" },
          fontFamily: 'Lato, sans-serif',
          color: '#0e315a',
          textShadow: '2px 2px 2px rgba(0, 0, 0, 0.25)',
          display: 'flex',
          justifyContent: 'start',
          alignItems: 'center',
          margin: 4,
          ml: 1
        }} >
          Editar ticket
        </Typography>
        <Grid container my={2} display='flex' justifyContent='center' ml={{xs:0, md:30}}>
          <Grid
            item
            display={{ xs: "block", sm: "flex" }}
            alignItems="center"
            gap={2}
            xs={12}
            my={2}
          >
            <TextField
              label="Interno"
              sx={{
                ".MuiOutlinedInput-notchedOutline": {
                  borderColor: "black",
                },
                ".MuiInputBase-root": {
                  color: "black",
                  width: { xs: "80%", lg: "80%" },
                },
              }}
              InputProps={{
                type: "text",
              }}
              InputLabelProps={{
                style: { color: "black" },
              }}
              value={formik.values.interno}
              name="interno"
              onChange={formik.handleChange}
              error={formik.errors.interno}
              helperText={formik.errors.interno}
            />
          </Grid>
          <Grid item display={{ xs: "none", md: "none" }} alignItems="center">
            <TextField
              sx={{
                ".MuiOutlinedInput-notchedOutline": {
                  borderColor: "black",
                },

                ".MuiInputBase-root": {
                  color: "black",
                },
              }}
              InputLabelProps={{
                style: { color: "black" },
                readOnly: true,
              }}
              label="Inserte usuario ID"
              name="usuarios_id"
              value={formik.values.usuarios_id}
              onChange={formik.handleChange}
              error={formik.errors.usuarios_id}
              helperText={formik.errors.usuarios_id}
            />
          </Grid>
          <Grid
            item
            display={{ xs: "block", md: "flex" }}
            alignItems="center"
            gap={2}
            xs={12}
            my={2}
          >
            <TextField
              select
              label={
                ticket.registro_empresa.id === 1
                  ? "Seleccionar empresa"
                  : ticket.registro_empresa.empresa
              }
              sx={{
                ".MuiOutlinedInput-notchedOutline": {
                  borderColor: "black",
                },
                ".MuiInputBase-root": {
                  color: "black",
                },
                "& .MuiSvgIcon-root": {
                  color: "black",
                },
                minWidth: "200px",
              }}
              InputLabelProps={{
                style: { color: "black" },
              }}
              name="empresa_id"
              value={formik.registro_empresa?.id}
              onChange={formik.handleChange}
              error={formik.errors.empresa_id}
              helperText={formik.errors.empresa_id}
            >
              {dataDropdown.empresas?.map((empresa) => (
                <MenuItem key={empresa.id} value={empresa.id}>
                  {empresa.empresa}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid
            item
            display={{ xs: "block", md: "flex" }}
            alignItems="center"
            gap={2}
            xs={12}
            my={2}
          >
            <TextField
              select
              label={ticket.registro_servicio.tipo_servicio}
              sx={{
                ".MuiOutlinedInput-notchedOutline": {
                  borderColor: "black",
                },
                ".MuiInputBase-root": {
                  color: "black",
                },
                "& .MuiSvgIcon-root": {
                  color: "black",
                },
                minWidth: "260px",
              }}
              InputLabelProps={{
                style: { color: "black" },
              }}
              name="servicios_id"
              value={formik.servicios_id}
              onChange={formik.handleChange}
              error={formik.errors.servicios_id}
              helperText={formik.errors.servicios_id}
            >
              {dataDropdown.servicios?.map((servicio) => (
                <MenuItem key={servicio.id} value={servicio.id}>
                  {servicio.tipo_servicio} - {servicio.siglas}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid
            item
            display={{ xs: "block", md: "flex" }}
            alignItems="center"
            gap={2}
            xs={12}
            my={2}
          >
            <Autocomplete
              key={autocompleteKey}
              freeSolo
              id="combo-box-demo"
              options={locations}
              getOptionLabel={(option) => option}
              style={{ width: 300 }}
              value={formik.values.destino || ''} 
              onInputChange={(event, newValue) => {
                formik.setFieldValue('destino', newValue);
              }}
              renderInput={(params) =>
                <TextField
                  {...params}
                  label="Inserte destino / origen / servicio"
                  variant="outlined"
                  InputProps={{
                    ...params.InputProps,
                    classes: {
                      root: classes.inputRoot,
                    },
                  }}
                  InputLabelProps={{
                    style: { color: 'black' },
                  }}
                />
              }
            />
          </Grid>
        </Grid>
        <Grid container display='flex' justifyContent='center' ml={{xs:0}}>
          <Grid item sx={{ marginRight: "auto" }} align="center" xs={12} pt={4}>
            <Button variant="contained" ml="auto" type="submit" my={2}>
              Editar ingreso
            </Button>
          </Grid>
          {openModal && (
            <>
              <BasicModal
                title="Éxito"
                message="El registro fue modificado"
                openModal={openModal}
                type="ingresos"
              />
            </>
          )}
        </Grid>
      </form>
    </Stack>
  );
};

export default SecurityFormEditTicketError;