import * as React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import axios from 'axios'
import {  Stack } from '@mui/system'
import { Button, TextField, Typography } from '@mui/material'
import * as yup from 'yup';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';


const validationSchema = yup.object({
    texto: yup.string().required('Campo requerido'),
  });
 
const FormMarquesine = ({ texto, estado }) => {

    const token = window.sessionStorage.getItem('jwt')


    const formStyle = {
      ".MuiOutlinedInput-notchedOutline": {
        borderColor: "black",
      },
      ".MuiInputBase-root": {
        color: "black",
      },
      ".css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
        borderColor: "black",
      },
      minWidth: {xs:"60vw", md:"60vw"},
    }
    
    const setInitialReason = () => {
      return (
        {
          texto: ''
        }
      )
    };

    const url = 'https://terminaldigital.com.ar/api/marquesinas';
    const config =  { headers: { 'authorization': `Bearer ${token}` } };

  const queryClient = useQueryClient();
  const mutation = useMutation(data => axios.post(url, data, config), {
    onSuccess: () => {
      queryClient.invalidateQueries('marquesinas');
    }
  });

  const formik = useFormik({
    initialValues: setInitialReason(),
    validationSchema: validationSchema,
    onSubmit: (values) => {
      mutation.mutate(values, {
        onSuccess: () => {
          formik.resetForm({ values: setInitialReason() });
          toast.success('Marquesina creada con éxito');
        },
        onError: (error) => {
          toast.error('Error Post Method Marquesine Front:' + String(error));
        }
      });
    },
  });

  return (
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "40vh" }}> 
      <Stack sx={{ background: "#F4F2EC", shadow: 4, borderRadius:10 }}  my={0}  mx={0} p={4} sm={6} pl={{ xs: 4, sm: 4 }} width="60vw" mt={3}>
        <form onSubmit={formik.handleSubmit}>
          <Typography
            sx={{
              fontSize: { lg: "2rem" },
              fontFamily: 'Lato, sans-serif',
              color: '#0e315a',
              textShadow: '2px 2px 2px rgba(0, 0, 0, 0.25)',
              display: 'flex',
              justifyContent: 'start',
              marginBottom: 3
            }}
          >
             Marquesina
          </Typography>
          <TextField
            sx={formStyle}
            InputProps={{
              type: "text"
            }}
            multiline
            rows={2}
            value={formik.values.texto}
            name="texto"
            onChange={formik.handleChange}
            error={formik.errors.texto}
            helperText={formik.errors.texto}
            placeholder="Escriba aquí el mensaje que desea crear..."
          />
          <Stack direction='row' justifyContent={'flex-end'} mt={2}>
            <Button variant="contained" type="submit" my={2} >
                  Crear
            </Button>
          </Stack>
        </form>
      </Stack>
    </div>
    );
}

export default FormMarquesine