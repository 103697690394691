import React, { useEffect, useState } from "react";
import TableUsersLogs from "../../components/table/TableUsersLogs";
import { styled, alpha } from "@mui/material/styles";
import axios from "axios";
import { Box, TextField, Grid, Button, Typography, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination } from '@mui/material';
import { useSelector } from "react-redux";
import { format } from 'date-fns';

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    width: "35%",
  },
}));

const style = {
  background: "#0e315a",
  color: "white",
  minWidth: "100%",
  paddingBlock: "2vh",
  textAlign: "center",
};

const ListUsers = () => {
  const token = sessionStorage.getItem("jwt");
  const [users, setUsers] = useState([]);
  const [registros, setRegistros] = useState([]);
  const [fechaInicio, setFechaInicio] = useState(new Date());
  const [fechaFin, setFechaFin] = useState(new Date());
  const [shouldFetch, setShouldFetch] = useState(true);

  const config = { headers: { authorization: `Bearer ${token}` } };

  const count = useSelector((state) => state.counter.value);

  const [paginaActual, setPaginaActual] = useState(0);
  const [elementosPorPagina, setElementosPorPagina] = useState(100);
  const [resultadosBusqueda, setResultadosBusqueda] = useState(users);

  const handlePageChange = (event, newPage) => {
    setPaginaActual(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setElementosPorPagina(parseInt(event.target.value, 10));
    setPaginaActual(0);
  };

  useEffect(() => {
    if (shouldFetch) {
      const fechaInicioDate = new Date(fechaInicio);
      const fechaFinDate = new Date(fechaFin);

      const url = `https://terminaldigital.com.ar/api/logs/usuarios?page=${count}&fechaInicio=${format(fechaInicioDate, 'yyyy-MM-dd')}&fechaFin=${format(fechaFinDate, 'yyyy-MM-dd')}`;

      axios
        .get(url, config)
        .then((data) => {
          if (Array.isArray(data.data.usuarios.rows)) {
            setUsers(data.data.usuarios.rows);
          } else {
            console.error('data.data.usuarios no es un array:', data.data.usuarios);
          }
        })
        .catch((error) => console.log("error users", error));
      setShouldFetch(false);
    }
  }, [count, fechaInicio, fechaFin, shouldFetch]);

  useEffect(() => {
    setResultadosBusqueda(users);
  }, [users]);

  const datosPaginados = resultadosBusqueda
    .slice(paginaActual * elementosPorPagina, (paginaActual + 1) * elementosPorPagina);


  const filtrar = (terminoBusqueda) => {
    const resultadosBusqueda = users.filter((dato) => {
      const operacion = dato.tipo_de_estado;
      const usuario = dato.usuario_log;
      const createdAt = dato.createdAt;
      if (
        usuario.includes(terminoBusqueda) ||
        operacion.includes(
          terminoBusqueda.charAt(0).toUpperCase() + terminoBusqueda.slice(1)
        ) ||
        createdAt.includes(terminoBusqueda)
      ) {
        return dato;
      }
    });
    setRegistros(resultadosBusqueda);
  };

  const StyledButton = styled(Button)`
  background-color: #0e315a;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 10px;
  margin: 4px 2px 0 20px;
  cursor: pointer;
  vertical-align: middle; 
  &:hover {
    background-color: #4CAF50; 
  }
`;

  const renderTextField = (id, label, value, onChange) => (
    <TextField
      id={id}
      label={label}
      type="date"
      value={value}
      onChange={onChange}
      InputLabelProps={{
        shrink: true,
      }}
      inputProps={{
        max: format(new Date(), 'yyyy-MM-dd'),
      }}
      sx={{ width: { xs: "100%", md: "40%" }, marginLeft: { xs: 2, md: 2 } }}
    />
  );

  return (
    <Box px={3} pb={6} sx={{ background: "#0e315a" }}>
      <Box pt={4}>
        <Grid item xs={12} sm={12}>
          <Typography
            sx={{
              fontSize: { xs: "1.5rem", lg: "2.5rem" },
              fontFamily: 'Lato, sans-serif',
              color: '#0e315a',
              textShadow: '2px 2px 2px rgba(0, 0, 0, 0.25)',
              display: 'flex',
              justifyContent: 'center',
              display: { xs: "none", sm: "block" },
              background: "#fff",
              padding: 5
            }}
          >
            Registro de usuarios
          </Typography>
        </Grid>
        <TableUsersLogs
          data={registros.length === 0 ? datosPaginados : registros}
          count={resultadosBusqueda.length}
          rowsPerPage={elementosPorPagina}
          page={paginaActual}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          renderTextField={renderTextField}
          fechaInicio={fechaInicio}
          setFechaInicio={setFechaInicio}
          fechaFin={fechaFin}
          setFechaFin={setFechaFin}
          setShouldFetch={setShouldFetch}
        />
      </Box>
    </Box>
  );
};

export default ListUsers;
