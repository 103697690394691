import React, { useState, useEffect } from "react";
import { Grid, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import FormInput from "../FormInput";
import { styled, alpha } from "@mui/material/styles";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import DateRangeIcon from '@mui/icons-material/DateRange';
import SearchIcon from "@mui/icons-material/Search";
import ImagenCardCompany from "../../assets/img/cardCompany.png";

const Search = styled("div")(({ theme }) => ({
  color: "white",
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: "#F4F2EC",
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    width: "35%",
  },
}));

const cardStyle = {
  borderRadius: "5px",
  padding: "20px",
  margin: "1%",
  color: "black",
  marginBlock: "10px",
  paddingBottom: "20px",
  boxShadow: "rgb(0 0 0 / 35%) 1px 2px 6px 2px",
  width: "350px", 
  height: "450px", 
  overflow: "auto", 
  marginTop: "50px",
  marginBottom: "50px",

};

const displayStyle = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  justifyContent: "space-evenly",
  alignItems: "center",
};

const colorLetrasBox = {
  color: "#6c757d",
  minWidth: { xs: "20vw", lg: "10vw" },
};

const styleCheckboxList = {
  display: "flex",
  flexDirection: "column",
};

export default function CardCompany({ data }) {
  const showData = () => {
    const datos = data;
    setRegistrosInView(datos);
  };

  const [count, setCount] = useState(1);

  useEffect(() => {
    showData();
  }, [count === 1]);

  const [registrosInView, setRegistrosInView] = useState([]);
  const [search, setSearch] = useState("");

  const handleChange = (event) => {
    setCount(count + 1);
    if (event.target.value === "") {
      setCount(1);
    }
    setSearch(event.target.value);
    filtrar(event.target.value);
  };

  const filtrar = (terminoBusqueda) => {
    const resultadosBusqueda = data.filter((dato) => {
      if (
        dato.empresa.includes(
          terminoBusqueda.charAt(0).toUpperCase() + terminoBusqueda.slice(1)
        )
      ) {
        return dato;
      } else if (terminoBusqueda === "" || terminoBusqueda === null) {
        return data;
      }
    });
    setRegistrosInView(resultadosBusqueda);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ width: "100%" }}>
        <Typography
          fontWeight="bold"
          textAlign="center"
          fontSize={{ xs: "1.5rem", md: "3rem" }}
          marginTop={{ xs: "0", md: "5%", lg: "0%" }}
          marginBottom={{ xs: "0", sm: "5%", lg: "2%" }}
          sx={{
            width: { lg: "100%" },
            height: { lg: "100%" },
            fontFamily: 'Lato, sans-serif',
            color: '#0e315a',
            textShadow: '2px 2px 2px rgba(255, 255, 255, 0.75)',
          }}
          backgroundColor="rgb(28, 104, 192)"
          borderRadius={{ xs: "0", sm: "0px" }}
          position={"sticky"}
          top="0"
          right="0"
          left="0"
          color="white"
        >
          Agencias
        </Typography>
      </Box>
      <Box>
        <Grid
          container
          sx={{
            width: { sm: "90%", xs: "100%", md: "100%", lg: "10%" },
            justifyContent: { sm: "center", md: "left" },
          }}
          display="flex"
          mb={3}
        >
          <Grid item sm={2} style={styleCheckboxList}>
            <Box sx={{ marginLeft: { xs: "5%", sm: "-17%", md: "170%", lg: "25px" } }}>
              <Search sx={{ marginTop: { xs: "5%", lg: "15%" } }}>
                <FormInput
                  startAdornment={<SearchIcon />}
                  placeholder="Buscar empresa"
                  inputProps={{ "aria-label": "search" }}
                  value={search}
                  onChange={handleChange}
                  fullWidth
                  sx={{
                    width: { xs: "91vw", sm: "30vw", md: "47vw", lg: "14vw" },
                  }}
                />
              </Search>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box>
        <Card style={displayStyle} sx={{ 
          backgroundColor: "#fffdfd"
        }}>
          {registrosInView &&
            registrosInView.map((row) => (
              <Grid
                container
                style={cardStyle}
                key={row.id}
                sx={{
                  width: { sm: "100%", md: "100%", lg: "30%" },
                  maxWidth: "100vw",
                  maxHeight: "100vh",
                  minHeight: { xs: "0vw", sm: "24vh", md: "20vh", lg: "10vh" },
                  minWidth: { xs: "100vw", sm: "10vw", md: "20vw", lg: "10vw" },
                }}
              >
                <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                  <CardContent>
                    <Grid item xs={0}>
                      <Typography
                        component="img"
                        sx={{
                          height: { xs: "15vh", md: "10vh", lg: "15vh" },
                          width: { xs: "90vw", md: "35vw", lg: "10vw" },
                          objectFit: "contain",
                          margin: "auto",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          display: "block"
                        }}
                        alt={row.img}
                        src={row.img}
                      ></Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        fontSize={{ xs: "1rem", sm: "1.5rem", md: "1.5rem" }}
                        fontWeight="bold"
                        sx={{ minWidth: "20vw", display: "flex", justifyContent: "center", alignItems: "center"}}
                      >
                        {row.empresa}
                      </Typography>
                      <Typography style={colorLetrasBox} fontSize={{ xs: "1rem", sm: "1.2rem", md: "1.8rem", lg: "1.2rem" }}>
                        <span ><LocalPhoneIcon sx={{color: "black", marginRight: 1, marginTop: 2}}/> {" "} </span> {row.telefono === null || row.telefono === "null" ? "No disponible" : row.telefono}
                      </Typography>
                      <Typography style={colorLetrasBox} fontSize={{ xs: "1rem", sm: "1.2rem", md: "1.8rem", lg: "1.2rem" }}>
                        <span ><MailOutlineIcon sx={{ color: "black", marginRight: 1, marginTop: 2 }} /> {" "} </span> {row.email === null || row.email === "null" ? "No disponible" : row.email}
                      </Typography>
                      <Typography style={colorLetrasBox} fontSize={{ xs: "1rem", sm: "1.2rem", md: "1.8rem", lg: "1.2rem" }}>
                        <span ><LocationOnIcon sx={{ color: "black", marginRight: 1, marginTop: 2 }} /> {" "} </span>  {row.ubicacion_en_terminal === null || row.ubicacion_en_terminal === "null" ? "No disponible" : row.ubicacion_en_terminal}
                      </Typography>
                      <Typography style={colorLetrasBox} fontSize={{ xs: "1rem", sm: "1.2rem", md: "1.8rem", lg: "1.2rem" }}>
                        <span ><DateRangeIcon sx={{ color: "black", marginRight: 1, marginTop: 2 }} /> {" "} </span>  {row.horario_atencion === null || row.horario_atencion === "null" ? "No disponible" : row.horario_atencion}
                      </Typography>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            ))}
        </Card>
      </Box>
    </Box>
  );
}
