import React, { useState, useEffect } from "react";
import ImageGallery from "react-image-gallery";
import axios from "axios";

const images = [
  { original: "/publi1-web.png" },
  { original: "/publi2-web.png" },
  { original: "/publi3-web.png" },
];


const MarketingModuleWeb = () => {
  const [publicidad, setPublicidad] = useState([]);
  const defaultImage = [{ original: "/publi1-web.png" }];

  const [token, setToken] = useState(null);
  const obtenerJWT = async () => {
    try {
      const response = await axios.get('https://terminaldigital.com.ar/api/auth/codeTerminal');
      setToken(response.data);
    } catch (error) {
      console.error('Error al obtener el JWT:', error);
    }
  };
  useEffect(() => {
    obtenerJWT();
  }, []);

  useEffect(() => {
    if (token) {
      const getPublicidad = async () => {
        try {
          const response = await axios.get(`http://localhost:9090/advertising/getAdvertisingPngWebTerminal`, { headers: { Authorization: `Bearer ${token}` } });
          setPublicidad(response.data.advertisingTerminalPngUrls);
        } catch (error) {
          console.log("error publicidad", error);
        }
      };

      getPublicidad();
    }
  }, [token]);
  

  return (
    <ImageGallery
      items={publicidad.length > 0 ? publicidad : images}
      autoPlay={true}
      // swipingTransitionDuration={1250}
      slideInterval={3800}
      showNav={false}
      slideDuration={3880}
      showPlayButton={false}
      showThumbnails={false}
      showFullscreenButton={false}
      infinite={true}
      disableSwipe={true}
    />
  );
};

export default MarketingModuleWeb;
