import React, { useEffect, useState } from "react";
import { Button, Grid, MenuItem, Typography } from "@mui/material";
import { Stack } from "@mui/system";

import { useFormik } from "formik";
import * as yup from "yup";
import axios from "axios";
import { toast } from "react-toastify";
import FormField from "./FormField";

const validationSchema = yup.object({
  plataforma: yup.string().required("Campo requerido"),
  servicio_id: yup.string().required("Campo requerido"),
});

const FormCreatePlatform = () => {

  const [service, setService] = useState([]);

  const token = sessionStorage.getItem("jwt");
  const config = { headers: { authorization: `Bearer ${token}` } };

  const getDropdown = () => {
    axios
      .all([axios.get("https://terminaldigital.com.ar/api/servicios/listado", config)])
      .then((data) => {
        setService(data[0].data.servicios);
      })
      .catch((error) => console.log("error users", error));
  };

  useEffect(() => {
    getDropdown();
  }, []);

  const setInitialPlatform = () => {
    return {
      plataforma: "",
      servicio_id: "",
      disponible: "",
    };
  }

  const formik = useFormik({
    initialValues: setInitialPlatform(),
    //validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      const config = {
        headers: {
          authorization: `Bearer ${token}`,
        },
      };

      //url = "https://terminaldigital.com.ar/api/plataformas/addNewPlataforma";

      const data = formik.values;

      axios
        .post("https://terminaldigital.com.ar/api/plataformas/addNewPlataforma", data, config)
        .then((response) => {
          toast.success(response.data.message);
          formik.resetForm();
        })
        .catch((error) => {
          toast.error(error.response.data.message);
          console.log(error);
        });
    },
  });

  return (
    <Stack
      sx={{ background: "#F4F2EC", borderRadius: "25px", shadow: 4, minHeight: { xs: "50%", sm: "60%", md: "73%", lg: "50%" } }}
      my={4}
      mx={{ xs: 1, sm: 6 }}
      p={4}
      sm={6}
      md={8}
      lg={10}
      mb={7.2}
    >
      <form onSubmit={formik.handleSubmit}>
        <Typography
          sx={{
            fontSize: { xs: "1.5rem", md: "2rem" },
            fontFamily: 'Lato, sans-serif',
            color: '#0e315a',
            textShadow: '2px 2px 2px rgba(0, 0, 0, 0.25)',
            display: 'flex',
            justifyContent: 'start',
            alignItems: 'center',
            margin: 5,
            ml: 1
          }} >
          Crear Plataforma
        </Typography>
        <Grid container my={2} gap={1} p={3}>
          <Grid
            item
            gap={2}
            xs={12}
            md={3}
            lg={4}
            my={2}
          >
            <FormField
              name="plataforma"
              label="Nombre de la plataforma"
              value={formik.values.plataforma}
              onChange={formik.handleChange}
              error={formik.errors.plataforma}
              helperText={formik.errors.plataforma}
            />
          </Grid>
          <Grid
            item
            gap={2}
            xs={12}
            sm={12}
            md={4}
            lg={4}
            my={2}
          >
            <FormField
              select
              fullWidth
              multiline
              label="Tipo de Servicio"
              name="servicio_id"
              value={formik.values.servicio_id}
              onChange={formik.handleChange}
              error={formik.errors.servicio_id}
              helperText={formik.errors.servicio_id}
            >
              {service?.map((service) => (
                <MenuItem
                  value={service.id}
                  key={service.tipo_servicio + service.id}
                  selected={true}
                >
                  {service.tipo_servicio}
                </MenuItem>
              ))}
            </FormField>
          </Grid>
          <Grid
            item
            gap={2}
            xs={12}
            sm={12}
            lg={2}
            md={4}
            my={2}
          >
            <FormField
              select
              fullWidth
              multiline
              label="Estado"
              name="disponible"
              value={formik.values.disponible}
              onChange={formik.handleChange}
              error={formik.errors.disponible}
              helperText={formik.errors.disponible}
            >
              <MenuItem value={1} selected={formik.values.disponible === 1}>
                Disponible
              </MenuItem>
              <MenuItem value={0} selected={formik.values.disponible === 2}>
                Ocupada
              </MenuItem>
            </FormField>
          </Grid>
          <Grid item sx={{ marginLeft: "auto" }} align="center" xs={12} pt={9}>
            <Button type="submit" variant="contained">
              Crear Plataforma
            </Button>
          </Grid>
        </Grid>
      </form>
    </Stack>
  );

};

export default FormCreatePlatform;