import React, { useState } from 'react';
import axios from 'axios'
import { Stack } from '@mui/system'
import { Button, Grid, MenuItem, TextField, Typography } from '@mui/material'
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useMarquesineUpdate } from '../../pages/marquesine/MarquesineContext';
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';

const formStyle = {
  ".MuiOutlinedInput-notchedOutline": {
    borderColor: "white",
  },
  ".MuiInputBase-root": {
    color: "white",
  },
  ".css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
    borderColor: "white",
  },
  minWidth: "200px",
}

const inputStyle = {
  ".MuiOutlinedInput-notchedOutline": {
    borderColor: "white",
  },
  ".MuiInputBase-root": {
    color: "white",
  },
  ".css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
    borderColor: "white",
  },
}

const validationSchema = yup.object({
  texto: yup.string().required('Campo requerido'),
});

const FormMarquesineEdit = ({ texto, estado, id, onClose, updateList }) => {
  const [updateTrigger, setUpdateTrigger] = useState(false);
  const navigate = useNavigate();
  const token = window.sessionStorage.getItem('jwt')

  const updateMarquesineTable = useMarquesineUpdate();

  const initialReason = {
    texto: texto,
    estado: estado 
  }

  const url = 'https://terminaldigital.com.ar/api/marquesinas/' + id
  const config = { headers: { 'authorization': `Bearer ${token}` } }

  const formik = useFormik({
    initialValues: initialReason,
    validationSchema: validationSchema,
    onSubmit: () => {
      const data = formik.values
      axios.patch(url, data, config)
        .then((res) => {
          if (res.status === 200) {
            toast.success('Marquesina editada con éxito', { autoClose: 2000 })
            setUpdateTrigger(!updateTrigger);
            updateMarquesineTable(true);
            onClose();
            updateList();
            navigate('/supervisor/marquesina')
          }
        })
        .catch(function (error) {
          toast.error('Error en la edición de la Marquesina:' + String(error));
        });
    },
  });

  return (
    <Stack sx={{ background: "#0e315a", shadow: 4, border: 'none' }} my={0} mx={0} px={{ xs: 2, sm: 2 }} > 
        <form onSubmit={formik.handleSubmit}>
          <Typography variant="h4" color="white" mt={4} sx={{ fontSize: { xs: "1rem", md: "2rem" }, }} >
            Editar Marquesina
          </Typography>
          <Grid container my={2} gap={2}>
            <Grid item alignItems="top" xs={12} sm={12} my={0}>
              <Typography variant="subtitle1" color="white" mb={1}>
                Editar mensaje:
              </Typography>
              <TextField
                sx={formStyle}
                InputProps={{
                  type: "text"
                }}
                fullWidth
                multiline
                rows={2}
                value={formik.values.texto}
                name="texto"
                onChange={formik.handleChange}
                error={formik.errors.texto}
                helperText={formik.errors.texto}
                placeholder="Escriba aquí su mensaje..."
              />
            </Grid>
            <Grid xs={12} sm={12} pt={{ xs: 2, sm: 0 }} >
              <Typography variant="subtitle1" color="white" mb={1}>
                Estado:
              </Typography>
              <TextField
                fullWidth
                select
                sx={inputStyle}
                InputLabelProps={{
                  style: { color: "#fff" },
                }}
                name="estado"
                value={formik.values.estado}
                onChange={formik.handleChange}
              >
                <MenuItem
                  value={1}
                  key={1}
                  selected={true}
                >
                  Activo
                </MenuItem>
                <MenuItem
                  value={0}
                  key={0}
                  selected={true}
                >
                  Inactivo
                </MenuItem>
              </TextField>
            </Grid>
            <Grid item align="center" pt={4} xs={12} flexDirection="column" >
              <Button sx={{ background: 'whitesmoke', color: '#343333' }} variant="contained" type="submit" my={2}>
                Editar Marquesina
              </Button>
            </Grid>
            {/* {openModal && (
              <BasicModal
                title="Éxito"
                message="Marquesina creada con éxito"
                openModal={openModal}
              />
            )} */}
          </Grid>
        </form>
    </Stack>
  );
}

export default FormMarquesineEdit