import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export const withCheckPasswordStatus = (WrappedComponent) => {
  return (props) => {
    const navigate = useNavigate();
    const token = window.sessionStorage.getItem("jwt");

    useEffect(() => {
      if (token.estado_password === "0") {
        navigate("/perfil/modificar_clave");
      }
    }, [token, navigate]);

    return <WrappedComponent {...props} />;
  };
};