import  {forwardRef} from "react"
import TextField from "@mui/material/TextField"
import Box from "@mui/material/Box"
import InputLabel from "@mui/material/InputLabel"
import {styled} from "@mui/material";

const StyledInputLabel = styled(InputLabel)(({ theme  }) => ({
    "&.MuiInputLabel-root": {
         color:  'black',
         fontSize: "14px",
         marginBottom: '2px',
          ".MuiInputLabel-asterisk": {
             color: theme.palette.error.main
          }
    },
}))

const StyledTextField = styled(TextField)(({ theme  }) => ({
    "&.MuiTextField-root": {
        maxWidth: '290px',
        ".MuiInputBase-formControl": {
            background: "transparent",
            borderRadius: "8px",
        },
        "& .MuiSvgIcon-root": {
            color: "black",
        },
        ".MuiOutlinedInput-notchedOutline": {
            borderColor: "black",
          },
        '& label.Mui-focused': {
            color: 'black',
            fontWeight: 'bold',
            fontSize: 16,
        },
        '& label': {
            color: 'theme.palette.primary.light',
            fontWeight: 'bold',
            fontSize: 18,
        },
        '.MuiInputBase-input': {
            color: 'black',
            fontSize: 16,
            borderRadius: "8px",
        },
    },

}));


// eslint-disable-next-line  @typescript-eslint/no-explicit-any
const FormField = forwardRef(({ label, type, size, variant, ...props }, ref) => {
    return (
        <Box>
            {label && (
                <StyledInputLabel sx={{mb:0}} error={props.error} required={props.required} color={props.color}>
                    {label}:
                </StyledInputLabel>
            )}
            <StyledTextField
                type={type}
                variant={variant ? variant : "outlined"}
                label={undefined}
                fullWidth
                ref={ref}
                {...props}
            />
        </Box>
    )
});



FormField.displayName = "FormField"
export default FormField
