import axios from "axios";
import io from 'socket.io-client';
import Marquee from "react-fast-marquee";
import React, { useCallback, useEffect, useState } from "react";
import { Box, CircularProgress } from "@mui/material";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { Stack } from "@mui/system";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import Typography from "@mui/material/Typography";
import Greating from "../../components/clock2/Greating";
import MarketingModuleTV from "../../components/MarketingModule/MarketingModuleTV";
import TableDepartures from "../../components/table/TableDepartures";
import TableDeparturesHead from "../../components/table/TableDeparturesHead";
import Weather from '../../components/Weather/Weather';
import "./ArrivalsBoard.styles.css";
import sinRegistros from "../../assets/img/sinregistros-1.png";
import Pagination from '@mui/material/Pagination';


const socket = io('https://terminaldigital.com.ar');

const api = axios.create({
  baseURL: 'https://terminaldigital.com.ar/api'
});

const getMarquesina = () => api.get('/marquesinas/marquesina');
const getDepartures = () => api.get('/plataforma/partidas');
const getLogoTerminal = () => api.get('/administrarterminal');

const Clock = () => {
  const [time, setTime] = useState(new Date().toLocaleTimeString());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTime(new Date().toLocaleTimeString());
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <Greating
      text={time}
      style={{
        color: "white",
        fontSize: { xs: "30px", sm: "45px", md: "50px", lg: "55px" },
        fontWeight: "400",
        marginLeft: "0",
        paddingBottom: "70px",
      }}
    />
  );
};

const imgArray = [
  "/publi1.png",
  "/publi2.png",
  "/publi3.png",
  "/publi1.png",
  "/publi2.png",
  "/publi3.png",
];

const textTranslate = [
  "partidas", // Español
  "departures", // Inglés
  "Partidas", // Portugués
  "départs", // Francés
  "Abflüge", // Alemán
  "partenze", // Italiano
  "出发", // Chino (para turistas de Taiwán)
  "출발", // Coreano
  "出発", // Japonés
  "יציאות", // Hebreo
  "المغادرة", // Árabe
];

const DeparturesBoard = () => {
  const handle = useFullScreenHandle();
  const [data, setData] = React.useState([]);
  const [partidas, setPartidas] = React.useState([]);
  const [logo, setLogo] = useState([]);
  const [index, setIndex] = useState(0);
  const [page, setPage] = React.useState(1);
  const itemsPerPage = 11;

  useEffect(() => {
    getMarquesina()
      .then((data) => setData(data.data.marquesina))
      .catch((error) => console.log("Error Marquesine Table:", error));
  }, []);

  useEffect(() => {
    const fetchDepartures = async () => {
      try {
        const data = await getDepartures();
        setPartidas(data.data);
      } catch (error) {
        console.log('error', error);
      }
    };

    socket.on('connect', () => {
      console.log('Conectado al servidor');
      fetchDepartures();
    });
    
    socket.on('newRegisterDepartures', ({ shouldRerenderDepartures }) => {
      if (shouldRerenderDepartures) {
        fetchDepartures();
      }
    });

    return () => {
      socket.off('newRegisterDepartures');
    };

    fetchDepartures();
  }, []);

  useEffect(() => {
    getLogoTerminal()
      .then((data) => setLogo(data.data.terminal.logo))
      .catch((error) => console.log("error users", error));
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % textTranslate.length);
    }, 30000);
    return () => clearInterval(intervalId);
  }, []);

  const styleMarquee = {
    padding: "0px",
    maxHeight: "100px",
    color: "#0E315A",
    margin: "0",
    background: "white",
    transform: "rotate(90deg)",
    textTransform: "uppercase",
    position: "absolute",
    left: "49.3%",
    bottom: "96.6vh",
  };

  const handleChange = (event, value) => {
    setPage(value);
  };

  useEffect(() => {
    const totalPages = Math.ceil(partidas.length / itemsPerPage);
    const timer = setInterval(() => {
      setPage((prevPage) => {
        if (prevPage >= totalPages) {
          return 1;
        } else {
          return prevPage + 1;
        }
      });
    }, 15000);
    return () => {
      clearInterval(timer);
    };
  }, [partidas, itemsPerPage]);

  return (
    <>
      <Marquee gradient={false} speed={0.051} style={styleMarquee}>
        <span className="texto" style={{ paddingTop: "3px" }}>
          {data?.texto && data.texto}
        </span>
      </Marquee>
      <Box
        className="containerBoard"
        sx={{
          textOrientation: "sideways-right",
          writingMode: "tb-rl",
          position: "relative",
          overflow: "hidden",
        }}
      >
        {/* ACA VA lo que entra en full screen */}
        <FullScreen handle={handle}>
          <Stack
            direction="row"
            pr={3}
            justifyContent="space-between"
            alignItems="flex-start"
            spacing={4}
            sx={{ height: "100vh", background: "#0e315a" }}
          >
            <Typography
              pr={1.4}
              pt={2}
              textAlign="left"
              sx={{
                fontFamily: "Roboto",
                color: "white",
                fontSize: { xs: "15px", sm: "20px", md: "30px" },
                marginBottom: "80px",
              }}
              textTransform=" uppercase"
            >
              {textTranslate[0]} {index !== 0 && ` - ${textTranslate[index]}`}
            </Typography>
            {/* CLIMA */}
            <Weather />
            {/* RELOJ */}
            <Clock />
          </Stack>
          <Box>
            {partidas.length === 0 && (
              <>
                <TableDeparturesHead />
                <Stack
                  justifyContent={"center"}
                  alignItems={"center"}
                  width={"40vw"}
                  mr={4}
                >
                  <Box
                    component="img"
                    src={logo}
                    sx={{
                      width: "auto",
                      height: { xs: "10vh", sm: "15vh", lg: "15vh" },
                      transform: "rotate(90deg)",
                    }}
                  />
                </Stack>
              </>
            )}
            {partidas.length === undefined && (
              <Stack
                justifyContent={"center"}
                alignItems={"center"}
                height={"40vh"}
              >
                <CircularProgress />
              </Stack>
            )}
            {partidas.length > 0 && (
              <TableDepartures props={partidas.slice((page - 1) * itemsPerPage, page * itemsPerPage)} height="100%" width="60px" />
            )}
            <Pagination count={Math.ceil(partidas.length / itemsPerPage)} page={page} onChange={handleChange} style={{ display: "none" }} />
            <MarketingModuleTV data={imgArray} />
          </Box>
        </FullScreen>
        {/* BOTÓN FULL SCREEN */}
        <button className="fullscreen-button" onClick={handle.enter}>
          <FullscreenIcon />
        </button>
      </Box>
    </>
  );
};

export default DeparturesBoard;
