import axios from 'axios';
import { Box, CircularProgress, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import React, { useEffect, useState } from "react";
import TableArrivals from "../../components/table/TableArrivals";
import Greating from "../../components/clock2/Greating";
import "./ArrivalsBoard.styles.css";
import TableArrivalsHead from "../../components/table/TableArrivalsHead";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import Marquee from "react-fast-marquee";
import MarketingModuleWeb from "../../components/MarketingModule/MarketingModuleWeb";
import io from 'socket.io-client';
import WeatherH from '../../components/Weather/WeatherH';
import styled from 'styled-components';
import { isFullScreenEnabled } from '@react-pdf-viewer/core';

const socket = io('https://terminaldigital.com.ar');

const api = axios.create({
  baseURL: 'https://terminaldigital.com.ar/api'
});

const getMarquesina = () => api.get('/marquesinas/marquesina');
const getArrivals = () => api.get('/plataforma/arribos');
const getLogoTerminal = () => api.get('/administrarterminal');

const Clock = () => {
  const [time, setTime] = useState(new Date().toLocaleTimeString());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTime(new Date().toLocaleTimeString());
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <Greating
      text={time}
      style={{
        color: "white",
        fontSize: { xs: "30px", sm: "45px", md: "50px", lg: "55px" },
        fontWeight: "400",
        marginLeft: "0",
        marginRight: "10px",
        paddingBottom: "70px",
      }}
    />
  );
};

const ArrivalsBoard = () => {
  const handle = useFullScreenHandle();
  const [data, setData] = React.useState([]);
  const [arribos, setArribos] = React.useState([]);
  const [logo, setLogo] = useState([]);
  const [index, setIndex] = useState(0);

  const [isFullscreen, setIsFullscreen] = useState(false);

  useEffect(() => {
    getMarquesina()
      .then((data) => setData(data.data.marquesina))
      .catch((error) => console.log("Error Marquesine Table:", error));
  }, []);

  useEffect(() => {
    const fetchArrivals = async () => {
      try {
        const data = await getArrivals();
        setArribos(data.data);
      } catch (error) {
        console.log('error', error);
      }
    };

    socket.on('connect', () => {
      console.log('Conectado al servidor');
      fetchArrivals();
    });

    socket.on('newRegisterArrives', ({ shouldRerenderArrives }) => {
      if (shouldRerenderArrives) {
        fetchArrivals();
      }
    });

    return () => {
      socket.off('newRegisterArrives');
    };

    fetchArrivals();
  }, []);

  useEffect(() => {
    getLogoTerminal()
      .then((data) => setLogo(data.data.terminal.logo))
      .catch((error) => console.log("error users", error));
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % textTranslate.length);
    }, 30000);
    return () => clearInterval(intervalId);
  }, []);


  const textTranslate = [
    "arribos", // Español
    "arrivals", // Inglés
    "Chegadas", // Portugués
    "arrivées", // Francés
    "Ankünfte", // Alemán
    "arrivi", // Italiano
    "到达", // Chino (para turistas de Taiwan)
    "도착", // Coreano
    "到着", // Japonés
    "הגעות", // Hebreo
    "وصول", // Árabe
  ];

  const imgArray = [
    "/publi1.png",
    "/publi2.png",
    "/publi3.png",
    "/publi1.png",
    "/publi2.png",
    "/publi3.png",
  ];

  const handleEnter = () => {
    handle.enter();
    setIsFullscreen(true);
  };

  const handleExit = () => {
    setIsFullscreen(false);
  };

  const StyledBox = styled(Box)`
  width: 101vw;
  height: 100vh;
  overflow: hidden;
  position: absolute;

  @media (max-width: 600px) { /* mobile */
    top: ${props => props.isFullscreen ? "86%" : "84%"};
  }

  @media (min-width: 601px) and (max-width: 1024px) { /* tablet */
    top: ${props => props.isFullscreen ? "80%" : "78%"};
  }

  @media (min-width: 1025px) and (max-width: 1919px) { /* desktop */
    top: ${props => props.isFullscreen ? "84.3%" : "80.3%"};
  }

  @media (min-width: 1920px) { /* TV */
   top: ${props => props.isFullscreen ? "84.3%" : "82.1%"};
  }
`;

  return (
    <>
      <Box
        className="containerBoard"
        sx={{
          // writingMode: "vertical-rl",
          // transform: "rotate(0deg)",
          position: "relative",
          overflow: "hidden",
          marginTop: "0%",
        }}
      >
        {/* ACA VA lo que entra en full screen */}
        <FullScreen handle={handle} onExit={() => setIsFullscreen(false)}>
          <Stack
            direction="row"
            pr={3}
            justifyContent="space-between"
            alignItems="flex-start"
            spacing={4}
            sx={{ height: "10vh", background: "#0e315a", padding: "30px" }}
          >
            <Typography
              pr={1.4}
              pt={2}
              textAlign="left"
              sx={{
                fontFamily: "Roboto",
                color: "white",
                fontSize: { xs: "15px", sm: "20px", md: "30px" },
                marginBottom: "80px",
              }}
              textTransform=" uppercase"
            >
              {textTranslate[0]} {index !== 0 && ` - ${textTranslate[index]}`}
            </Typography>
            {/* CLIMA */}
            <WeatherH />
            {/* RELOJ */}
            <Clock />
          </Stack>
          <Box height={isFullscreen ? "82vh" : "84vh"}>
            {arribos.length === 0 && (
              <>
                <TableArrivalsHead />
                <Stack
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '20vh', 
                    width: '100vw',
                  }}
                >
                  <Box
                    component="img"
                    src={logo}
                    sx={{
                      width: "auto",
                      height: { xs: "10vh", sm: "15vh", lg: "15vh" },
                      // transform: "rotate(90deg)",
                    }}
                  />
                </Stack>
              </>
            )}
            {arribos.length === undefined && (
              <Stack
                justifyContent={"center"}
                alignItems={"center"}
                height={"40vh"}
              >
                <CircularProgress />
              </Stack>
            )}
            {arribos.length > 0 && (
              <TableArrivals props={arribos} height="100%" width="60px" />
            )}
            <StyledBox isFullscreen={isFullscreen ? 1 : 0}>
              <MarketingModuleWeb data={imgArray} />
            </StyledBox>
          </Box>
        </FullScreen>
        <button
          className="fullscreen-button"
          style={{ zIndex: "99" }}
          onClick={handleEnter}
        >
          <FullscreenIcon />
        </button>
      </Box>
    </>
  );
};

export default ArrivalsBoard;
