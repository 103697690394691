import React, { useState, useEffect } from "react";
import axios from "axios";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box, TextField } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DialogDomain from "../modals/dialogDomain";
import { styled, alpha } from "@mui/material/styles";
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";






export default function TableAdminTerminal() {

  const token = sessionStorage.getItem("jwt");

  const [unidades, setUnidades] = useState([]);
  const [editado, setEditado] = useState("false");
  const [open, setOpen] = useState(false);
  const [dataModal, setDataModal] = useState({});
  console.log("🚀 ~ TableAdminTerminal ~ dataModal:", dataModal)
  const [search, setSearch] = useState("");
  const [count, setCount] = useState(1);
  const [resultadosBusqueda, setResultadosBusqueda] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [deleteId, setDeleteId] = useState("");

  const StickyHeader = styled(TableHead)(({ theme }) => ({
    position: 'sticky',
    top: 10,
    zIndex: theme.zIndex.appBar - 1,
    backgroundColor: theme.palette.background.paper,
  }));

  const Search = styled("div")(({ theme }) => ({
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.5),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.7),
    },
    color: 'black',
    marginLeft: 10,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "35%",
    },
  }));

  
  const getUnit = () => {
    axios
      .get("https://terminaldigital.com.ar/api/empresas/unidadesPorDominio", {
        headers: { authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setUnidades(response.data.unidades);
        setResultadosBusqueda(response.data.unidades);
      })
      .catch((error) => {
        console.log("error jwt:", error.response.data.message);

      });
  };

  useEffect(() => {
    getUnit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editado, count === 1]);

  const editThis = (data) => {
    setOpen(true);
    setDataModal(data);
  };

  const openFalse = (openFalse) => {
    setOpen(openFalse);
  };

  const editadoFalse = (editadoFalse) => {
    setEditado(editadoFalse);
    setTimeout(() => setEditado("false"), 2000);
  };
  const handleChange = (event) => {
    setCount(count + 1);
    if (event.target.value === "") {
      setCount(1);
    }
    setSearch(event.target.value);
    filtrar(event.target.value);
  };
  const filtrar = (terminoBusqueda) => {
    const resultadosBusqueda = unidades.filter((dato) => {
      const dominio = dato.dominio;
      if (dominio && dominio.includes(terminoBusqueda.toUpperCase())) {
        return dato;
      }
    });
    setResultadosBusqueda(resultadosBusqueda);
  };

  const deleteDomain = () => {
    if (deleteId !== null) {
      const url = `https://terminaldigital.com.ar/api/empresas/borrarDominio/${deleteId}`;
      
      const token = sessionStorage.getItem("jwt");
      const config = { headers: { authorization: `Bearer ${token}` } };

      axios
        .patch(`https://terminaldigital.com.ar/api/empresas/borrarDominio/${deleteId}`, null, config)
        .then((res) => {
          if (res.status === 200) {
            handleCloseDialog();
            setTimeout(() => {
              getUnit();
              toast.success("Dominio borrado con éxito", { autoClose: 2000 });
            }, "500");
          }
        })
        .catch((err) => {
          console.log("Error delete:", err);
          console.log("Response data:", err.response.data);
        });
    }
  };

  const handleOpenDialog = (id) => {
    setDeleteId(id);
    setOpenDialog(true);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };


  return (
    <>
  
      <TableContainer
        component={Paper}
        sx={{ width: "98%", margin: "auto", borderRadius: "7px" }}
      >
        <Box
          sx={{ marginLeft: { xs: "26%", sm: 5 } }}
        >
          <Grid container direction="row" alignItems="center" spacing={2}>
            <Grid item xs={12} sm={4}>
              <Typography sx={{
                fontSize: { lg: "2rem" },
                fontFamily: 'Lato, sans-serif',
                color: '#0e315a',
                textShadow: '2px 2px 2px rgba(0, 0, 0, 0.25)',
                display: 'flex',
                justifyContent: 'start',
                mt: { xs: "5%", sm: "10%" },
              }} >
                Registro de dominios
              </Typography>
            </Grid>

            <Grid item xs={12} sm={4}>
              <Search
                sx={{
                  marginTop: { xs: "5%", lg: "10%" },
                  marginBottom: { xs: "1%", sm: "1%", lg: "1%" },
                  marginLeft: { xs: "-30%", sm: "1%", md: "1%", lg: "1%" },
                  width: { xs: "70%", sm: "90%", md: "70%", lg: "80%" },
                }}
              >
                <TextField
                  placeholder="Buscar dominio"
                  value={search}
                  onChange={handleChange}
                  fullWidth
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                    sx: {
                      color: 'black', 
                      '&::placeholder': {
                        color: 'black', 
                      },
                      '&.Mui-focused': {
                        color: 'black', 
                      },
                    }
                  }}
                />
              </Search>
            </Grid>

            <Grid item xs={12} sm={4} >
              <StickyHeader>
                <Box pl={2.5} display="flex" justifyContent="flex-end" width="100%" backgroundColor="withe">
                  <Link to="/supervisor/dominios/crear">
                    <Button
                      sx={{
                        my: 2,
                        color: "#FFFFFF",
                        fontSize: "0.80rem",
                        width: { xs: "32vw", sm: "20%", lg: "100%" },
                        ml: { xs: "68%", sm: "0%", md: "100%" },
                        mt: { xs: "-78%", sm: "0%", md: "40%" },
                      }}
                      variant="contained"
                    >
                      Crear Dominio
                    </Button>
                  </Link>
                </Box>
              </StickyHeader>
            </Grid>
          </Grid>
        </Box>
        <Box px={4} mt={4}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table" id="informes">
            <TableHead>
              <TableRow>
                <TableCell align="left">DOMINIO</TableCell>
                <TableCell align="center">EMPRESA</TableCell>
                <TableCell align="center">INTERNO</TableCell>
                <TableCell align="center">TIPO DE SERVICIO</TableCell>
                <TableCell align="center">DESCUENTO</TableCell>
                <TableCell align="center">EDITAR</TableCell>
                <TableCell align="center">ELIMINAR</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
                {resultadosBusqueda.length > 0 ? (
                  resultadosBusqueda.map((row) => (
                  <TableRow
                    key={row.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="left">{row.dominio}</TableCell>
                    <TableCell align="center">{row.empresas.empresa}</TableCell>
                    <TableCell align="center">{row.interno}</TableCell>
                    <TableCell align="center">
                      {row.servicios.tipo_servicio}
                      </TableCell>
                      {row.descuento === 0 ? (<TableCell align="center">NO</TableCell>) : (<TableCell align="center">SI</TableCell>)}
                    <TableCell align="center">
                      {/* <Link to={`/admin/editar/terminal/${row.id}`}>
                        <EditIcon />
                      </Link> */}
                        <EditIcon onClick={() => editThis(row)} />
                      </TableCell>
                      <TableCell align="center">
                        <DeleteIcon
                          sx={{ cursor: "pointer" }}
                          onClick={() => handleOpenDialog(`${row.id}`)}
                        />
                      </TableCell>
                  </TableRow>
                  ))
              ) : (
                <TableRow>
                  <TableCell align="center" colSpan={8}>
                    No hay datos para mostrar
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
            <br />
            <br />
          </Table>
        </Box>
        <Dialog
          open={openDialog}
          onClose={handleCloseDialog}
          sx={{
            '.MuiDialog-paper': {
              width: '100%',
              maxHeight: 435,
            },
          }}
        >
          <DialogTitle
            sx={{
              background: "#0e315a",
              color: '#fff',
              fontWeight: 'bold',
              textAlign: 'center',
              fontSize: '1.1rem',
            }}
          >
            ¿Estás seguro de que quieres borrar este dominio?
          </DialogTitle>
          <DialogActions
            sx={{
              justifyContent: 'center',
              padding: '1em',
              background: "#0e315a",
            }}
          >
            <Button
              onClick={handleCloseDialog}
              sx={{
                backgroundColor: '#f5f5f5',
                color: '#000',
                '&:hover': {
                  backgroundColor: '#F4F2EC',
                },
              }}
            >
              Cancelar
            </Button>
            <Button
              onClick={deleteDomain}
              color="primary"
              autoFocus
              sx={{
                backgroundColor: 'red',
                color: '#fff',
                '&:hover': {
                  backgroundColor: '#303f9f',
                },
              }}
            >
              Borrar
            </Button>
          </DialogActions>
        </Dialog>
      </TableContainer>
      <DialogDomain
        dataModal={dataModal}
        openDialog={open}
        openFalse={openFalse}
        editadoFalse={editadoFalse}
      />
    </>
  );
}
