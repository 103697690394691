import React from 'react'
import FormCompany from '../../components/forms/FormCompanyCreate'

const AdminCompaniesCreate = () => {


    return (
        <FormCompany  />
    )
}

export default AdminCompaniesCreate