import { Box, Typography } from "@mui/material";
import axios from "axios";
import React from "react";
import TablePlatform from "../../components/table/TablePlatform";

const AdminPlatforms = () => {

  const style = {
    background: "#0e315a",
    color: "white",
    minWidth: "100%",
    paddingBlock: "2vh",
    textAlign: "center",

  };

  return (
    <Box px={3} pb={4} py={4} sx={{ background: "#0e315a" }}>
      {/* <Typography
        style={style}
        sx={{
          fontSize: { xs: "1.56rem", sm: "2.18rem", lg: "2.81rem" },
        }}
      >
        Listado de Plataformas
      </Typography> */}
      <Box
        sx={{
          minHeight: { xs: "59vh", sm: "65vh", md: "48vh", lg: "36vh" },
        }}
      >
        <TablePlatform />
      </Box>
    </Box>
  );
};

export default AdminPlatforms;
