import React, { useState } from "react";
import {
  Button,
  Divider,
  FormControlLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import axios from "axios";
import BasicModal from "../modals/Modal";
import { Link } from "react-router-dom";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ReplyIcon from "@mui/icons-material/Reply";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const validationSchema = yup.object({
  estado_id: yup.string().required("Campo requerido"),
  usuarios_id: yup.string().required("Campo requerido"),
  plataformas_id: yup.number().required('Campo requerido'),
});

const FormEditTicketOnPlatform = ({ ticket }) => {
  const [fechaHoraSalida, setFechaHoraSalida] = useState(
    ticket.tipo_tv_id === 2 ? "true" : "false"
  );
  const [openModal, setOpenModal] = useState(false);
  const [dataDropdown, setDataDropdown] = useState({});
  const navigate = useNavigate();
  const token = sessionStorage.getItem("jwt");
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const dateNow = new Date();
  const menosUno = 1;
  dateNow.setHours(menosUno);
  const year = dateNow.getFullYear();
  const monthWithOffset = dateNow.getUTCMonth() + 1;
  const month =
    monthWithOffset.toString().length < 2
      ? `0${monthWithOffset}`
      : monthWithOffset;
  const date =
    dateNow.getUTCDate().toString().length < 2
      ? `0${dateNow.getUTCDate()}`
      : dateNow.getUTCDate();

  const initialTicket = {
    fecha_ingreso: "",
    hora_ingreso: "",
    interno: "",
    empresa_id: "",
    servicios_id: "",
    usuarios_id: "",
    plataformas_id: "1" || 1 || null,
    estado_id: "",
    tipo_tv: "",
    destino: "",
    hora_salida: "",
    fecha_salida: "",
    operacion_id: "",
  };

  const editTicket = {
    fecha_ingreso: ticket.fecha_ingreso,
    hora_ingreso: ticket.hora_ingreso,
    interno: ticket.interno,
    usuarios_id: ticket.usuarios_id,
    plataformas_id: ticket.plataformas_id || 1 || "1",
    estado_id: ticket.registro_estado.id,
    destino: ticket.destino,
    tipo_tv: ticket.tipo_tv_id,
    fecha_salida: `${year}-${month}-${date}`,
    hora_salida: ticket.hora_salida,
    operacion_id: 4,
  };

  const inputStyle = {
    ".MuiOutlinedInput-notchedOutline": {
      borderColor: "black",
      transition: "250ms all ease",
    },
    ".MuiInputBase-root": {
      color: "black",
      transition: "250ms all ease",
    },
    ".MuiSvgIcon-root": {
      color: "black",
    },
    ".css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root": {
      color: "black",
    },
    "input:hover": {
      background: "transparent",
    },
    ".MuiInputBase-root:hover ": {
      boxShadow: " inset 0 0 9px rgb(63, 100, 143)",
    },
    ".MuiInputBase-root:hover .MuiSvgIcon-root": {
      color: "rgb(19, 46, 77)",
      border: "none",
    },
    ".css-md26zr-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
    {
      border: "none",
    },
    ".css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
    {
      borderColor: "black",
    },
  };
  const style = {
    background: "#F4F2EC",
    color: "black",
    ".MuiAccordionSummary-root .MuiSvgIcon-root": {
      color: "black",
    },
    "Mui-expanded": {
      margin: "0px",
    },
    "&:focus": {
      background: "#F4F2EC",
    },
  };

  React.useEffect(() => {
    const url = "https://terminaldigital.com.ar/api/informes/dataDropdown";
    axios
      .get(url, { headers: { authorization: `Bearer ${token}` } })
      .then((response) => setDataDropdown(response.data))
      .catch((error) => console.log("error jwt:", error.response.data.message));
  }, [token]);

  let initialValues = {
    ...editTicket,
    plataformas_id: dataDropdown.plataformas?.[0]?.id || '' || 1 || "1",
    estado_id: editTicket?.estado_id || 1,
  } || initialTicket;

  if (initialValues.estado_id !== '1') {
    initialValues = {
      ...initialValues,
      estado_id: 1,
    };
  }

  const showToastWarning = (message) => {
    toast.warning(message, {
      duration: 5000,
      position: 'top-right',
      style: { color: 'black', fontSize: '1rem', textTransform: "uppercase" },
      className: '',
      icon: '🚨',
      ariaProps: {
        role: 'status',
        'aria-live': 'polite',
      },
    });
    setSubmitting(false);
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting }) => {
      if (values.estado_id === 1 && values.tipo_tv === 3 && values.plataformas_id === 1) {
        showToastWarning('Por favor, selecciona plataforma y tipo de TV.');
      } else if (values.plataformas_id === 1 && values.tipo_tv !== 3) {
        showToastWarning('Por favor, selecciona plataforma.');
      } else if (values.tipo_tv === 3 && values.plataformas_id !== 1) {
        showToastWarning('Por favor, selecciona tipo de TV.');
      } else {
        const config = { headers: { authorization: `Bearer ${token}` } };
        const url = `https://terminaldigital.com.ar/api/informes/modificar/${ticket.id}`;
        const data = formik.values;
        axios
          .patch(url, data, config)
          .then((res) => {
            if (res.status === 200) {
              toast.success(`Unidad de ${ticket.registro_empresa?.empresa} enviada a plataforma con éxito`)
              navigate(-1);
            }
          })
          .catch(function (error) {
            console.log("Error:", error);
          });
      }
    },
  });

  const onClickFechaHoraSalida = () => {
    setFechaHoraSalida("true");
  };
  const onClickFechaHoraArribo = () => {
    setFechaHoraSalida("false");
  };

  const expandPanelSelectedMd = expanded === "panel1" ? "70vh" : "60vh";

  let expandPanelSelectedXs = "80vh";

  if (expanded === "panel1" && fechaHoraSalida === "true") {
    expandPanelSelectedXs = "195vh";
  } else if (expanded === "panel1") {
    expandPanelSelectedXs = "165vh";
  } else if (fechaHoraSalida === "true") {
    expandPanelSelectedXs = "115vh";
  }

  return (
    <Stack
      sx={{ background: "#F4F2EC", borderRadius: "25px", shadow: 4, height: { xs: expandPanelSelectedXs, md: expandPanelSelectedMd } }}
      my={4}
      mx={{ xs: 1, sm: 6 }}
      p={4}
      sm={6}
    >
      <form onSubmit={formik.handleSubmit}>
        <Link to="/informes">
          <Button
            py={2}
            my={4}
            mx={4}
            sx={{
              color: "#b22222",
              marginLeft: { xs: "85%", sm: "92%", md: "95%" },
            }}
            variant="outlined"
            color="error"
          >
            <ReplyIcon />
          </Button>
        </Link>
        <Typography sx={{
          fontSize: { xs: "1.5rem", lg: "2rem" },
          fontFamily: 'Lato, sans-serif',
          color: '#0e315a',
          textShadow: '2px 2px 2px rgba(0, 0, 0, 0.25)',
          display: 'flex',
          justifyContent: 'start',
          alignItems: 'center',
          margin: 5,
          ml: 1
        }} >
          Editar ticket
        </Typography>
        <Grid container my={4}>
          <Grid
            item
            display={{ xs: "block", md: "flex" }}
            alignItems="center"
            gap={2}
            xs={12}
            md={3}
            my={2}
          >
            <Typography
              variant="subtitle1"
              color="black"
              display={{ xs: "block", sm: "block" }}
            >
              Estado:
            </Typography>
            <RadioGroup
              row
              aria-labelledby="Estado"
              fullWidth
              sx={inputStyle}
              name="estado_id"
              value={formik.values.estado_id}
              onChange={formik.handleChange}
              error={formik.errors?.estado_id}
              helperText={formik.errors?.estado_id}
            >
              <FormControlLabel
                value={"1"}
                control={<Radio />}
                sx={{ color: "black" }}
                label="En Plataforma"
              />
              <FormControlLabel
                value={"4"}
                control={<Radio />}
                sx={{ color: "black" }}
                label="Fuera de Plataforma"
              />
            </RadioGroup>
          </Grid>
          <Grid
            item
            display={{ xs: "block", md: "flex" }}
            alignItems="center"
            gap={2}
            xs={7}
            sm={3.8}
            md={3}
            lg={3}
            my={2}
          >
            <Typography variant="subtitle1" color="black">
              Plataforma:
            </Typography>
            <TextField
              select
              sx={{
                ".MuiOutlinedInput-notchedOutline": {
                  borderColor: "black",
                },
                ".MuiInputBase-root": {
                  color: "black",
                  width: "100%",
                },
              }}
              InputLabelProps={{
                style: { color: "black" },
              }}
              name="plataformas_id"
              value={formik.values.plataformas_id}
              onChange={formik.handleChange}
              error={formik.errors.plataformas_id}
              helperText={formik.errors.plataformas_id}
            >
              <MenuItem value={1}>
                <em>Sin Plataforma</em>
              </MenuItem>
              {dataDropdown.plataformas?.filter(plataforma => plataforma.servicios_id === ticket.servicios_id).map((plataforma) => (
                <MenuItem
                  key={plataforma.id}
                  value={plataforma.id}
                  selected={formik.values.plataformas_id === plataforma.id}
                >
                  {plataforma.plataforma}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid
            item
            display={{ xs: "block", md: "flex" }}
            alignItems="center"
            gap={2}
            xs={4}
            sm={fechaHoraSalida === "true" ? 2 : 4}
            my={2}
          >
            <Typography
              variant="subtitle1"
              color="black"
              display={{ xs: "block", sm: "block" }}
            >
              Tipo de tv:
            </Typography>
            <RadioGroup
              row
              aria-labelledby="Estado"
              fullWidth
              sx={inputStyle}
              name="tipo_tv"
              value={formik.values.tipo_tv}
              onChange={formik.handleChange}
              error={formik.errors.tipo_tv}
              helperText={formik.errors.tipo_tv}
            >
              <FormControlLabel
                value={"1"}
                control={<Radio />}
                onClick={onClickFechaHoraArribo}
                sx={{ color: "black" }}
                label="Arribos"
              />
              <FormControlLabel
                value={"2"}
                control={<Radio />}
                onClick={onClickFechaHoraSalida}
                sx={{ color: "black" }}
                label="Partidas"
              />
            </RadioGroup>
          </Grid>
          <Grid
            item
            display={{ xs: fechaHoraSalida === "true" ? "block" : "none" }}
            alignItems="center"
            gap={2}
            xs={8}
            sm={4.5}
            md={2.2}
            lg={2}
            my={2}
          >
            <Typography variant="subtitle1" color="black" mb={{ xs: 1, sm: 0 }}>
              Fecha de salida:
            </Typography>
            <TextField
              sx={{
                ".MuiOutlinedInput-notchedOutline": {
                  borderColor: "black",
                },
                ".MuiInputBase-root": {
                  color: "black",
                  width: {xs: "184%", md: "100%"},
                },
              }}
              InputProps={{
                type: "date",
              }}
              name="fecha_salida"
              value={formik.values.fecha_salida}
              onChange={formik.handleChange}
              error={formik.errors.fecha_salida}
              helperText={formik.errors.fecha_salida}
            />
          </Grid>
          <Grid
            item
            display={{ xs: fechaHoraSalida === "true" ? "block" : "none" }}
            alignItems="center"
            gap={2}
            xs={12}
            sm={4}
            md={4}
            lg={2}
            my={2}
          >
            <Typography variant="subtitle1" color="black" mb={{ xs: 1, sm: 0 }}>
              Hora de salida:
            </Typography>
            <TextField
              sx={{
                ".MuiOutlinedInput-notchedOutline": {
                  borderColor: "black",
                },
                ".MuiInputBase-root": {
                  color: "black",
                  width: { xs: "300%", md: "150%" },
                },
                "& .MuiSvgIcon-root": {
                  color: "black",
                },
              }}
              InputProps={{
                type: "time",
              }}
              value={formik.values.hora_salida}
              name="hora_salida"
              onChange={formik.handleChange}
              error={formik.errors.hora_salida}
              helperText={formik.errors.hora_salida}
            />
          </Grid>
          <Grid item alignItems="center" gap={2} xs={12} sm={12} my={2}>
            <Accordion
              expanded={expanded === "panel1"}
              onChange={handleChange("panel1")}
              sx={style}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Divider
                  sx={{
                    color: "black",
                    textAlign: "left",
                    fontSize: {
                      xs: "1rem",
                      sm: "1rem",
                      md: "1rem",
                      lg: "1.25rem",
                    },
                  }}
                >
                  DATOS REGISTRADOS DEL TICKET
                </Divider>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container direction={{ xs: "column", sm: "row" }}>
                  <Grid
                    item
                    display={{ xs: "block", sm: "flex" }}
                    alignItems="center"
                    gap={2}
                    xs={12}
                    sm={1.5}
                    my={2}
                  >
                    <TextField
                      label="Interno"
                      sx={{
                        ".MuiOutlinedInput-notchedOutline": {
                          borderColor: "black",
                        },
                        ".MuiInputBase-root": {
                          color: "black",
                          width: "76%",
                        },
                      }}
                      InputProps={{
                        type: "text",
                        readOnly: true,
                      }}
                      InputLabelProps={{
                        style: { color: "black" },
                      }}
                      value={formik.values.interno}
                      name="interno"
                      onChange={formik.handleChange}
                      error={formik.errors.interno}
                      helperText={formik.errors.interno}
                    />
                  </Grid>
                  <Grid
                    item
                    display={{ xs: "none", md: "none" }}
                    alignItems="center"
                  >
                    <TextField
                      sx={{
                        ".MuiOutlinedInput-notchedOutline": {
                          borderColor: "black",
                        },

                        ".MuiInputBase-root": {
                          color: "black",
                        },
                      }}
                      InputLabelProps={{
                        style: { color: "black" },
                        readOnly: true,
                      }}
                      label="Inserte usuario ID"
                      name="usuarios_id"
                      value={formik.values.usuarios_id}
                      onChange={formik.handleChange}
                      error={formik.errors.usuarios_id}
                      helperText={formik.errors.usuarios_id}
                    />
                  </Grid>
                  <Grid
                    item
                    display={{ xs: "block", sm: "flex" }}
                    alignItems="center"
                    gap={2}
                    xs={12}
                    sm={1.7}
                    lg={2}
                    my={2}
                  >
                    <TextField
                      label="Fecha Ingreso"
                      sx={{
                        ".MuiOutlinedInput-notchedOutline": {
                          borderColor: "black",
                        },
                        ".MuiInputBase-root": {
                          color: "black",
                          width: "100%",
                        },
                      }}
                      InputProps={{
                        type: "date",
                        readOnly: true,
                      }}
                      InputLabelProps={{
                        style: { color: "black" },
                      }}
                      name="fecha_ingreso"
                      value={formik.values.fecha_ingreso}
                      onChange={formik.handleChange}
                      error={formik.errors.fecha_ingreso}
                      helperText={formik.errors.fecha_ingreso}
                    />
                  </Grid>
                  <Grid
                    item
                    display={{ xs: "block", sm: "flex" }}
                    alignItems="center"
                    gap={2}
                    xs={12}
                    sm={1.4}
                    my={2}
                  >
                    <TextField
                      label="Hora Ingreso"
                      sx={{
                        ".MuiOutlinedInput-notchedOutline": {
                          borderColor: "black",
                        },
                        ".MuiInputBase-root": {
                          color: "black",
                          width: "100%",
                        },
                      }}
                      InputProps={{
                        type: "time",
                        readOnly: true,
                      }}
                      InputLabelProps={{
                        style: { color: "black" },
                      }}
                      value={formik.values.hora_ingreso}
                      name="hora_ingreso"
                      onChange={formik.handleChange}
                      error={formik.errors.hora_ingreso}
                      helperText={formik.errors.hora_ingreso}
                    />
                  </Grid>
                  <Grid
                    item
                    display={{ xs: "block", md: "flex" }}
                    alignItems="center"
                    gap={2}
                    xs={12}
                    md={2.1}
                    my={2}
                  >
                    <TextField
                      label="Empresa"
                      sx={{
                        ".MuiOutlinedInput-notchedOutline": {
                          borderColor: "black",
                        },
                        ".MuiInputBase-root": {
                          color: "black",
                          width: "90%",
                        },
                        "& .MuiSvgIcon-root": {
                          color: "black",
                        },
                        minWidth: "200px",
                      }}
                      InputProps={{
                        type: "text",
                        readOnly: true,
                      }}
                      InputLabelProps={{
                        style: { color: "black" },
                      }}
                      name="empresa_id"
                      value={ticket.registro_empresa?.empresa}
                      onChange={formik.handleChange}
                      error={formik.errors.empresa_id}
                      helperText={formik.errors.empresa_id}
                    ></TextField>
                  </Grid>
                  <Grid
                    item
                    display={{ xs: "block", md: "flex" }}
                    alignItems="center"
                    gap={2}
                    xs={12}
                    md={2.8}
                    my={2}
                  >
                    <TextField
                      label="Tipo de servicio"
                      sx={{
                        ".MuiOutlinedInput-notchedOutline": {
                          borderColor: "black",
                        },
                        ".MuiInputBase-root": {
                          color: "black",
                        },
                        "& .MuiSvgIcon-root": {
                          color: "black",
                        },
                        minWidth: "260px",
                      }}
                      InputLabelProps={{
                        style: { color: "black" },
                      }}
                      InputProps={{
                        readOnly: true,
                      }}
                      name="servicios_id"
                      value={ticket.registro_servicio.tipo_servicio}
                      onChange={formik.handleChange}
                      error={formik.errors.servicios_id}
                      helperText={formik.errors.servicios_id}
                    >
                      {dataDropdown.servicios?.map((servicio) => (
                        <MenuItem
                          key={servicio.id}
                          value={servicio.id}
                          selected={true}
                        >
                          {servicio.tipo_servicio} - {servicio.siglas}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid
                    item
                    display={{ xs: "block", md: "flex" }}
                    alignItems="center"
                    gap={2}
                    xs={12}
                    md={2}
                    my={2}
                  >
                    <TextField
                      sx={{
                        ".MuiOutlinedInput-notchedOutline": {
                          borderColor: "black",
                        },
                        ".MuiInputBase-root": {
                          color: "black",
                        },
                      }}
                      InputLabelProps={{
                        style: { color: "black" },
                      }}
                      InputProps={{
                        readOnly: true,
                      }}
                      label=" Destino / Origen"
                      name="destino"
                      value={formik.values.destino}
                      onChange={formik.handleChange}
                      error={formik.errors.destino}
                      helperText={formik.errors.destino}
                    />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item sx={{ marginRight: "auto" }} align="center" xs={12} pt={4}>
            <Button variant="contained" ml="auto" type="submit" my={2}>
              Editar ingreso
            </Button>
          </Grid>
          {openModal && (
            <>
              <BasicModal
                title="Éxito"
                message="El registro fue modificado"
                openModal={openModal}
                type="ingresos"
              />
            </>
          )}
        </Grid>
      </form>
    </Stack>
  );
};

export default FormEditTicketOnPlatform;
