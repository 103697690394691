import axios from "axios";
import React, { useState, useEffect } from "react";
import TableAdminDomain from "../../components/table/TableAdminDomain";
import { Typography, Box } from "@mui/material";

const style = {
  background: "#0e315a",
  color: "white",
  minWidth: "100%",
  paddingBlock: "2vh",
  textAlign: "center",

};

const AdminDomain = () => {
  const token = sessionStorage.getItem("jwt");
  const [unidades, setUnidades] = useState([]);

  const getUnit = () => {
    axios
      .all([
        axios.get("https://terminaldigital.com.ar/api/empresas/unidadesPorDominio", {
          headers: { authorization: `Bearer ${token}` },
        }),
      ])
      .then((data) => {
        setUnidades(data[0].data.unidades);
      })
      .catch((error) => console.log("error jwt:", error.response.data.message));
  };

  useEffect(() => {
    getUnit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (
    <Box px={3} pb={4} py={4} sx={{ background: "#0e315a" }}>
      <TableAdminDomain dominios={unidades} />
    </Box>
  );
};

export default AdminDomain;
