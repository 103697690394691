import React, { useState, useEffect } from "react";
import { Box, CircularProgress, Typography  } from "@mui/material";
import { Stack } from "@mui/system";
import axios from "axios";
import "./ArrivalsBoard.styles.css";
import GenericTableInterurbanos from "../../components/table/TableInterurbanos";

const style = {
  background: "#0e315a",
  color: "white",
  minWidth: "100%",
  paddingBlock: "2vh",
  textAlign: "center",

};

const InterurbanosBoard = () => {
  const token = sessionStorage.getItem("jwt");
  const [data, setData] = React.useState([]);

  const url = "https://terminaldigital.com.ar/api/interurbanos/all";
  const config = { headers: { authorization: `Bearer ${token}` } };

  const getInterurbano = () => {
    axios
      .get(url, config)
      .then((data) => {
        return setData(data.data.interurbanos);
      })
      .catch((error) => console.log("Error Interurbanos Table:", error));
  };
  React.useEffect(() => {
     getInterurbano();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [logo, setLogo] = useState([]);

  const getLogoTerminal = async () => {
    axios
      .get("https://terminaldigital.com.ar/api/administrarterminal")
      .then((data) => {
        setLogo(data.data.terminal.logo);
      })
      .catch((error) => console.log("error users", error));
  };

  useEffect(() => {
     getLogoTerminal();
  }, []);

  return (
    <Box px={3} pb={4} py={4} sx={{ background: "#0e315a" }}>
      <Box>
        {data.length === undefined ? (
          <Stack justifyContent={"center"} alignItems={"center"} height={"40vh"}>
            <CircularProgress />
          </Stack>
        ) : (
          <Box
            px={3}
            pb={2}
            py={2}
            sx={{
              background: "#0e315a",
              minHeight: { xs: "0vh", sm: "74vh", md: "56vh", lg: "30vh" },
            }}
          >
            <GenericTableInterurbanos props={data} />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default InterurbanosBoard;
