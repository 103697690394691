import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { useParams } from 'react-router-dom';
import FormEditTicketError from "../../components/forms/FormEditTicketError";
import { Box, CircularProgress, Typography } from '@mui/material';

const InformsEditTicketError = () => {
  const [ticket, setTicket] = useState("");
  const token = sessionStorage.getItem("jwt");
  const params = useParams();
  const id = params.id;


  useEffect(() => {
    const url = `https://terminaldigital.com.ar/api/informes/${id}`;
    const config = { headers: { authorization: `Bearer ${token}` } };

    axios
      .get(url, config)
      .then((data) => {
        setTicket(data.data.ingresos);
      })
      .catch((error) => console.log("error get edit ticket:", error));
  }, [id, token]);

  return (
    <>
      {ticket === "" ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "70vh",
            width: "100vw",
          }}
        >
          <CircularProgress />
          <Typography mt={4}>Cargado información...</Typography>
        </Box>
      ) : (
        <FormEditTicketError ticket={ticket} />
      )}
    </>
  );
};

export default InformsEditTicketError;