import React, { createContext, useContext, useState } from 'react'
import { Stack } from '@mui/material';
const MarquesineContext = createContext();
const MarquesineUpdateContext = createContext();

export function useMarquesine() {
  return useContext(MarquesineContext);
}

export function useMarquesineUpdate() {
  return useContext(MarquesineUpdateContext);
}

export function MarquesineProvider({ children }) {

  const [marquesineUpdate, setMarquesineUpdate] = useState(true);

  function updateMarquesineTable(value) {
    setMarquesineUpdate(value);
  }

  return (
    <Stack sx = {{ height: {xs:'100vh', md:'70vh'} }}>
    <MarquesineContext.Provider value={marquesineUpdate}>
      <MarquesineUpdateContext.Provider value={updateMarquesineTable}>
        {children}
      </MarquesineUpdateContext.Provider>
    </MarquesineContext.Provider>
    </Stack>
  );
}