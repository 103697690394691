import React, { useState } from "react";
import axios from "axios";
import {
  Button,
  TextField,
  Stack,
  RadioGroup,
  Radio,
  FormControlLabel,
  Typography,
  Grid,
} from "@mui/material";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import BasicModal from "../../components/modals/Modal";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import * as yup from "yup";
import CloseIcon from "@mui/icons-material/Close";

const inputStyle = {
  ".MuiOutlinedInput-notchedOutline": {
    borderColor: "white",
    transition: "250ms all ease",
  },
  ".MuiInputBase-root": {
    color: "white",
    transition: "250ms all ease",
  },
  ".MuiSvgIcon-root": {
    color: "white",
  },
  ".css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root": {
    color: "white",
  },
  "input:hover": {
    background: "transparent",
    
  },
  ".MuiInputBase-root:hover ": {
    
    
    boxShadow: " inset 0 0 9px rgb(63, 100, 143)",
  },
  ".MuiInputBase-root:hover .MuiSvgIcon-root": {
    color: "rgb(19, 46, 77)",
    border: "none",
  },
  ".css-md26zr-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
    {
      border: "none",
    },
  ".css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
    {
      borderColor: "white",
    },
  marginLeft: "50px",
};

const AdminGetPay = () => {
  const [openModal, setOpenModal] = useState(false);
  const [configuracion, setConfiguracion] = useState([]);
  const [time, setTimeSal] = useState("");
  // eslint-disable-next-line
  const [open, setOpen] = React.useState(true);

  const token = window.sessionStorage.getItem("jwt");
  const navigate = useNavigate();

  const handleCloseIcon = () => {
    setOpen(false);
    navigate("/supervisor/telepeaje/configuracion-valores");
  };
  const closeEverything = () => {
    handleCloseIcon();
  };

  React.useEffect(() => {
    const url = `https://terminaldigital.com.ar/api/sictranscore/ObtenerConfiguracionCobro`;

    axios
      .get(url)
      .then((data) => {
        setConfiguracion(data.data.getConfig);
        let tolerancia = data.data.getConfig.tolerancia;
        let valor;
        if (tolerancia >= 2 && tolerancia <= 59) {
          valor = tolerancia;
          setTimeSal("minutos");
          formik.values.tiempo = 'minutos';
        } else if (tolerancia == 1) {
          valor = tolerancia;
          setTimeSal("minutos");
          formik.values.tiempo = 'minutos';
        } else if (tolerancia == 60) {
          valor = 1;
          setTimeSal("hora");
          formik.values.tiempo = 'hora';
        } else if (tolerancia > 60) {
          valor = tolerancia / 60;
          setTimeSal("hora");
          formik.values.tiempo = 'hora';
        }
        formik.values.tolerancia = valor;
      })
      .catch((error) => console.log("error supervisor home", error));
  }, []);

  const initialValues = {
    tolerancia: configuracion.tolerancia,
    valor_basico: configuracion.valor_basico,
    valor_excedente: configuracion.valor_excedente,
    descuento_diferencial: configuracion.descuento_diferencial,
    tiempo: `${time}`,
  };
  const editValues = {
    tolerancia: configuracion.tolerancia,
    valor_basico: configuracion.valor_basico,
    valor_excedente: configuracion.valor_excedente,
    descuento_diferencial: configuracion.descuento_diferencial,
    tiempo: time,
  };
  const validationSchema = yup.object({
    //tolerancia: yup.number().required("Campo requerido"),
    tiempo: yup.string().required("Campo requerido"),
  });

  const formik = useFormik({
    initialValues: initialValues || editValues,
    validationSchema: validationSchema,

    onSubmit: (values) => {
      const config = { headers: { authorization: `Bearer ${token}` } };
      const url = `https://terminaldigital.com.ar/api/sictranscore/ModificarConfiguracionCobro`;
      const data = formik.values;

      axios.patch(url, data, config).then((res) => {
        if (res.status === 200) {
          functionOpenModal();
          setTimeout(() => {
            navigate("/supervisor/telepeaje/configuracion-valores");
          }, "2000");
        }
      });
    },
  });

  const functionCloseModal = () => {
    return setOpenModal(false);
  };

  const functionOpenModal = () => {
    setTimeout(() => functionCloseModal(), 5000);
    return setOpenModal(true);
  };

  const handleClose = () => {
    setOpen(false);
    navigate("/supervisor/telepeaje/configuracion-valores");
  };

  return (
    <Stack
      sx={{ background: "#F4F2EC", borderRadius: "25px", shadow: 4 }}
      my={4}
      mx={{ xs: 1, sm: 6 }}
      p={4}
      sm={6}
    >
      <form onSubmit={formik.handleSubmit}>
        <Button
          onClick={closeEverything}
          sx={{ color: "#b22222", marginLeft: { xs: "88%", sm: "95%" } }}
        >
          <CloseIcon />
        </Button>
        <Typography
          sx={{
            fontSize: {
              sx: "0.1",
              xs: "1.5rem",
              sm: "1.15rem",
              md: "1.25rem",
              lg: "1.5rem",
            },
          }}
          color="white"
        >
          Editar valores de cobro:
        </Typography>
        <Grid container my={4}>
          <Grid
            item
            display={{ xs: "block", md: "flex" }}
            alignItems="center"
            gap={2}
            xs={4}
            sm={4.3}
            lg={1.8}
            my={2}
          >
            <Typography
              variant="subtitle1"
              color="white"
              display={{ xs: "block", sm: "block" }}
              sx={{
                fontSize: {
                  sx: "0.1",
                  xs: "1.2rem",
                  sm: "1.3rem",
                  md: "1.22rem",
                  lg: "1.20rem",
                },
              }}
            >
              Tolerancia:
            </Typography>
            <TextField
              sx={{
                ".MuiOutlinedInput-notchedOutline": {
                  borderColor: "white",
                },
                ".MuiInputBase-root": {
                  color: "white",
                },
              }}
              InputLabelProps={{
                style: { color: "white" },
              }}
              InputProps={{
                type: "number",
                inputProps: {
                  min: 1,
                },
              }}
              label={
                (() => {
                  const tolerancia = configuracion.tolerancia;
                  let valor;
                  if (tolerancia >= 2 && tolerancia <= 59) {
                    valor = tolerancia;;
                  } else if (tolerancia == 1) {
                    valor = tolerancia;;
                  } else if (tolerancia == 60) {
                    valor = 1;;
                  } else if (tolerancia > 60) {
                    valor = tolerancia / 60;;
                  }

                  return `${valor}`;
                })()
              }
              name="tolerancia"
              value={formik.values.tolerancia}
              onChange={formik.handleChange}
              error={formik.errors.tolerancia}
              helperText={formik.errors.tolerancia}
            ></TextField>
          </Grid>
          <Grid
            item
            display={{ xs: "block", md: "flex" }}
            alignItems="center"
            gap={2}
            xs={6}
            md={3}
            lg={1.8}
            my={{ xs: 4, sm: 7, lg: 4 }}
          >
            <RadioGroup
              sx={inputStyle}
              row
              aria-labelledby="Estado"
              fullWidth
              name="tiempo"
              label={time}
              value={formik.values.tiempo}
              onChange={formik.handleChange}
              error={formik.errors.tiempo}
              helperText={formik.errors.tiempo}
            >
              <FormControlLabel
                value={"hora"}
                control={<Radio />}
                sx={{ color: "black" }}
                label="Hora/as"
              />
              <FormControlLabel
                value={"minutos"}
                control={<Radio />}
                sx={{ color: "black" }}
                label=" Minuto/os"
              />
            </RadioGroup>
          </Grid>
          <Grid
            item
            display={{ xs: "block", md: "flex" }}
            alignItems="center"
            gap={2}
            xs={6}
            sm={4}
            lg={2.4}
            my={2}
          >
            <Typography
              variant="subtitle1"
              color="white"
              sx={{
                fontSize: {
                  sx: "0.1",
                  xs: "1.2rem",
                  sm: "1.3rem",
                  md: "1.22rem",
                  lg: "1.20rem",
                },
              }}
            >
              Valor básico:
            </Typography>
            <TextField
              sx={{
                ".MuiOutlinedInput-notchedOutline": {
                  borderColor: "white",
                  width: "100%",
                },
                ".MuiInputBase-root": {
                  color: "white",
                },
                maxWidth: { xs: "100px", sm: "80px" },
              }}
              InputLabelProps={{
                style: { color: "white" },
              }}
              InputProps={{
                type: "number",
                inputProps: {
                  min: 1,
                },
              }}
              label={configuracion.valor_basico}
              name="valor_basico"
              value={formik.values.valor_basico}
              onChange={formik.handleChange}
              error={formik.errors.valor_basico}
              helperText={formik.errors.valor_basico}
            ></TextField>
          </Grid>
          <Grid
            item
            display={{ xs: "block", md: "flex" }}
            alignItems="center"
            gap={2}
            xs={6}
            sm={4.5}
            lg={2.7}
            my={2}
          >
            <Typography
              variant="subtitle1"
              color="white"
              display={{ xs: "block", sm: "block" }}
              sx={{
                fontSize: {
                  sx: "0.1",
                  xs: "1.1rem",
                  sm: "1.3rem",
                  md: "1.12rem",
                  lg: "1.20rem",
                },
              }}
            >
              Valor excedente:
            </Typography>
            <TextField
              sx={{
                ".MuiOutlinedInput-notchedOutline": {
                  borderColor: "white",
                  width: "100%",
                },
                ".MuiInputBase-root": {
                  color: "white",
                },
                maxWidth: { xs: "100px", sm: "80px" },
              }}
              InputLabelProps={{
                style: { color: "white" },
              }}
              InputProps={{
                type: "number",
                inputProps: {
                  min: 1,
                },
              }}
              label={configuracion.valor_excedente}
              name="valor_excedente"
              value={formik.values.valor_excedente}
              onChange={formik.handleChange}
              error={formik.errors.valor_excedente}
              helperText={formik.errors.valor_excedente}
            ></TextField>
          </Grid>

          <Grid
            item
            alignItems="center"
            gap={2}
            xs={6}
            sm={2}
            lg={2.3}
            my={2}
            display={{ xs: "block", md: "flex" }}
          >
            <Typography
              variant="subtitle1"
              color="white"
              mb={{ xs: 1, sm: 0 }}
              sx={{
                fontSize: {
                  sx: "0.1",
                  xs: "1.2rem",
                  sm: "1.3rem",
                  md: "1.22rem",
                  lg: "1.20rem",
                },
              }}
            >
              Descuento:
            </Typography>
            <TextField
              sx={{
                ".MuiOutlinedInput-notchedOutline": {
                  borderColor: "white",
                  width: "100%",
                },
                ".MuiInputBase-root": {
                  color: "white",
                },
                maxWidth: { xs: "100px", sm: "80px" },
              }}
              InputLabelProps={{
                style: { color: "white" },
              }}
              InputProps={{
                type: "number",
                inputProps: {
                  min: 1,
                },
              }}
              label={configuracion.descuento_diferencial}
              name="descuento_diferencial"
              value={formik.values.descuento_diferencial}
              onChange={formik.handleChange}
              error={formik.errors.descuento_diferencial}
              helperText={formik.errors.descuento_diferencial}
            ></TextField>
          </Grid>
          <Grid
            item
            alignItems="center"
            gap={2}
            xs={4}
            sm={12}
            lg={1}
            mt={{ xs: 8.5, sm: 8, lg: 0 }}
            ml={{ xs: 0, sm: 35, lg: 0 }}
            display={{ xs: "block", md: "flex" }}
          >
            <Button variant="contained" ml="auto" type="submit" my={2}>
              GUARDAR
            </Button>
          </Grid>
          {openModal && (
            <>
              <BasicModal
                title="Éxito"
                message="El registro fue modificado"
                openModal={openModal}
                type="ingresos"
              />
            </>
          )}
          <br></br>
          {openModal && (
            <BasicModal
              title=<CheckCircleOutlineIcon
                sx={{ fontSize: "3.12rem", marginLeft: "-25px" }}
              />
              message="Valores modificado con éxito"
              openModal={openModal}
              //redirectTo={"/seguridad/crear-ingreso"}
            />
          )}
        </Grid>
      </form>
    </Stack>
  );
};

export default AdminGetPay;
