import React, { useState, useEffect } from "react";
import axios from "axios";
import { useFormik } from 'formik';
import {
  Autocomplete,
  Button,
  TextField,
  Typography,
  Dialog,
  ListItemText,
  ListItem,
  List,
  Divider,
  AppBar,
  Toolbar,
  IconButton,
  Slide,
  Box,
  Radio,
  RadioGroup,
  FormControlLabel
} from "@mui/material";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


import CloseIcon from "@mui/icons-material/Close";

const inputStyle = {
  ".MuiOutlinedInput-notchedOutline": {
    borderColor: "black",
    transition: "250ms all ease",
  },
  ".MuiInputBase-root": {
    color: "black",
    transition: "250ms all ease",
  },
  ".MuiSvgIcon-root": {
    color: "black",
  },
  ".css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root": {
    color: "black",
  },
  "input:hover": {
    background: "transparent",

  },
  ".MuiInputBase-root:hover ": {


    boxShadow: " inset 0 0 9px rgb(63, 100, 143)",
  },
  ".MuiInputBase-root:hover .MuiSvgIcon-root": {
    color: "rgb(19, 46, 77)",
    border: "none",
  },
  ".css-md26zr-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
  {
    border: "none",
  },
  ".css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
  {
    borderColor: "black",
  },
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DialogDomain({ dataModal, openDialog, openFalse, editadoFalse }) {
  const token = sessionStorage.getItem("jwt");
  const [open, setOpen] = useState(false);
  const [dataDropdown, setDataDropdown] = useState({});
  const [valueEmpresa, setValueEmpresa] = useState(null);
  const [valueServicio, setValueServicio] = useState(null);
  const [isDataModalReady, setIsDataModalReady] = React.useState(false);
  const [showDiscountInput, setShowDiscountInput] = useState(false);

  useEffect(() => {
    if (dataModal) {
      setIsDataModalReady(true);
      formik.setFieldValue('descuento', dataModal.descuento !== null && dataModal.descuento !== undefined ? Number(dataModal.descuento) : 0);
      formik.setFieldValue('servicios_id', Number(dataModal.servicios_id));
      formik.setFieldValue('valor_descuento', dataModal.valor_descuento !== null && dataModal.valor_descuento !== undefined ? Number(dataModal.valor_descuento) : 0);
      setShowDiscountInput(dataModal.descuento === 1);
    }
  }, [dataModal]);

  const handleAutocompleteChange = (setValue, fieldName) => (event, newValue) => {
    setValue(newValue);
    formik.setFieldValue(fieldName, newValue?.id || null);
  };
  
  const getInitialDomain = () => {
    return {
      interno: "",
      empresa_id: "",
      servicios_id: "",
      dominio: "",
      descuento: "",
      valor_descuento: "",
    };
  };

  const getEditDomain = () => {
    return {
      interno: dataModal?.interno,
      empresa_id: dataModal?.empresa_id,
      servicios_id: Number(dataModal?.servicios_id),
      dominio: dataModal?.dominio,
      descuento: Number(dataModal?.descuento), 
      valor_descuento: Number(dataModal?.valor_descuento),
    };
  };

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("https://terminaldigital.com.ar/api/informes/dataDropdown", { headers: { authorization: `Bearer ${token}` } });
        setDataDropdown(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
        toast.error('Hubo un error al obtener los datos', {
          position: toast.POSITION.TOP_CENTER
        });
      }
    };

    fetchData();
  }, [token]);

  useEffect(() => {
    setOpen(openDialog);
  }, [openDialog]);

  const formik = useFormik({
    initialValues: getEditDomain() || getInitialDomain(),
    onSubmit: async (values) => {
      try {
        const response = await axios.patch(`https://terminaldigital.com.ar/api/empresas/actualizarDominio/${dataModal.id}`, values, { headers: { authorization: `Bearer ${token}` } });
        if (response.status === 200) {
          toast.success('Los cambios se han guardado exitosamente', {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setValueEmpresa(null);
          setValueServicio(null);
          formik.resetForm();
          openFalse(false);
          editadoFalse("true");
        }
      } catch (error) {
        console.error("Error updating domain:", error);
        toast.error('Para guardar debes de cambiar algún dato', {
          position: toast.POSITION.TOP_CENTER
        });
      }
    },
  });

  
  return (
    <>
      {/* Modal */}
      <Dialog
        fullScreen
        open={open}
        onClose={() => openFalse(false)}
        TransitionComponent={Transition}
      >
        <form onSubmit={formik.handleSubmit}>
          <AppBar sx={{ position: "relative" }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => openFalse(false)}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography
                sx={{ ml: 2, flex: 1, display: { xs: "none", sm: "block" } }}
                variant="h6"
                component="div"
              >
                ID: {dataModal.id}
              </Typography>
              <Typography
                sx={{
                  ml: { xs: 9, sm: 31, md: -16, lg: -15 },
                  flex: { xs: 15, md: 1 },
                }}
                variant="h6"
                component="div"
              >
                Dominio: {dataModal.dominio}
              </Typography>
              <Button
                autoFocus
                color="inherit"
                type="submit"
                sx={{ display: { xs: "none", md: "block" } }}
              >
                Guardar
              </Button>
            </Toolbar>
          </AppBar>
          <List>
            <ListItem>
              <ListItemText
                primary={
                  <TextField
                    label={dataModal.dominio}
                    sx={{ display: "none" }}
                    InputProps={{
                      type: "text",
                    }}
                    value={formik.values.dominio}
                    name="dominio"
                    onChange={formik.handleChange}
                    error={formik.errors.dominio}
                    helperText={formik.errors.dominio}
                  />
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Empresa"
                secondary={
                  dataDropdown.empresas && (
                    <Autocomplete
                      clearOnEscape
                      value={valueEmpresa}
                      options={dataDropdown.empresas}
                      sx={inputStyle}
                      onChange={handleAutocompleteChange(setValueEmpresa, "empresa_id")}
                      getOptionLabel={(option) => option.empresa}
                      renderInput={(params) => (
                        <TextField
                          error={formik.errors.empresa_id}
                          {...params}
                          label={dataModal.empresas.empresa}
                          style={{
                            color: "black",
                            width: "22vw",
                          }}
                          sx={{
                            ".css-1sumxir-MuiFormLabel-root-MuiInputLabel-root":
                            {
                              color: "black",
                            },
                          }}
                        />
                      )}
                    />
                  )
                }
              />
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemText
                primary="Interno"
                secondary={
                  <TextField
                    label={dataModal.interno}
                    sx={inputStyle}
                    InputProps={{
                      type: "number",
                    }}
                    value={formik.values.interno}
                    name="interno"
                    onChange={formik.handleChange}
                    error={formik.errors?.interno}
                    helperText={formik.errors?.interno}
                  />
                }
              />
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemText
                primary="Descuento"
                secondary={
                  <Box width="100%" marginLeft="0px" >  
                  <RadioGroup
                    name="descuento"
                    value={formik.values.descuento}
                      onChange={(event) => {
                        formik.setFieldValue('descuento', Number(event.target.value));
                        setShowDiscountInput(Number(event.target.value) === 1);
                        if (Number(event.target.value) === 0) {
                          formik.setFieldValue('valor_descuento', 0);
                        }
                      }}
                  >
                    <FormControlLabel value={0} control={<Radio />} label="No" />
                    <FormControlLabel value={1} control={<Radio />} label="Sí" />
                  </RadioGroup>

                  {showDiscountInput && (
                    <TextField
                      name="valor_descuento" 
                      label="% de descuento"
                      type="number"
                      value={formik.values.descuento === 1 ? formik.values.valor_descuento : 0} 
                      onChange={formik.handleChange}
                    />
                  )}
                  </Box>
                }
              />
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemText
                primary="Servicio"
                secondary={
                  <RadioGroup
                    row
                    aria-labelledby="Estado"
                    defaultValue={null}
                    sx={inputStyle}
                    fullWidth
                    name="servicios_id"
                    value={formik.values.servicios_id}
                    error={formik.errors?.servicios_id}
                    helperText={formik.errors?.servicios_id ? "Campo requerido" : null}
                    onChange={formik.handleChange}
                  >
                    <FormControlLabel
                      value={2}
                      control={<Radio />}
                      sx={{ color: "black" }}
                      label="Larga Distancia"
                    />
                    <FormControlLabel
                      value={1}
                      control={<Radio />}
                      sx={{ color: "black" }}
                      label="Media Distancia"
                    />
                    <FormControlLabel
                      value={3}
                      control={<Radio />}
                      sx={{ color: "black" }}
                      label="Corta Distancia"
                    />
                    <FormControlLabel
                      value={4}
                      control={<Radio />}
                      sx={{ color: "black" }}
                      label="Servicio Externo"
                    />
                  </RadioGroup>
                }
              />
            </ListItem>
            <Divider />
            <Box mt={2}>
              <Button
                autoFocus
                variant="contained"
                color="primary"
                type="submit"
                sx={{ ml: { xs: 34, sm: 82, md: 113, lg: 165 } }}
              >
                Guardar
              </Button>
            </Box>
            <Box mt={2} />
          </List>
        </form>
      </Dialog>
    </>
  );
}
