import React, { useState } from "react";
import axios from "axios";

import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

const MONTHS = [
  "Enero",
  "Febrero",
  "Marzo",
  "Abril",
  "Mayo",
  "Junio",
  "Julio",
  "Agosto",
  "Septiembre",
  "Octubre",
  "Noviembre",
  "Diciembre",
];

export function months(config) {
  var cfg = config || {};
  var count = cfg.count || 12;
  var currentMonth = new Date().getMonth();
  var currentYear = new Date().getFullYear();
  var values = [];

  for (var i = 0; i < count; ++i) {
    var monthIndex = (currentMonth - i + 12) % 12;
    var yearOffset = i > currentMonth ? -1 : 0;
    var year = currentYear + yearOffset;
    var monthName = MONTHS[monthIndex] + " " + year;
    values.unshift(monthName);
  }

  return values;
}


const dateNow = new Date();
const menosUno = 1;
dateNow.setHours(menosUno);
const year = dateNow.getFullYear();
const monthWithOffset = dateNow.getUTCMonth() + 1;
const month =
  monthWithOffset.toString().length < 2
    ? `0${monthWithOffset}`
    : monthWithOffset;
const date =
  dateNow.getUTCDate().toString().length < 2
    ? `0${dateNow.getUTCDate()}`
    : dateNow.getUTCDate();



export default function BarsChartMonth({dateFrom, dateTo}) {
  const [monthData, setMonthData] = useState({
    larga: [],
    media: [],
    corta: [],
    otro_servicio: [],
    total: [],
    fechas: [] // Aquí almacenarás las etiquetas del eje X
  });
  const [maxCount, setMaxCount] = useState();
  const [total, setTotal] = useState(0);

  const token = sessionStorage.getItem("jwt");
  React.useEffect(() => {
    const url = `https://terminaldigital.com.ar/api/graficas/meses?dateFrom=${dateFrom}&dateTo=${dateTo}`;

    axios
      .get(url)
      .then((response) => {
        setMonthData(response.data.registrosModificados);
        const flatArray = response.data.registrosModificados.total.flat();
        const totalSum = flatArray.reduce((sum, value) => sum + value, 0);
        setMaxCount(Math.max(...flatArray) + 10);
        setTotal(totalSum);
      })
      .catch((error) => console.log("error jwt:", error));

  }, [dateFrom, dateTo, token]);

  const midata = {
    labels: months({ count: 12 }),
    datasets: [
      {
        label: "Larga distancia",
        data: monthData.larga,
        backgroundColor: "rgba(255, 99, 132, 0.5)", // Rosa
      },
      {
        label: "Media distancia",
        data: monthData.media,
        backgroundColor: "rgba(255, 159, 64, 0.5)", // Naranja
      },
      {
        label: "Corta distancia",
        data: monthData.corta,
        backgroundColor: "rgba(75, 192, 192, 0.5)", // Verde azulado
      },
      {
        label: "Otro servicio",
        data: monthData.otro_servicio,
        backgroundColor: "rgba(153, 102, 255, 0.5)", // Púrpura
      },
    ],
  };

  var misoptions = {
    plugins: {
      title: {
        display: true,
        text: 'Total registros: ' + total // Asegúrate de que 'total' es el total mensual
      }
    },
    scales: {
      y: {
        min: 0,
        max: maxCount,
      },
      x: {
        ticks: { color: "rgb(0, 0, 0)" },
      },
    },
  };
  return <Bar data={midata} options={misoptions} />;
}
