
import { Box, CircularProgress } from "@mui/material";
import { Stack } from "@mui/material";
import axios from "axios";
import React, { useState, useEffect } from "react";
import TableArrivals from "../../components/table/TableArrivals";
import TableArrivalsHead from "../../components/table/TableArrivalsHead";
import "./ArrivalsBoard.styles.css";
import TicketCardArrivals from "../../components/ticketCards/TicketCardArrivals";
import MarketingModuleWeb from "../../components/MarketingModule/MarketingModuleWeb";
import sinRegistros from '../../assets/img/sinregistros-1.png'
import Pagination from '@mui/material/Pagination';


import Typography from "@mui/material/Typography";
import io from 'socket.io-client';

const style = {
  padding: "auto",
  margin: "0px",
  maxWidth: "100%",
  height: "auto",
  marginBottom: "auto",
  marginLeft: "1%",
};

const socket = io('https://terminaldigital.com.ar');

const ArrivalsBoard = () => {
  const [arribos, setArribos] = React.useState([]);
  const [logo, setLogo] = useState([]);
  const [page, setPage] = React.useState(1);
  const itemsPerPage = 5;

  const token = sessionStorage.getItem("jwt");
  
  const isLogged = token ? "true" : "false";

  const getArrivals = () => {
    axios
      .get("https://terminaldigital.com.ar/api/plataforma/arribos")
      .then((data) => {
        setArribos(data.data);
      })
      .catch((error) => {
        console.log("Error GET arrivals:", error);
        // Considera manejar este error de alguna manera
      });
  };

  const getLogoTerminal = () => {
    axios
      .get("https://terminaldigital.com.ar/api/administrarterminal")
      .then((data) => {
        setLogo(data.data.terminal.logo);
      })
      .catch((error) => {
        console.log("error users", error);
        // Considera manejar este error de alguna manera
      });
  };

  useEffect(() => {
    getArrivals();
    getLogoTerminal();

    socket.on('newRegisterArrives', ({ shouldRerenderArrives }) => {
      if (shouldRerenderArrives) {
        getArrivals();
      }
    });
    socket.on('connect', () => {
      console.log('Conectado al servidor');
    });
    return () => {
      socket.off('newRegisterArrives');
    };
  }, []);

  const handleChange = (event, value) => {
    setPage(value);
  };

  useEffect(() => {
    const totalPages = Math.ceil(arribos.length / itemsPerPage);
    const timer = setInterval(() => {
      setPage((prevPage) => {
        if (prevPage >= totalPages) {
          return 1; 
        } else {
          return prevPage + 1; 
        }
      });
    }, 10000); 
    return () => {
      clearInterval(timer);
    };
  }, [arribos, itemsPerPage]);

  return (
    <div className="containerBoard">
      <div>
        {!arribos && (
          <div className="centeredContent" style={{ height: "40vh" }}>
            <CircularProgress />
          </div>
        )}
        {arribos?.length === 0 && (
          <>
            <Box display={{ xs: "none", sm: "block" }} sx={{ minHeight: { xs: "1vh", md: "16vh" }, maxHeight: { xs: "1vh", md: "16vh" }, overflow: "auto" }}>
              <TableArrivalsHead />
            </Box>
            <Typography
              fontWeight="bold"
              textAlign="center"
              fontSize={{ xs: "3vh", sm: "5rem" }}
              margin={{ xs: "0", sm: "5%" }}
              sx={{ fontFamily: "Roboto" }}
              backgroundColor="#2267c8"
              borderRadius={{ xs: "0", sm: "2.5vh" }}
              position={"sticky"}
              top="0"
              right="0"
              left="0"
              color="white"
              textTransform=" uppercase"
              width={"100%"}
              display={{ xs: "block", sm: "none" }}
            >
              ARRIBOS
            </Typography>
            <Box className="centeredContent" sx={{
              minHeight: isLogged === "false" ? window.innerWidth <= 768 ? "1vh" : "55.5vh" : window.innerWidth <= 768 ? "68vh" : "58vh"
            }}>
              <img
                style={{ maxWidth: "20vw", ...style }}
                src={sinRegistros}
                alt={sinRegistros}
              />
            </Box>
          </>
        )}
        {arribos?.length > 0 && (
          <>
            <Box display={{ xs: "none", sm: "block" }} style={{ minHeight: "71.5vh", maxHeight: "71.5vh", overflow: "auto" }}>
              <Typography
                fontWeight="bold"
                textAlign="center"
                fontSize={{ sm: "1rem", md: "2rem" }}
                margin={{ xs: "0", sm: "0%" }}
                sx={{ fontFamily: "Roboto" }}
                backgroundColor="#2267c8"
                position={"sticky"}
                top="0"
                right="0"
                left="0"
                color="white"
                textTransform=" uppercase"
                width={{ sm: "216.1%", md: "222.3%", lg: "100vw" }}
                display={{xxl:"none"}}
              >
                ARRIBOS
              </Typography>
              <TableArrivals props={arribos.slice((page - 1) * itemsPerPage, page * itemsPerPage)} />
            </Box>
            <Box display={{ xs: "block", sm: "none" }} style={{ minHeight: "87vh", maxHeight: "87vh", overflow: "auto" }}>
              <TicketCardArrivals props={arribos.slice((page - 1) * itemsPerPage, page * itemsPerPage)} />
            </Box>
            <Pagination count={Math.ceil(arribos.length / itemsPerPage)} page={page} onChange={handleChange} style={{ display: "none" }} />
          </>
        )}
        <Box sx={{
          minHeight: "18vh",
          maxHeight: "18vh",
          display: { xs: "none", sm: "block" }
        }}>
        <MarketingModuleWeb />
        </Box>
      </div>
    </div>
  );
};

export default ArrivalsBoard;
