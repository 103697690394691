import React, { useState, useEffect } from "react";
import axios from "axios";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

const dateNow = new Date();
const hour = dateNow.getHours() + 1;

const HOUR = [
  "00:00",
  "01:00",
  "02:00",
  "03:00",
  "04:00",
  "05:00",
  "06:00",
  "07:00",
  "08:00",
  "09:00",
  "10:00",
  "11:00",
  "12:00",
  "13:00",
  "14:00",
  "15:00",
  "16:00",
  "17:00",
  "18:00",
  "19:00",
  "20:00",
  "21:00",
  "22:00",
  "23:00",
];

export function hours(config) {
  var cfg = config || {};
  var count = cfg.count || 24;
  var section = cfg.section;
  var values = [];
  var i, value;

  for (i = 0; i < count; ++i) {
    value = HOUR[Math.ceil(i) % 24];
    values.push(value.substring(0, section));
  }

  return values;
}

export default function LinesChartDayHours({dateFrom,dateTo,empresa}) {
  const [dayData, setMonth] = useState([]);
  const [maxCount, setMaxCount] = useState();
  const [maxHour, setMaxHour] = useState();
  const [totalIngresos, setTotalIngresos] = useState();

  const token = sessionStorage.getItem("jwt");
  
  useEffect(() => {
    const url = `https://terminaldigital.com.ar/api/graficas/dia?dateFrom=${dateFrom}&dateTo=${dateTo}&empresa_id=${empresa}`;
    axios
      .get(url)
      .then((response) => {
        setMonth(response.data.registrosModificados);
        setMaxCount(Math.max(...response.data.max) + 0.5);
        if (response.data.registrosModificados[0]) {
          let maxIndex = response.data.registrosModificados[0].reduce((iMax, x, i, arr) => x > arr[iMax] ? i : iMax, 0);
          setMaxHour(HOUR[maxIndex]);
          let totalIngresos = response.data.registrosModificados[4].reduce((a, b) => a + b, 0);
          setTotalIngresos(totalIngresos);
        }
      })
      .catch((error) => console.log("error jwt:", error));
  }, [dateFrom, dateTo, empresa, token]);

  var midata = {
    labels: HOUR,
    datasets: [
      {
        label: "Larga distancia",
        data: dayData[0],
        tension: 0.1,
        borderColor: "rgb(255, 99, 132)",
        pointRadius: 4,
        pointBorderColor: "rgba(255, 99, 132)",
        pointBackgroundColor: "rgba(255, 99, 132)",
      },
      {
        label: "Mediana distancia",
        data: dayData[1],
        tension: 0.1,
        borderColor: "rgb(255, 159, 64)",
        pointRadius: 4,
        pointBorderColor: "rgba(255, 159, 64)",
        pointBackgroundColor: "rgba(255, 159, 64)",
      },
      {
        label: "Corta distancia",
        data: dayData[2],
        tension: 0.1,
        borderColor: "rgb(75, 192, 192)",
        pointRadius: 4,
        pointBorderColor: "rgba(75, 192, 192)",
        pointBackgroundColor: "rgba(75, 192, 192)",
      },
      {
        label: "Otro servicio",
        data: dayData[3],
        tension: 0.1,
        borderColor: "rgb(153, 102, 255)",
        pointRadius: 4,
        pointBorderColor: "rgba(153, 102, 255)",
        pointBackgroundColor: "rgba(153, 102, 255)",
      },
    ],
  };

  var misoptions = {
    plugins: {
      title: {
        display: true,
        text: maxHour ? `${maxHour}hs el horario con mayor registro de ingresos - (Total: ${totalIngresos})` : '',
        font: {
          weight: 'bold'
        }
      },
    },
    scales: {
      y: {
        min: 0,
        max: maxCount,
      },
      x: {
        ticks: { color: "rgb(0, 0, 0)" },
      },
    },
  };
  
  return (
    <Line data={midata} options={misoptions} />
    //Crear el elemento para mostrar los formularios
  );
}