import React, { useState, useEffect } from "react";
import axios from "axios";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import { Box } from "@mui/material";
import { Link } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import Divider from "@mui/material/Divider";
import { Stack } from "@mui/system";
import jwt_decode from "jwt-decode";

const AdminGetPay = () => {
  const [configuracion, setConfiguracion] = useState([]);
  const [usuario, setUsuario] = useState([]);
  const [userInfo, setUserInfo] = React.useState({});
  const token = window.sessionStorage.getItem("jwt");

  useEffect(() => {
    if (token) {
      const tokenDecoded = jwt_decode(token);
      const { rol } = tokenDecoded;
      setUserInfo((state) => ({ ...state,  rol }));
    }
  }, [token]);
  useEffect(() => {
    const token = window.sessionStorage.getItem("jwt");
    const url = `https://terminaldigital.com.ar/api/sictranscore/ObtenerConfiguracionCobro`;
    const config = { headers: { authorization: `Bearer ${token}` } };

    axios
      .get(url, config)
      .then((data) => {
        setConfiguracion(data.data.getConfig);
        setUsuario(data.data.getConfig.usuario_id.nombre);
      })
      .catch((error) => console.log("error supervisor home", error));
  }, []);

  return (
    <Stack sx={{ background: "#0e315a", height: "73vh" }}>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <TableContainer
        component={Paper}
        sx={{ width: "98%", margin: "auto", borderRadius: "30px" }}
      >
        <br></br>
        <br></br>
        <Divider
          sx={{
            fontSize: { xs: "1rem", md: "2rem" },
            fontFamily: 'Lato, sans-serif',
            color: '#0e315a',
            textShadow: '2px 2px 2px rgba(0, 0, 0, 0.25)',
            display: 'flex',
            justifyContent: 'start',
            alignItems: 'center',
            margin: 3,
            ml: 0
          }}
          textAlign="left"
        >
          Valores actuales de cobro para sistema Telepeaje
        </Divider>
        <br></br>
        <Box px={2} display={{ xs: "none", sm: "block" }}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table" id="informes">
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    fontSize: { xs: "0.81rem", md: "1rem" },
                  }}
                  align="center"
                >
                  TOLERANCIA
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: { xs: "0.81rem", md: "1rem" },
                  }}
                  align="center"
                >
                  VALOR BÁSICO
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: { xs: "0.81rem", md: "1rem" },
                  }}
                  align="center"
                >
                  VALOR EXCEDENTE
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: { xs: "0.81rem", md: "1rem" },
                  }}
                  align="center"
                >
                  DESCUENTO
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: { xs: "0.81rem", md: "1rem" },
                  }}
                  align="center"
                >
                  ÚLTIMA MODIFICACIÓN
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: { xs: "0.81rem", md: "1rem" },
                  }}
                  align="center"
                >
                  Editar
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow
                key={configuracion.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell
                  sx={{
                    fontSize: { xs: "0.75rem", md: "0.81rem" },
                  }}
                  align="center"
                >
                  {
                    (() => {
                      const tolerancia = configuracion.tolerancia;
                      let valor;
                      let unidad;
                      if (tolerancia >= 2 && tolerancia <= 59) {
                        valor = tolerancia;
                        unidad = 'MINUTOS';
                      } else if (tolerancia == 1) {
                        valor = tolerancia;
                        unidad = 'MINUTO';
                      } else if (tolerancia == 60) {
                        valor = 1;
                        unidad = 'HORA';
                      } else if (tolerancia > 60) {
                        valor = tolerancia / 60;
                        unidad = 'HORAS';
                      }

                      return `${valor} ${unidad}`;
                    })()
                  }
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: { xs: "0.75rem", md: "0.81rem" },
                  }}
                  align="center"
                >
                  ${configuracion.valor_basico} (ARG)
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: { xs: "10px", md: "13px" },
                  }}
                  align="center"
                >
                  ${configuracion.valor_excedente} (ARG) por minuto
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: { xs: "0.75rem", md: "0.81rem" },
                  }}
                  align="center"
                >
                  ${configuracion.descuento_diferencial} (ARG)
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: { xs: "0.75rem", md: "0.81rem" },
                  }}
                  align="center"
                >
                  {" "}
                  {usuario}
                </TableCell>
                {userInfo.rol === 1 ? (
                <TableCell align="center">
                  <Link to={`/supervisor/editarConfiguracionValoresTelepase`}>
                    {" "}
                    <EditIcon />
                  </Link>
                  </TableCell>) : (
                    <TableCell align="center">
                      <Typography variant="body1" color="error">
                        Sin permiso de edición
                      </Typography> 
                    </TableCell>
                  )}
              </TableRow>
            </TableBody>
            <TableBody></TableBody>
          </Table>
          <br></br>
          <br></br>
        </Box>
        <Box px={2} display={{ xs: "block", sm: "none" }}>
          <Table sx={{ minWidth: { xs: 300, sm: 650 } }} aria-label="simple table" id="informes">
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    fontSize: { xs: "0.81rem", md: "1rem" },
                  }}
                  align="center"
                >
                  TOLERANCIA
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: { xs: "0.81rem", md: "1rem" },
                  }}
                  align="center"
                >
                  VALOR BÁSICO
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow
                key={configuracion.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell
                  sx={{
                    fontSize: { xs: "0.75rem", md: "0.81rem" },
                  }}
                  align="center"
                >
                  {configuracion.tolerancia > 10000
                    ? `${configuracion.tolerancia / 10000} HORAS`
                    : configuracion.tolerancia === 10000
                      ? `${configuracion.tolerancia / 10000} HORA`
                      : configuracion.tolerancia === 100
                        ? `${configuracion.tolerancia / 100} MINUTO`
                        : `${configuracion.tolerancia / 100} MINUTOS`}
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: { xs: "0.75rem", md: "0.81rem" },
                  }}
                  align="center"
                >
                  ${configuracion.valor_basico} (ARG)
                </TableCell>
              </TableRow>
            </TableBody>
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    fontSize: { xs: "0.81rem", md: "1rem" },
                    width: "25%",
                  }}
                  align="center"
                >
                  DESCUENTO
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: { xs: "0.81rem", md: "1rem" },
                  }}
                  align="center"
                >
                  ÚLTIMA MODIFICACIÓN
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow
                key={configuracion.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell
                  sx={{
                    fontSize: { xs: "0.75rem", md: "0.81rem" },
                  }}
                  align="center"
                >
                  ${configuracion.descuento_diferencial} (ARG)
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: { xs: "0.75rem", md: "0.81rem" },
                  }}
                  align="center"
                >
                  {" "}
                  {usuario}
                </TableCell>
              </TableRow>
            </TableBody>
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    fontSize: { xs: "0.81rem", md: "1rem" },
                    width: "50%",
                  }}
                  align="center"
                >
                  VALOR EXCEDENTE
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: { xs: "0.81rem", md: "1rem" },
                  }}
                  align="center"
                >
                  EDITAR
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow
                key={configuracion.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell
                  sx={{
                    fontSize: { xs: "10px", md: "13px" },
                  }}
                  align="center"
                >
                  ${configuracion.valor_excedente} (ARG) por minuto
                </TableCell>
                <TableCell align="center">
                  <Link to={`/supervisor/editarConfiguracionValoresTelepase`}>
                    {" "}
                    <EditIcon />
                  </Link>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <br></br>
          <br></br>
        </Box>
      </TableContainer>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
    </Stack>
  );
};

export default AdminGetPay;
