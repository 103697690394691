import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Card, CardContent, Typography, Box } from '@material-ui/core';
import WbSunnyIcon from '@material-ui/icons/WbSunny';
import CloudIcon from '@material-ui/icons/Cloud';
import DeviceThermostatIcon from '@mui/icons-material/DeviceThermostat';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import WaterDropIcon from '@mui/icons-material/WaterDrop';
import AirIcon from '@mui/icons-material/Air';
import { makeStyles } from '@material-ui/core/styles';

const Snow = "https://iconos8.es/icon/15356/nieve";

const useStyles = makeStyles({
  rotate: {
    transform: 'rotate(0deg)',
  },
  card: {
    width: '20vw', // Ajusta el ancho de la tarjeta al 80% del contenedor
    height: '80%', // Ajusta la altura de la tarjeta al 80% del contenedor
    margin: 'auto', // Centra la tarjeta horizontalmente
    display: 'flex', // Usa Flexbox para el diseño
    flexDirection: 'row', // Alinea los elementos verticalmente
    justifyContent: 'center', // Centra los elementos verticalmente
    alignItems: 'center', // Centra los elementos horizontalmente
  },
});

const Weather = () => {
  const [weather, setWeather] = useState(null);
  const [lang, setLang] = useState('es');
  const classes = useStyles();
  const [dataTerminal, setDataTerminal] = useState([]);

  const apiKey = '2a356197b80662f982f961726354b038';

  const getDataTerminal = async () => {
    axios
      .get("https://terminaldigital.com.ar/api/administrarterminal")
      .then(async (data) => {
        setDataTerminal(data.data.terminal.provincia);
        const encodedLocation = encodeURIComponent(data.data.terminal.provincia);
        try {
          const response = await axios.get(`https://api.openweathermap.org/data/2.5/weather?q=${encodedLocation}&units=metric&lang=${lang}&APPID=${apiKey}`);
          const weatherData = response.data;
          weatherData.main.temp = Math.round(weatherData.main.temp);
          setWeather(weatherData);
        } catch (error) {
          console.error('Error fetching weather data:', error);
        }
      })
      .catch((error) => console.log("error users", error));
  };

  useEffect(() => {
    getDataTerminal();
  }, []);
  

  const getWeatherIcon = (weather) => {
    let IconComponent;
    let iconColor;
    switch (weather) {
      case 'Clear':
        IconComponent = WbSunnyIcon;
        iconColor = '#fecc00eb'; // amarillo para clima soleado
        break;
      case 'Clouds':
        IconComponent = CloudIcon;
        iconColor = '#d3d3d3'; // gris para clima nublado
        break;
      case 'Snow':
        IconComponent = AcUnitIcon;
        iconColor = '#5AABEC'; // blanco para clima nevado
        break;
      case 'Rain':
        IconComponent = WaterDropIcon;
        iconColor = '#5AABEC'; // blanco para clima lluvioso
        break;
      case 'Wind':
        IconComponent = AirIcon;
        iconColor = '#deb887'; // blanco para clima ventoso
        break;
      default:
        IconComponent = DeviceThermostatIcon;
        iconColor = '#fff'; // blanco por defecto
    }

    return <IconComponent className={classes.rotate} style={{ color: iconColor }} />;
  };

  const getCardStyles = () => {
    const borderColor = '#d3d3d3';
    const boxShadowColor = 'rgba(211, 211, 211, 0.5)';

    return {
      border: `3px solid ${borderColor}`,
      boxShadow: `inset 0 0 10px ${boxShadowColor}, 0 0 10px ${boxShadowColor}`, 
    };
  };

  const capitalizeFirstLetterOfEachWord = (string) => {
    return string.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
  };

  return (
    <Card className={weather ? classes.card : ''} style={weather ? getCardStyles(weather.weather[0].main) : {}}>
      <CardContent style={{ padding: '8px' }}>
        {weather ? (
          <>
            <Box display="flex" alignItems="center" justifyContent="center" flexDirection="row">
              <Typography variant="h6" component="h2" style={{ marginRight: "10px" }}>
                {`${weather.main.temp}°C`}
              </Typography>
              <Typography color="textSecondary" style={{ marginRight: '10px', color: 'black' }}>
                {` - ${capitalizeFirstLetterOfEachWord(weather.weather[0].description)}`}
              </Typography>
              {getWeatherIcon(weather.weather[0].main)}
            </Box>
          </>
        ) : (
          'Cargando datos del clima...'
        )}
      </CardContent>
    </Card>
  );
};

export default Weather;