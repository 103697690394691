import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, Button, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import SettingsIcon from '@mui/icons-material/Settings';
import DeleteIcon from '@mui/icons-material/Delete';
import Modal from '@mui/material/Modal';
import axios from 'axios';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { toast } from 'react-toastify';




export default function TableAdminCompanies({ data }) {

  const [open, setOpen] = React.useState(false);
  const [openSuccessModal, setOpenSuccessModal] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleOpenSuccess = () => setOpenSuccessModal(true);
  const handleCloseSuccess = () => setOpenSuccessModal(false);
  const [dataModal, setDataModal] = React.useState({})
  const [dialogOpen, setDialogOpen] = useState(false);

  const day = `Registro de Listado de Empresas ${new Date().toJSON().slice(0, 10)}`;


  const styleModal = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 250,
    height: 400,
    bgcolor: 'white',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    borderRadius: 5
  };

  const editThis = (data) => {
    handleOpen();
    setDataModal(data)
  }

  const closeEverything = () => {
    handleClose();
    handleCloseSuccess()
  }

  const deleteCompany = (id) => {
    const url = `https://terminaldigital.com.ar/api/empresas/${id}`;
    const config = { headers: { "authorization": `Bearer ${sessionStorage.getItem('jwt')}` } };

    axios.delete(url, config)
      .then(res => {
        toast.success('Empresa eliminada exitosamente');
        setOpen(false);
      })
      .catch(err => console.log('Error delete:', err))
  }

  return (
    <>
      <TableContainer
        component={Paper}
        sx={{ width: "98%", margin: "auto", borderRadius: "7px", maxWidth: '100%', boxShadow: 3 }}
      >
        <Box px={2}>
          <Box>
            <Typography sx={{
              fontSize: {xs:"1.5rem", lg: "2rem" },
              fontFamily: 'Lato, sans-serif',
              color: '#0e315a',
              textShadow: '2px 2px 2px rgba(0, 0, 0, 0.25)',
              display: 'flex',
              justifyContent: 'start',
              alignItems: 'center',
              margin: 5,
              ml: 1
            }} >
              Listado de Empresas
            </Typography>
            <Box pl={{xs: 0, md:2.5}} display="flex" justifyContent="flex-end" width="100%" backgroundColor="withe" >
              <Link to="/supervisor/empresas/crear">
                <Button
                  sx={{
                    my: 2,
                    color: "primary",
                    fontSize: "0.80rem",
                    width: { xs: "40vw", sm: "20%", lg: "100%" },
                    ml: { xs: "-50%", sm: "0%", md: "-30%" },
                    mt: { xs: "-10%", sm: "0%", md: "-100%" },
                    mb: { xs: "0%", sm: "0%", md: "0%" },
                  }}
                  variant="contained"
                >
                  Crear Empresa
                </Button>
              </Link>
            </Box>
          </Box>
          <Table sx={{ minWidth: 650 }} aria-label="simple table" id="informes">
            <TableHead>
              <TableRow>
                <TableCell align="center">Nombre</TableCell>
                <TableCell align="center">Siglas</TableCell>
                <TableCell align="center">Imagen</TableCell>
                <TableCell align="center">CUIT</TableCell>
                <TableCell align="center">Tipo de servicio</TableCell>
                <TableCell align="center">Contacto</TableCell>
                <TableCell align="center">Email</TableCell>
                <TableCell align="center">Ubicación en terminal</TableCell>
                <TableCell align="center">Horario de atención</TableCell>
                <TableCell align="center">Eliminar</TableCell>
                <TableCell align="center">Editar</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.length > 0 &&
                data.map((row) => (
                  <>
                    <TableRow
                      key={row.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell align="center">{row.empresa}</TableCell>
                      <TableCell component="th" scope="row" align="center">
                        {row.siglas}
                      </TableCell>
                      <TableCell align="center">
                        <Box
                          component="img"
                          sx={{
                            height: 60,
                            width: 200,
                            maxHeight: { xs: 233, md: 167 },
                            maxWidth: { xs: 350, md: 250 },
                          }}
                          alt={row.img}
                          src={row.img}
                        />
                      </TableCell>
                      <TableCell align="center">{row.cuit}</TableCell>
                      <TableCell align="center">
                        {row.servicio.tipo_servicio}
                      </TableCell>
                      <TableCell align="center">{row.telefono}</TableCell>
                      <TableCell align="center">{row.email}</TableCell>
                      <TableCell align="center">
                        {row.ubicacion_en_terminal}
                      </TableCell>
                      <TableCell align="center">
                        {row.horario_atencion}
                      </TableCell>
                      <TableCell align="center">
                        <DeleteIcon
                          sx={{ cursor: "pointer" }}
                          onClick={() => editThis(row)}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <Link to={`/supervisor/empresas/editar/${row.id}`}>
                          {" "}
                          <SettingsIcon />{" "}
                        </Link>
                      </TableCell>
                    </TableRow>
                  </>
                ))}
            </TableBody>
          </Table>
        </Box>
        <br />
        <br />
        <br />
      </TableContainer>

      {/* MODAL DE CONFIRMACIÓN DE ELIMINACIÓN DE EMPRESA */}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
      >
        <DialogTitle id="dialog-title" sx={{
          fontFamily: 'Lato, sans-serif',
          color: '#0e315a',
          textShadow: '2px 2px 2px rgba(0, 0, 0, 0.25)',
          fontSize: { lg: "1.5rem" },
        }}>Vas a eliminar:</DialogTitle>
        <DialogContent>
          <Typography sx={{
            fontFamily: 'Lato, sans-serif',
            color: '#000000',
          }}>Empresa: {dataModal.empresa}</Typography>
          <Typography id="dialog-description" sx={{
            mt: 2, fontFamily: 'Lato, sans-serif',
            color: '#000000', }}>
            CUIT: {dataModal.cuit}
          </Typography>
          <Typography id="dialog-description" sx={{
            mt: 2, fontFamily: 'Lato, sans-serif',
            color: '#000000', }}>
            SIGLAS: {dataModal.siglas}
          </Typography>
          <img
            src={dataModal.img}
            alt={dataModal.empresa}
            margin="auto"
            width="100%"
            height="45%"
            alignItems="center"
          />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" textColor="white" onClick={handleClose} color='secondary' startIcon={<CancelIcon />} >
            Cancelar
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => deleteCompany(dataModal.id)}
            startIcon={<CheckCircleIcon />}
            sx={{ marginLeft: "20px" }}
          >
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
