import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./login.css";
import ModalRememberLogin from "../../components/modals/ModalRememberLogin";
import LoginForm from "../../components/forms/FormLogin";
import axios from "axios";
import { Button, Typography } from "@mui/material";
import { toast } from "react-toastify";
import ImagBus from "../../assets/img/cardCompany.png";


const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [openModalRemember, setOpenModalRemember] = useState(false);

  //Eye to see the password
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const navigate = useNavigate();

  const handleServerError = (error) => {
    if (error.response.status === 401) {
      toast.warning("Usuario inhabilitada, contacta a recursos humanos");
    } else {
      if (error.response.data.message === "contraseña incorrecta") {
        toast.error("Verifica tus datos por favor");
      } else {
        toast.error("Verifica tus datos por favor");
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (email === "" || password === "") {
      toast.error("Por favor, rellena todos los campos");
      return;
    }

    const url = "https://terminaldigital.com.ar/api/auth/login";
    const data = { email, password };

    axios
      .post(url, data)
      .then((res) => {
        if (res.status === 200) {
          const jwt = res.data;
          window.sessionStorage.setItem("jwt", jwt);
          navigate("/ingreso");
        }
      })
      .catch(handleServerError);
  };

  return (
    <>
      <div className="container" style={{ 
        backgroundImage: `url(${ImagBus})`,
        backgroundSize: 'cover', 
        backgroundRepeat: 'no-repeat', 
        backgroundPosition: 'center' 
      }}>
        <div className="login">
          <Typography
            className="azul bienvenido"
            variant="h5"
            fontWeight="bold"
            sx={{ marginBottom: "16px" }}
          >
            Bienvenido
          </Typography>
          <LoginForm
            email={email}
            setEmail={setEmail}
            password={password}
            setPassword={setPassword}
            handleSubmit={handleSubmit}
            handleClickShowPassword={handleClickShowPassword}
            showPassword={showPassword}
            setOpenModalRemember={setOpenModalRemember}
          />
          {openModalRemember ? (
            <ModalRememberLogin
              title=""
              message="Debes contactar a recursos humanos para recuperar tu clave"
              openModal={openModalRemember}
            />
          ) : null}
        </div>
      </div>
    </>
  );
};

export default Login;
