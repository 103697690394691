import { Box, Typography } from "@mui/material";
import axios from "axios";
import React from "react";
import TableAdminCompanies from "../../components/table/TableAdminCompanies";

const AdminCompaniesList = () => {
  const [companies, setCompanies] = React.useState([]);

  const token = sessionStorage.getItem("jwt");
  const config = { headers: { authorization: `Bearer ${token}` } };

  const getCompanies = async () => {
    axios
      .get("https://terminaldigital.com.ar/api/empresas/listado", config)
      .then((data) => {
        setCompanies(data.data.empresas);
      })
      .catch((error) => console.log("error users", error));
  };

  React.useEffect(() => {
    getCompanies();
  }, []);

  const style = {
    background: "#0e315a",
    color: "white",
    minWidth: "100%",
    paddingBlock: "2vh",
    textAlign: "center",
    
  };

  return (
    <Box px={3} pb={4} py={4} sx={{ background: "#0e315a" }}>
      <Box
        sx={{
          minHeight: { xs: "59vh", sm: "65vh", md: "48vh", lg: "46vh" },
        }}
      >
        <TableAdminCompanies data={companies} />
      </Box>
    </Box>
  );
};

export default AdminCompaniesList;
