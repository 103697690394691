import React from 'react'
import ResetPassword from '../../components/forms/FormResetPassword'

const PageResetPassword = () => {

    return (
        <ResetPassword />
    )   
}

export default PageResetPassword