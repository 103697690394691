import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import Divider from "@mui/material/Divider";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import MarkdownPreview from "../../app/MarkdownPreview.js";


export default function TableAdminTerminal({ dataTerminal }) {

  return (
    <TableContainer
      component={Paper}
      sx={{ width: "98%", margin: "auto", borderRadius: "7px" }}
    >
      <Box sx={{display: "flex", justifyContent:"center" }}>
      <Typography
        sx={{
          fontSize: { xs: "1rem", md: "2rem" },
          fontFamily: 'Lato, sans-serif',
          color: '#0e315a',
          textShadow: '2px 2px 2px rgba(0, 0, 0, 0.25)',
          display: 'flex',
          justifyContent: 'start',
          alignItems: 'center',
          margin: 5,
          ml: 1
        }} >
        Datos de Terminal
      </Typography>
      </Box>
      <br></br>
      <Divider variant="h5" textAlign="left" mt={2}>
        <Typography fontWeight='bold' sx={{
          fontFamily: 'Lato, sans-serif',
          color: '#0e315a',
          textShadow: '2px 2px 2px rgba(0, 0, 0, 0.25)'}} >
          Información administrativa

        </Typography>
      </Divider>
      <br></br>
      <Box px={2}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table" id="informes">
          <TableHead>
            <TableRow>
              <TableCell align="left">Terminal</TableCell>
              <TableCell align="left">Representante</TableCell>
              <TableCell align="center">Contacto Tel 1</TableCell>
              <TableCell align="center">Contacto Tel 2</TableCell>
              <TableCell align="center">Contacto email Principal</TableCell>
              <TableCell align="center">Contacto email RrHh</TableCell>
              <TableCell align="center">Provincia</TableCell>
              <TableCell align="center">Editar</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow
              key={dataTerminal.id}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell align="left">{dataTerminal.nombre}</TableCell>
              <TableCell align="left">{dataTerminal.representante}</TableCell>
              <TableCell align="center">{dataTerminal.contacto_tel}</TableCell>
              <TableCell align="center">{dataTerminal.contacto_cel}</TableCell>
              <TableCell align="center">
                {dataTerminal.contacto_email_1}
              </TableCell>
              <TableCell align="center">
                {dataTerminal.contacto_email_2}
              </TableCell>
              <TableCell align="center">{dataTerminal.provincia}</TableCell>
              <TableCell align="center">
                <Link to={`/supervisor/datos-terminal/editar/IA`}>
                  {" "}
                  <EditIcon />
                </Link>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Box>
      <br></br>
      <Divider variant="h5" textAlign="left" mt={4}>
        <Typography fontWeight='bold' sx={{
          fontFamily: 'Lato, sans-serif',
          color: '#0e315a',
          textShadow: '2px 2px 2px rgba(0, 0, 0, 0.25)'}} >
          Redes sociales{" "}
        </Typography>
      </Divider>
      <br></br>
      <Box px={1}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table" id="informes">
          <TableHead>
            <TableRow>
              <TableCell align="left">Facebook</TableCell>
              <TableCell align="left">Instagram</TableCell>
              <TableCell align="left">Editar</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow
              key={dataTerminal.id}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell align="left">
                <a
                  href={dataTerminal.facebook}
                  rel="noreferrer"
                  target="_blank"
                >
                  {dataTerminal.facebook}
                </a>
              </TableCell>
              <TableCell align="left">
                <a
                  href={dataTerminal.instagram}
                  rel="noreferrer"
                  target="_blank"
                >
                  {dataTerminal.instagram}
                </a>
              </TableCell>
              <TableCell align="left">
                <Link to={`/supervisor/datos-terminal/editar/RS`}>
                  {" "}
                  <EditIcon />
                </Link>
              </TableCell>
            </TableRow>
          </TableBody>
          <TableHead>
            <TableRow>
              <TableCell align="left">XTwitter</TableCell>
              <TableCell align="left">Venta de pasajes</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow
              key={dataTerminal.id}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell align="left">
                <a href={dataTerminal.twitter} rel="noreferrer" target="_blank">
                  {dataTerminal.twitter}
                </a>
              </TableCell>
              <TableCell align="left">
                <a
                  href={dataTerminal.venta_pasajes}
                  rel="noreferrer"
                  target="_blank"
                >
                  {dataTerminal.venta_pasajes}
                </a>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Box>
      <br></br>
      <Divider variant="h5" textAlign="left" mt={2}>
        <Typography fontWeight='bold' sx={{
          fontFamily: 'Lato, sans-serif',
          color: '#0e315a',
          textShadow: '2px 2px 2px rgba(0, 0, 0, 0.25)'}} >
          Locación e institucional{" "}
        </Typography>
      </Divider>
      <br></br>
      <Box px={1}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table" id="informes">
          <TableHead>
            <TableRow>
              <TableCell align="left">Ubicación</TableCell>
              <TableCell align="left">Institucional</TableCell>
              <TableCell align="center">Editar</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow
              key={dataTerminal.id}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell align="left">
                <a
                  href={dataTerminal.como_llegar}
                  rel="noreferrer"
                  target="_blank"
                >
                  {" "}
                  <LocationOnIcon sx={{ color: "#b22222" }} /> Ubicación Maps
                </a>
              </TableCell>
              <TableCell align="left">
                <MarkdownPreview markdown={dataTerminal.institucional} />
              </TableCell>
              <TableCell align="center">
                <Link to={`/supervisor/datos-terminal/editar/LI`}>
                  {" "}
                  <EditIcon />
                </Link>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Box>
      <br></br>
      <Divider variant="h5" textAlign="left" mt={2}>
        <Typography fontWeight='bold' sx={{
          fontFamily: 'Lato, sans-serif',
          color: '#0e315a',
          textShadow: '2px 2px 2px rgba(0, 0, 0, 0.25)'}} >
          Logo institucional{" "}
        </Typography>
      </Divider>
      <br></br>
      <Box px={1}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table" id="informes">
          <TableBody>
            <TableRow
              key={dataTerminal.id}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell align="center" sx={{ borderRadius: "red" }}>
                <Box
                  component="img"
                  sx={{
                    borderRadiuscolor: "#b22222",
                    height: 200,
                    width: 300,
                    maxHeight: { xs: 233, md: 167 },
                    maxWidth: { xs: 350, md: 250 },
                  }}
                  alt={dataTerminal.logo}
                  src={dataTerminal.logo}
                />
              </TableCell>
              <TableCell align="left">
                <Link to={`/supervisor/datos-terminal/editar/Logo`}>
                  {" "}
                  <EditIcon />
                </Link>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Box>
    </TableContainer>
  );
}
