/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Button, Grid, MenuItem, Typography } from "@mui/material";
import { Stack } from "@mui/system";

import { useFormik } from "formik";
import * as yup from "yup";
import axios from "axios";
import { toast } from "react-toastify";
import FormField from "./FormField";

const validationSchema = yup.object({
  empresa: yup.string().required("Campo requerido"),
  siglas: yup.string().required("Campo requerido"),
  cuit: yup.string().required("Campo requerido"),
  servicio_id: yup.string().required("Campo requerido"),
});

const FormCompany = () => {
  const [servicio, setServicio] = useState([]);
  const [img, setImg] = useState(null);
  const token = sessionStorage.getItem("jwt");
  const config = { headers: { authorization: `Bearer ${token}` } };

  const getDropdown = () => {
    axios
      .all([axios.get("https://terminaldigital.com.ar/api/servicios/listado", config)])
      .then((data) => {
        setServicio(data[0].data.servicios);
      })
      .catch((error) => console.log("error users", error));
  };

  useEffect(() => {
    getDropdown();
  }, []);

  const setInitialCompany = () => {
    return {
      empresa: "",
      siglas: "",
      imagen: "",
      cuit: "",
      servicio_id: "",
      telefono: "",
      email: "",
      horario_atencion: "",
      ubicacion_en_terminal: "",
    };
  };

  const formik = useFormik({
    initialValues: setInitialCompany(),
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      const config = {
        headers: {
          authorization: `Bearer ${token}`,
        },
      };
      const url = `https://terminaldigital.com.ar/api/empresas/nueva`;
      const data = formik.values;

      const formData = new FormData();

      formData.append("imagen", img);
      formData.append("empresa", data.empresa);
      formData.append("siglas", data.siglas);
      formData.append("cuit", data.cuit);
      formData.append("servicio_id", data.servicio_id);
      formData.append("telefono", data.telefono);
      formData.append("email", data.email);
      formData.append("ubicacion_en_terminal", data.ubicacion_en_terminal);
      formData.append("horario_atencion", data.horario_atencion);

      axios
        .post(url, formData, config)
        .then((res) => {
          if (res.status === 200) {
            toast.success("Empresa creada con éxito.");
          }
        })
        .catch(function (error) {
          toast.error("Empresa creada con éxito." + String(error));
        });
    },
  });

  return (
    <Stack
      sx={{ background: "#F4F2EC", borderRadius: "25px", shadow: 4 }}
      my={4}
      mx={{ xs: 1, sm: 6 }}
      p={4}
      sm={6}
    >
      <form onSubmit={formik.handleSubmit}>
        <Typography
          sx={{
            fontSize: { xs: "1.5rem", md: "2rem" },
            fontFamily: 'Lato, sans-serif',
            color: '#0e315a',
            textShadow: '2px 2px 2px rgba(0, 0, 0, 0.25)',
            display: 'flex',
            justifyContent: 'start',
            alignItems: 'center',
            margin: 5,
            ml: 1
          }} >
          Crear nueva Empresa:
        </Typography>
        <Grid container my={2} gap={1}>
          <Grid
            item
            gap={2}
            xs={12}
            md={5}
            lg={4}
            my={2}
          >
            <FormField
              fullWidth
              label="Nombre"
              name="empresa"
              value={formik.values.empresa}
              onChange={formik.handleChange}
              error={formik.errors.empresa}
              helperText={formik.errors.empresa}
              />
          </Grid>
          <Grid
            item
            gap={2}
            xs={12}
            sm={12}
            md={6}
            lg={4}
            my={2}
          >
            <FormField
              fullWidth
              label="Siglas"
              name="siglas"
              value={formik.values.siglas}
              onChange={formik.handleChange}
              error={formik.errors.siglas}
              helperText={formik.errors.siglas}
            />
          </Grid>
          <Grid
            item
            gap={2}
            xs={12}
            sm={12}
            md={5}
            lg={3}
            my={2}
          >
            <FormField
              fullWidth
              label="CUIT"
              name="cuit"
              value={formik.values.cuit}
              onChange={formik.handleChange}
              error={formik.errors.cuit}
              helperText={formik.errors.cuit}
            />
          </Grid>
          <Grid
            item
            gap={2}
            xs={12}
            sm={12}
            md={6}
            lg={4}
            my={2}
          >
            <FormField
              label="Imagen"
              id="imagen"
              fullWidth
              name="imagen"
              type="file"
              accept="image/*"
              onChange={(event) => {
                console.log("setIMG", event.target.files[0]);
                setImg(event.target.files[0]);
              }}
              />
          </Grid>
          <Grid
            item
            gap={2}
            xs={12}
            sm={12}
            md={5}
            lg={4}
            my={2}
          >
            <FormField
              select
              fullWidth
              multiline
              label="Tipo de Serv: Larga, Media o corta distancia"
              name="servicio_id"
              value={formik.values.servicio_id}
              onChange={formik.handleChange}
              error={formik.errors.servicio_id}
              helperText={formik.errors.servicio_id}
            >
              {servicio?.map((servicio) => (
                <MenuItem
                  value={servicio.id}
                  key={servicio.tipo_servicio + servicio.id}
                  selected={true}
                >
                  {servicio.tipo_servicio}
                </MenuItem>
              ))}
            </FormField>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={3}
            my={2}
          >
           
            <FormField
              label="Teléfono de contacto para el público"
              name="telefono"
              fullWidth
              value={formik.values.telefono}
              onChange={formik.handleChange}
              error={formik.errors.telefono}
              helperText={formik.errors.telefono}
            />
          </Grid>
          <Grid
            item
            gap={2}
            xs={12}
            sm={12}
            md={5}
            lg={4}
            my={2}
          >
            <FormField
              fullWidth
              label="Email público"
              placeholder="informes@terminaldigital.com"
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.errors.email}
              helperText={formik.errors.email}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={4}
            my={2}
          >
            <FormField
              fullWidth
              label="Ubicación de la Terminal"
              placeholder="Ala Este, PB E-12"
              name="ubicacion_en_terminal"
              value={formik.values.ubicacion_en_terminal}
              onChange={formik.handleChange}
              error={formik.errors.ubicacion_en_terminal}
              helperText={formik.errors.ubicacion_en_terminal}
           />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={5}
            lg={3}
            my={2}
          >
            <FormField
              fullWidth
              label="Horarios"
              placeholder="Todos los días de 8.00hs a 21.00hs"
              name="horario_atencion"
              value={formik.values.horario_atencion}
              onChange={formik.handleChange}
              error={formik.errors.horario_atencion}
              helperText={formik.errors.horario_atencion}
            />
          </Grid>
          <Grid item sx={{ marginLeft: "auto" }} align="center" xs={12} pt={4}>
            <Button
              variant="contained"
              ml="auto"
              type="submit"
              my={2}
              sx={{ marginTop: { xs: "10px" } }}
            >
              Crear Empresa
            </Button>
            <br></br>
            <br></br>
            <Button
              onClick={() => formik.resetForm({ values: setInitialCompany() })}
              variant="text"
              ml="auto"
              type="submit"
              my={2}
              sx={{ marginTop: { xs: "10px" }, color: "black" }}
            >
              Limpiar Formulario
            </Button>
          </Grid>
        </Grid>
      </form>
    </Stack>
  );
};

export default FormCompany;
