import React from "react";
import { Route, Routes } from "react-router-dom";
import "./styles/App.css";
// css swiper
import "react-image-gallery/styles/css/image-gallery.css";
import ArrivalsBoardTV from "./pages/board-tv/ArrivalsBoardTV";
import DeparturesBoardTV from "./pages/board-tv/DeparturesBoardTV";
import InterurbanosBoardTV from "./pages/board-tv/InterurbanosBoardTV";
import AppWebRouter from "./AppWebRouter";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SoloPublicidadVerticalVideo from "./components/MarketingModule/SoloPublicidadVerticalVideo";
import SoloPublicidadHorizontalVideo from "./components/MarketingModule/SoloPublicidadHorizontalVideo";
import ArrivalsBoardTVHorizontal from "./pages/board-tv/ArrivalsBoardTVHorizontal";
import ArrivalsAndDeparturesBoardTV from "./pages/board-tv/ArrivalsAndDeparturesBoardTV";
import Brochure from "./pages/home/Brochure";
import { QueryClient, QueryClientProvider } from 'react-query';

const queryClient = new QueryClient();

function App() {
  return (
    <div className="App">
      <QueryClientProvider client={queryClient}>
        <Routes>
          <Route exact path="/tv-arribos-v" element={<ArrivalsBoardTV />} />
          <Route exact path="/tv-arribos-h" element={<ArrivalsBoardTVHorizontal />} />
          <Route exact path="/tv-partidas-v" element={<DeparturesBoardTV />} />
          <Route exact path="/tv-ambos-v" element={<ArrivalsAndDeparturesBoardTV />} />
          <Route exact path="/tv-interurbanos-v" element={<InterurbanosBoardTV />} />
          <Route path="/publicidad-vertical" element={<SoloPublicidadVerticalVideo />} />
          <Route path="/publicidad-horizontal" element={<SoloPublicidadHorizontalVideo />} />
          <Route path="/propuesta" element={<Brochure />} />



          <Route path="*" element={<AppWebRouter />} />
          {/* <Route component={AppWebRouter}/> */}
        </Routes>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
          className="toast-position"
        />
      </QueryClientProvider>
    </div>
  );
}

export default App;
