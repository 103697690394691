import React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Chart from '../CharJsGraphics/Lines/LinesChartDayHoursDashboard';
import PlatformsStatus from './PlatformsStatus';
import Cards from './Cards';


export default function Dashboard() {
  
  const defaultTheme = createTheme();

  const dateNow = new Date();
  const menosUno = 1;
  dateNow.setHours(menosUno);
  const year = dateNow.getFullYear();
  const monthWithOffset = dateNow.getUTCMonth() + 1;
  const month =
    monthWithOffset.toString().length < 2
      ? `0${monthWithOffset}`
      : monthWithOffset;
  const date =
    dateNow.getUTCDate().toString().length < 2
      ? `0${dateNow.getUTCDate()}`
      : dateNow.getUTCDate();

  const [open, setOpen] = React.useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <Container maxWidth="lg" sx={{ mt: 8 }}>
          <Grid container spacing={6}>
            {/* Cards */}
            <Cards />
            {/* Chart */}
            <Grid item xs={12} md={8} lg={8}>
              <Paper
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  overflow: 'auto',
                  justifyContent: 'flex-start', 
                  alignItems: 'center',
                  width: { xs:"100%", lg:'100%'},
                  height: { xs: "110vh", lg:"75vh"},
                }}
              >
                <Chart year={year} month={month} date={date} />
              </Paper>
            </Grid>
            {/* Recent Deposits */}
            <Grid item xs={12} md={4} lg={4}>
              <Paper
                sx={{
                  p: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  position: { xs: 'relative', sm: 'static' },
                  top: { xs: '-55%', sm: 'auto' },
                  height: { xs: "100%", lg:"90%"},
                  width: '100%',
                }}
              >
                <PlatformsStatus />
                {/*<Deposits />*/}
              </Paper>
            </Grid>
            {/* Recent Orders */}
            {/*<Grid item xs={12}>
              <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                <Orders />
              </Paper>
            </Grid>*/}
          </Grid>
        </Container>
      </Box>
    </ThemeProvider>
  );
}