import React, { useState, useEffect } from "react";
import {
  Box,
  Stack,
  Typography,
  FormControl,
  FormControlLabel,
  Radio,
  TextField,
  MenuItem,
  RadioGroup,
} from "@mui/material";
import { useFormik } from "formik";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LinesChartMonth from "../../components/CharJsGraphics/Lines/LinesChartMonth";
import BarsChartMonth from "../../components/CharJsGraphics/Bars/BarsChartMonth";
import LinesChartWeek from "../../components/CharJsGraphics/Lines/LinesChartWeek";
import BarsChartWeek from "../../components/CharJsGraphics/Bars/BarsChartWeek";
import LinesChartDayHours from "../../components/CharJsGraphics/Lines/LinesChartDayHours";
import BarsChartDayHours from "../../components/CharJsGraphics/Bars/BarsChartDayHours";
import axios from "axios";

const style = {
  background: "#0e315a",
  color: "white",
  ".MuiAccordionSummary-root .MuiSvgIcon-root": {
    color: "white",
    fontSize: { xs: "2rem", sm: "2.5rem", md: "3rem", lg: "3.5rem" },
  },
  "Mui-expanded": {
    margin: "0px",
  },
  "&:focus": {
    background: "#f5f5f5",
  },
};

const styleTitleAccordion = {
  fontSize: { xs: "15px", sm: "20px", md: "25px", lg: "30px" },
};

const Graphics = () => {
  const dateNow = new Date();
  const menosUno = 1;
  dateNow.setHours(menosUno);
  const year = dateNow.getFullYear();
  const monthWithOffset = dateNow.getUTCMonth() + 1;
  const month =
    monthWithOffset.toString().length < 2
      ? `0${monthWithOffset}`
      : monthWithOffset;
  const date =
    dateNow.getUTCDate().toString().length < 2
      ? `0${dateNow.getUTCDate()}`
      : dateNow.getUTCDate();

  const [radioValueMonth, setRadioValueMonth] = useState("lines");
  const [radioValueWeek, setRadioValueWeek] = useState("lines");
  const [radioValueHours, setRadioValueHours] = useState("lines");
  const [dateFrom, setDateFrom] = useState(`${year}-${month}-${date}`);
  const [dateTo, setDateTo] = useState(`${year}-${month}-${date}`);
  const [dataDropdown, setDataDropdown] = useState({ empresas: [] });
  const [empresa, setEmpresa] = useState('');

  const [expanded, setExpanded] = React.useState("panel1");
  const handleChangeExpand = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  function handleClickMonth(event) {
    setRadioValueMonth(event.target.value);
  }
  function handleClickWeek(event) {
    setRadioValueWeek(event.target.value);
  }
  function handleClickHours(event) {
    setRadioValueHours(event.target.value);
  }

  const handleDateChange = (event) => {
    const { name, value } = event.target;

    if (name === "dateFrom") {
      if (new Date(value) > new Date(dateTo)) {
        alert("Fecha desde no puede ser mayor que Fecha hasta");
        return;
      }
      setDateFrom(value);
    } else if (name === "dateTo") {
      if (new Date(value) < new Date(dateFrom)) {
        alert("Fecha hasta no puede ser menor que Fecha desde");
        return;
      }
      setDateTo(value);
    }
  };

  function handleEmpresaChange(event) {
    setEmpresa(event.target.value);
  }

  const token = sessionStorage.getItem("jwt");
  useEffect(() => {
    const url = "https://terminaldigital.com.ar/api/empresas/listado";
    axios
      .get(url, { headers: { authorization: `Bearer ${token}` } })
      .then((response) => {
        if (Array.isArray(response.data.empresas)) {
          setDataDropdown({ ...dataDropdown, empresas: response.data.empresas });
        }
      })
      .catch((error) => console.log("error:", error));
  }, [token]);

  useEffect(() => {
    const url = "https://terminaldigital.com.ar/api/graficas/ultima-fecha";
    axios
      .get(url, { headers: { authorization: `Bearer ${token}` } })
      .then((response) => {
        if (response.data && response.data.ultimaFecha) {
          setDateFrom(response.data.ultimaFecha);
        }
      })
      .catch((error) => console.log("error:", error));
  }, [token]);

  return (
    <Box
      sx={{
        width: "100vw",
        margin: "auto",
        background: "#0e315a",
        minHeight: { xs: "75vh", sm: "80vh", md: "60vh", lg: "74vh" }
      }}
    >
      <br></br>
      <br></br>

      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChangeExpand("panel1")}
        sx={style}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography sx={styleTitleAccordion}>
            Gráfico de ingreso mensual
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ backgroundColor: "#f5f5f5" }}>
          <Stack sx={{ width: "100%", margin: "auto" }}>
            <Typography
              sx={{
                color: "black",
                margin: "20px",
                textAlign: "left",
                fontSize: { xs: "15px", lg: "30px" },
              }}
            >
              Gráfico de ingresos mensual según servicios: <br></br>{" "}
              <FormControl>
                <RadioGroup
                  aria-labelledby="interurbano-radio"
                  value={radioValueMonth}
                  row
                  sx={{
                    color: "black",
                    ".css-vqmohf-MuiButtonBase-root-MuiRadio-root": {
                      color: "black",
                    },
                  }}
                >
                  <FormControlLabel
                    value="lines"
                    control={<Radio onClick={handleClickMonth} />}
                    label="Gráfico de lineas"
                  />
                  <FormControlLabel
                    value="bars"
                    control={<Radio onClick={handleClickMonth} />}
                    label="Gráfico de barras"
                  />
                </RadioGroup>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap', 
                    gap: 2, 
                  }}
                >
                  <TextField
                    label="Fecha desde"
                    sx={{
                      ".MuiOutlinedInput-notchedOutline": {
                        borderColor: "black",
                        width: "100%",
                      },
                      ".MuiInputBase-root": {
                        color: "black",
                      },
                      flexGrow: 1, 
                    }}
                    InputProps={{
                      type: "date",
                    }}
                    name="dateFrom"
                    value={dateFrom}
                    onChange={handleDateChange}
                  />
                  <TextField
                    label="Fecha hasta"
                    sx={{
                      ".MuiOutlinedInput-notchedOutline": {
                        borderColor: "black",
                        width: "100%",
                      },
                      ".MuiInputBase-root": {
                        color: "black",
                      },
                      flexGrow: 1,
                    }}
                    InputProps={{
                      type: "date",
                    }}
                    name="dateTo"
                    value={dateTo}
                    onChange={handleDateChange}
                  />
                </Box>
              </FormControl>
            </Typography>
            <Box sx={{ width: "60%", height: "100%", margin: "auto" }}>
              {radioValueMonth === "lines" ? (
                <LinesChartMonth dateFrom={dateFrom} dateTo={dateTo} />
              ) : radioValueMonth === "bars" ? (
                <BarsChartMonth  dateFrom={dateFrom} dateTo={dateTo}/>
              ) : (
                ""
              )}
            </Box>
          </Stack>
        </AccordionDetails>
      </Accordion>
      <br></br>
      <br></br>
      <Accordion
        expanded={expanded === "panel2"}
        onChange={handleChangeExpand("panel2")}
        sx={style}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography sx={styleTitleAccordion}>
            Gráfico de ingreso semanal
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ backgroundColor: "#f5f5f5" }}>
          <Stack sx={{ width: "100%", margin: "auto" }}>
            <Typography
              sx={{
                color: "black",
                margin: "20px",
                textAlign: "left",
                fontSize: { xs: "15px", lg: "30px" },
              }}
            >
              Gráfico de ingresos semanal según servicios:
              <br></br>{" "}
              <FormControl>
                <RadioGroup
                  aria-labelledby="interurbano-radio"
                  value={radioValueWeek}
                  row
                  sx={{
                    color: "black",
                    ".css-vqmohf-MuiButtonBase-root-MuiRadio-root": {
                      color: "black",
                    },
                  }}
                >
                  <FormControlLabel
                    value="lines"
                    control={<Radio onClick={handleClickWeek} />}
                    label="Gráfico de lineas"
                  />
                  <FormControlLabel
                    value="bars"
                    control={<Radio onClick={handleClickWeek} />}
                    label="Gráfico de barras"
                  />
                </RadioGroup>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap', 
                    gap: 2, 
                  }}
                >
                  <TextField
                    label="Fecha desde"
                    sx={{
                      ".MuiOutlinedInput-notchedOutline": {
                        borderColor: "black",
                        width: "100%",
                      },
                      ".MuiInputBase-root": {
                        color: "black",
                      },
                      flexGrow: 1, 
                    }}
                    InputProps={{
                      type: "date",
                    }}
                    name="dateFrom"
                    value={dateFrom}
                    onChange={handleDateChange}
                  />
                  <TextField
                    label="Fecha hasta"
                    sx={{
                      ".MuiOutlinedInput-notchedOutline": {
                        borderColor: "black",
                        width: "100%",
                      },
                      ".MuiInputBase-root": {
                        color: "black",
                      },
                      flexGrow: 1,
                    }}
                    InputProps={{
                      type: "date",
                    }}
                    name="dateTo"
                    value={dateTo}
                    onChange={handleDateChange}
                  />
                </Box>
              </FormControl>
            </Typography>
            <Box sx={{ width: "60%", height: "100%", margin: "auto" }}>
              {radioValueWeek === "lines" ? (
                <LinesChartWeek dateFrom={dateFrom} dateTo={dateTo} />
              ) : radioValueWeek === "bars" ? (
                <BarsChartWeek dateFrom={dateFrom} dateTo={dateTo} />
              ) : (
                ""
              )}
            </Box>
          </Stack>
        </AccordionDetails>
      </Accordion>
      <br></br>
      <br></br>
      <Accordion
        expanded={expanded === "panel3"}
        onChange={handleChangeExpand("panel3")}
        sx={style}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography sx={styleTitleAccordion}>
            Gráfico de ingreso diario
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ backgroundColor: "#f5f5f5" }}>
          <Stack sx={{ width: "100%", margin: "auto" }}>
            <Typography
              sx={{
                color: "black",
                margin: "20px",
                textAlign: "left",
                fontSize: { xs: "15px", lg: "30px" },
              }}
            >
              Gráfico de ingresos diarios según servicios:
              <br></br>{" "}
              <FormControl>
                <RadioGroup
                  aria-labelledby="interurbano-radio"
                  value={radioValueHours}
                  row
                  sx={{
                    color: "black",
                    ".css-vqmohf-MuiButtonBase-root-MuiRadio-root": {
                      color: "black",
                    },
                  }}
                >
                  <FormControlLabel
                    value="lines"
                    control={<Radio onClick={handleClickHours} />}
                    label="Gráfico de lineas"
                  />
                  <FormControlLabel
                    value="bars"
                    control={<Radio onClick={handleClickHours} />}
                    label="Gráfico de barras"
                  />
                </RadioGroup>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap', 
                    gap: 2, 
                  }}
                >
                  <TextField
                    label="Fecha desde"
                    sx={{
                      ".MuiOutlinedInput-notchedOutline": {
                        borderColor: "black",
                        width: "100%",
                      },
                      ".MuiInputBase-root": {
                        color: "black",
                      },
                      flexGrow: 1,
                    }}
                    InputProps={{
                      type: "date",
                    }}
                    name="dateFrom"
                    value={dateFrom}
                    onChange={handleDateChange}
                  />
                  <TextField
                    label="Fecha hasta"
                    sx={{
                      ".MuiOutlinedInput-notchedOutline": {
                        borderColor: "black",
                        width: "100%",
                      },
                      ".MuiInputBase-root": {
                        color: "black",
                      },
                      flexGrow: 1,
                    }}
                    InputProps={{
                      type: "date",
                    }}
                    name="dateTo"
                    value={dateTo}
                    onChange={handleDateChange}
                  />
                  <TextField
                    select
                    label="Seleccione Empresa"
                    sx={{
                      ".MuiOutlinedInput-notchedOutline": {
                        borderColor: "black",
                        width: "100%",
                      },
                      ".MuiInputBase-root": {
                        color: "black",
                      },
                      "& .MuiSvgIcon-root": {
                        color: "black",
                      },
                      flexGrow: 1,
                      minWidth: "200px",
                    }}
                    InputLabelProps={{
                      style: { color: "black" },
                    }}
                    name="empresa"
                    value={empresa}
                    onChange={handleEmpresaChange}
                  >
                    <MenuItem key="" value="" placeholder="Todas">Todas</MenuItem>
                    {dataDropdown.empresas?.map((empresa) => (
                      <MenuItem key={empresa.id} value={empresa.id} selected={true}>
                        {" "}
                        {empresa.empresa}{" "}
                      </MenuItem>
                    ))}
                  </TextField>
                </Box>
              </FormControl>
            </Typography>
            <Box sx={{ width: "60%", height: "100%", margin: "auto" }}>
              {radioValueHours === "lines" ? (
                <LinesChartDayHours dateFrom={dateFrom} dateTo={dateTo} empresa={empresa} />
              ) : radioValueHours === "bars" ? (
                <BarsChartDayHours dateFrom={dateFrom} dateTo={dateTo} empresa={empresa} />
              ) : (
                ""
              )}
            </Box>
          </Stack>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};
export default Graphics;