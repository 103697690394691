import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box, Button, Stack, Typography, TextField, Grid } from "@mui/material";
import { Link } from "react-router-dom";
import SettingsIcon from "@mui/icons-material/Settings";
import DeleteIcon from "@mui/icons-material/Delete";
import Modal from "@mui/material/Modal";
import axios from "axios";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { toast } from 'react-toastify';
import { styled, alpha } from "@mui/material/styles";


const styleModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "20%",
  height: "27%",
  bgcolor: "white",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  borderRadius: 5,
};

const styleModal2 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "39%",
  height: "34%",
  bgcolor: "white",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  borderRadius: 5,
  color: "green",
  fontSize: "20px",
};

export default function TableUsers({ data, updateData }) {
  const [open, setOpen] = React.useState(false);
  const [dataModal, setDataModal] = React.useState({});
  const [searchText, setSearchText] = React.useState('');

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleOpenSuccess = () => setOpenSuccessModal(true);
  const handleCloseSuccess = () => setOpenSuccessModal(false);

  const editThis = (data) => {
    handleOpen();
    setDataModal(data);
  };

  const closeEverything = () => {
    handleClose();
    handleCloseSuccess();
  };

  const fetchUsers = () => {
    axios.get('https://terminaldigital.com.ar/api/users', config)
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        console.log("Error fetching users:", err);
      });
  };

  const deleteUser = (id) => {
    const url = `https://terminaldigital.com.ar/api/users/deleteUser/${id}`;
    const config = {
      headers: { authorization: `Bearer ${sessionStorage.getItem("jwt")}` },
    };

    axios
      .delete(url, config)
      .then((res) => {
        toast.success('El usuario fue eliminado exitosamente');
        handleClose();
        updateData();
      })
      .catch((err) => {
        toast.error('Ocurrió un error al eliminar el usuario');
        console.log("Error delete:", err);
      });
  };

  const filteredData = data?.filter((row) => {
    return (
      row.nombre.toLowerCase().includes(searchText.toLowerCase()) ||
      row.apellido.toLowerCase().includes(searchText.toLowerCase()) ||
      row.usuario.toLowerCase().includes(searchText.toLowerCase()) ||
      row.rol_usuario.rol.toLowerCase().includes(searchText.toLowerCase())
    );
  });

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const StickyHeader = styled(TableHead)(({ theme }) => ({
    position: 'sticky',
    top: 0,
    zIndex: theme.zIndex.appBar - 1,
    backgroundColor: theme.palette.background.paper,
  }));

  return (
    <>
      <Stack direction='row' justifyContent={{ xs: 'center', sm: 'flex-end' }} pr={{ xs: 0, sm: 4 }} pb={{ xs: 14, md: 1 }} pt={{ xs: 10, md: 2 }}>
        <TableContainer component={Paper} sx={{ borderRadius: "7px", overflow: 'auto', maxHeight: 480 }}>
          <Box px={4}>
            <Grid item xs={12} sm={4} pb={4} pt={5} ml={4}>
              <Typography
                sx={{
                  fontSize: { xs: "1.5rem", md: "2rem" },
                  fontFamily: 'Lato, sans-serif',
                  color: '#0e315a',
                  textShadow: '2px 2px 2px rgba(0, 0, 0, 0.25)',
                  display: 'flex',
                  justifyContent: 'center',
                  textAlign: { xs: "center", sm: "inherit" },
                  display: { xs: "block", sm: "none" },
                }}
              >
                Listado de usuarios
              </Typography>
            </Grid>
            <Box
              px={{ xs: 1, sm: 4 }}
              display="flex"
              flexDirection={{ xs: "column", sm: "row" }}
              alignItems="center"
              justifyContent="space-between"
              marginTop={4}
              marginBottom={4}
              marginLeft={isMobile ? "-140px" : 4}
            >
              <Grid item xs={6} sm={4}>
                <TextField
                  label="Buscar usuario"
                  value={searchText}
                  onChange={(event) => setSearchText(event.target.value)}
                  sx={{
                    marginLeft: { xs: 3, md: 0 },
                  }}
                />
              </Grid>
              <Grid item xs={6} sm={4}>
                <Typography
                  sx={{
                    fontSize: { xs: "1.5rem", md: "2rem" },
                    fontFamily: 'Lato, sans-serif',
                    color: '#0e315a',
                    textShadow: '2px 2px 2px rgba(0, 0, 0, 0.25)',
                    display: 'flex',
                    justifyContent: 'center',
                    textAlign: { xs: "center", sm: "inherit" },
                    display: { xs: "none", sm: "flex" },
                  }}
                >
                  Listado de usuarios
                </Typography>
              </Grid>
              <Grid item xs={6} sm={4} display="flex" justifyContent={{ xs: "center", sm: "flex-end" }}>
                <Link to="/usuarios/crear">
                  <Button variant="contained" sx={{ background: "#0e315a", mt: { xs: "-75px", sm: 0 }, ml: { xs: 45, md: 0 }, fontSize: { xs: "0.5rem", lg: "1rem" } }}>
                    Crear usuario
                  </Button>
                </Link>
              </Grid>
            </Box>
            <Table sx={{ marginBottom: '24px' }} aria-label="simple table" id="rrhh" >
              <StickyHeader>
                <TableRow>
                  {/* <TableCell>ID</TableCell> */}
                  <TableCell style={{ position: 'sticky', top: 0 }} align="center">Nombre</TableCell>
                  <TableCell style={{ position: 'sticky', top: 0 }} align="center">Apellido</TableCell>
                  <TableCell style={{ position: 'sticky', top: 0 }} align="center">Usuario</TableCell>
                  <TableCell style={{ position: 'sticky', top: 0 }} align="center">Rol</TableCell>
                  <TableCell style={{ position: 'sticky', top: 0 }} align="center">Correo Electrónico</TableCell>
                  <TableCell style={{ position: 'sticky', top: 0 }} align="center">Teléfono</TableCell>
                  <TableCell style={{ position: 'sticky', top: 0 }} align="center">Edit</TableCell>{" "}
                  <TableCell style={{ position: 'sticky', top: 0 }} align="center">Eliminar</TableCell>
                </TableRow>
              </StickyHeader>

              <TableBody>
                {filteredData.length > 0 ? (
                  filteredData.map((row) => (
                    <TableRow
                      key={row.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      {/* <TableCell component="th" scope="row">
                      {row.id}{" "}
                    </TableCell> */}
                      <TableCell align="center">{row.nombre}</TableCell>
                      <TableCell align="center">{row.apellido}</TableCell>
                      <TableCell align="center">{row.usuario}</TableCell>
                      <TableCell align="center">{row.rol_usuario.rol}</TableCell>
                      <TableCell align="center">{row.email}</TableCell>
                      <TableCell align="center">{row.telefono}</TableCell>
                      <TableCell align="center">
                        <Link to={`/usuarios/editar/${row.id}`}>
                          <SettingsIcon />
                        </Link>
                      </TableCell>
                      <TableCell align="center">
                        <DeleteIcon
                          sx={{ cursor: "pointer" }}
                          onClick={() => editThis(row)}
                        />
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={7} align="center">
                      Sin coincidencias para esta búsqueda
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </Box>
        </TableContainer>


        {/* MODAL DE CONFIRMACIÓN DE ELIMINACIÓN DE EMPRESA */}
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={styleModal}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Va a ser eliminado el usuario:
            </Typography>
            <Typography
              id="modal-modal-description"
              sx={{ mt: 4, fontSize: 20, marginLeft: 5, fontWeight: "bold" }}
            >
              {dataModal.nombre} {dataModal.apellido}
            </Typography>
            <br></br>
            <Typography
              sx={{ fontSize: 18, marginLeft: 5, fontWeight: "normal" }}
            >
              Usuario: {dataModal.usuario}
            </Typography>
            <Box mt={4} gap={2} sx={{ marginLeft: 4 }}>
              <Button variant="outlined" onClick={handleClose}>
                Cancelar
              </Button>
              <Button
                variant="contained"
                onClick={() => deleteUser(dataModal.id)}
                sx={{ marginLeft: "20px" }}
              >
                Eliminar
              </Button>
            </Box>
          </Box>
        </Modal>
      </Stack>
    </>
  );
}
