import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function Brochure() {
  const navigate = useNavigate();

  useEffect(() => {
    window.open('/BROCHURE 2024 - TERMINAL DIGITAL.pdf', '_blank');
    navigate('/');
  }, [navigate]);

  return null;
}

export default Brochure;