import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { DataGrid } from '@mui/x-data-grid';
import { esES } from '@mui/x-data-grid';
import { styled } from '@mui/material/styles';
import { Box, Skeleton, Button as MuiButton, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TextField, Typography, Grid, useMediaQuery, Divider, Button, TableRow, InputLabel, Paper } from '@mui/material';
import Stack from '@mui/material/Stack';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import Tooltip from '@mui/material/Tooltip';
import { format } from 'date-fns';


const AdminRegistry = () => {
  const [arrivals, setArrivals] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [fechaInicio, setFechaInicio] = useState(new Date());
  const [fechaFin, setFechaFin] = useState(new Date());

  const count = useSelector((state) => state.counter.value);

  const StickyFooter = styled(TableFooter)(({ theme }) => ({
    position: 'sticky',
    bottom: -20,
    zIndex: theme.zIndex.appBar - 1,
    backgroundColor: theme.palette.background.paper,
  }));

  const [paginaActual, setPaginaActual] = useState(0);
  const [elementosPorPagina, setElementosPorPagina] = useState(5);
  const [search, setSearch] = useState("");
  const [resultadosBusqueda, setResultadosBusqueda] = useState(arrivals);
  const [shouldFetch, setShouldFetch] = useState(true);

  const handlePageChange = (event, newPage) => {
    setPaginaActual(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setElementosPorPagina(parseInt(event.target.value, 10));
    setPaginaActual(0);
  };

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };

  useEffect(() => {
    const resultadosFiltrados = arrivals.filter((dato) =>
      (dato.plataforma?.toString().toLowerCase().includes(search.toLowerCase())) ||
      (dato.destino?.toString().toLowerCase().includes(search.toLowerCase())) ||
      (dato.dominio?.toString().toLowerCase().includes(search.toLowerCase())) ||
      (dato.empresa?.toString().toLowerCase().includes(search.toLowerCase())) ||
      (dato.interno?.toString().toLowerCase().includes(search.toLowerCase())) ||
      (dato.fecha_ingreso?.toString().toLowerCase().includes(search.toLowerCase())) ||
      (dato.horario_ingreso?.toString().toLowerCase().includes(search.toLowerCase())) ||
      (dato.fecha_salida?.toString().toLowerCase().includes(search.toLowerCase())) ||
      (dato.horario_salida?.toString().toLowerCase().includes(search.toLowerCase())) ||
        (dato.tipo_operacion?.toString().toLowerCase().includes(search.toLowerCase())) ||
      (dato.tipo_servicio?.toString().toLowerCase().includes(search.toLowerCase())) ||
      (dato.cancelado?.toString().toLowerCase().includes(search.toLowerCase())) ||
      (dato.demora?.toString().toLowerCase().includes(search.toLowerCase()))
    );
    setResultadosBusqueda(resultadosFiltrados);
    setPaginaActual(0);
  }, [search, arrivals]);

  useEffect(() => {
    if (shouldFetch) {
      const token = window.sessionStorage.getItem("jwt");
      const fechaInicioDate = new Date(fechaInicio);
      const fechaFinDate = new Date(fechaFin);
      const url = `https://terminaldigital.com.ar/api/logs?page=${count}&fechaInicio=${format(fechaInicioDate, 'yyyy-MM-dd')}&fechaFin=${format(fechaFinDate, 'yyyy-MM-dd')}`;
      const config = { headers: { authorization: `Bearer ${token}` } };

      axios
        .get(url, config)
        .then((data) => {
          setArrivals(data.data.registros.rows);
          setIsLoading(false);
        })
        .catch((error) => console.log("error supervisor home", error));
      setShouldFetch(false);
    }
  }, [count, fechaInicio, fechaFin, shouldFetch]);


  const styles = {
    margin: "auto",
    width: "98%",
    borderRadius: '7px',
    background: "#fff",
    color: "white",
    paddingBottom: "32px",
    marginBlock: '16px'
  };

  const StyledButton = styled(Button)`
  background-color: #0e315a;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 10px;
  margin: 4px 2px 0 20px;
  cursor: pointer;
  vertical-align: middle; 
  &:hover {
    background-color: #4CAF50; 
  }
`;
  
  return (
    <>
      <Box style={styles} minHeight={{ xs: "70.8vh", sm: "76.5vh", md: "70vh", lg: "66.8vh" }}>
        <Box
          px={4}
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          marginTop={4}
          marginBottom={4}
        >

          <Typography
            sx={{
              fontSize: { xs: "1rem", lg: "2rem" },
              fontFamily: 'Lato, sans-serif',
              color: '#0e315a',
              textShadow: '2px 2px 2px rgba(0, 0, 0, 0.25)',
              display: 'flex',
              justifyContent: 'center',
              marginTop: 5
            }}
          >
            Registro de Operaciones
          </Typography>

        </Box>
        {isLoading ? (
          <TableContainer component={Paper} sx={{ borderRadius: "7px", overflow: 'auto', maxHeight: 480 }}>
            <Table aria-label="simple table" id="rrhh">
              <TableHead>
                <TableRow>
                  <TableCell align="center">Registro</TableCell>
                  <TableCell align="center">Operación</TableCell>
                  <TableCell align="center">Empresa</TableCell>
                  <TableCell align="center">Destino/Origen/Servicio</TableCell>
                  <TableCell align="center">Fecha Ingreso</TableCell>
                  <TableCell align="center">Horario Ingreso</TableCell>
                  <TableCell align="center">Interno</TableCell>
                  <TableCell align="center">Dominio</TableCell>
                  <TableCell align="center">Servicio</TableCell>
                  <TableCell align="center">Fecha Salida</TableCell>
                  <TableCell align="center">Horario Salida</TableCell>
                  <TableCell align="center">Plat</TableCell>
                  <TableCell align="center">Estado</TableCell>
                  <TableCell align="center">Barrera</TableCell>

                </TableRow>
              </TableHead>
              <TableBody>
                {[
                    <TableRow key="no-data">
                      <TableCell colSpan={13} align="center">No hay registros</TableCell>
                    </TableRow>,
                  ...Array.from(new Array(5)).map((_, index) => (
                  <TableRow key={index}>
                    {Array.from(new Array(13)).map((_, cellIndex) => (
                      <TableCell key={cellIndex}>
                        <Skeleton />
                      </TableCell>
                    ))}
                  </TableRow>
                ))]}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <TableContainer component={Paper} sx={{ width: "99%", margin: "auto", borderRadius: "7px" }}>
              <Box p={2}>
                <Grid container justifyContent="space-between">
                  <Grid item xs={12} md={4}>
                    <TextField
                      label="Buscar"
                      variant="outlined"
                      value={search}
                      onChange={handleSearchChange}
                      sx={{ width: { xs: "100%", md: "80%" }, height: { xs: 1, md: "6vh" } }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                        sx: {
                          color: 'black',
                          '&::placeholder': {
                            color: 'black',
                          },
                          '&.Mui-focused': {
                            color: 'black',
                          },
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={8} sx={{display: {xs: "none", md: "block"}}}>
                    <TextField
                      id="fecha-inicio"
                      label="Fecha de inicio"
                      type="date"
                      value={format(fechaInicio, 'yyyy-MM-dd')}
                      onChange={(e) => setFechaInicio(new Date(e.target.value + 'T00:00:00'))}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        max: format(new Date(), 'yyyy-MM-dd'),
                      }}
                      sx={{ width: { xs: "100%", md: "40%" } }}
                    />
                    <TextField
                      id="fecha-fin"
                      label="Fecha de fin"
                      type="date"
                      value={format(fechaFin, 'yyyy-MM-dd')}
                      onChange={(e) => setFechaFin(new Date(e.target.value + 'T00:00:00'))}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        max: format(new Date(), 'yyyy-MM-dd'),
                      }}
                      sx={{ width: { xs: "100%", md: "40%" }, marginLeft: { xs: 2, md: 2 } }}
                    />
                    <StyledButton onClick={() => setShouldFetch(true)}>Buscar</StyledButton>
                  </Grid>
                </Grid>
              </Box>
              <Box px={2}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table" id="informes">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Registro</TableCell>
                      <TableCell align="center">Operación</TableCell>
                      <TableCell align="center">Creado</TableCell>
                      <TableCell align="center">Dominio</TableCell>
                      <TableCell align="center">Empresa</TableCell>
                      <TableCell align="center">Destino/Origen/Servicio</TableCell>
                      <TableCell align="center">Fecha Ingreso</TableCell>
                      <TableCell align="center">Horario Ingreso</TableCell>
                      <TableCell align="center">Fecha Salida</TableCell>
                      <TableCell align="center">Horario Salida</TableCell>
                      <TableCell align="center">Plat</TableCell>
                      <TableCell align="center">Demora</TableCell>
                      <TableCell align="center">Cancelado</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {arrivals.length > 0 ? resultadosBusqueda.slice(paginaActual * elementosPorPagina, paginaActual * elementosPorPagina + elementosPorPagina).map((row) => (
                      <TableRow key={row.id}
                        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                      >
                        <TableCell align="center">{row.id}</TableCell>
                        <TableCell align="center">{row.registro_operacion.tipo_operacion}</TableCell>
                        <TableCell align="center">{row.createdAt}</TableCell>
                        <Tooltip title={`Interno: ${row.interno}, ${row.registro_servicio.tipo_servicio}`}>
                          <TableCell align="center">{row.dominio}</TableCell>
                        </Tooltip>
                        <TableCell align="center">{row.registro_empresa.empresa}</TableCell>
                        <TableCell align="center">{row.destino}</TableCell>
                        <TableCell align="center">{row.fecha_ingreso}</TableCell>
                        <TableCell align="center">{row.hora_ingreso}</TableCell>
                        <TableCell align="center">{row.fecha_salida}</TableCell>
                        <TableCell align="center">{row.hora_salida}</TableCell>
                        <TableCell align="center">{row.registro_plataforma.plataforma}</TableCell>
                        <TableCell align="center">{row.demora === 0 ? "Sin demora" : row.tiempo_demora}</TableCell>
                        <TableCell align="center">{row.cancelado ? "Si" : "No"}</TableCell>
                      </TableRow>
                    )) : (
                        [
                          <TableRow key="no-data">
                            <TableCell colSpan={13} align="center">No hay registros, verifica las fichas</TableCell>
                          </TableRow>,
                          ...Array.from(new Array(5)).map((_, index) => (
                            <TableRow key={index}>
                              {Array.from(new Array(13)).map((_, cellIndex) => (
                                <TableCell key={cellIndex}>
                                  <Skeleton />
                                </TableCell>
                              ))}
                            </TableRow>
                          ))]
                    )}
                  </TableBody>
                  <StickyFooter>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 25, 100, 1000]}
                      count={resultadosBusqueda.length}
                      rowsPerPage={elementosPorPagina}
                      page={paginaActual}
                      onPageChange={handlePageChange}
                      onRowsPerPageChange={handleRowsPerPageChange}
                      labelRowsPerPage="Filas por página"
                    />
                  </StickyFooter>
                </Table>
              </Box>
            </TableContainer>
        )}
      </Box>
    </>
  );
};

export default AdminRegistry;