import React, { useEffect, useState } from 'react';
import { Box, Skeleton, Button as MuiButton, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TextField, Typography, useMediaQuery, Divider, Button, TableRow, InputLabel, Paper } from '@mui/material';
import { Link } from "react-router-dom";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import EditIcon from "@mui/icons-material/Edit";
import CustomTablePaginationActions from '../Custom/CustomTablePaginationActions';
import { alpha, makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@mui/icons-material/Search';
import { styled } from '@mui/system';
import jwt_decode from "jwt-decode";
import InputAdornment from '@mui/material/InputAdornment';
import SettingsIcon from '@mui/icons-material/Settings';
import MultipleStopIcon from '@mui/icons-material/MultipleStop';
import axios from 'axios';
import { toast } from 'react-toastify';
import Tooltip from '@mui/material/Tooltip';

export default function TableAdmin({ data, edit = false, actualizarDatos }) {

  const token = sessionStorage.getItem("jwt");

  const StickyFooter = styled(TableFooter)(({ theme }) => ({
    position: 'sticky',
    bottom: -20,
    zIndex: theme.zIndex.appBar - 1,
    backgroundColor: theme.palette.background.paper,
  }));

  const [paginaActual, setPaginaActual] = useState(0);
  const [elementosPorPagina, setElementosPorPagina] = useState(5); 
  const [search, setSearch] = useState("");
  const [resultadosBusqueda, setResultadosBusqueda] = useState(data);

  const handlePageChange = (event, newPage) => {
    setPaginaActual(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setElementosPorPagina(parseInt(event.target.value, 10));
    setPaginaActual(0); 
  };

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };

  useEffect(() => {
    const resultadosFiltrados = data.filter((dato) =>
      (dato.plataforma?.toString().toLowerCase().includes(search.toLowerCase())) ||
      (dato.destino?.toString().toLowerCase().includes(search.toLowerCase())) ||
      (dato.dominio?.toString().toLowerCase().includes(search.toLowerCase())) ||
      (dato.empresa?.toString().toLowerCase().includes(search.toLowerCase())) ||
      (dato.interno?.toString().toLowerCase().includes(search.toLowerCase())) ||
      (dato.tipo_tv?.toString().toLowerCase().includes(search.toLowerCase())) ||
      (dato.estado?.toString().toLowerCase().includes(search.toLowerCase())) ||
      (dato.fecha_ingreso?.toString().toLowerCase().includes(search.toLowerCase())) ||
      (dato.horario_ingreso?.toString().toLowerCase().includes(search.toLowerCase())) ||
      (dato.fecha_salida?.toString().toLowerCase().includes(search.toLowerCase())) ||
      (dato.horario_salida?.toString().toLowerCase().includes(search.toLowerCase()))
    );
    setResultadosBusqueda(resultadosFiltrados);
    setPaginaActual(0);
  }, [search, data]);

  const handleButtonClickFueraDePlataforma = (row) => {
    const url = `https://terminaldigital.com.ar/api/informes/fueraDePlataforma/${row.id}`;
    const config = { headers: { authorization: `Bearer ${token}` } };
    const data = { estado: 4 };
    axios.patch(url, data, config)
      .then(response => {
        if (response.data && response.data.error) {
          toast.error('Hubo un error al actualizar el estado: ' + response.data.error);
        } else {
          toast.success('El estado ha sido actualizado a fuera de plataforma');
          actualizarDatos();
        }
      })
      .catch(error => {
        toast.error('Hubo un error al actualizar el estado');
      });
  };

  return (
    <TableContainer component={Paper} sx={{ width: "98%", margin: "auto", borderRadius: "7px" }}>
      <Box p={2}>
        <TextField
          label="Buscar"
          variant="outlined"
          value={search}
          onChange={handleSearchChange}
          sx={{ width: { xs: "40%", md: "15%" }, margin: "auto", height: { xs: 1, md: "4vh" } }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            sx: {
              color: 'black',
              '&::placeholder': {
                color: 'black',
              },
              '&.Mui-focused': {
                color: 'black',
              },
            }
          }}
        />
      </Box>
      <Box px={2}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table" id="informes">
          <TableHead>
            <TableRow>
              {edit && <TableCell align="center">Cambiar estado</TableCell>}
              <TableCell align="center">Destino/Origen</TableCell>
              <TableCell align="center">Estado</TableCell>
              <TableCell align="center">Dominio</TableCell>
              <TableCell align="center">Empresa</TableCell>
              <TableCell align="center">Interno</TableCell>
              <TableCell align="center">Plataforma</TableCell>
              <TableCell align="center">Tipo TV</TableCell>
              <TableCell align="center">Fecha ingreso</TableCell>
              <TableCell align="center">Horario Ingreso</TableCell>
              <TableCell align="center">Fuera Plat</TableCell>
              <TableCell align="center">Editar</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.length > 0 ?
              (elementosPorPagina > 0
                ? resultadosBusqueda.slice(paginaActual * elementosPorPagina, (paginaActual + 1) * elementosPorPagina)
                : resultadosBusqueda
              ).map((row) => (
                <TableRow
                  key={row.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  {edit && (
                    <TableCell align="center">
                      {row.estado_de_datos === 1 ? (
                        <Link to={`/informes/editar/registroDatoFaltante/${row.id}`}>
                          <EditIcon sx={{ color: "#b22222" }} />
                        </Link>
                      ) : (
                        <>
                          <Link to={`/informes/editar/ingreso/${row.id}`}>
                            <Tooltip title="Colocar en Plataforma">
                              <ChangeCircleIcon sx={{ color: "green" }} />
                            </Tooltip>
                          </Link>
                        </>
                      )}
                    </TableCell>
                  )}
                  {row.destino === null ? (
                    <TableCell
                      component="th"
                      scope="row"
                      align="center"
                      sx={{ color: "#b22222" }}
                    >
                      FALTA INGRESAR DESTINO
                    </TableCell>
                  ) : (
                    <TableCell component="th" scope="row" align="center">
                      {row.destino}{" "}
                    </TableCell>
                  )}
                  <TableCell align="center">{row.estado}</TableCell>
                  <TableCell align="center">{row.dominio}</TableCell>
                  {row.empresa === "SIN EMPRESA" ? (
                    <TableCell align="center" sx={{ color: "#b22222" }}>
                      DOMINIO SIN EMPRESA
                    </TableCell>
                  ) : (
                    <TableCell align="center">{row.empresa}</TableCell>
                  )}
                  {row.interno === null ? (
                    <TableCell align="center" sx={{ color: "#b22222" }}>
                      DOMINIO SIN INTERNO
                    </TableCell>
                  ) : (
                    <TableCell align="center">{row.interno}</TableCell>
                  )}
                  <TableCell align="center">{row.plataforma}</TableCell>
                  <TableCell align="center">{row.tipo_tv}</TableCell>

                  <TableCell align="center">{row.fecha_ingreso}</TableCell>
                  <TableCell align="center">{row.horario_ingreso}</TableCell>
                  <TableCell align="center">
                  <Tooltip title="Fuera de plataforma">
                    <MultipleStopIcon sx={{ color: "blue", fontSize: "1.5rem", marginRight: 2 }} onClick={() => handleButtonClickFueraDePlataforma(row)} />
                    </Tooltip>
                  </TableCell>
                  {edit && (
                    <TableCell align="right">
                      <Link to={`/informes/editar/registro/${row.id}`}>
                        {" "}
                        <SettingsIcon sx={{ pt: 1 }} />{" "}
                      </Link>
                    </TableCell>
                  )}
                </TableRow>
              ))
              : (
                Array(7).fill().map((_, i) => (
                  <TableRow key={i} >
                    <TableCell colSpan={11}>
                      <Skeleton animation={i % 7 === 0 ? "wave" : false} />
                    </TableCell>
                  </TableRow>
                ))
              )}
            {data.length > 0 && (elementosPorPagina > 0 ? resultadosBusqueda.slice(paginaActual * elementosPorPagina, (paginaActual + 1) * elementosPorPagina) : resultadosBusqueda).length < 5 && Array(5 - (elementosPorPagina > 0 ? resultadosBusqueda.slice(paginaActual * elementosPorPagina, (paginaActual + 1) * elementosPorPagina) : resultadosBusqueda).length).fill().map((_, i) => (
              <TableRow key={i}>
                <TableCell colSpan={11} sx={{ height: { xs: "50px", lg: "37px" } }} />
              </TableRow>
            ))}
          </TableBody>
          <StickyFooter>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              count={resultadosBusqueda.length}
              rowsPerPage={elementosPorPagina}
              page={paginaActual}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleRowsPerPageChange}
              labelRowsPerPage="Filas por página"
            />
          </StickyFooter>
        </Table>
      </Box>
    </TableContainer>
  );
}
