import React, { useEffect, useState } from "react";
import axios from "axios";
import { Stack } from "@mui/system";
import { Button, Grid, Autocomplete, Typography, Box } from "@mui/material";
import * as yup from "yup";
import { useFormik } from "formik";
import FormField from "./FormField";
import { toast } from "react-toastify";


const validationSchema = yup.object({
  motivo: yup.string().required("Campo requerido"),
  id: yup.number().required("Campo requerido"),
});



const ResetPassword = () => {
  const [users, setUsers] = useState([]);
  const token = window.sessionStorage.getItem("jwt");
  useEffect(() => {
    axios
      .get("https://terminaldigital.com.ar/api/users", {
        headers: { authorization: `Bearer ${token}` },
      })
      .then((data) => {
        setUsers(data.data.usuarios);
      })
      .catch((error) => console.log("error users", error));
  }, [token]);

  const initialReason = {
    motivo: "",
    id: "",
  };

  const url = "https://terminaldigital.com.ar/api/users/resetPassword";
  const config = { headers: { authorization: `Bearer ${token}` } };

  const formik = useFormik({
    initialValues: initialReason,
    validationSchema: validationSchema,
    onSubmit: () => {
      const data = formik.values;

      axios
        .post(url, data, config)
        .then((res) => {
          if (res.status === 200) {
            formik.resetForm({
              values: initialReason,
            });
            toast.success('Contraseña restablecida con éxito.')
          }
        })
        .catch(function (error) {
          console.log("Error send reset password:", error);
        });
    },
  });

  return (
    <div style={{ display: 'flex', justifyContent: 'center'}}>
      <Stack sx={{ background: "#F4F2EC", borderRadius: "25px", shadow: 4, minHeight: { xs: "58vh", lg: "auto" } }} my={4} mx={{ xs: 1, sm: 6 }} p={4} sm={6} width={{ xs: "60vw", md: "50vw" }}>
        <form onSubmit={formik.handleSubmit}>
          <Typography
            sx={{
              fontSize: { xs: "1rem", lg: "2rem" },
              fontFamily: 'Lato, sans-serif',
              color: '#0e315a',
              textShadow: '2px 2px 2px rgba(0, 0, 0, 0.25)',
              display: 'flex',
              justifyContent: 'start',
              marginTop: { xs: "0%", md: "0%" }
            }}
          >
            Restablecer contraseña
          </Typography>
          <Grid container my={4}>
            <Box marginLeft={{ xs: 0, md: 26 }}>
              <Grid item xs={12} md={12} my={2}>
                <Autocomplete
                  id="user-select"
                  options={users}
                  getOptionLabel={(option) => `${option.usuario} - ${option.nombre} ${option.apellido}`}
                  renderInput={(params) => (
                    <FormField
                      {...params}
                      label="Seleccione usuario"
                      name="id"
                      error={formik.errors.id}
                      helperText={formik.errors.id}
                    />
                  )}
                  onChange={(event, newValue) => {
                    formik.setFieldValue('id', newValue ? newValue.id : '');
                  }}

                />
              </Grid>
              <Grid item xs={12} md={12} my={2}
                width={{ xs: "60vw", md: 500 }}>
                <FormField
                  multiline
                  rows={4}
                  value={formik.values.motivo}
                  name="motivo"
                  label="Motivo"
                  onChange={formik.handleChange}
                  error={formik.errors.motivo}
                  helperText={formik.errors.motivo}
                />
              </Grid>
            </Box>
            <Grid item sx={{ marginRight: "auto" }} align="center" pt={4} xs={12}>
              <Button variant="contained" ml="auto" type="submit" my={2}>
                Restablecer
              </Button>
            </Grid>
          </Grid>
        </form>
      </Stack>
    </div>
  );
};

export default ResetPassword;
