import axios from 'axios';
import { Box, CircularProgress, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import React, { useEffect, useState } from "react";
import TableArrivals from "../../components/table/TableArrivals";
import Greating from "../../components/clock2/Greating";
import "./ArrivalsBoard.styles.css";
import TableArrivalsHead from "../../components/table/TableArrivalsHead";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import Marquee from "react-fast-marquee";
import MarketingModuleTV from "../../components/MarketingModule/MarketingModuleTV";
import io from 'socket.io-client';
import Weather from '../../components/Weather/Weather';
import Pagination from '@mui/material/Pagination';


const socket = io('https://terminaldigital.com.ar');

const api = axios.create({
  baseURL: 'https://terminaldigital.com.ar/api'
});

const getMarquesina = () => api.get('/marquesinas/marquesina');
const getArrivals = () => api.get('/plataforma/arribos');
const getLogoTerminal = () => api.get('/administrarterminal');

const Clock = () => {
  const [time, setTime] = useState(new Date().toLocaleTimeString());
  

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTime(new Date().toLocaleTimeString());
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <Greating
      text={time}
      style={{
        color: "white",
        fontSize: { xs: "30px", sm: "45px", md: "50px", lg: "55px" },
        fontWeight: "400",
        marginLeft: "0",
        marginRight: "10px",
        paddingBottom: "70px",
      }}
    />
  );
};

const ArrivalsBoard = () => {
  const handle = useFullScreenHandle();
  const [data, setData] = React.useState([]);
  const [arribos, setArribos] = React.useState([]);
  const [logo, setLogo] = useState([]);
  const [index, setIndex] = useState(0);
  const [page, setPage] = React.useState(1);
  const itemsPerPage = 11;

  useEffect(() => {
    getMarquesina()
      .then((data) => setData(data.data.marquesina))
      .catch((error) => console.log("Error Marquesine Table:", error));
  }, []);

  useEffect(() => {
    const fetchArrivals = async () => {
      try {
        const data = await getArrivals();
        setArribos(data.data);
      } catch (error) {
        console.log('error', error);
      }
    };

    socket.on('connect', () => {
      console.log('Conectado al servidor');
      fetchArrivals();
    });

    socket.on('newRegisterArrives', ({ shouldRerenderArrives }) => {
      if (shouldRerenderArrives) {
        fetchArrivals();
      }
    });


    return () => {
      socket.off('newRegisterArrives');
    };

    fetchArrivals();
  }, []);

  useEffect(() => {
    getLogoTerminal()
      .then((data) => setLogo(data.data.terminal.logo))
      .catch((error) => console.log("error users", error));
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % textTranslate.length);
    }, 30000);
    return () => clearInterval(intervalId);
  }, []);


  const textTranslate = [
    "arribos", // Español
    "arrivals", // Inglés
    "Chegadas", // Portugués
    "arrivées", // Francés
    "Ankünfte", // Alemán
    "arrivi", // Italiano
    "到达", // Chino (para turistas de Taiwan)
    "도착", // Coreano
    "到着", // Japonés
    "הגעות", // Hebreo
    "وصول", // Árabe
  ];

  const imgArray = [
    "/publi1.png",
    "/publi2.png",
    "/publi3.png",
    "/publi1.png",
    "/publi2.png",
    "/publi3.png",
  ];

  const styleMarquee = {
    padding: "0px",
    maxHeight: "100px",
    color: "#0E315A",
    margin: "0",
    background: "white",
    transform: "rotate(90deg)",
    textTransform: "uppercase",
    position: "absolute",
    left: "49.3%",
    bottom: "96.6vh",
  };

  const handleChange = (event, value) => {
    setPage(value);
  };

  useEffect(() => {
    const totalPages = Math.ceil(arribos.length / itemsPerPage);
    const timer = setInterval(() => {
      setPage((prevPage) => {
        if (prevPage >= totalPages) {
          return 1;
        } else {
          return prevPage + 1;
        }
      });
    }, 15000);
    return () => {
      clearInterval(timer);
    };
  }, [arribos, itemsPerPage]);

  return (
    <>
      <Marquee gradient={false} speed={0.051} style={styleMarquee}>
        <span className="texto" style={{ paddingTop: "3px" }}>
          {data?.texto && data.texto}
        </span>
      </Marquee>
      <Box
        className="containerBoard"
        sx={{
          textOrientation: "sideways-right",
          writingMode: "tb-rl",
          position: "relative",
          overflow: "hidden",
        }}
      >
        {/* ACA VA lo que entra en full screen */}
        <FullScreen handle={handle}>
          <Stack
            direction="row"
            pr={3}
            justifyContent="space-between"
            alignItems="flex-start"
            spacing={4}
            sx={{ height: "100vh", background: "#0e315a" }}
          >
            <Typography
              pr={1.4}
              pt={2}
              textAlign="left"
              sx={{
                fontFamily: "Roboto",
                color: "white",
                fontSize: { xs: "15px", sm: "20px", md: "30px" },
                marginBottom: "80px",
              }}
              textTransform=" uppercase"
            >
              {textTranslate[0]} {index !== 0 && ` - ${textTranslate[index]}`}
            </Typography>
            {/* CLIMA */}
            <Weather />
            {/* RELOJ */}
            <Clock />
          </Stack>
          <Box>
            {arribos.length === 0 && (
              <>
                <TableArrivalsHead />
                <Stack
                  justifyContent={"center"}
                  alignItems={"center"}
                  width={"40vw"}
                  mr={4}
                >
                  <Box
                    component="img"
                    src={logo}
                    sx={{
                      width: "auto",
                      height: { xs: "10vh", sm: "15vh", lg: "15vh" },
                      transform: "rotate(90deg)",
                    }}
                  />
                </Stack>
              </>
            )}
            {arribos.length === undefined && (
              <Stack
                justifyContent={"center"}
                alignItems={"center"}
                height={"40vh"}
              >
                <CircularProgress />
              </Stack>
            )}
            {arribos.length > 0 && (
              <TableArrivals props={arribos.slice((page - 1) * itemsPerPage, page * itemsPerPage)} height="100%" width="60px" />
            )}
            <Pagination count={Math.ceil(arribos.length / itemsPerPage)} page={page} onChange={handleChange} style={{ display: "none" }} />
            <MarketingModuleTV data={imgArray} />
          </Box>
        </FullScreen>
        {/* BOTÓN FULL SCREEN */}
        <button
          className="fullscreen-button"
          style={{ zIndex: "99" }}
          onClick={handle.enter}
        >
          <FullscreenIcon />
        </button>
      </Box>
    </>
  );
};

export default ArrivalsBoard;
