import React, { useState, useEffect } from "react";
import axios from "axios";

import { Line } from "react-chartjs-2";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);




const dateNow = new Date();

const WEEK = [
  "Domingo",
  "Lunes",
  "Martes",
  "Miércoles",
  "Jueves",
  "Viernes",
  "Sábado",
];

export function weeks(config) {
  var cfg = config || {};
  var count = cfg.count || 7;
  var section = cfg.section;
  var values = [];
  var i, value;

  for (i = 0; i < count; ++i) {
    
    value = WEEK[Math.ceil(i) % 7];
    values.push(value.substring(0, section));
  }

  return values;
}

export default function LinesChartWeek({dateFrom,dateTo}) {
  const [weekData, setWeekData] = useState([]);
  const [maxCount, setMaxCount] = useState([])
  const [weekDates, setWeekDates] = useState([]);
  const [total, setTotal] = useState([])
  
 
  const token = sessionStorage.getItem("jwt");
  
  useEffect(() => {
    const url = `https://terminaldigital.com.ar/api/graficas/semana?dateFrom=${dateFrom}&dateTo=${dateTo}`;

    axios
      .get(url)
      .then((response) => {
        if (Array.isArray(response.data.registrosModificados)) {

          const newData = response.data.registrosModificados.map(dataset =>
            dataset.map(item => item.value ? item.value : 0) // Asegúrate de que los valores nulos se conviertan en 0
          );
          let newDates = response.data.registrosModificados[0].map(item =>
            item.fecha ? new Date(item.fecha + 'T00:00:00Z') : null // Convierte a objetos Date o null si la fecha es inválida
          );
          newDates = newDates.filter(date => date instanceof Date).sort((a, b) => a.getDay() - b.getDay());
          setWeekData(newData);
          setWeekDates(newDates);
        
          const flatArray = newData.flat();
          const totalSum = flatArray.reduce((sum, value) => sum + Number(value), 0);
          setMaxCount(Math.max(...flatArray) + 10);
          setTotal(totalSum);
        }
      })
      .catch((error) => console.log("error jwt:", error));
  }, [dateFrom, dateTo, token]);

  var midata = {
    labels: weeks({ count: 7 }),
    datasets: [
      {
        label: "Larga distancia",
        data: weekData[0] ? weekData[0] : [],
        tension: 0.1,
        borderColor: "rgb(255, 99, 132)", // Rosa
        pointRadius: 4,
        pointBorderColor: "rgba(0, 0, 0)",
        pointBackgroundColor: "rgba(255, 99, 132)",
      },
      {
        label: "Mediana distancia",
        data: weekData[1] ? weekData[1] : [],
        tension: 0.1,
        borderColor: "rgb(255, 159, 64)", // Naranja
        pointRadius: 4,
        pointBorderColor: "rgba(0, 0, 0)",
        pointBackgroundColor: "rgba(255, 159, 64)",
      },
      {
        label: "Corta distancia",
        data: weekData[2] ? weekData[2] : [],
        tension: 0.1,
        borderColor: "rgb(75, 192, 192)", // Verde azulado
        pointRadius: 4,
        pointBorderColor: "rgba(0, 0, 0)",
        pointBackgroundColor: "rgba(75, 192, 192)",
      },
      {
        label: "Otro servicio",
        data: weekData[3] ? weekData[3] : [],
        tension: 0.1,
        borderColor: "rgb(153, 102, 255)", // Púrpura
        pointRadius: 4,
        pointBorderColor: "rgba(0, 0, 0)",
        pointBackgroundColor: "rgba(153, 102, 255)",
      },
    ]
  };

  var misoptions = {
    plugins: {
      title: {
        display: true,
        text: 'Total registros: ' + total
      }
    },
    scales: {
      y: {
        min: 0,
        max: maxCount,
      },
      x: {
        ticks: {
          color: "rgb(0, 0, 0)",
          callback: function (value, index, values) {
            if (index < weekDates.length && weekDates[index] instanceof Date) {
              const date = weekDates[index];
              const day = date.getUTCDate();
              const month = date.getUTCMonth() + 1;
              return WEEK[date.getUTCDay()] + '\n' + day + '/' + month;
            }else{
              return WEEK[index];
            }
          }
        },
      },
    },
  };
  return <Line data={midata} options={misoptions} />;
}
