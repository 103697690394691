import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Box, Button, TextField, Fab } from "@material-ui/core";
import Chart from "chart.js/auto";
import 'chartjs-plugin-annotation';
import jsPDF from 'jspdf';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import PermMediaIcon from '@mui/icons-material/PermMedia';
import 'jspdf-autotable';
import "./chartLines.css"

const HOUR = [
  "00:00", "01:00", "02:00", "03:00", "04:00", "05:00", "06:00", "07:00",
  "08:00", "09:00", "10:00", "11:00", "12:00", "13:00", "14:00", "15:00",
  "16:00", "17:00", "18:00", "19:00", "20:00", "21:00", "22:00", "23:00",
];

export default function LinesChartDayHours({ year, month, date }) {
  const chartRef = useRef();
  
  const [dayData, setDayData] = useState([]);
  const [maxCount, setMaxCount] = useState();
  const [maxHour, setMaxHour] = useState();
  const [totalIngresos, setTotalIngresos] = useState();
  const [height, setHeight] = useState(window.innerHeight);
  const [isChartReady, setIsChartReady] = useState(false);
  const [chartInstance, setChartInstance] = useState(null);
  
  const [dateFrom, setDateFrom] = useState(`${year}-${month}-${date}`);
  const [dateTo, setDateTo] = useState(`${year}-${month}-${date}`);

  const token = sessionStorage.getItem("jwt");

  useEffect(() => {
    const handleResize = () => setHeight(window.innerHeight);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const url = `https://terminaldigital.com.ar/api/graficas/dia?dateFrom=${dateFrom}&dateTo=${dateTo}`;
    axios
      .get(url)
      .then((response) => {
        setDayData(response.data.registrosModificados);
        setMaxCount(Math.max(...response.data.max) + 0.5);
        if (response.data.registrosModificados[0]) {
          let maxIndex = response.data.registrosModificados[0].reduce((iMax, x, i, arr) => x > arr[iMax] ? i : iMax, 0);
          setMaxHour(HOUR[maxIndex]);
          let totalIngresos = response.data.registrosModificados[4].reduce((a, b) => a + b, 0);
          setTotalIngresos(totalIngresos);
        }
      })
      .catch((error) => console.log("error jwt:", error));
  }, [dateFrom, dateTo, token]);

  useEffect(() => {
    if (chartInstance) {
      chartInstance.destroy();
    }

    if (chartRef.current) {
      const ctx = chartRef.current.getContext("2d");
      const newChartInstance = new Chart(ctx, {
        type: 'line',
        data: {
          labels: HOUR,
          datasets: [
            {
              label: "Larga distancia",
              data: dayData[0],
              tension: 0.1,
              borderColor: "rgb(255, 99, 132)",
              pointRadius: 4,
              pointBorderColor: "rgba(255, 99, 132)",
              pointBackgroundColor: "rgba(255, 99, 132)",
              backgroundColor: "rgba(255, 99, 132, 0.1)", // Fondo transparente
            },
            {
              label: "Mediana distancia",
              data: dayData[1],
              tension: 0.1,
              borderColor: "rgb(255, 159, 64)",
              pointRadius: 4,
              pointBorderColor: "rgba(255, 159, 64)",
              pointBackgroundColor: "rgba(255, 159, 64)",
              backgroundColor: "rgba(255, 159, 64, 0.1)", // Fondo transparente
            },
            {
              label: "Corta distancia",
              data: dayData[2],
              tension: 0.1,
              borderColor: "rgb(75, 192, 192)",
              pointRadius: 4,
              pointBorderColor: "rgba(75, 192, 192)",
              pointBackgroundColor: "rgba(75, 192, 192)",
              backgroundColor: "rgba(75, 192, 192, 0.1)", // Fondo transparente
            },
            {
              label: "Otro servicio",
              data: dayData[3],
              tension: 0.1,
              borderColor: "rgb(153, 102, 255)",
              pointRadius: 4,
              pointBorderColor: "rgba(153, 102, 255)",
              pointBackgroundColor: "rgba(153, 102, 255)",
              backgroundColor: "rgba(153, 102, 255, 0.1)", // Fondo transparente
            },
          ],
        },
        options: {
          plugins: {
            title: {
              display: true,
              text: maxHour ? `${maxHour}hs el horario con mayor registro de ingresos - (Total: ${totalIngresos})` : '',
              font: {
                weight: 'bold'
              }
            },
            annotation: { 
              annotations: [{
                type: 'line',
                mode: 'horizontal',
                scaleID: 'y',
                value: totalIngresos, 
                borderColor: 'rgb(75, 192, 192)',
                borderWidth: 2,
                label: {
                  enabled: true,
                  content: 'Total Ingresos',
                  position: 'end',
                },
              }],
            },
          },
          scales: {
            y: {
              beginAtZero: true,
              min: 0,
              max: maxCount,
            },
            x: {
              ticks: { color: "rgb(0, 0, 0)" },
            },
          },
          maintainAspectRatio: false,
        },
        plugins: [{
          afterDraw: function (chart) {
            setTimeout(() => setIsChartReady(true), 5);
          }
        }]
      });

      setChartInstance(newChartInstance);
    }
  }, [dayData, maxCount, maxHour, totalIngresos]);

  const handleDownloadImage = () => {
    if (chartRef.current) {
      const canvas = chartRef.current;
      canvas.toBlob((blob) => {
        if (blob) {

          const currentDate = new Date();
          const day = currentDate.getDate();
          const month = currentDate.getMonth() + 1; 
          const year = currentDate.getFullYear();

          const fileName = `Ingresos_del_dia_${day < 10 ? '0' : ''}${day}${month < 10 ? '0' : ''}${month}${year}_TerminalDigital.png`;

          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = fileName;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } else {
          console.log("Error al crear Blob");
        }
      });
    } else {
      console.log("No se encontró el gráfico o la instancia del gráfico");
    }
  };

  const handleDownloadPDF = () => {
    if (chartRef.current) {

      const fileName = `${date < 10 ? '0' : ''}${date}-${month < 10 ? '0' : ''}${month}-${year}_TerminalDigital.pdf`;

      const canvas = chartRef.current;
      const pdf = new jsPDF('l', 'mm', 'a4'); 
      const width = pdf.internal.pageSize.getWidth();
      const height = pdf.internal.pageSize.getHeight();

      let title = 'Registro de ingresos diario';
      let subtitle = `(${dateFrom} - ${dateTo})`;
      let subtitle2 = '(Este reporte muestra el registro de ingresos por hora de los servicios de larga, mediana y corta distancia, y otros servicios)';

      let pageWidth = pdf.internal.pageSize.getWidth();
      let titleWidth = pdf.getStringUnitWidth(title) * pdf.internal.getFontSize() / pdf.internal.scaleFactor;
      let titleX = (pageWidth - titleWidth) / 2;

      pdf.text(title, titleX, 10); 
      pdf.setFontSize(10); 
      pdf.text(subtitle, titleX, 20); 


      const aspectRatio = canvas.width / canvas.height;

   
      let imgWidth = width - 20; 
      let imgHeight = imgWidth / aspectRatio;

      if (imgHeight > height - 20) {
        imgHeight = height - 20;
        imgWidth = imgHeight * aspectRatio;
      }

      const imgData = canvas.toDataURL('image/png');

      pdf.addImage(imgData, 'PNG', 10, 25, imgWidth, imgHeight);

      let headers = ['Serv', ...Array.from({ length: 24 }, (_, i) => `${i + 1}`)];
      let categories = ['Lar', 'Med', 'Cor', 'Ext', "Total"];
      let rows = dayData.map((day, i) => [categories[i], ...day.map(hour => hour.toString())]);

      let columnWidths = headers.map((header, index) => {
        let maxWidth = header.length;
        rows.forEach(row => {
          if (row[index] && row[index].length > maxWidth) {
            maxWidth = row[index].length;
          }
        });
        return maxWidth;
      });


      let totalWidth = columnWidths.reduce((a, b) => a + b, 0);
      columnWidths = columnWidths.map(width => width / totalWidth);


      pdf.addPage();

      pdf.setFontSize(15); 
      pdf.text('Reporte de ingresos por hora', 14, 20);

      pdf.autoTable({
        startY: 25,
        head: [headers],
        headStyles: { fillColor: [0, 100, 255], halign: 'left' },
        body: rows,
      });

      pdf.setFontSize(10); 
      pdf.text(subtitle2, 10, 80);

      pdf.save(fileName);
    }
  };

  const handleDateChange = (event) => {
    const { name, value } = event.target;

    if (name === "dateFrom") {
      if (new Date(value) > new Date(dateTo)) {
        alert("Fecha desde no puede ser mayor que Fecha hasta");
        return;
      }
      setDateFrom(value);
    } else if (name === "dateTo") {
      if (new Date(value) < new Date(dateFrom)) {
        alert("Fecha hasta no puede ser menor que Fecha desde");
        return;
      }
      setDateTo(value);
    }
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap', 
          gap: 2, 
          justifyContent: 'flex-start',
          mb: 5,
          mt: 3,
        }}
      >
        <TextField
          label="Fecha desde"
          sx={{
            ".MuiOutlinedInput-notchedOutline": {
              borderColor: "black",
              width: "100%",
            },
            ".MuiInputBase-root": {
              color: "black",
            },
            flexGrow: 1, 
            mr: 2,
          }}
          InputProps={{
            type: "date",
          }}
          name="dateFrom"
          value={dateFrom}
          onChange={handleDateChange}
        />
        <TextField
          label="Fecha hasta"
          sx={{
            ".MuiOutlinedInput-notchedOutline": {
              borderColor: "black",
              width: "100%",
            },
            ".MuiInputBase-root": {
              color: "black",
            },
            flexGrow: 1,
          }}
          InputProps={{
            type: "date",
          }}
          name="dateTo"
          value={dateTo}
          onChange={handleDateChange}
        />
      </Box>
      <br />
      <Box sx={{ height: `${height * 0.5}px`, width: '95%', padding: 2, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "flex-end" }}>
        <canvas ref={chartRef}></canvas>
        <Box
          sx={{ mt: 2, flexDirection: "row", justifyContent: "flex-end", gap: 2 }}
          className={isChartReady ? 'visible' : 'hidden'}
        >
          <div className="custom-button2" onClick={handleDownloadImage}>
            <PermMediaIcon />
          </div>
          <div className="custom-button" onClick={handleDownloadPDF}>
            <PictureAsPdfIcon />
          </div>
        </Box>
        
      </Box>
    </>

  );
}
