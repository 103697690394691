import React, { useState  } from "react";
import { useFormik } from "formik";
import axios from "axios";
import { Box, Button, TextField, Typography, Stack,
Alert } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Modal from "@mui/material/Modal";
import * as yup from "yup";
import ScreenRotationAltIcon from "@mui/icons-material/ScreenRotationAlt";
import CloseIcon from "@mui/icons-material/Close";
const styleModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 650,
  height: 400,
  bgcolor: "white",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  borderRadius: 5,
};

export default function CropsImage({ data }) {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(true);
  const [labelNombre, setLabelNombre] = useState("");
  const [img, setImg] = useState(data);
  const [preview, setPreview] = useState();
  const [type, setType] = useState("");
  
  const label =
    labelNombre === ""
      ? "Sin imagen seleccionada "
      : "(' " + labelNombre + "')"; //+ " Nombre de la imagen seleccionada";

  const initialImage = {
    imagen: "" || img,
  };

  const editImagen = {
    imagen: img,
  };

  const handleClose = () => {
    formik.resetForm();
    setOpen(false);
    navigate("/supervisor/datos-terminal");
  };
  const labelStyle =
    labelNombre === "" ? { textAlign: " center" } : { textAlign: " center" };


  const closeEverything = () => {
    handleClose();
  };

  const setImage = (event) => {
    setImg(event.target.files[0]);
    setLabelNombre(event.target.files[0].name);
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setPreview(reader.result);
    };
    reader.readAsDataURL(file);
    formik.resetForm(img);
    formik.values.imagen = file;
    setType(event.target.files[0].type);
  };

  const formik = useFormik({
    initialValues: editImagen || initialImage,
    validationSchema: yup.object({
      imagen: yup
        .mixed()
        .required("Imagen requerida")
        .test(
          "FILE_SIZE",
          "Archivo demasiado grande, el tamaño debe de ser menor a 1Mb",
          (value) => value && value.size < 1000000
        )
        .test(
          "FILE_TYPE",
          "Formato invalido, debe de ser JPG / PNG",
          (value) => value && ["image/png", "image/jpg"].includes(value.type)
        ),
    }),
    enableReinitialize: true,
    onSubmit: async () => {
      const url = `https://terminaldigital.com.ar/api/administrarterminal/edit/logo`;

      const { imagen } = formik.values;

      const formData = new FormData();
      formData.append("imagen", imagen);

      axios

        .patch(url, formData)
        .then((res) => {
          if (res.status === 200) {
            navigate("/supervisor/datos-terminal");
          }
        })
        .catch(function (error) {
          console.log("Error:", error);
          console.log("Error:", error.response.data.msg);
          formik.resetForm();
        });
    },
  });

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={styleModal}>
        <form onSubmit={formik.handleSubmit}>
          <Button
            onClick={closeEverything}
            sx={{ color: "#b22222", marginLeft: "90%" }}
          >
            <CloseIcon />
          </Button>
          <Typography sx={labelStyle}>{label}</Typography>
          <br></br>
          <TextField
            label=""
            InputLabelProps={{
              style: { color: "#fff" },
            }}
            sx={{
              ".MuiOutlinedInput-notchedOutline": {
                borderColor: "white",
              },
              ".MuiInputBase-root": {
                color: "white",
              },
              "& .MuiSvgIcon-root": {
                color: "white",
              },
              minWidth: "250px",
              marginLeft: "30%",
            }}
            error={formik.errors.imagen}
            id="imagen"
            name="imagen"
            type="file"
            onChange={setImage}
          ></TextField>
          <br></br>
          <br></br>
          <Box sx={{ display: "flex", width: "100%" }}>
            <Box
              component="img"
              sx={{
                height: 100,
                width: 300,
                maxHeight: { xs: 233, md: 167 },
                maxWidth: { xs: 350, md: 250 },
                marginLeft: "auto",
              }}
              alt={img}
              src={data || img}
            />
            <ScreenRotationAltIcon
              sx={{ marginLeft: "5%", marginTop: "4%", fontSize: "3.12rem", }}
            />
            <Box
              component="img"
              sx={{
                height: 100,
                width: 300,
                maxHeight: { xs: 233, md: 167 },
                maxWidth: { xs: 350, md: 250 },
                marginLeft: "8%",
              }}
              alt={img}
              src={
                preview == null
                  ? require("../../assets/img/uploadImg.png")
                  : type === '' ? require("../../assets/img/uploadImgBad.png") : preview
              }
            />
          </Box>
          <br></br>
          <Box sx={{ display: "flex" }}>
            <br></br>
            <br></br>
            <Stack sx={{ width: "100%" }} spacing={2}>
              {formik.errors.imagen !== undefined ? (
                <Alert severity="error">
                  {formik.errors.imagen}
                </Alert>
              ) : (
                <Alert severity="info">
                  Se recomienda usar imagen de 1410 x 500 cm - Formato PNG / JPG
                </Alert>
              )}
            </Stack>
          </Box>
          <br></br>
          <br></br>
          <Box align="center">
            <Button
              onClose={closeEverything}
              variant="contained"
              ml="auto"
              type="submit"
              my={2}
              sx={{ marginLeft: "-8px" }}
            >
              Modificar
            </Button>
          </Box>
        </form>
      </Box>
    </Modal>
  );
}
