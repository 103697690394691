import { React, useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import Typography from "@mui/material/Typography";

function toUpperCaseLatin(text, index) {
  if (index < 6) {
    return text.toUpperCase();
  } else {
    return text;
  }
}

const textTranslateEmpresa = [
  "Empresa", // Español
  "Company", // Inglés
  "Empresa", // Portugués
  "Entreprise", // Francés
  "Unternehmen", // Alemán
  "Azienda", // Italiano
  "公司", // Chino (para turistas de Taiwán)
  "회사", // Coreano
  "会社", // Japonés
  "חברה", // Hebreo
  "شركة", // Árabe
].map(toUpperCaseLatin);

const textTranslateDesdePlataforma = [
  "Desde plataforma", // Español
  "From platform", // Inglés
  "Da plataforma", // Portugués
  "De la plateforme", // Francés
  "Von der Plattform", // Alemán
  "Dalla piattaforma", // Italiano
  "从平台", // Chino (para turistas de Taiwán)
  "플랫폼에서", // Coreano
  "プラットフォームから", // Japonés
  "מהפלטפורמה", // Hebreo
  "منصة", // Árabe
].map(toUpperCaseLatin);

const textTranslateHastaPlataforma = [
  "Hasta plataforma", // Español
  "To platform", // Inglés
  "Para plataforma", // Portugués
  "À la plateforme", // Francés
  "Zur Plattform", // Alemán
  "Alla piattaforma", // Italiano
  "到平台", // Chino (para turistas de Taiwán)
  "플랫폼까지", // Coreano
  "プラットフォームへ", // Japonés
  "לפלטפורמה", // Hebreo
  "إلى المنصة", // Árabe
].map(toUpperCaseLatin);

const StyledTableCell2 = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#0E315A",
    height: "30px",
  },
  [`&.${tableCellClasses.body}`]: {},
  [`&.${tableCellClasses.root}`]: {
    borderBottom: "none",
    fontSize: 20,
    color: "white",
    fontWeight: "bold",
    padding: "10px",
    marginLeft: '2rem'
  },
}));

const StyledTableCell3 = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#0E315A",
    height: "20px",
  },
  [`&.${tableCellClasses.body}`]: {},
  [`&.${tableCellClasses.root}`]: {
    borderBottom: "none",
    fontSize: 20,
    color: "white",
    fontWeight: "bold",
    padding: "10px",
    marginLeft: '2rem'
  },
}));

export default function TableIntercityHead(  ) {
   
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setIndex((index) => index + 1);
    }, 30000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <TableContainer component={Paper} sx={{ borderRadius: "0px" }}>
      <Table
        sx={{ fontSize: "20px", height: "100%" }}
        aria-label="customized table"
      >
        <TableHead>
          <TableRow>
            <StyledTableCell2 align="center">
              <Typography fontSize={{ xs: "10px", sm: "15px", md: "20px" }}>
                {textTranslateEmpresa[0]}
              </Typography>
              {index !== 0 &&
                <Typography fontSize={{ xs: "8px", sm: "10px", md: "15px" }}>
                  ( {textTranslateEmpresa[index]} )
                </Typography>
              }
            </StyledTableCell2>
            <StyledTableCell2 align="center" >
              <Typography fontSize={{ xs: "10px", sm: "15px", md: "20px" }}>
                {textTranslateDesdePlataforma[0]}
              </Typography>
              {index !== 0 &&
                <Typography fontSize={{ xs: "8px", sm: "10px", md: "15px" }}>
                  ( {textTranslateDesdePlataforma[index]} )
                </Typography>
              }
            </StyledTableCell2>
            <StyledTableCell2 align="center">
              <Typography fontSize={{ xs: "10px", sm: "15px", md: "20px" }}>
                {textTranslateHastaPlataforma[0]}
              </Typography>
              {index !== 0 &&
                <Typography fontSize={{ xs: "8px", sm: "10px", md: "15px" }}>
                  ( {textTranslateHastaPlataforma[index]} )
                </Typography>
              }
            </StyledTableCell2>
          </TableRow>
        </TableHead>
      </Table>
    </TableContainer>
  );
}
