import React, { useEffect, useState } from "react";
import { Button, Grid, MenuItem, TextField, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as yup from "yup";
import axios from "axios";
import ReplyIcon from "@mui/icons-material/Reply";
import { Link } from "react-router-dom";
import BasicModal from "../modals/Modal";
import ModalError from "../../components/modals/ModalError";
import {useNavigate } from "react-router-dom";

const validationSchema = yup.object({
  empresa_id: yup.string().required("Campo requerido"),
  desde: yup.string().required("Campo requerido"),
  hasta: yup.string().required("Campo requerido"),
});

const FormIntercityEdit = ({ intercity }) => {
  const [openModal, setOpenModal] = useState(false);
  const [error, setError] = useState("")
  const [openModal2, setOpenModal2] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [platforms, setPlatforms] = useState([]);
  const navigate = useNavigate();
  const token = window.sessionStorage.getItem("jwt");
  const url = "https://terminaldigital.com.ar/api/interurbanos/";
  const config = { headers: { authorization: `Bearer ${token}` } };

  const initialInterCity = {
    empresa_id: "",
    desde: "",
    hasta: "",
  };

  const editInterCity = {
    empresa_id: intercity?.empresa.id, 
    desde: intercity?.desde, 
    hasta: intercity?.hasta, 
  };

  const getDropdown = () => {
    axios
      .all([
        axios.get("https://terminaldigital.com.ar/api/empresas/listadoInterurbano", config),
        axios.get(
          "https://terminaldigital.com.ar/api/plataformas/listadoInterurbano",
          config
        ),
      ])
      .then((data) => {
        const filteredCompanies = data[0].data.empresas.filter(
          (empresa) => empresa.servicio_id === 3
        );
        setCompanies(filteredCompanies);
        setPlatforms(data[1].data.plataformas);
      })
      .catch((error) => console.log("error users", error));
  };

  useEffect(() => {
     getDropdown();
  }, []);

  const formik = useFormik({
    initialValues: editInterCity || initialInterCity,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      const url = `https://terminaldigital.com.ar/api/interurbanos/modificar/${intercity?.id}`;
      const config = { headers: { authorization: `Bearer ${token}` } };
      const data = formik.values;

      axios
        .patch(url, data, config)
        .then((res) => {
          if (res.status === 200) {
            setOpenModal(true);
            navigate("/supervisor/interurbanos")
            toast.success('Empresa Interurbana actualizada con éxito.')
          }
        })
        .catch(function (error) {
          if (error.response.status === 400) {
            setError(error.response.data.message);
            setOpenModal2(true);
             setTimeout(() => {
               setOpenModal2(false);
             }, "3000");
          }
        });
    },
  });

  return (
    <Stack
      sx={{ background: "#F4F2EC", borderRadius: "25px", shadow: 4 }}
      my={4}
      mx={{ xs: 1, sm: 6 }}
      p={4}
      sm={6}
      minHeight={{ xs: "10vh", sm: "68vh", md: "52vh" }}
    >
      <form onSubmit={formik.handleSubmit}>
        <Link to="/supervisor/interurbanos">
          <Button
            py={2}
            my={4}
            mx={4}
            sx={{
              color: "#b22222",
              marginLeft: { xs: "85%", sm: "92%", md: "95%" },
            }}
            variant="outlined"
            color="error"
          >
            <ReplyIcon />
          </Button>
        </Link>
        <Typography
          sx={{
            fontSize: { xs: "1rem", md: "2rem" },
            fontFamily: 'Lato, sans-serif',
            color: '#0e315a',
            textShadow: '2px 2px 2px rgba(0, 0, 0, 0.25)',
            display: 'flex',
            justifyContent: 'start',
            alignItems: 'center',
            margin: 5,
            ml: 1
          }} >
          Asignar plataformas a empresa interurbana:
        </Typography>
        <Grid container my={4}>
          <Grid
            item
            display={{ xs: "block", md: "flex" }}
            alignItems="center"
            gap={2}
            xs={12}
            sm={12}
            md={10}
            lg={8}
            xl={6}
            my={2}
          >
            <Typography
              variant="subtitle1"
              color="black"
              mb={{ xs: 1, sm: 0 }}
              display={{ xs: "block", sm: "block" }}
            >
              Empresa:
            </Typography>
            <TextField
              select
              label={intercity?.empresa.empresa}
              sx={{
                ".MuiOutlinedInput-notchedOutline": {
                  borderColor: "black",
                },
                ".MuiInputBase-root": {
                  color: "black",
                },
                "& .MuiSvgIcon-root": {
                  color: "black",
                },
                minWidth: "300px",
              }}
              InputLabelProps={{
                style: { color: "#black" },
              }}
              name="empresa_id"
              value={formik.values.empresa_id}
              onChange={formik.handleChange}
              error={formik.errors.empresa_id}
              helperText={formik.errors.empresa_id}
            >
              {companies?.map((comp) => (
                <MenuItem
                  value={comp.id}
                  key={comp.id + "empresa"}
                  selected={true}
                >
                  {comp.empresa}
                </MenuItem>
              ))}
            </TextField>
            <br></br>

            <Typography
              variant="subtitle1"
              color="black"
              mb={{ xs: 1, sm: 0 }}
              sx={{ marginTop: { xs: "20px", sm: "0px" } }}
            >
              Desde:
            </Typography>
            <TextField
              sx={{
                ".MuiOutlinedInput-notchedOutline": {
                  borderColor: "black",
                },
                ".MuiInputBase-root": {
                  color: "black",
                },
                ".css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                  borderColor: "black",
                },
                minWidth: "300px",
                marginLeft: { sm: "20px" },
              }}
              select
              label={intercity?.plataforma_desde.plataforma}
              InputLabelProps={{
                style: { color: "#black" },
              }}
              multiline
              rows={4}
              value={formik.values.desde}
              name="desde"
              onChange={formik.handleChange}
              error={formik.errors.desde}
              helperText={formik.errors.desde}
            >
              {platforms?.filter(plat => plat.disponible === 1).map((plat) => (
                <MenuItem
                  value={plat.id}
                  key={"desde" + plat.plataforma + plat.id}
                  selected={true}
                >
                  {plat.plataforma}
                </MenuItem>
              ))}
            </TextField>
            <br></br>
            <Typography
              variant="subtitle1"
              color="black"
              mb={{ xs: 1, sm: 0 }}
              sx={{ marginTop: { xs: "20px", sm: "0px" } }}
            >
              Hasta:
            </Typography>
            <TextField
              sx={{
                ".MuiOutlinedInput-notchedOutline": {
                  borderColor: "black",
                },
                ".MuiInputBase-root": {
                  color: "black",
                },
                ".css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                  borderColor: "black",
                },
                minWidth: "300px",
                marginLeft: { sm: "20px" },
              }}
              select
              label={intercity?.plataforma_hasta.plataforma}
              InputLabelProps={{
                style: { color: "#black" },
              }}
              multiline
              rows={4}
              value={formik.values.hasta}
              name="hasta"
              onChange={formik.handleChange}
              error={formik.errors.hasta}
              helperText={formik.errors.hasta}
            >
              {platforms?.filter(plat => plat.disponible === 1).map((plat) => (
                <MenuItem
                  value={plat.id}
                  key={"hasta" + plat.plataforma + plat.id}
                  selected={true}
                >
                  {plat.plataforma}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item sx={{ marginRight: "auto" }} align="center" pt={8} xs={12}>
            <Button variant="contained" ml="auto" type="submit" my={2}>
              Asignar Plataformas
            </Button>
          </Grid>
          {openModal && (
            <BasicModal
              title="Éxito"
              message="Plataformas seleccionadas con éxito"
              openModal={openModal}
            />
          )}
          {openModal2 && (
            <ModalError
              title="Error"
              message={error}
              openModal={openModal2}
              type="1"
            />
          )}
        </Grid>
      </form>
    </Stack>
  );
};

export default FormIntercityEdit;
