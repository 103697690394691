import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Link } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import Divider from "@mui/material/Divider";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { useFormik } from "formik";
import axios from "axios";
import { Box, Button, TextField, Alert, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import MarkdownPreview from "../../app/MarkdownPreview.js";
import CloseIcon from "@mui/icons-material/Close";
import CropsImage from "../CropsImage/CropsImage.jsx";
import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromHTML,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Typography } from "@mui/material";

export default function TableAdminTerminalEditar({ dataTerminal, edit }) {
  let contentFromHTML = dataTerminal.institucional
    ? convertFromHTML(dataTerminal.institucional)
    : convertFromHTML("");

  let editorState = EditorState.createWithContent(
    ContentState.createFromBlockArray(contentFromHTML)
  );

  const [description, setDescription] = useState(editorState);

  const [institucional, setInstitucional] = useState("");

  const [labelError, setLabelError] = useState("");

  const [labelSuccess, setLabelSuccess] = useState("");

  const handleClose = () => {
    setOpen(false);
    navigate("/supervisor/datos-terminal");
  };
  // eslint-disable-next-line
  const [open, setOpen] = React.useState(true);
  const closeEverything = () => {
    handleClose();
  };

  useEffect(() => {
    setInstitucional(dataTerminal?.institucional);
  }, [dataTerminal]);

  const navigate = useNavigate();

  const editValues = {
    nombre: dataTerminal?.nombre,
    representante: dataTerminal?.representante,
    logo: dataTerminal?.logo,
    contacto_tel: dataTerminal?.contacto_tel,
    contacto_cel: dataTerminal?.contacto_cel,
    contacto_email_1: dataTerminal?.contacto_email_1,
    contacto_email_2: dataTerminal?.contacto_email_2,
    provincia: dataTerminal?.provincia,
    como_llegar: dataTerminal?.como_llegar,
    institucional: dataTerminal?.institucional,
    facebook: dataTerminal?.facebook,
    instagram: dataTerminal?.instagram,
    twitter: dataTerminal?.twitter,
    venta_pasajes: dataTerminal?.venta_pasajes,
  };

  const labelStyleError =
    labelError === ""
      ? { width: "25%", display: "none" }
      : { width: "25%", display: "block", marginLeft: "1%" };

  const labelStyleSuccess =
    labelSuccess === ""
      ? { width: "25%", display: "none" }
      : { width: "25%", display: "block", marginLeft: "1%" };

  const formik = useFormik({
    initialValues: editValues,

    onSubmit: (values) => {
      const url = `https://terminaldigital.com.ar/api/administrarterminal/edit`;
      const data = formik.values;

      axios

        .patch(url, data)
        .then((res) => {
          if (res.status === 200) {
            setLabelSuccess(" Modificación exitosa");
            setTimeout(() => {
              setLabelSuccess("");
            }, "3000");
            setTimeout(() => {
              navigate("/supervisor/datos-terminal");
            }, "3000");
          }
        })
        .catch(function (error) {
          //console.log("Error:", error);
          //console.log(error.response.status);
          if (error.response.status === 400) {
            setLabelError(" Se debe de modificar un dato al menos");
          } else {
            setLabelError("");
          }
        });
    },
  });

  const onEditorStateChange = (editorState) => {
    setDescription(editorState);
    setInstitucional(
      draftToHtml(convertToRaw(description.getCurrentContent()))
    );
  };

  return (
    <TableContainer
      component={Paper}
      sx={{ width: "98%", margin: "auto", borderRadius: "7px" }}
    >
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Typography
          sx={{
            fontSize: { xs: "1rem", md: "2rem" },
            fontFamily: 'Lato, sans-serif',
            color: '#0e315a',
            textShadow: '2px 2px 2px rgba(0, 0, 0, 0.25)',
            display: 'flex',
            justifyContent: 'start',
            alignItems: 'center',
            margin: 5,
            ml: 1
          }} >
          Datos de Terminal
        </Typography>
      </Box>
      <br></br>
      <Divider variant="h5" textAlign="left" sx={{
          fontFamily: 'Lato, sans-serif',
          color: '#0e315a',
          textShadow: '2px 2px 2px rgba(0, 0, 0, 0.25)'}} >
        Información administrativa{" "}
      </Divider>
      <Button
        onClick={closeEverything}
        sx={{ color: "#b22222", marginLeft: "95%" }}
      >
        <CloseIcon />
      </Button>
      <br></br>
      {edit === "1" ? (
        <form onSubmit={formik.handleSubmit}>
          <Box px={2}>
            <Table
              sx={{ minWidth: 650 }}
              aria-label="simple table"
              id="informes"
            >
              <TableHead>
                <TableRow>
                  <TableCell align="left">Terminal</TableCell>
                  <TableCell align="left">Representante</TableCell>
                  <TableCell align="center">Contacto Tel 1</TableCell>
                  <TableCell align="center">Contacto Tel 2</TableCell>
                  <TableCell align="center">Contacto email Principal</TableCell>
                  <TableCell align="center">Contacto email RrHh</TableCell>
                  <TableCell align="center">Provincia</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow
                  key={dataTerminal.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="left">
                    <TextField
                      sx={{
                        ".MuiOutlinedInput-notchedOutline": {
                          borderColor: "black",
                          width: "100%",
                        },
                        ".MuiInputBase-root": {
                          color: "black",
                        },
                      }}
                      InputLabelProps={{
                        style: { color: "black" },
                      }}
                      InputProps={{
                        type: "text",
                      }}
                      name="nombre"
                      value={formik.values.nombre}
                      onChange={formik.handleChange}
                      error={formik.errors.nombre}
                      helperText={formik.errors.nombre}
                    ></TextField>
                  </TableCell>
                  <TableCell align="left">
                    <TextField
                      sx={{
                        ".MuiOutlinedInput-notchedOutline": {
                          borderColor: "black",
                          width: "100%",
                        },
                        ".MuiInputBase-root": {
                          color: "black",
                        },
                      }}
                      InputLabelProps={{
                        style: { color: "black" },
                      }}
                      InputProps={{
                        type: "text",
                      }}
                      name="representante"
                      value={formik.values.representante}
                      onChange={formik.handleChange}
                      error={formik.errors.representante}
                      helperText={formik.errors.representante}
                    ></TextField>
                  </TableCell>
                  <TableCell align="center">
                    <TextField
                      sx={{
                        ".MuiOutlinedInput-notchedOutline": {
                          borderColor: "black",
                          width: "100%",
                        },
                        ".MuiInputBase-root": {
                          color: "black",
                        },
                      }}
                      InputLabelProps={{
                        style: { color: "black" },
                      }}
                      InputProps={{
                        type: "number",
                      }}
                      name="contacto_tel"
                      value={formik.values.contacto_tel}
                      onChange={formik.handleChange}
                      error={formik.errors.contacto_tel}
                      helperText={formik.errors.contacto_tel}
                    ></TextField>
                  </TableCell>
                  <TableCell align="center">
                    <TextField
                      sx={{
                        ".MuiOutlinedInput-notchedOutline": {
                          borderColor: "black",
                          width: "100%",
                        },
                        ".MuiInputBase-root": {
                          color: "black",
                        },
                      }}
                      InputLabelProps={{
                        style: { color: "black" },
                      }}
                      InputProps={{
                        type: "number",
                      }}
                      name="contacto_cel"
                      value={formik.values.contacto_cel}
                      onChange={formik.handleChange}
                      error={formik.errors.contacto_cel}
                      helperText={formik.errors.contacto_cel}
                    ></TextField>
                  </TableCell>
                  <TableCell align="center">
                    <TextField
                      sx={{
                        ".MuiOutlinedInput-notchedOutline": {
                          borderColor: "black",
                          width: "100%",
                        },
                        ".MuiInputBase-root": {
                          color: "black",
                        },
                      }}
                      InputLabelProps={{
                        style: { color: "black" },
                      }}
                      InputProps={{
                        type: "email",
                      }}
                      name="contacto_email_1"
                      value={formik.values.contacto_email_1}
                      onChange={formik.handleChange}
                      error={formik.errors.contacto_email_1}
                      helperText={formik.errors.contacto_email_1}
                    ></TextField>
                  </TableCell>
                  <TableCell align="center">
                    <TextField
                      sx={{
                        ".MuiOutlinedInput-notchedOutline": {
                          borderColor: "black",
                          width: "100%",
                        },
                        ".MuiInputBase-root": {
                          color: "black",
                        },
                      }}
                      InputLabelProps={{
                        style: { color: "black" },
                      }}
                      InputProps={{
                        type: "email",
                      }}
                      name="contacto_email_2"
                      value={formik.values.contacto_email_2}
                      onChange={formik.handleChange}
                      error={formik.errors.contacto_email_2}
                      helperText={formik.errors.contacto_email_2}
                    ></TextField>
                  </TableCell>
                  <TableCell align="center">
                    <TextField
                      sx={{
                        ".MuiOutlinedInput-notchedOutline": {
                          borderColor: "black",
                          width: "100%",
                        },
                        ".MuiInputBase-root": {
                          color: "black",
                        },
                      }}
                      InputLabelProps={{
                        style: { color: "black" },
                      }}
                      InputProps={{
                        type: "text",
                      }}
                      name="provincia"
                      value={formik.values.provincia}
                      onChange={formik.handleChange}
                      error={formik.errors.provincia}
                      helperText={formik.errors.provincia}
                    ></TextField>
                  </TableCell>
                  <TableCell align="center">
                    <Button
                      variant="contained"
                      ml="auto"
                      type="submit"
                      my={2}
                      sx={{ marginLeft: "20px" }}
                    >
                      Modificar
                    </Button>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <Stack sx={labelStyleError} spacing={2}>
              <Alert severity="warning">{labelError}</Alert>
            </Stack>
            <Stack sx={labelStyleSuccess} spacing={2}>
              <Alert severity="success">{labelSuccess}</Alert>
            </Stack>
          </Box>
        </form>
      ) : (
        <Box px={2}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table" id="informes">
            <TableHead>
              <TableRow>
                <TableCell align="left">Terminal</TableCell>
                <TableCell align="left">Representante</TableCell>
                <TableCell align="center">Contacto Tel 1</TableCell>
                <TableCell align="center">Contacto Tel 2</TableCell>
                <TableCell align="center">Contacto email</TableCell>
                <TableCell align="center">Contacto recursos humanos </TableCell>
                <TableCell align="center">Provincia</TableCell>
                <TableCell align="center">Editar</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow
                key={dataTerminal.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell align="left">{dataTerminal.nombre}</TableCell>
                <TableCell align="left">{dataTerminal.representante}</TableCell>
                <TableCell align="center">
                  {dataTerminal.contacto_tel}
                </TableCell>
                <TableCell align="center">
                  {dataTerminal.contacto_cel}
                </TableCell>
                <TableCell align="center">
                  {dataTerminal.contacto_email_1}
                </TableCell>
                <TableCell align="center">
                  {dataTerminal.contacto_email_2}
                </TableCell>
                <TableCell align="center">{dataTerminal.provincia}</TableCell>
                <TableCell align="center">
                  <Link to={`/supervisor/datos-terminal/editar/IA`}>
                    {" "}
                    <EditIcon />
                  </Link>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Box>
      )}
      <br></br>
      <Divider variant="h5" textAlign="left" sx={{
          fontFamily: 'Lato, sans-serif',
          color: '#0e315a',
          textShadow: '2px 2px 2px rgba(0, 0, 0, 0.25)'}} >
        Redes sociales{" "}
      </Divider>
      <Button
        onClick={closeEverything}
        sx={{ color: "#b22222", marginLeft: "95%" }}
      >
        <CloseIcon />
      </Button>
      <br></br>
      {edit === "2" ? (
        <form onSubmit={formik.handleSubmit}>
          <Box px={1}>
            <Table aria-label="simple table" id="informes">
              <TableHead>
                <TableRow>
                  <TableCell align="center">Facebook</TableCell>
                  <TableCell align="center">Instagram</TableCell>
                  <TableCell align="center">XTwitter</TableCell>
                  <TableCell align="center">Venta de pasajes</TableCell>
                  <TableCell align="center"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow
                  key={dataTerminal.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="center">
                    <TextField
                      sx={{
                        ".MuiOutlinedInput-notchedOutline": {
                          borderColor: "black",
                          width: "100%",
                        },
                      }}
                      InputLabelProps={{
                        style: { color: "black" },
                      }}
                      InputProps={{
                        type: "text",
                      }}
                      name="facebook"
                      value={formik.values.facebook}
                      onChange={formik.handleChange}
                      error={formik.errors.facebook}
                      helperText={formik.errors.facebook}
                    ></TextField>
                  </TableCell>
                  <TableCell align="center">
                    <TextField
                      sx={{
                        ".MuiOutlinedInput-notchedOutline": {
                          borderColor: "black",
                          width: "100%",
                        },
                        ".MuiInputBase-root": {
                          color: "black",
                        },
                      }}
                      InputLabelProps={{
                        style: { color: "black" },
                      }}
                      InputProps={{
                        type: "text",
                      }}
                      name="instagram"
                      value={formik.values.instagram}
                      onChange={formik.handleChange}
                      error={formik.errors.instagram}
                      helperText={formik.errors.instagram}
                    ></TextField>
                  </TableCell>
                  <TableCell align="center">
                    <TextField
                      sx={{
                        ".MuiOutlinedInput-notchedOutline": {
                          borderColor: "black",
                          width: "100%",
                        },
                        ".MuiInputBase-root": {
                          color: "black",
                        },
                      }}
                      InputLabelProps={{
                        style: { color: "black" },
                      }}
                      InputProps={{
                        type: "text",
                      }}
                      name="twitter"
                      value={formik.values.twitter}
                      onChange={formik.handleChange}
                      error={formik.errors.twitter}
                      helperText={formik.errors.twitter}
                    ></TextField>
                  </TableCell>
                  <TableCell align="center">
                    <TextField
                      sx={{
                        ".MuiOutlinedInput-notchedOutline": {
                          borderColor: "black",
                          width: "100%",
                        },
                        ".MuiInputBase-root": {
                          color: "black",
                        },
                      }}
                      InputLabelProps={{
                        style: { color: "black" },
                      }}
                      InputProps={{
                        type: "text",
                      }}
                      name="venta_pasajes"
                      value={formik.values.venta_pasajes}
                      onChange={formik.handleChange}
                      error={formik.errors.venta_pasajes}
                      helperText={formik.errors.venta_pasajes}
                    ></TextField>
                  </TableCell>
                  <TableCell align="left">
                    <Button
                      variant="contained"
                      ml="auto"
                      type="submit"
                      my={2}
                      sx={{ marginLeft: "20px" }}
                    >
                      Modificar
                    </Button>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <Stack sx={labelStyleError} spacing={2}>
              <Alert severity="warning">{labelError}</Alert>
            </Stack>
            <Stack sx={labelStyleSuccess} spacing={2}>
              <Alert severity="success">{labelSuccess}</Alert>
            </Stack>
          </Box>
        </form>
      ) : (
        <Box px={1}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table" id="informes">
            <TableHead>
              <TableRow>
                <TableCell align="left">Facebook</TableCell>
                <TableCell align="left">Instagram</TableCell>
                <TableCell align="left">XTwitter</TableCell>
                <TableCell align="left">Venta de pasajes</TableCell>
                <TableCell align="left">Editar</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow
                key={dataTerminal.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell align="left">
                  <a
                    href={dataTerminal.facebook}
                    rel="noreferrer"
                    target="_blank"
                  >
                    {dataTerminal.facebook}
                  </a>
                </TableCell>
                <TableCell align="left">
                  <a
                    href={dataTerminal.instagram}
                    rel="noreferrer"
                    target="_blank"
                  >
                    {dataTerminal.instagram}
                  </a>
                </TableCell>
                <TableCell align="left">
                  <a
                    href={dataTerminal.twitter}
                    rel="noreferrer"
                    target="_blank"
                  >
                    {dataTerminal.twitter}
                  </a>
                </TableCell>
                <TableCell align="left">
                  <a
                    href={dataTerminal.venta_pasajes}
                    rel="noreferrer"
                    target="_blank"
                  >
                    {dataTerminal.venta_pasajes}
                  </a>
                </TableCell>
                <TableCell align="left">
                  <Link to={`/supervisor/datos-terminal/editar/RS`}>
                    {" "}
                    <EditIcon />
                  </Link>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Box>
      )}

      <br></br>
      <Divider variant="h5" textAlign="left" sx={{
          fontFamily: 'Lato, sans-serif',
          color: '#0e315a',
          textShadow: '2px 2px 2px rgba(0, 0, 0, 0.25)'}} >
        Locación e institucional{" "}
      </Divider>
      <Button
        onClick={closeEverything}
        sx={{ color: "#b22222", marginLeft: "95%" }}
      >
        <CloseIcon />
      </Button>
      <br></br>
      {edit === "3" ? (
        <form onSubmit={formik.handleSubmit}>
          <Box px={1}>
            <Table
              sx={{ minWidth: 650 }}
              aria-label="simple table"
              id="informes"
            >
              <TableHead>
                <TableRow>
                  <TableCell align="left">Ubicación</TableCell>
                  <TableCell align="left">Institucional</TableCell>
                  <TableCell align="center"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow
                  key={dataTerminal.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="left" sx={{ width: "20%" }}>
                    <TextField
                      sx={{
                        ".MuiOutlinedInput-notchedOutline": {
                          borderColor: "black",
                          width: "100%",
                        },
                        ".MuiInputBase-root": {
                          color: "black",
                        },
                      }}
                      InputLabelProps={{
                        style: { color: "black" },
                      }}
                      InputProps={{
                        type: "text",
                      }}
                      name="como_llegar"
                      value={formik.values.como_llegar}
                      onChange={formik.handleChange}
                      error={formik.errors.como_llegar}
                      helperText={formik.errors.como_llegar}
                    ></TextField>
                  </TableCell>
                  <TableCell align="left" sx={{ width: "100%" }}>
                    <Editor
                      editorState={description}
                      toolbarClassName="toolbarClassName"
                      wrapperClassName="wrapperClassName"
                      editorClassName="editorClassName"
                      onEditorStateChange={onEditorStateChange}
                      InputProps={{
                        type: "text",
                      }}
                    />
                    <textarea
                      style={{ display: "none" }}
                      disabled
                      name="institucional"
                      onChange={formik.handleChange}
                      ref={(val) =>
                        (formik.values.institucional = institucional)
                      }
                      value={draftToHtml(
                        convertToRaw(description.getCurrentContent())
                      )}
                    />
                  </TableCell>
                  <TableCell align="right">
                    <Button
                      variant="contained"
                      ml="auto"
                      type="submit"
                      my={2}
                      sx={{ marginLeft: "20px" }}
                    >
                      Modificar
                    </Button>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <Stack sx={labelStyleError} spacing={2}>
              <Alert severity="warning">{labelError}</Alert>
            </Stack>
            <Stack sx={labelStyleSuccess} spacing={2}>
              <Alert severity="success">{labelSuccess}</Alert>
            </Stack>
          </Box>
        </form>
      ) : (
        <Box px={1}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table" id="informes">
            <TableHead>
              <TableRow>
                <TableCell align="left">Ubicación</TableCell>
                <TableCell align="left">Institucional</TableCell>
                <TableCell align="center">Editar</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow
                key={dataTerminal.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell align="left">
                  <a
                    href={dataTerminal.como_llegar}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {" "}
                    <LocationOnIcon sx={{ color: "#b22222" }} /> Ubicación Maps
                  </a>
                </TableCell>
                <TableCell align="left">
                  {" "}
                  <MarkdownPreview markdown={dataTerminal.institucional} />
                </TableCell>
                <TableCell align="center">
                  <Link to={`/supervisor/datos-terminal/editar/LI`}>
                    {" "}
                    <EditIcon />
                  </Link>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Box>
      )}
      <br></br>
      <Divider variant="h5" textAlign="left" sx={{
          fontFamily: 'Lato, sans-serif',
          color: '#0e315a',
          textShadow: '2px 2px 2px rgba(0, 0, 0, 0.25)'}} >
        Logo institucional{" "}
      </Divider>
      <br></br>
      {edit === "4" ? (
        <Box px={1}>
          <TableBody>
            <TableRow>
              <CropsImage data={dataTerminal.logo} />
            </TableRow>
          </TableBody>
        </Box>
      ) : (
        <Box px={1}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table" id="informes">
            <TableBody>
              <TableRow
                key={dataTerminal.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell align="center" sx={{ borderRadius: "red" }}>
                  <Box
                    component="img"
                    sx={{
                      borderRadiuscolor: "#b22222",
                      height: 200,
                      width: 300,
                      maxHeight: { xs: 233, md: 167 },
                      maxWidth: { xs: 350, md: 250 },
                    }}
                    alt={dataTerminal.logo}
                    src={dataTerminal.logo}
                  />
                </TableCell>
                <TableCell align="left">
                  <Link to={`/supervisor/datos-terminal/editar/Logo`}>
                    {" "}
                    <EditIcon />
                  </Link>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Box>
      )}
    </TableContainer>
  );
}
