import React, { useState } from "react";
import axios from "axios";
import { Stack } from "@mui/system";
import { Button, Grid, TextField, Typography, Box } from "@mui/material";
import * as yup from "yup";
import BasicModal from "../modals/Modal";
import ModalRemember from "../modals/ModalRemember";
import { useFormik } from "formik";

import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const validationSchema = yup.object({
  password: yup.string().required("Campo requerido"),
  nuevaClave: yup
    .string()
    .required("Campo requerido")
    .trim()
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[\W_])(?=.*[0-9]).{8,}$/,
      "La contraseña debe contener al menos una minúscula, una mayúscula, un caracter especial y ser mayor o igual a 8 caracteres"
    ),
  nuevaClaveConfirmation: yup
    .string()
    .oneOf(
      [yup.ref("nuevaClave"), null],
      "Las contraseñas tienen que ser iguales"
    ),
});

const WritePassword = () => {
  const [openModal, setOpenModal] = useState(false);
  const [openModal2, setOpenModal2] = useState(false);
  const [openModalRemember, setOpenModalRemember] = useState(false);
  const token = window.sessionStorage.getItem("jwt");

  //Eye to see the password
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const functionOpenModal2 = () => {
    setOpenModal2(true);
    setTimeout(() => setOpenModal2(false), 3000);
  };

  const initialValues = {
    password: "",
    nuevaClave: "",
  };
  const url = `https://terminaldigital.com.ar/api/users/changePassword`;
  const config = { headers: { authorization: `Bearer ${token}` } };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const data = formik.values;
      const dataPassword = {
        password: data.password,
        nuevaClave: data.nuevaClave,
      };

      console.log("data to send:", dataPassword);

      axios
        .post(url, dataPassword, config)
        .then((res) => {
          if (res.status === 200) {
            setOpenModal(true);
          }
        })
        .catch(function (error) {
          console.log("Error send reset password:", error);
          functionOpenModal2();
        });
    },
  });

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: {xs: '72vh', md:'68vh'},
      }}
    >
      <Stack
        sx={{ background: "#F4F2EC", borderRadius: "25px", shadow: 4, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}
        my={4}
        mx={{ xs: 1, sm: 6 }}
        p={4}
        sm={6}
        m={10}
      >
        <form onSubmit={formik.handleSubmit}>
          <Grid
            item
            display={{ xs: "flex", sm: "flex" }}
            flexDirection={{ xs: "column", md: "row" }}
            alignItems="left"
            gap={2}
            xs={12}
            my={2}
          >
            <Typography variant="subtitle1" color="black" mb={{ xs: 1, sm: 0 }}>
              {" "}
              Contraseña actual:{" "}
            </Typography>
            <div>
              <TextField
                type={showPassword ? "text" : "password"}
                name="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                sx={{ marginLeft: { xs: 0, md: 3 } }}
              ></TextField>
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                edge="end"
                sx={{ color: "black" }}
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </div>
          </Grid>
          <Grid
            item
            display={{ xs: "block", md: "flex" }}
            alignItems="center"
            gap={2}
            xs={12}
            md={8}
            my={2}
          >
            <Typography variant="subtitle1" color="black" mb={{ xs: 1, sm: 0 }}>
              Nueva Contraseña:
            </Typography>
            <div>
              <TextField
                type={showPassword ? "text" : "password"}
                name="nuevaClave"
                value={formik.values.nuevaClave}
                onChange={formik.handleChange}
                sx={{ marginLeft: { xs: 0, md: 3 } }}
              ></TextField>
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                edge="end"
                sx={{ color: "black" }}
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </div>
          </Grid>
          <Grid
            item
            display={{ xs: "flex", sm: "flex" }}
            flexDirection={{ xs: "column", md: "row" }}
            alignItems="left"
            gap={2}
            xs={12}
            my={2}
          >
            <Typography variant="subtitle1" color="black" mb={{ xs: 1, sm: 0 }}>
              Confirmar contraseña:
            </Typography>
            <div>
              <TextField
                type={showPassword ? "text" : "password"}
                name="nuevaClaveConfirmation"
                value={formik.values.nuevaClaveConfirmation}
                onChange={formik.handleChange}
              ></TextField>
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                edge="end"
                sx={{ color: "black" }}
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </div>
          </Grid>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              width: '100%', // Asegura que el Box ocupe todo el ancho disponible
            }}
          >
            <button type="submit">Cambiar contraseña</button>
          </Box>
        </form>
      </Stack>
    </Box>
  );
};

export default WritePassword;
