import React, { useState } from "react";
import axios from "axios";
import { Button, Grid, Modal, TextField, Typography, styled, useTheme } from "@mui/material";
import * as yup from "yup";
import { useFormik } from "formik";
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";


const validationSchema = yup.object({
  nuevaClave: yup.string().required("Campo requerido").trim()
    .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[\W_])(?=.*[0-9]).{8,}$/, 'La contraseña debe contener al menos una minúscula, una mayúscula, un character especial y ser mayor o igual a 8 caracteres'),

  nuevaClaveConfirmation: yup
    .string()
    .oneOf(
      [yup.ref("nuevaClave"), null],
      "Las contraseñas tienen que ser iguales"
    ),
});

const WritePassword = () => {
  const token = window.sessionStorage.getItem("jwt");
  const theme = useTheme();
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = React.useState(true);
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const initialValues = {
    password: "",
    nuevaClave: "",
  };
  const url = `https://terminaldigital.com.ar/api/users/changePassword`;
  const config = { headers: { authorization: `Bearer ${token}` } };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const data = formik.values;

      const dataPassword = {
        password: data.password,
        nuevaClave: data.nuevaClave,
      };

      axios
        .post(url, dataPassword, config)
        .then((res) => {
          if (res.status === 200) {
            toast.success('Contraseña modificada. Se cerrará la sesión para actualizar datos', {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 2000,
              onClose: () => closeSession()
            });
          }
        })
        .catch(function (error) {
          console.log("Error send reset password:", error);
          toast.error('Error al modificar la contraseña', {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2000,
          });
        });
    },
  });

  const closeSession = () => {
    axios
      .get("https://terminaldigital.com.ar/api/auth/logout", {
        headers: { authorization: `Bearer ${token}` },
      })
      .then((res) => res.json())
      .catch((err) => console.log("Error en logout:", err));
    window.sessionStorage.removeItem("jwt");
    navigate("/login");
    window.location.reload();
  };

  const StyledModal = {
      background: "#F4F2EC",
      borderRadius: "25px",
      shadow: 4,
      padding: 4,
      my: 4,
      mx: 1,
      width: '80vw', 
      height: '90vh', 
      margin: 'auto',
      [theme.breakpoints.up("md")]: {
        width: '100vw',
        height: '100vh' 
      },
  };

  const StyledTitle = styled(Typography)(() => ({
    textAlign: 'center',
    color: "black",
  }))


  return (
    <Modal open={true} sx={StyledModal}>
      <form onSubmit={formik.handleSubmit}>
        <StyledTitle fontSize={{ xs: "2rem", md: "2.3rem" }} marginTop={{ xs: 2, md: 10}}>
          ¡Atención!
        </StyledTitle>
        <StyledTitle fontSize={{ xs: "1rem", md: "1.3rem" }} marginTop={{ xs: 2, md: 4 }}>
          Debes establecer nueva contraseña para continuar
        </StyledTitle>
        <Grid container my={4} sx={{ textAlign: "center", alignItems: "center", justifyContent: 'center', backgroundColor: "rgba (0, 0, 0, 0) " }} >
          <Grid item
            display={{ xs: "block", md: "flex" }}
            alignItems="center"
            gap={2}
            xs={12}
            md={8}
            my={2}>
            <TextField
              fullWidth
              label="Contraseña inicial del mail"
              sx={{
                ".MuiOutlinedInput-notchedOutline": {
                  borderColor: "black",
                },
                ".MuiInputBase-root": {
                  color: "black",
                },
                "& .MuiSvgIcon-root": {
                  color: "black",
                },
                minWidth: "0px",
              }}
              InputLabelProps={{
                style: {
                  color: "black",
                },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      edge="end"
                      sx={{ color: "black" }}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              type={showPassword ? "text" : "password"}
              name="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.errors.password}
              helperText={formik.errors.password}
              autoComplete="off"
              onPaste={(e) => {
                e.preventDefault();
                return false;
              }}
            ></TextField>
          </Grid>
          <Grid
            item
            display={{ xs: "block", md: "flex" }}
            gap={2}
            xs={12}
            md={8}
            my={2}
          >
            <TextField
              fullWidth
              label="Nueva contraseña"
              id="outlined-adornment-password"
              type={showPassword ? "text" : "password"}
              name="nuevaClave"
              value={formik.values.nuevaClave}
              onChange={formik.handleChange}
              error={formik.errors.nuevaClave}
              helperText={formik.errors.nuevaClave}
              sx={{
                ".MuiOutlinedInput-notchedOutline": {
                  borderColor: "black",
                },
                ".MuiInputBase-root": {
                  color: "black",
                },
                "& .MuiSvgIcon-root": {
                  color: "black",
                },
                minWidth: "200px",
                color: "black",
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      edge="end"
                      sx={{ color: "black" }}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              autoComplete="off"
              onPaste={(e) => {
                e.preventDefault();
                return false;
              }}
            />
          </Grid>
          <Grid
            item
            display={{ xs: "block", md: "flex" }}
            alignItems="center"
            gap={2}
            xs={12}
            md={8}
            my={2}
          >
            <TextField
              fullWidth
              label="Confirmar contraseña"
              sx={{
                ".MuiOutlinedInput-notchedOutline": {
                  borderColor: "black",
                },
                ".MuiInputBase-root": {
                  color: "black",
                },
                "& .MuiSvgIcon-root": {
                  color: "black",
                },
                minWidth: "200px",
                color: "black",
              }}
              id="outlined-adornment-password2"
              type={showPassword ? "text" : "password"}
              value={formik.values.nuevaClaveConfirmation}
              name="nuevaClaveConfirmation"
              onChange={formik.handleChange}
              error={formik.errors.nuevaClaveConfirmation}
              helperText={formik.errors.nuevaClaveConfirmation}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      edge="end"
                      sx={{ color: "black" }}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              autoComplete="off"
              onPaste={(e) => {
                e.preventDefault();
                return false;
              }}
            />
          </Grid>
          <Grid item xs={12} pt={6}>
            <Button variant="contained" type="submit" my={2}>
              Establecer contraseña
            </Button>
          </Grid>
        </Grid>
      </form>
    </Modal>
  );
};

export default WritePassword;
