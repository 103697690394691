import React, { useState, useEffect } from 'react'
import { Button, Grid, MenuItem, TextField, Typography, Box, Dialog, DialogTitle, DialogContent } from '@mui/material'
import { Stack } from '@mui/system'
import { useParams } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { useFormik } from 'formik';
import * as yup from 'yup';
import axios from 'axios'
import BasicModal from '../../components/modals/Modal';
import { toast } from 'react-toastify';
import { Link } from "react-router-dom";
import ReplyIcon from "@mui/icons-material/Reply";




const validationSchema = yup.object({
    nombre: yup.string().required('Campo requerido'),
    apellido: yup.string().required('Campo requerido'),
    rol: yup.number().required('Campo requerido'),
    usuario: yup.string().required('Campo requerido'),
    email: yup.string().email('Ingrese un email válido').required('Campo requerido'),
    telefono: yup.number().required('Campo requerido'),
});

const FormEditUser = () => {
    const [roles, setRoles] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [formValues, setFormValues] = useState(null);
    const navigate = useNavigate();
    const params = useParams();
    const id = params.id;
    const token = sessionStorage.getItem('jwt');

    const userInitial = {
        nombre: "",
        apellido: "",
        rol: "",
        usuario: "",
        password: "",
        estado_password: "",
        email: "",
        telefono: "",
    };

    useEffect(() => {
        getUser();
    }, [id, token]);

    const getUser = async () => {
        try {
            const response = await axios.get(`https://terminaldigital.com.ar/api/users/getUser/${id}`, { headers: { "authorization": `Bearer ${token}` } });
            setRoles(response.data.rolesDisponibles);
            setFormValues(response.data.usuarioAEnviar);
        } catch (error) {
            toast.error('Error al obtener los datos del usuario');
        }
    };

    const formik = useFormik({
        initialValues: formValues || userInitial,
        validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit: (values) => {
            modifyUser(values);
        },
    });

    const modifyUser = async (values) => {
        try {
            const response = await axios.patch(`https://terminaldigital.com.ar/api/users/modifyUser/${id}`, formik.values, { headers: { "authorization": `Bearer ${token}` } });
            if (response.status === 200) {
                toast.success(`Usuario ${formik.values.usuario} editado con éxito`);
                navigate("/usuarios");
            }
        } catch (error) {
            const mensaje = error.response.data.message;
            const mensajeCapitalizado = mensaje.charAt(0).toUpperCase() + mensaje.slice(1);
            toast.error(mensajeCapitalizado);
        }
    };


    return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Stack sx={{ background: "#F4F2EC", borderRadius: "25px", shadow: 4 }} my={4} mx={{ xs: 1, sm: 6 }} p={4} sm={6} width={{ xs: "60vw", md: "50vw" }} height={{ xs: "65vh", md:"75vh"}}>
                <form onSubmit={formik.handleSubmit}>
                    <Link to="/usuarios">
                        <Button
                            sx={{
                                color: "#b22222",
                                marginLeft: { xs: "85%", sm: "90%", md: "93%" },
                            }}
                            variant="outlined"
                            color="error"
                            position="fixed"
                        >
                            <ReplyIcon />
                        </Button>
                    </Link>
                    <Typography
                        sx={{
                            fontSize: {xs: "1.5rem", lg: "2rem" },
                            fontFamily: 'Lato, sans-serif',
                            color: '#0e315a',
                            textShadow: '2px 2px 2px rgba(0, 0, 0, 0.25)',
                            display: 'flex',
                            justifyContent: 'start',
                            marginTop: {xs: "0%", md:"-5%"}
                        }}
                    >
                        Editar usuario
                    </Typography>
                    <Grid container my={4}>
                        <Box marginLeft={{xs: 0, md: 27}}>
                            <Grid item display={{ xs: 'block', sm: 'flex' }} alignItems='center' gap={2} xs={12} sm={6} md={12} my={2}>
                                <TextField
                                    label='Nombre'
                                    sx={{
                                        '.MuiOutlinedInput-notchedOutline': {
                                            borderColor: 'black'
                                        },
                                        '.MuiInputBase-root': {
                                            color: 'black'
                                        },
                                        width: { xs: "60vw", md: '20vw' }
                                    }}
                                    InputProps={{
                                        type: "text"
                                    }}
                                    InputLabelProps={{
                                        style: { color: 'black' },
                                    }}
                                    value={formik.values.nombre}
                                    name='nombre'
                                    onChange={formik.handleChange}
                                    error={formik.errors.nombre}
                                    helperText={formik.errors.nombre}
                                />

                            </Grid>
                            <Grid item display={{ xs: 'block', md: 'flex' }} alignItems='center' gap={2} xs={12} sm={6} md={12} my={2}>
                                <TextField
                                    sx={{
                                        '.MuiOutlinedInput-notchedOutline': {
                                            borderColor: 'black'
                                        },

                                        '.MuiInputBase-root': {
                                            color: 'black'
                                        },
                                        width: { xs: "60vw", md: '20vw' }
                                    }}
                                    InputLabelProps={{
                                        style: { color: 'black' },
                                    }}
                                    label='Apellido'
                                    name='apellido'
                                    value={formik.values.apellido}
                                    onChange={formik.handleChange}
                                    error={formik.errors.apellido}
                                    helperText={formik.errors.apellido}
                                />
                            </Grid>
                            <Grid item display={{ xs: 'block', sm: 'flex' }} alignItems='center' gap={2} xs={12} sm={6} md={12} my={2}>
                                <TextField
                                    label='Usuario'
                                    sx={{
                                        '.MuiOutlinedInput-notchedOutline': {
                                            borderColor: 'black'
                                        },
                                        '.MuiInputBase-root': {
                                            color: 'black'
                                        },
                                        width: { xs: "60vw", md: '20vw' }
                                    }}
                                    InputLabelProps={{
                                        style: { color: 'black' },
                                    }}
                                    InputProps={{
                                        type: "text",
                                    }}
                                    name='usuario'
                                    value={formik.values.usuario}
                                    onChange={formik.handleChange}
                                    error={formik.errors.usuario}
                                    helperText={formik.errors.usuario}
                                />
                            </Grid>
                            <Grid item display={{ xs: 'block', md: 'flex' }} alignItems='center' gap={2} xs={12} md={12} my={2}>
                                <TextField
                                    select
                                    label='Seleccione Rol'
                                    sx={{
                                        '.MuiOutlinedInput-notchedOutline': {
                                            borderColor: 'black'
                                        },
                                        '.MuiInputBase-root': {
                                            color: 'black'
                                        },
                                        '& .MuiSvgIcon-root': {
                                            color: 'black',
                                        },
                                        minWidth: '200px',
                                        width: { xs: "60vw", md: '20vw' }
                                    }}
                                    InputLabelProps={{
                                        style: { color: 'black' },
                                    }}
                                    name='rol'
                                    value={formik.values.rol}
                                    onChange={formik.handleChange}
                                    error={formik.errors.rol}
                                    helperText={formik.errors.rol}
                                >
                                    {roles?.map((rol) =>
                                        <MenuItem key={rol.rol} value={rol.id} selected={true}> {rol.rol} </MenuItem>
                                    )}
                                    {/* <MenuItem value={'default'} disabled >Seleccione una opcion</MenuItem> */}
                                    {/* <MenuItem value={1}>Administracion</MenuItem>
                            <MenuItem value={2}>Recursos Humanos</MenuItem>
                            <MenuItem value={3}>Supervisor</MenuItem>
                            <MenuItem value={4}>Operador de Seguridad</MenuItem>
                            <MenuItem value={5}>Informes</MenuItem> */}
                                </TextField>
                            </Grid>
                            <Grid item display={{ xs: 'block', sm: 'flex' }} alignItems='center' gap={2} xs={12} sm={6} md={12} my={2}>
                                <TextField
                                    label='Correo electrónico'
                                    sx={{
                                        '.MuiOutlinedInput-notchedOutline': {
                                            borderColor: 'black'
                                        },
                                        '.MuiInputBase-root': {
                                            color: 'black'
                                        },
                                        width: {xs: "60vw", md:'20vw'}
                                    }}
                                    InputLabelProps={{
                                        style: { color: 'black' },
                                    }}
                                    InputProps={{
                                        type: "email",
                                    }}
                                    name='email'
                                    value={formik.values.email}
                                    onChange={formik.handleChange}
                                    error={formik.errors.email}
                                    helperText={formik.errors.email}
                                />
                            </Grid>
                            <Grid item display={{ xs: 'block', sm: 'flex' }} alignItems='center' gap={2} xs={12} sm={6} md={12} my={2}>
                                <TextField
                                    label='Telefono'
                                    sx={{
                                        '.MuiOutlinedInput-notchedOutline': {
                                            borderColor: 'black'
                                        },
                                        '.MuiInputBase-root': {
                                            color: 'black'
                                        },
                                        width: { xs: "60vw", md: '20vw' }
                                    }}
                                    InputLabelProps={{
                                        style: { color: 'black' },
                                    }}
                                    InputProps={{
                                        type: "number",
                                    }}
                                    name='telefono'
                                    value={formik.values.telefono}
                                    onChange={formik.handleChange}
                                    error={formik.errors.telefono}
                                    helperText={formik.errors.telefono}
                                />
                            </Grid>
                        </Box>
                        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', marginTop: 2 }}>
                            <Button variant="contained" type="submit" my={2}>Editar</Button>
                        </Grid>
                    </Grid>
                </form>
            </Stack>
        </div>
    )

}

export default FormEditUser