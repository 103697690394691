import React, { useState } from 'react';
import { Button, FilledInput, Box } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import axios from 'axios';
import { toast } from 'react-toastify';

const ForgotPasswordForm = ({ setOpenModalRemember }) => {
  const [email, setEmail] = useState('');
  const [formSent, setFormSent] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await axios.post('https://terminaldigital.com.ar/api/users/password-recovery', { email });
      if (response.status === 200) {
        if (response.data.message === "Usuario sin email") {
          toast.warning("No tienes un email registrado, Recursos Humanos se pondrá en contacto contigo.", {
            autoClose: 8000,
              closeButton: true,
          });;
        } else if (response.data.message === "Terminal sin email") {
          toast.warning("Debes de contactar a Recursos Humanos para que restauren tu contraseña.", {
            autoClose: 8000,
            closeButton: true,
          });
        }
        else {
          toast.success('Se ha enviado un correo con las instrucciones para recuperar tu contraseña', {
            autoClose: 8000,
            closeButton: true,
          });
        }
        setEmail('');
        setFormSent(true); 
        setTimeout(() => {
          setOpenModalRemember(false); 
        }, 5000);
      } 
    } catch (error) {
      toast.error("Ha ocurrido un error, por favor intenta de nuevo");
      console.error(error);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <FilledInput
        type="text"
        placeholder="Usuario"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        fullWidth
        required
        sx={{ marginBottom: "16px" }}
      />
      <Box display="flex" justifyContent="center" marginBottom="16px">
        <Button type="submit" variant="contained" fullWidth>
          Recuperar contraseña
        </Button>
        {formSent} 
      </Box>
    </form>
  );
};

export default ForgotPasswordForm;