import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";

import {
  Box,
  Button,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  TextField,
} from "@mui/material";

import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useSelector } from "react-redux";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import Tooltip from '@mui/material/Tooltip';
import { format } from 'date-fns';
import Skeleton from "@mui/material/Skeleton";
import { styled } from '@mui/system';
import { Margin } from "@mui/icons-material";



export default function TableApplicationPass() {
  const token = window.sessionStorage.getItem("jwt");
  const [users, setUsers] = useState([]);
  const [solicitud, setSolicitud] = useState("");

  const [fechaInicio, setFechaInicio] = useState(new Date());
  const [fechaFin, setFechaFin] = useState(new Date());

  const [shouldFetch, setShouldFetch] = useState(true);

  const count = useSelector((state) => state.counter.value);

  const [paginaActual, setPaginaActual] = useState(0);
  const [elementosPorPagina, setElementosPorPagina] = useState(10);
  const [resultadosBusqueda, setResultadosBusqueda] = useState(users);

  const handlePageChange = (event, newPage) => {
    setPaginaActual(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setElementosPorPagina(parseInt(event.target.value, 10));
    setPaginaActual(0);
  };

  useEffect(() => {
    if (shouldFetch) {
      const token = sessionStorage.getItem("jwt");
      const config = { headers: { authorization: `Bearer ${token}` } };
      const fechaInicioDate = new Date(fechaInicio);
      const fechaFinDate = new Date(fechaFin);
      const url = `https://terminaldigital.com.ar/api/users/usersToApplication?page=${count}&fechaInicio=${format(fechaInicioDate, 'yyyy-MM-dd')}&fechaFin=${format(fechaFinDate, 'yyyy-MM-dd')}`;

      axios
        .get(url, config)
        .then((data) => {
          setUsers(data.data.solicitudes.rows);
        })
        .catch((error) => {
          console.log("error users", error);
        });
      setShouldFetch(false);
    }
  }, [count, fechaInicio, fechaFin, shouldFetch]);


  const StickyHeader = styled(TableHead)(({ theme }) => ({
    position: 'sticky',
    top: 0,
    zIndex: theme.zIndex.appBar - 1,
    backgroundColor: theme.palette.background.paper,
  }));

  const StickyFooter = styled(TableFooter)(({ theme }) => ({
    position: 'sticky',
    bottom: 0,
    zIndex: theme.zIndex.appBar - 1,
    backgroundColor: theme.palette.background.paper,
  }));

  const StyledButton = styled(Button)`
  background-color: #0e315a;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 10px;
  margin: 4px 2px 0 20px;
  cursor: pointer;
  vertical-align: middle; 
  &:hover {
    background-color: #4CAF50; 
  }
`;

  return (
    <Stack direction='row' justifyContent={{ xs: 'center', sm: 'flex-end' }} pr={{ xs: 0, sm: 4 }} pb={2}>
      <TableContainer component={Paper} sx={{ borderRadius: "10px", maxHeight: 600, overflowY: 'auto' }}>
        <Box px={4}>
          <Box
            px={4}
            display="flex"
            alignItems="center"
            marginTop={4}
            marginBottom={4}
          >
            <Grid item xs={12} sm={12} p={4}>
              <Typography
                sx={{
                  fontSize: { xs: "1.1rem", lg: "2rem" },
                  fontFamily: 'Lato, sans-serif',
                  color: '#0e315a',
                  textShadow: '2px 2px 2px rgba(0, 0, 0, 0.25)',
                  display: 'flex',
                  justifyContent: 'center',
                  width: '100vw',
                  ml: "-90px"
                }}
              >
                Solicitudes de cambio de contraseña
              </Typography>
            </Grid>
          </Box>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            marginBottom={4}
          >
            <Grid item xs={12} sm={12} display="flex" flexDirection="row" justifyContent="center">
              <TextField
                id="fecha-inicio"
                label="Fecha de inicio"
                type="date"
                value={format(fechaInicio, 'yyyy-MM-dd')}
                onChange={(e) => setFechaInicio(new Date(e.target.value + 'T00:00:00'))}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  max: format(new Date(), 'yyyy-MM-dd'),
                }}
                sx={{ width: { xs: "20%", sm: "auto" }, marginLeft: { xs: 10, sm: 0 } }}
              />
              <TextField
                id="fecha-fin"
                label="Fecha de fin"
                type="date"
                value={format(fechaFin, 'yyyy-MM-dd')}
                onChange={(e) => setFechaFin(new Date(e.target.value + 'T00:00:00'))}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  max: format(new Date(), 'yyyy-MM-dd'),
                }}
                sx={{ width: { xs: "20%", sm: "auto" }, marginLeft: { xs: 1, sm: 2 } }}
              />
              <StyledButton onClick={() => setShouldFetch(true)}>Buscar</StyledButton>
            </Grid>
          </Box>
          <Table sx={{ minWidth: 650 }} aria-label="simple table" id="rrhh">
            <StickyHeader>
              <TableRow>
                <TableCell style={{ position: 'sticky', top: 0 }}>Nº</TableCell>
                <TableCell style={{ position: 'sticky', top: 0 }} align="center">Solicitud</TableCell>
                <TableCell style={{ position: 'sticky', top: 0 }} align="center">Motivo</TableCell>
                <TableCell style={{ position: 'sticky', top: 0 }} align="center">Usuario Solicitante</TableCell>
                <TableCell style={{ position: 'sticky', top: 0 }} align="center">Fecha de solicitud</TableCell>
                <TableCell style={{ position: 'sticky', top: 0 }} align="center">Mail</TableCell>{" "}
                <TableCell style={{ position: 'sticky', top: 0 }} align="center">Recibe</TableCell>
              </TableRow>
            </StickyHeader>
            <TableBody>
              {users?.length > 0 ? (
                users?.map((row) => (
                  <TableRow
                    key={row.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.id}{" "}
                    </TableCell>
                    {
                      row.estado === 0 ? (
                        <TableCell align="center">
                          <Box display="flex" alignItems="center">
                            <CheckCircleOutlineIcon sx={{ color: "green" }} />
                            <span> Solicitud enviada</span>
                          </Box>
                        </TableCell>
                      ) : row.usuario.estado_password === 0 && row.estado === 1 ? (
                        <TableCell align="center">
                          <Box display="flex" alignItems="center">
                            <CancelIcon sx={{ color: "red" }} />
                            <span>Sin cambio de clave primaria</span>
                          </Box>
                        </TableCell>
                      ) : (
                        <TableCell align="center"> - </TableCell>)
                    }
                    <TableCell align="center">{row.motivo}</TableCell>
                    <TableCell align="center">{row.solicitante}</TableCell>
                    <TableCell align="center">{row.createdAt}</TableCell>
                    {row.usuario.email === null ? (
                      <TableCell align="center">
                        Usuario sin email, verificar
                      </TableCell>) : (
                      <TableCell align="center">
                        {row.usuario.email}
                      </TableCell>)}
                    <TableCell align="center">
                      {row.recibe}
                    </TableCell>
                  </TableRow>
                ))) : (
                Array.from(new Array(3)).map((_, index) => (
                  <TableRow key={index}>
                    {Array.from(new Array(7)).map((_, cellIndex) => (
                      <TableCell key={cellIndex} align="center">
                        <Skeleton />
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              )}
            </TableBody>
            <StickyFooter>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 100, 1000]}
                count={resultadosBusqueda.length}
                rowsPerPage={elementosPorPagina}
                page={paginaActual}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleRowsPerPageChange}
                labelRowsPerPage="Filas por página"
              />
            </StickyFooter>
          </Table>
        </Box>
      </TableContainer>
    </Stack>
  );
}
