import React, { useState, useEffect } from "react";
import axios from "axios";
import { useFormik } from 'formik';
import {
  Autocomplete,
  Button,
  TextField,
  Typography,
  Dialog,
  ListItemText,
  ListItem,
  List,
  Divider,
  AppBar,
  Toolbar,
  IconButton,
  Slide,
  Box,
  useMediaQuery,
  Stack,
} from "@mui/material";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  status: {
    marginLeft: 20,
  },
  occupied: {
    color: 'red',
  },
  free: {
    color: 'green',
  },
});

import CloseIcon from "@mui/icons-material/Close";

const inputStyle = {
  ".MuiOutlinedInput-notchedOutline": {
    borderColor: "black",
    transition: "250ms all ease",
  },
  ".MuiInputBase-root": {
    color: "black",
    transition: "250ms all ease",
  },
  ".MuiSvgIcon-root": {
    color: "black",
  },
  ".css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root": {
    color: "black",
  },
  "input:hover": {
    background: "transparent",

  },
  ".MuiInputBase-root:hover ": {


    boxShadow: " inset 0 0 9px rgb(63, 100, 143)",
  },
  ".MuiInputBase-root:hover .MuiSvgIcon-root": {
    color: "rgb(19, 46, 77)",
    border: "none",
  },
  ".css-md26zr-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
  {
    border: "none",
  },
  ".css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
  {
    borderColor: "black",
  },
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DialogPlatforms({ dataModal, openDialog, openFalse, editadoFalse }) {

  let plataforma = null;

  if (dataModal && dataModal.plataforma) {

    plataforma = dataModal.plataforma;

  }

  const classes = useStyles();
  const token = sessionStorage.getItem("jwt");
  const [open, setOpen] = useState(false);
  const [dataDropdown, setDataDropdown] = useState([]);
  const [valueServicio, setValueServicio] = useState(null);
  const [initialValues, setInitialValues] = useState({});

  const getInitialPlatform = () => {
    return {
      plataforma: "",
      disponible: "",
      servicios_id: "",
    };
  };


  const getEditPlatform = () => {
    return {
      plataforma: plataforma,
      disponible: dataModal?.disponible,
      servicios_id: dataModal?.servicios_id,
    };
  };

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("https://terminaldigital.com.ar/api/informes/dataDropdown", { headers: { authorization: `Bearer ${token}` } });
        const serviciosFiltrados = response.data.servicios.filter(servicio => servicio.id !== 5);
        setDataDropdown(serviciosFiltrados);
      } catch (error) {
        console.error("Error fetching data:", error);
        toast.error('Hubo un error al obtener los datos', {
          position: toast.POSITION.TOP_CENTER
        });
      }
    };

    fetchData();
  }, [token]);


  useEffect(() => {
    setOpen(openDialog);
    if (dataModal) {
      formik.setValues(getEditPlatform());
    } else {
      formik.setValues(getInitialPlatform());
    }
  }, [openDialog, dataModal]);

  const formik = useFormik({
    initialValues: {},
    onSubmit: async (values) => {
      if (JSON.stringify(values) !== JSON.stringify(initialValues)) {
        try {
          const response = await axios.patch(`https://terminaldigital.com.ar/api/plataformas/${dataModal.id}`, values, { headers: { authorization: `Bearer ${token}` } });
          if (response.status === 200) {
            toast.success('Plataforma actualizada exitosamente', {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setValueServicio(null);
            formik.resetForm();
            openFalse(false);
            editadoFalse(true);
          }
        } catch (error) {
          console.error("Error updating domain:", error);
          toast.error('Ha ocurrido un error al guardar los cambios', {
            position: toast.POSITION.TOP_RIGHT
          });
        }
      } else {
        toast.info('No se realizaron cambios', {
          position: toast.POSITION.TOP_RIGHT
        });
      }
    },
  });

  const matches = useMediaQuery(theme => theme.breakpoints.up('lg'));

  const getStatus = () => {
    if (dataModal?.disponible === 0) {
      return <span className={`${classes.status} ${classes.occupied}`}>Ocupada</span>;
    } else {
      return <span className={`${classes.status} ${classes.free}`}>Libre</span>;
    }
  };

  return (
    <Stack sx={{ background: "#0e315a", shadow: 4 }} my={0} mx={0} p={4} sm={6} pl={{ xs: 4, sm: 4 }}>
      <Dialog
        fullScreen
        open={open}
        onClose={() => openFalse(false)}
        TransitionComponent={Transition}
      >
        <form onSubmit={formik.handleSubmit}>
          <AppBar sx={{ position: "relative" }}>
            <Toolbar sx={{ backgroundColor: "#0e315a" }} >
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => openFalse(false)}
                aria-label="close"
              >
                <CloseIcon sx={{ color: "black" }} />
              </IconButton>
              <Typography
                sx={{ ml: 2, flex: 1, display: { xs: 'none', sm: 'block', color: 'white' } }}
                variant="h6"
                component="div"
              >
                Estado: {getStatus()}
              </Typography>
              <Typography
                sx={{
                  ml: { xs: 9, sm: 31, md: -16, lg: -15 },
                  flex: { xs: 15, md: 1 },
                  color: "white"
                }}
                variant="h6"
                component="div"
              >

                Plataforma: <span style={{ marginLeft: 10 }}>
                  {dataModal?.plataforma}
                </span>
              </Typography>
              <Button
                autoFocus
                color="inherit"
                type="submit"
                sx={{ display: { xs: "none", md: "block", color: "white" } }}
              >
                Guardar
              </Button>
            </Toolbar>
          </AppBar>
          <List>
            <ListItem sx={{ display: { xs: "block", sm: "none" } }}>
              <ListItemText
                primary="Estado"
                secondary={
                  <Typography
                    sx={{ ml: 2, flex: 1, display: { xs: 'none', sm: 'block', color: 'white' } }}
                    variant="h6"
                    component="div"
                  >
                    Estado: {getStatus()}
                  </Typography>
                }
              />
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemText
                primary="Plataforma"
                secondary={
                  <TextField
                    fullWidth
                    id="plataforma"
                    name="plataforma"
                    type="text"
                    value={formik.values.plataforma}
                    onChange={formik.handleChange}
                    error={formik.errors.plataforma}
                    helperText={formik.errors.plataforma}
                    sx={inputStyle}
                    label={matches ? "La plataforma puede ser un número o una letra que haga referencia a su ubicación" : plataforma}
                    variant="outlined"
                  />
                }
              />
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemText
                primary="Servicio"
                secondary={
                  <Box component="div">
                    <Autocomplete
                      fullWidth
                      clearOnEscape
                      value={valueServicio}
                      options={dataDropdown || []}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      sx={inputStyle}
                      onChange={(event, newValue) => {
                        // eslint-disable-next-line no-unused-expressions
                        newValue === null
                          ? setValueServicio(newValue) +
                          formik.setFieldValue(
                            "servicios_id",
                            newValue
                          )
                          : setValueServicio(newValue) +
                          formik.setFieldValue(
                            "servicios_id",
                            newValue.id
                          );
                      }}
                      getOptionLabel={(option) => option.tipo_servicio}
                      renderInput={(params) => (
                        <TextField
                          error={formik.errors.servicios_id}
                          {...params}
                          label={dataModal.plataforma_servicio.tipo_servicio}
                          style={{
                            color: "black",
                          }}
                          sx={{
                            ".css-1sumxir-MuiFormLabel-root-MuiInputLabel-root": {
                              color: "black",
                            },
                          }}
                        />
                      )}
                    />
                  </Box>
                }
              />
            </ListItem>
            <Divider />
            <Box mt={2}>
              <Button
                autoFocus
                variant="contained"
                color="primary"
                type="submit"
                sx={{ ml: { xs: 35, sm: 82, md: 113, lg: 165 } }}
              >
                Guardar
              </Button>
            </Box>
            <Box mt={2} />
          </List>
        </form>
      </Dialog>
    </Stack>
  );

}
